import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BackendResponse } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class DemoDataService {
  constructor(protected http: HttpClient) {}

  getData() {
    return this.http.get<BackendResponse<{ steps: Record<string, unknown> }>>(
      `/api/v1/service/ddb`,
      {
        withCredentials: true,
      }
    );
  }
}
