import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { entityName } from '@qtek/shared/models';

import { HttpEntityManager } from './../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class PersonService extends HttpEntityManager<any, any> {
  entityName: entityName = 'prs';

  constructor(protected http: HttpClient) {
    super(true);
  }

  updatePerson(payload: any, additionalPayload: any = {}) {
    return super.put(payload, null, additionalPayload);
  }
}
