import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebSocketService } from '@qtek/core/websockets-core';
import { DashboardTiles } from '@qtek/shared/models';
import { Observable } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MenuService } from './menu.service';

import {
  ConnectDashboardAction,
  DisconnectDashboardAction,
  LoadDashboardAction,
  LoadDashboardSuccessAction,
  LoadMainSuccessAction,
  LoadSetupSuccessAction,
  MenuActionTypes,
  UpdateChartDataset,
} from './menu.actions';

@Injectable()
export class MenuEffects {
  main$: Observable<LoadMainSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActionTypes.LOAD_MAIN),
      switchMap(() =>
        this.menuService
          .getMainMenu()
          .pipe(map(({ res }) => new LoadMainSuccessAction(res.children)))
      )
    )
  );

  setup$: Observable<LoadSetupSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActionTypes.LOAD_SETUP),
      switchMap(() =>
        this.menuService
          .getSetupMenu()
          .pipe(map(({ res }) => new LoadSetupSuccessAction(res.children)))
      )
    )
  );

  dashboard$: Observable<LoadDashboardSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadDashboardAction>(MenuActionTypes.LOAD_DASHBOARD),
      switchMap(() =>
        this.menuService
          .getDashboardMenu()
          .pipe(
            map(
              (tiles: DashboardTiles) => new LoadDashboardSuccessAction(tiles)
            )
          )
      )
    )
  );

  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConnectDashboardAction>(MenuActionTypes.CONNECT_DASHBOARD),
      mergeMap(({ payload }) =>
        this.webSocketService.getWebSocket$().pipe(
          filter(data => data.mysid === payload),
          takeUntil(
            this.actions$.pipe(
              ofType<DisconnectDashboardAction>(
                MenuActionTypes.DISCONNECT_DASHBOARD
              )
            )
          )
        )
      ),
      map((data: any) => {
        switch (data.op) {
          case 'upds':
            return new UpdateChartDataset({
              data: (data as any).res,
              id: data.mysid,
            });
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  subscribe$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConnectDashboardAction>(MenuActionTypes.CONNECT_DASHBOARD),
        tap(({ payload }) => {
          this.menuService.subscribeDashboard({
            id: payload,
            mysid: payload,
            s: 'report',
          });
        })
      ),
    { dispatch: false }
  );

  disconnect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DisconnectDashboardAction>(MenuActionTypes.DISCONNECT_DASHBOARD),
        tap(({ payload }) =>
          this.menuService.unsubscribeDashboard({ mysid: payload })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private menuService: MenuService,
    private webSocketService: WebSocketService
  ) {}
}
