import {
  HttpBackend,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions, AuthState } from '@qtek/libs/store';
import { HTTP_BASE_URL } from '@qtek/shared/models';
import { checkRequestMetadata } from '@qtek/shared/utils';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, share, switchMap } from 'rxjs/operators';

import { QtHttpInterceptor } from '../qtek-interceptor';

@Injectable()
export class RevalidateInterceptor implements QtHttpInterceptor {
  readonly qtek = true;

  revalidateRequest = new HttpRequest(
    'GET',
    this.baseUrl + '/api/v1/service/refresh',
    undefined,
    {
      withCredentials: true,
    }
  );

  /**
   * Sometimes we send few request in short period. If user is not authenticated
   * then every requests will trigger revalidate
   */
  pendingRequest: null | Observable<HttpEvent<any>> = null;

  constructor(
    @Inject(HTTP_BASE_URL) private baseUrl: string,
    private backend: HttpBackend,
    private router: Router,
    private store: Store<AuthState>,
    private route: ActivatedRoute
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.startsWith(this.baseUrl) ||
      req.url.includes('/api/v1/service/logout')
    ) {
      return next.handle(req);
    }

    const { newReq, hasMetadata: skipRevalidateErrorHandling } =
      checkRequestMetadata(req, 'X-Skip-Revalidate-Error-Handling');

    return next.handle(newReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(
            newReq,
            next,
            skipRevalidateErrorHandling,
            error
          );
        }
        return throwError(() => new Error(error.error));
      })
    );
  }

  handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    skipRevalidateErrorHandling: boolean,
    error: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    if (this.pendingRequest === null) {
      this.pendingRequest = this.backend.handle(this.revalidateRequest).pipe(
        filter(event => event.type === HttpEventType.Response),
        share()
      );
    }
    return this.pendingRequest.pipe(
      switchMap(() => {
        this.pendingRequest = null;
        return next.handle(request);
      }),
      catchError(response => {
        this.pendingRequest = null;
        /**
         * Error handling for 400 status code in revalidation
         * request can be skipped with this flag. This is used primarely
         * in {@link AuthGuard} to get correct returnUrl from router.
         *
         * Attempt to get returnUrl in this interceptor will fail(return url will be empty string)
         * when user just started application on secured view, and he is not authenticated.
         */
        if (skipRevalidateErrorHandling) {
          return throwError(() => new Error(response));
        }

        /**
         * Revalidate requests failed. This means that some request to API
         * returned 401 status code and user have no revalidation token either.
         *
         * In this case user is not allowed to any secured route and should
         * be logged out(to restart any state that may be present). In addition
         * returnUrl query parametr should be set, so when user log in again,
         * it can start when he finished. It only works if user was authenticated,
         * but cookie expired.
         */
        const state = this.router.routerState.snapshot;
        const returnUrl = state.url !== '/' ? state.url : '';
        const queryParams = returnUrl ? { returnUrl } : null;
        const currentParams = this.route.snapshot.queryParams || {};
        this.store.dispatch(
          AuthActions.logout({
            queryParams: { ...queryParams, ...currentParams },
          })
        );

        /**
         * Rethrow original error.
         */
        return throwError(() => new Error(error.error));
      })
    );
  }
}
