import { NcinoActions, NcinoActionTypes } from './ncino.actions';

export interface State {
  ncino: any[];
}

const initialState: State = {
  ncino: [],
};

export function reducer(state = initialState, action: NcinoActions): State {
  switch (action.type) {
    case NcinoActionTypes.GET_NCINO_SUCCESS: {
      return {
        ...state,
        ncino: action.payload || [],
      };
    }

    default: {
      return state;
    }
  }
}

export const getNcino = (state: State) => state.ncino;
