import { Action } from '@ngrx/store';

import { type } from '@qtek/shared/utils';
import { FoodType } from '@qtek/shared/models';

export const MetaActionTypes = {
  LOAD_META: type('[Meta] Load'),
  LOAD_META_SUCCESS: type('[Meta] Load Success'),
  LOAD_META_FAILURE: type('[Meta] Load Failure'),
  LOAD_META_COUNTRIES: type('[Meta] Load Countries'),
  LOAD_META_COUNTRIES_SUCCESS: type('[Meta] Load Countries Success'),
  LOAD_META_COUNTRIES_FAILURE: type('[Meta] Load Countries Failure'),
  LOAD_META_COUNTRY: type('[Meta] Load Country'),
  LOAD_META_COUNTRY_SUCCESS: type('[Meta] Load Country Success'),
  LOAD_META_COUNTRY_FAILURE: type('[Meta] Load Country Failure'),
  LOAD_META_FOOD_TYPES: type('[Meta] Load food types'),
  LOAD_META_FOOD_TYPES_SUCCESS: type('[Meta] Load food types Success'),
  LOAD_META_FOOD_TYPES_FAILURE: type('[Meta] Load food types Failure'),
  LOAD_META_TIMEZONES: type('[Meta] Load timezones'),
  LOAD_META_UNITS: type('[Meta] Load units'),
  LOAD_META_FREQUENCIES: type('[Meta] Load frequencies'),
  LOAD_META_ROLES: type('[Meta] Load roles'),
  LOAD_META_LANGUAGES: type('[Meta] Load languages'),
  LOAD_META_TITLE: type('[Meta] Load title'),
  LOAD_META_MOBILE_TITLE: type('[Meta] Load mobile title'),
  LOAD_META_GUI_DOMAIN: type('[Meta] Load gui domain'),
  LOAD_META_GUI_ONLINE_BOOK_DOMAIN: type('[Meta] Load gui online book domain'),
  LOAD_META_GUI_SHR_DOMAIN: type('[Meta] Load gui shr domain'),
  LOAD_META_COUNTRY_CODE: type('[Meta] Load phones'),
  LOAD_META_WEBSOCKET: type('[Meta] Load websocket'),
  LOAD_META_CURRENCY: type('[Meta] Load currency'),
  LOAD_META_CMP_LICENSE: type('[Meta] Load company license'),
  LOAD_META_STATES: type('[Meta] Load states'),
  LOAD_META_TOKEN_SUCCESS: type('[Meta] Load token success'),
  LOAD_META_UI_THEME: type('[Meta] Load Meta Ui Theme'),
  LOAD_META_UI_THEME_SUCCESS: type('[Meta] Load Meta Ui Theme Success'),
  SET_META_UI_THEME: type('[Meta] Set Meta Ui Theme'),

  SUBSCRIBE_PS_META: type('[Meta] Subscribe Power Search meta data'),
  UNSUBSCRIBE_PS_META: type('[Meta] Unsubscribe Power Search meta data'),
  UPDATE_PS_META: type('[Meta] Update Power Search meta data'),
  UPDATE_PS_META_SUCCESS: type('[Meta] Update Sucess Power Search meta data'),
  UPDATE_PS_META_FAILURE: type('[Meta] Update Failure Power Search meta data'),
};

export class LoadMetaAction implements Action {
  type = MetaActionTypes.LOAD_META;

  constructor(public payload?: string[]) {}
}

export class LoadMetaTokenSuccessAction implements Action {
  type = MetaActionTypes.LOAD_META_TOKEN_SUCCESS;

  constructor(public payload: string) {}
}

export class LoadMetaSuccessAction implements Action {
  type = MetaActionTypes.LOAD_META_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMetaFailureAction implements Action {
  type = MetaActionTypes.LOAD_META_FAILURE;

  constructor(public payload?: any) {}
}

export class LoadMetaCountriesAction implements Action {
  type = MetaActionTypes.LOAD_META_COUNTRIES;

  constructor(public payload?: any) {}
}

export class LoadMetaCountriesSuccessAction implements Action {
  type = MetaActionTypes.LOAD_META_COUNTRIES_SUCCESS;

  constructor(public payload: Array<{ id: string; nm: string }>) {}
}

export class LoadMetaCountriesFailureAction implements Action {
  type = MetaActionTypes.LOAD_META_COUNTRIES_FAILURE;

  constructor(public payload?: any) {}
}

export class LoadMetaCountryAction implements Action {
  type = MetaActionTypes.LOAD_META_COUNTRY;

  constructor(public payload: string) {}
}

export class LoadMetaCountrySuccessAction implements Action {
  type = MetaActionTypes.LOAD_META_COUNTRY_SUCCESS;

  constructor(public payload: string[]) {}
}

export class LoadMetaCountryFailureAction implements Action {
  type = MetaActionTypes.LOAD_META_COUNTRY_FAILURE;

  constructor(public payload?: any) {}
}

export class LoadMetaFoodTypesAction implements Action {
  type = MetaActionTypes.LOAD_META_FOOD_TYPES;

  constructor(public payload?: any) {}
}

export class LoadMetaFoodTypesSuccessAction implements Action {
  type = MetaActionTypes.LOAD_META_FOOD_TYPES_SUCCESS;

  constructor(public payload: FoodType[]) {}
}

export class LoadMetaFoodTypesFailureAction implements Action {
  type = MetaActionTypes.LOAD_META_FOOD_TYPES_FAILURE;

  constructor(public payload?: any) {}
}

export class LoadMetaTimezonesAction implements Action {
  type = MetaActionTypes.LOAD_META_TIMEZONES;

  constructor(public payload?: any) {}
}

export class LoadMetaUnitsAction implements Action {
  type = MetaActionTypes.LOAD_META_UNITS;

  constructor(public payload?: any) {}
}

export class LoadMetaFrequenciesAction implements Action {
  type = MetaActionTypes.LOAD_META_FREQUENCIES;

  constructor(public payload?: any) {}
}

export class LoadMetaRolesAction implements Action {
  type = MetaActionTypes.LOAD_META_ROLES;

  constructor(public payload?: any) {}
}

export class LoadMetaLanguagesAction implements Action {
  type = MetaActionTypes.LOAD_META_LANGUAGES;

  constructor(public payload?: any) {}
}

export class LoadMetaTitleAction implements Action {
  type = MetaActionTypes.LOAD_META_TITLE;

  constructor(public payload?: any) {}
}

export class LoadMetaMobileTitleAction implements Action {
  type = MetaActionTypes.LOAD_META_MOBILE_TITLE;

  constructor(public payload?: any) {}
}

export class LoadMetaGuiDomainAction implements Action {
  type = MetaActionTypes.LOAD_META_GUI_DOMAIN;

  constructor(public payload?: any) {}
}

export class LoadMetaGuiOnlineBookDomainAction implements Action {
  type = MetaActionTypes.LOAD_META_GUI_ONLINE_BOOK_DOMAIN;

  constructor(public payload?: any) {}
}

export class LoadMetaGuiShrDomainAction implements Action {
  type = MetaActionTypes.LOAD_META_GUI_SHR_DOMAIN;

  constructor(public payload?: any) {}
}

export class LoadMetaCountryCodeAction implements Action {
  type = MetaActionTypes.LOAD_META_COUNTRY_CODE;

  constructor(public payload?: any) {}
}

export class LoadMetaWebsocketAction implements Action {
  type = MetaActionTypes.LOAD_META_WEBSOCKET;

  constructor(public payload?: any) {}
}

export class LoadMetaCurrencyAction implements Action {
  type = MetaActionTypes.LOAD_META_CURRENCY;

  constructor(public payload?: any) {}
}

export class LoadMetaCompanyLicenseAction implements Action {
  type = MetaActionTypes.LOAD_META_CMP_LICENSE;

  constructor(public payload?: any) {}
}

export class LoadMetaStates implements Action {
  type = MetaActionTypes.LOAD_META_STATES;

  constructor(public payload?: any) {}
}

export class LoadMetaUiThemeAction implements Action {
  type = MetaActionTypes.LOAD_META_UI_THEME;

  constructor(public payload?: any) {}
}

export class LoadMetaUiThemeActionSuccess implements Action {
  type = MetaActionTypes.LOAD_META_UI_THEME_SUCCESS;

  constructor(public payload?: any) {}
}

export class SetMetaUiThemeAction implements Action {
  type = MetaActionTypes.SET_META_UI_THEME;

  constructor(public payload: { isDarkTheme: boolean }) {}
}

export class SubscribePSMetaAction implements Action {
  type = MetaActionTypes.SUBSCRIBE_PS_META;

  constructor(public payload?: any) {}
}

export class UnsubscribePSMetaAction implements Action {
  type = MetaActionTypes.UNSUBSCRIBE_PS_META;

  constructor(public payload?: any) {}
}

export class UpdatePSMetaAction implements Action {
  type = MetaActionTypes.UPDATE_PS_META;

  constructor(public payload?: { mid: string; data: string }) {}
}

export class UpdatePSMetaSuccessAction implements Action {
  type = MetaActionTypes.UPDATE_PS_META_SUCCESS;

  constructor(public payload?: { mid: string; data?: string }) {}
}

export class UpdatePSMetaFailureAction implements Action {
  type = MetaActionTypes.UPDATE_PS_META_FAILURE;

  constructor(public payload?: any) {}
}

export type MetaActions =
  | LoadMetaAction
  | LoadMetaSuccessAction
  | LoadMetaFailureAction
  | LoadMetaCountriesAction
  | LoadMetaCountriesSuccessAction
  | LoadMetaCountriesFailureAction
  | LoadMetaCountryAction
  | LoadMetaCountrySuccessAction
  | LoadMetaCountryFailureAction
  | LoadMetaFoodTypesAction
  | LoadMetaFoodTypesSuccessAction
  | LoadMetaFoodTypesFailureAction
  | LoadMetaTimezonesAction
  | LoadMetaUnitsAction
  | LoadMetaFrequenciesAction
  | LoadMetaRolesAction
  | LoadMetaLanguagesAction
  | LoadMetaTitleAction
  | LoadMetaMobileTitleAction
  | LoadMetaGuiShrDomainAction
  | LoadMetaGuiDomainAction
  | LoadMetaGuiOnlineBookDomainAction
  | LoadMetaCountryCodeAction
  | LoadMetaWebsocketAction
  | LoadMetaCurrencyAction
  | LoadMetaCompanyLicenseAction
  | LoadMetaStates
  | LoadMetaTokenSuccessAction
  | LoadMetaUiThemeAction
  | LoadMetaUiThemeActionSuccess
  | SetMetaUiThemeAction
  | SubscribePSMetaAction
  | UnsubscribePSMetaAction
  | UpdatePSMetaAction
  | UpdatePSMetaSuccessAction
  | UpdatePSMetaFailureAction;
