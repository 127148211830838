import { Injectable, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, merge } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';

export interface ToolbarConfig {
  template: TemplateRef<any>;
  showBack?: boolean;
  url?: string;
  showBorder?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppBarService {
  /** Hold all configs. */
  readonly configs = new Map<string, ToolbarConfig>();

  /** Emit event when config is registered. */
  private readonly update = new Subject<ToolbarConfig>();

  /** Emits event when current config change. */
  configChange: Observable<ToolbarConfig | null>;

  constructor(private router: Router) {
    /**
     * Emit `null` each time we go to new view. If we don't do this, some views
     * could preserve app bar components from previews view.
     */
    const restart = this.router.events.pipe(
      filter(
        e =>
          e instanceof NavigationEnd &&
          // Don't do anything when query params change.
          !e.url.includes('?') &&
          // Check if view is alredy registered. It also protect from redirects to same route.
          !this.configs.has(e.urlAfterRedirects)
      ),
      mapTo(null)
    );

    this.configChange = merge(restart, this.update.asObservable());
  }

  /**
   * Returns full route path without query params.
   */
  private getRouteId() {
    const url = this.router.url;
    const index = url.indexOf('?');

    return url.slice(0, index > -1 ? index : undefined);
  }

  /**
   *  Register config for current route and emit event.
   */
  registerConfigForCurrentRoute(config: ToolbarConfig) {
    const id = this.getRouteId();

    /** Override configuration so components in TemplateRef can get correct dependencies. */
    this.configs.set(id, config);

    this.update.next(this.configs.get(id));
  }
}
