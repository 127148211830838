import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BackendResponse } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  constructor(private http: HttpClient) {}

  /**
   * Get data such as company, person, relations of currently logged in user.
   *
   * TODO: Add generic type to return value.
   *
   * @returns {Observable<BackendResponse>}
   *
   * @memberof PublicService
   */
  getCurrentUser(payload: any = {}): Observable<BackendResponse> {
    let params = new HttpParams();
    let headers = new HttpHeaders();

    if (payload.params && payload.params.prsId) {
      params = params.set('prsId', payload.params.prsId);
    }

    /**
     * Adding this header allows to redirect in auth guard instead of {@link RevalidateInterceptor}
     */
    if (payload.skipRevalidateErrorHandling) {
      headers = headers.append('X-Skip-Revalidate-Error-Handling', '');
    }
    return this.http.get<BackendResponse>('/api/v1/service/usr', {
      withCredentials: true,
      params,
      headers,
    });
  }
}
