import { EntityMeta, Note } from '@qtek/shared/models';
import { NoteActions, NoteActionTypes } from './note.actions';

export interface NoteState {
  notes: Record<string, Note[]>;
  notesMeta: EntityMeta;
  notesLoading: boolean;
}

const initialState: NoteState = {
  notes: {},
  notesMeta: new EntityMeta(),
  notesLoading: false,
};

export function noteReducer(
  state = initialState,
  { type, payload }: NoteActions
): NoteState {
  switch (type) {
    case NoteActionTypes.CONNECT_NOTE: {
      return {
        ...state,
        notesLoading: true,
      };
    }

    case NoteActionTypes.GET_NOTES_FAILURE: {
      return {
        ...state,
        notesLoading: false,
      };
    }

    case NoteActionTypes.GET_NOTES_USER_SUCCESS:
    case NoteActionTypes.GET_NOTES_COMPANY_SUCCESS:
    case NoteActionTypes.GET_NOTES_SUCCESS: {
      return {
        ...state,
        notes: {
          ...state.notes,
          [payload.mysid]: payload.notes,
        },
        notesMeta: payload.meta || { ...state.notesMeta },
        notesLoading: false,
      };
    }
    case NoteActionTypes.CREATE_NOTE_USER_SUCCESS:
    case NoteActionTypes.CREATE_NOTE_COMPANY_SUCCESS:
    case NoteActionTypes.CREATE_NOTE_SUCCESS: {
      return {
        ...state,
        notes: {
          ...state.notes,
          [payload.mysid]: [
            ...(state.notes[payload.mysid] || []),
            payload.note,
          ],
        },
      };
    }

    case NoteActionTypes.DELETE_NOTE_USER_SUCCESS:
    case NoteActionTypes.DELETE_NOTE_COMPANY_SUCCESS:
    case NoteActionTypes.DELETE_NOTE_SUCCESS: {
      return {
        ...state,
        notes: {
          ...state.notes,
          [payload.mysid]: [
            ...(state.notes[payload.mysid] || []).filter(
              note => note._id !== payload.id
            ),
          ],
        },
      };
    }

    case NoteActionTypes.UPDATE_NOTE_USER_SUCCESS:
    case NoteActionTypes.UPDATE_NOTE_COMPANY_SUCCESS:
    case NoteActionTypes.UPDATE_NOTE_SUCCESS: {
      const newArr = state.notes[payload.mysid].slice();
      const index = newArr.findIndex(
        (note: Note) => note._id === payload.note._id
      );
      newArr[index] = payload.note;

      return {
        ...state,
        notes: {
          ...state.notes,
          [payload.mysid]: newArr,
        },
      };
    }

    // -----------------------------
    // DEFAULT

    default: {
      return state;
    }
  }
}

export const getNotes = (state: NoteState) => state.notes;
export const getNotesLoading = (state: NoteState) => state.notesLoading;
export const getNotesMeta = (state: NoteState) => state.notesMeta;
