import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { QtCalendarEvent, SendDialogOutput } from '@qtek/shared/models';

export const CalendarActions = createActionGroup({
  source: 'Calendar',
  events: {
    'Connect Events': props<{ payload?: any }>(),
    'Disconnect Events': props<{ payload?: any }>(),
    'Subscribe Events': props<{ prms?: any; mysid?: string; id?: string }>(),
    'Unsubscribe Events': props<{ mysid?: string }>(),
    'Get Events': emptyProps(),
    'Get Events Success': props<{ payload: QtCalendarEvent[] }>(),
    'Get Events Failure': props<{ error: any }>(),
    'Get Event': props<{ payload: string }>(),
    'Get Event Success': props<{ payload: QtCalendarEvent }>(),
    'Add Event': props<{ payload: QtCalendarEvent; dst?: string }>(),
    'Add Event Success': props<{ payload: QtCalendarEvent }>(),
    'Add Event Failure': props<{ error: any }>(),
    'Update Event': props<{ id: string; event: QtCalendarEvent }>(),
    'Update Event Success': props<{ payload: QtCalendarEvent }>(),
    'Update Event Failure': props<{ error: any }>(),
    'Delete Event': props<{ payload: string }>(),
    'Delete Event Success': props<{ payload: QtCalendarEvent }>(),
    'Delete Event Failure': props<{ error: any }>(),
    'Send Event': props<{ payload: SendDialogOutput; params: any }>(),
    'Send Event Success': props<{ payload: any }>(),
    'Send Event Failure': props<{ error: any }>(),
    'Send Walk In Ready': props<{ payload: string }>(),
    'Send Walk In Ready Success': props<{ payload?: string }>(),
    'Send Walk In Ready Failure': props<{ error: any }>(),
  },
});
