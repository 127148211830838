import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AgentService } from './agent.service';
import {
  AgentActionsTypes,
  CreateAgentAction,
  CreateAgentActionFailure,
  CreateAgentActionSuccess,
  DeleteAgentAction,
  DeleteAgentActionFailure,
  DeleteAgentActionSuccess,
  GetAgentsAction,
  GetAgentsActionFailure,
  GetAgentsActionSuccess,
  UpdateAgentAction,
  UpdateAgentActionFailure,
  UpdateAgentActionSuccess,
} from './agents.actions';

@Injectable()
export class AgentsEffects {
  agents$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAgentsAction>(AgentActionsTypes.GET_AGENTS),
      switchMap(() =>
        this.agentService.getAgents().pipe(
          map(({ res }) => new GetAgentsActionSuccess(res)),
          catchError(({ sts }) => of(new GetAgentsActionFailure(sts)))
        )
      )
    )
  );

  updateAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAgentAction>(AgentActionsTypes.UPDATE_AGENT),
      switchMap(({ payload, print }) =>
        this.agentService.updateAgent(payload, print).pipe(
          map(({ res }) => new UpdateAgentActionSuccess(res)),
          catchError(({ sts }) => of(new UpdateAgentActionFailure(sts)))
        )
      )
    )
  );

  deleteAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteAgentAction>(AgentActionsTypes.DELETE_AGENT),
      switchMap(({ payload }) =>
        this.agentService.deleteAgent(payload).pipe(
          map(({ res }) => new DeleteAgentActionSuccess(res)),
          catchError(({ sts }) => of(new DeleteAgentActionFailure(sts)))
        )
      )
    )
  );

  createAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAgentAction>(AgentActionsTypes.CREATE_AGENT),
      switchMap(({ payload, print }) =>
        this.agentService.addAgent(payload, print).pipe(
          map(({ res }) => new CreateAgentActionSuccess(res)),
          catchError(({ sts }) => of(new CreateAgentActionFailure(sts)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private agentService: AgentService) {}
}
