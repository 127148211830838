import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  BackendResponse,
  CalendarIntegrationsVendor,
  IntegratedCalendar,
  SalesforceInfo,
  MicrosoftInfo,
  DebtXProfile,
} from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(private http: HttpClient) {}

  getAvailableCalendars() {
    return this.http.get<BackendResponse<CalendarIntegrationsVendor[]>>(
      '/api/v1/vnd/status',
      {
        withCredentials: true,
      }
    );
  }

  getVendorCalendar(vendor: CalendarIntegrationsVendor) {
    return this.http.get<BackendResponse<IntegratedCalendar>>(
      `/api/v1/vnd/${vendor}/status`,
      {
        withCredentials: true,
      }
    );
  }

  getSalesforce() {
    return this.http.get<BackendResponse<SalesforceInfo>>(
      `/api/v1/vnd/sf/status`,
      {
        withCredentials: true,
      }
    );
  }

  getMicrosoft() {
    return this.http.get<BackendResponse<MicrosoftInfo>>(
      `/api/v1/vnd/ms/status`,
      {
        withCredentials: true,
      }
    );
  }

  getDebtX() {
    return this.http.get<
      BackendResponse<{ status: string; data: DebtXProfile }>
    >(`/api/v1/vnd/debtx/status`, {
      withCredentials: true,
    });
  }

  getClover() {
    return this.http.get<BackendResponse<{ expStr: string }>>(
      `/api/v1/vnd/clv`,
      {
        withCredentials: true,
      }
    );
  }

  updateCalendar(
    vendor: CalendarIntegrationsVendor,
    payload: IntegratedCalendar
  ) {
    return this.http.post<BackendResponse<IntegratedCalendar>>(
      `/api/v1/vnd/${vendor}`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  connectCalendar(
    vendor: CalendarIntegrationsVendor,
    payload: IntegratedCalendar
  ) {
    return this.http.put<BackendResponse<IntegratedCalendar>>(
      `/api/v1/vnd/${vendor}`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  disconnectCalendar(vendor: CalendarIntegrationsVendor) {
    return this.http.delete<void>(`/api/v1/vnd/${vendor}`, {
      withCredentials: true,
    });
  }

  connectDebtX(payload: { username?: string; password?: string }) {
    return this.http.post<BackendResponse<{ status: string }>>(
      '/api/v1/vnd/debtx',
      payload,
      {
        withCredentials: true,
      }
    );
  }

  connectDebtXMfa(mfaCode: string) {
    return this.http.post<BackendResponse<{ status: string }>>(
      '/api/v1/vnd/debtx/mfa',
      { code: mfaCode },
      {
        withCredentials: true,
      }
    );
  }
}
