import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  map,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AppState } from '..';
import { MetaActions } from './meta.actions';
import {
  selectAllRoles,
  selectCountryCode,
  selectCurs,
  selectFrequencies,
  selectGuiDomain,
  selectGuiOnlineBookDomain,
  selectGuiShrDomain,
  selectLanguages,
  selectMobileTitle,
  selectStates,
  selectTimezones,
  selectTitle,
  selectUnits,
  selectWsPingPong,
} from './meta.reducer';
import { MetaService } from './meta.service';
import { PowerSearchService } from './power-search.service';

@Injectable()
export class MetaEffects {
  constructor(
    private actions$: Actions,
    private metaService: MetaService,
    private psService: PowerSearchService,
    private store: Store<AppState>
  ) {}

  meta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaActions.loadMeta),
      mergeMap(({ payload }) =>
        this.metaService.getMetaData(payload).pipe(
          map(res => MetaActions.loadMetaSuccess({ payload: res })),
          catchError(err => of(MetaActions.loadMetaFailure({ payload: err })))
        )
      )
    )
  );

  metaCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaActions.loadMetaCountry),
      switchMap(({ payload }) =>
        this.metaService.getMetaDataCountryInfo(payload).pipe(
          map(({ res }) =>
            MetaActions.loadMetaCountrySuccess({ payload: res })
          ),
          catchError(err =>
            of(MetaActions.loadMetaCountryFailure({ payload: err }))
          )
        )
      )
    )
  );

  metaCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaActions.loadMetaCountries),
      switchMap(() =>
        this.metaService.getMetaDataCountries().pipe(
          map(({ res }) =>
            MetaActions.loadMetaCountriesSuccess({ payload: res })
          ),
          catchError(err =>
            of(MetaActions.loadMetaCountriesFailure({ payload: err }))
          )
        )
      )
    )
  );

  metaSetUiTheme = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MetaActions.setMetaUiTheme),
        tap(({ payload }) => {
          localStorage.setItem(
            'uiTheme',
            payload.isDarkTheme ? 'dark' : 'light'
          );
        })
      ),
    { dispatch: false }
  );

  metaLoadUiTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaActions.loadMetaUiTheme, ROOT_EFFECTS_INIT),
      switchMap(() => {
        const theme = localStorage.getItem('uiTheme');
        return of(
          MetaActions.loadMetaUiThemeSuccess({ payload: theme === 'dark' })
        );
      })
    )
  );

  private metaEffect = <T extends Action>(
    type: string,
    selector: any,
    params?: string[]
  ) =>
    this.actions$.pipe(
      ofType<T>(type),
      withLatestFrom(this.store.pipe(select(selector))),
      mergeMap(([, data]) => {
        return (data ? Boolean(data.toString()) : false)
          ? EMPTY
          : this.metaService.getMetaData(params).pipe(
              map(({ res }) => MetaActions.loadMetaSuccess({ payload: res })),
              catchError(err =>
                of(MetaActions.loadMetaFailure({ payload: err }))
              )
            );
      })
    );

  metaTimezones$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaTimezones.type, selectTimezones, [
      'tzs',
    ])
  );

  metaUnits$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaUnits.type, selectUnits, ['unts'])
  );

  metaFrequencies$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaFrequencies.type, selectFrequencies, [
      'frq',
    ])
  );

  metaRoles$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaRoles.type, selectAllRoles, [
      'ptlRoleGroups',
    ])
  );

  metaLanguages$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaLanguages.type, selectLanguages, [
      'lngs',
    ])
  );

  metaTitle$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaTitle.type, selectTitle, ['ptl'])
  );

  metaMobileTitle$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaMobileTitle.type, selectMobileTitle, [
      'ptl',
    ])
  );

  metaGuiDomain$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaGuiDomain.type, selectGuiDomain, [
      'ptl',
    ])
  );

  metaGuiOnlineBookDomain$ = createEffect(() =>
    this.metaEffect(
      MetaActions.loadMetaGuiOnlineBookDomain.type,
      selectGuiOnlineBookDomain,
      ['ptl']
    )
  );

  metaGuiShrDomain$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaGuiShrDomain.type, selectGuiShrDomain, [
      'ptl',
    ])
  );

  metaPhones$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaCountryCode.type, selectCountryCode, [
      'phns',
    ])
  );

  metaWebsocket$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaWebsocket.type, selectWsPingPong, [
      'wsPingPong',
    ])
  );

  metaCurrency$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaCurrency.type, selectCurs, ['curs'])
  );

  metaCompanyLicense$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaCompanyLicense.type, selectUnits, [
      'licCmp',
    ])
  );

  metaStates$ = createEffect(() =>
    this.metaEffect(MetaActions.loadMetaStates.type, selectStates, ['adr'])
  );

  connectPSMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaActions.subscribeMetaPowerSearchData),
      switchMap(({ payload }) =>
        this.psService.subscribe({ prms: { mid: payload }, s: 'model' }).pipe(
          map(value => ({ ...value, mid: payload })),
          takeUntil(
            this.actions$.pipe(
              ofType(MetaActions.unsubscribeMetaPowerSearchData)
            )
          )
        )
      ),
      map(({ res, op, sts, mid }: any) => {
        switch (op) {
          case 'query':
            return MetaActions.updateMetaPowerSearchDataSuccess({
              payload: res?.[0] ? res[0] : { mid },
            });
          case 'upd':
            return res
              ? MetaActions.updateMetaPowerSearchDataSuccess({ payload: res })
              : MetaActions.updateMetaPowerSearchDataFailure({ payload: sts });
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  updatePSMeta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MetaActions.updateMetaPowerSearchData),
        tap(({ payload }) => this.psService.updateMeta({ res: payload }))
      ),
    { dispatch: false }
  );
}
