import { ErrorHandler, Injectable } from '@angular/core';
import { TranslationCoreService } from '@qtek/libs/translation-core';

@Injectable()
export class QtErrorHandler extends ErrorHandler {
  // TODO: remove when translation file loading will be moved to APP_INITIALIZER(https://github.com/rfilarski/qt-app-gui/issues/593)
  fallbackMessage =
    'An error occured. Press OK to reload the application. ' +
    'Please contact us at info@calio.io if the problem persists.';
  opened: boolean;

  constructor(private translate: TranslationCoreService) {
    super();
  }

  override handleError(error: any): void {
    super.handleError(error);
    if (error?.status === 0) {
      console.log('CORS ERROR !!!!');
    }
  }
}
