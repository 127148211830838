import { Action } from '@ngrx/store';

import { Message, WebDomain } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class WebDomainsActionTypes {
  static readonly GET_WEBDOMAINS = type('[WebDomain] Get Web Domains');
  static readonly GET_WEBDOMAINS_SUCCESS = type(
    '[WebDomain] Get Web Domains Success'
  );
  static readonly GET_WEBDOMAINS_FAILURE = type(
    '[WebDomain] Get Web Domains Failure'
  );

  static readonly CREATE_WEBDOMAIN = type('[WebDomain] Create Web Domain');
  static readonly CREATE_WEBDOMAIN_SUCCESS = type(
    '[WebDomain] Create Web Domain Success'
  );
  static readonly CREATE_WEBDOMAIN_FAILURE = type(
    '[WebDomain] Create Web Domain Failure'
  );

  static readonly DELETE_WEBDOMAIN = type('[WebDomain] Delete Web Domain');
  static readonly DELETE_WEBDOMAIN_SUCCESS = type(
    '[WebDomain] Delete Web Domain Success'
  );
  static readonly DELETE_WEBDOMAIN_FAILURE = type(
    '[WebDomain] Delete Web Domain Failure'
  );
}

export class GetWebDomainsAction implements Action {
  readonly type = WebDomainsActionTypes.GET_WEBDOMAINS;

  constructor(public payload?: any) {}
}

export class GetWebDomainsSuccessAction implements Action {
  readonly type = WebDomainsActionTypes.GET_WEBDOMAINS_SUCCESS;

  constructor(public payload: WebDomain[]) {}
}

export class GetWebDomainsFailureAction implements Action {
  readonly type = WebDomainsActionTypes.GET_WEBDOMAINS_FAILURE;

  constructor(public payload: any) {}
}

export class CreateWebDomainAction implements Action {
  readonly type = WebDomainsActionTypes.CREATE_WEBDOMAIN;

  constructor(public payload: any) {}
}

export class CreateWebDomainSuccessAction implements Action {
  readonly type = WebDomainsActionTypes.CREATE_WEBDOMAIN_SUCCESS;

  constructor(public payload: WebDomain) {}
}

export class CreateWebDomainFailureAction implements Action {
  readonly type = WebDomainsActionTypes.CREATE_WEBDOMAIN_FAILURE;

  constructor(public payload: any) {}
}

export class DeleteWebDomainAction implements Action {
  readonly type = WebDomainsActionTypes.DELETE_WEBDOMAIN;

  constructor(public payload: string) {}
}

export class DeleteWebDomainSuccessAction implements Action {
  readonly type = WebDomainsActionTypes.DELETE_WEBDOMAIN_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteWebDomainFailureAction implements Action {
  readonly type = WebDomainsActionTypes.DELETE_WEBDOMAIN_FAILURE;

  constructor(public payload: any) {}
}

export type WebDomainsActions =
  | GetWebDomainsAction
  | GetWebDomainsSuccessAction
  | GetWebDomainsFailureAction
  | CreateWebDomainAction
  | CreateWebDomainSuccessAction
  | CreateWebDomainFailureAction
  | DeleteWebDomainAction
  | DeleteWebDomainSuccessAction
  | DeleteWebDomainFailureAction;
