import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  LOCALE_ID,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';

import { MatKeyboardRef } from '../../classes';
import { MAT_KEYBOARD_LAYOUTS } from '../../configs';
import { KeyboardClassKey, KeyboardModifier } from '../../enums';
import { KeyboardLayout, KeyboardLayouts } from '../../interfaces';
import { getLayoutForLocale, mapLocale } from '../../utils';
import { KeyboardKeyComponent } from '../keyboard-key/keyboard-key.component';

@Component({
  selector: 'qt-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class KeyboardComponent implements OnInit {
  private _darkTheme: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isDebug: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _inputInstance$: BehaviorSubject<ElementRef | null> =
    new BehaviorSubject(null);

  @ViewChildren(KeyboardKeyComponent)
  private _keys: QueryList<KeyboardKeyComponent>;

  private _modifier: KeyboardModifier = KeyboardModifier.None;
  private _capsLocked = false;

  locale: string;
  layout: KeyboardLayout;
  control: AbstractControl;
  keyboardRef: MatKeyboardRef<KeyboardComponent>;

  @HostBinding('class.qt-keyboard')
  cssClass = true;

  enterClick: EventEmitter<void> = new EventEmitter<void>();
  capsClick: EventEmitter<void> = new EventEmitter<void>();
  altClick: EventEmitter<void> = new EventEmitter<void>();
  shiftClick: EventEmitter<void> = new EventEmitter<void>();

  get inputInstance(): Observable<ElementRef | null> {
    return this._inputInstance$.asObservable();
  }

  set darkTheme(darkTheme: boolean) {
    if (this._darkTheme.getValue() !== darkTheme) {
      this._darkTheme.next(darkTheme);
    }
  }

  set isDebug(isDebug: boolean) {
    if (this._isDebug.getValue() !== isDebug) {
      this._isDebug.next(isDebug);
    }
  }

  get darkTheme$(): Observable<boolean> {
    return this._darkTheme.asObservable();
  }

  get isDebug$(): Observable<boolean> {
    return this._isDebug.asObservable();
  }

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(MAT_KEYBOARD_LAYOUTS) private _layouts: KeyboardLayouts
  ) {}

  setInputInstance(inputInstance: ElementRef) {
    this._inputInstance$.next(inputInstance);
  }

  attachControl(control: AbstractControl) {
    this.control = control;
  }

  ngOnInit() {
    if (!this.layout) {
      this.locale = mapLocale(this._locale, this._layouts)
        ? this._locale
        : 'en-US';
      this.layout = getLayoutForLocale(this.locale, this._layouts);
    }
  }

  dismiss() {
    this.keyboardRef.dismiss();
  }

  /**
   * checks if a given key is currently pressed
   * @param key
   * @param
   */
  isActive(key: (string | KeyboardClassKey)[]): boolean {
    const modifiedKey: string = this.getModifiedKey(key);
    const isActiveCapsLock: boolean =
      modifiedKey === KeyboardClassKey.Caps && this._capsLocked;
    const isActiveModifier: boolean =
      modifiedKey === KeyboardModifier[this._modifier];
    return isActiveCapsLock || isActiveModifier;
  }

  getModifiedKey(key: (string | KeyboardClassKey)[]): string {
    let modifier: KeyboardModifier = this._modifier;

    if (this._capsLocked) {
      modifier = this._invertShiftModifier(this._modifier);
    }

    return key[modifier];
  }

  /**
   * listens to users keyboard inputs to simulate on virtual keyboard, too
   * @param event
   */
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // 'activate' corresponding key
    this._keys
      .filter((key: KeyboardKeyComponent) => key.key === event.key)
      .forEach((key: KeyboardKeyComponent) => {
        key.pressed = true;
      });

    if (event.key === KeyboardClassKey.Caps) {
      this.onCapsClick(event.getModifierState(KeyboardClassKey.Caps));
    }
    if (
      event.key === KeyboardClassKey.Alt &&
      this._modifier !== KeyboardModifier.Alt &&
      this._modifier !== KeyboardModifier.ShiftAlt
    ) {
      this.onAltClick();
    }
    if (
      event.key === KeyboardClassKey.Shift &&
      this._modifier !== KeyboardModifier.Shift &&
      this._modifier !== KeyboardModifier.ShiftAlt
    ) {
      this.onShiftClick();
    }
  }

  /**
   * listens to users keyboard inputs to simulate on virtual keyboard, too
   * @param event
   */
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this._keys
      .filter((key: KeyboardKeyComponent) => key.key === event.key)
      .forEach((key: KeyboardKeyComponent) => {
        key.pressed = false;
      });

    if (
      event.key === KeyboardClassKey.Alt &&
      (this._modifier === KeyboardModifier.Alt ||
        this._modifier === KeyboardModifier.ShiftAlt)
    ) {
      this.onAltClick();
    }
    if (
      event.key === KeyboardClassKey.Shift &&
      (this._modifier === KeyboardModifier.Shift ||
        this._modifier === KeyboardModifier.ShiftAlt)
    ) {
      this.onShiftClick();
    }
  }

  /**
   * bubbles event if submit is potentially triggered
   */
  onEnterClick() {
    this.enterClick.next();
  }

  /**
   * simulates clicking `CapsLock` key
   * @param targetState
   */
  onCapsClick(targetState = !this._capsLocked) {
    this._capsLocked = targetState;

    this.capsClick.next();
  }

  /**
   * simulates clicking `Alt` key
   */
  onAltClick() {
    this._modifier = this._invertAltModifier(this._modifier);

    this.altClick.next();
  }

  /**
   * simulates clicking `Shift` key
   */
  onShiftClick() {
    this._modifier = this._invertShiftModifier(this._modifier);

    this.shiftClick.next();
  }

  private _invertAltModifier(modifier: KeyboardModifier): KeyboardModifier {
    switch (modifier) {
      case KeyboardModifier.None:
        return KeyboardModifier.Alt;

      case KeyboardModifier.Shift:
        return KeyboardModifier.ShiftAlt;

      case KeyboardModifier.ShiftAlt:
        return KeyboardModifier.Shift;

      case KeyboardModifier.Alt:
        return KeyboardModifier.None;
    }
  }

  private _invertShiftModifier(modifier: KeyboardModifier): KeyboardModifier {
    switch (modifier) {
      case KeyboardModifier.None:
        return KeyboardModifier.Shift;

      case KeyboardModifier.Alt:
        return KeyboardModifier.ShiftAlt;

      case KeyboardModifier.ShiftAlt:
        return KeyboardModifier.Alt;

      case KeyboardModifier.Shift:
        return KeyboardModifier.None;
    }
  }
}
