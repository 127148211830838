import { Action } from '@ngrx/store';
import { type } from '@qtek/shared/utils';

export class DocumentActivityActionTypes {
  static readonly SUBSCRIBE_DOCUMENT_ACTIVITY = type(
    '[Document Activity] Subscribe Document Activity'
  );
  static readonly UNSUBSCRIBE_DOCUMENT_ACTIVITY = type(
    '[Document Activity] Unsubscribe Document Activity'
  );
  static readonly UPDATE_DOCUMENT_ACTIVITY = type(
    '[Document Activity] Update Document Activity Success'
  );
}

export class SubscribeDocumentActivity implements Action {
  readonly type = DocumentActivityActionTypes.SUBSCRIBE_DOCUMENT_ACTIVITY;

  constructor(public payload: { mysid: string; query: any[] }) {}
}

export class UnsubscribeDocumentActivity implements Action {
  readonly type = DocumentActivityActionTypes.UNSUBSCRIBE_DOCUMENT_ACTIVITY;

  constructor(public payload: { mysid: string }) {}
}

export class UpdateDocumentActivity implements Action {
  readonly type = DocumentActivityActionTypes.UPDATE_DOCUMENT_ACTIVITY;

  constructor(public payload: any) {}
}

export type DocumentsActivityAction =
  | SubscribeDocumentActivity
  | UnsubscribeDocumentActivity
  | UpdateDocumentActivity;
