<button
  mat-raised-button
  class="qt-keyboard-key"
  tabindex="-1"
  [class.qt-keyboard-key-active]="active$ | async"
  [class.qt-keyboard-key-pressed]="pressed$ | async"
  [ngClass]="cssClass"
  (click)="onClick($event)">
  <mat-icon *ngIf="hasIcon">{{ icon }}</mat-icon>
  <ng-container *ngIf="!hasIcon">{{ key }}</ng-container>
</button>
