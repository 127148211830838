export const GENERIC_PHONE_MASK = [
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export function getPhoneMask(pattern?: string): any {
  if (!pattern || pattern === '') {
    return {
      mask: GENERIC_PHONE_MASK,
      guide: false,
    };
  } else {
    const mask = [];
    let length = 0;

    for (let i = 0; i < pattern.length; i++) {
      if (pattern[i] === 'x') {
        mask.push(/\d/);
        length++;
      } else {
        mask.push(pattern[i]);
      }
    }
    return {
      mask,
      length,
      guide: false,
    };
  }
}
