import { MatDialogConfig } from '@angular/material/dialog';

export const fullscreenClass = 'fullscreen';
export const fullscreenAllClass = 'fullscreenAll';
export const minWidth = '600px';

export const defaultDialogConfig: MatDialogConfig = {
  panelClass: fullscreenClass,
  width: minWidth,
};
