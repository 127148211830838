import { DocumentActivityHistory } from '@qtek/shared/models';
import {
  DocumentActivityActionTypes,
  DocumentsActivityAction,
} from '../actions';

export interface State {
  documentActivity: DocumentActivityHistory[];
}

const initialState: State = {
  documentActivity: [],
};

export function reducer(
  state = initialState,
  action: DocumentsActivityAction
): State {
  switch (action.type) {
    case DocumentActivityActionTypes.SUBSCRIBE_DOCUMENT_ACTIVITY: {
      return { ...state, documentActivity: [] };
    }

    case DocumentActivityActionTypes.UPDATE_DOCUMENT_ACTIVITY: {
      return {
        ...state,
        documentActivity: [
          ...(Array.isArray(action.payload)
            ? action.payload
            : [action.payload]),
          ...state.documentActivity,
        ],
      };
    }

    default: {
      return state;
    }
  }
}

export const getDocumentActivity = (state: State) => state.documentActivity;
