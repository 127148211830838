import {
  DashboardTile,
  DashboardTileDataset,
  DashboardTiles,
  MenuNode,
  MenuSetupNode,
} from '@qtek/shared/models';
import { createFeature, createReducer, on, createSelector } from '@ngrx/store';
import { MenuActions } from './menu.actions';
import { getScopesFromMenuConfigByUrl } from '@qtek/shared/utils';

export interface MenuState {
  mainMenu: MenuNode[];
  wizardMenu: MenuNode[];
  setupMenu: MenuSetupNode[];
  dashboardMenu: DashboardTiles;
  websocketDatasets: Record<string, DashboardTileDataset[]>;
  wizardStep: string;
}

const initialState: MenuState = {
  mainMenu: [],
  wizardMenu: [],
  setupMenu: [],
  dashboardMenu: null,
  websocketDatasets: {},
  wizardStep: '',
};

export const menuReducer = createReducer(
  initialState,
  on(MenuActions.loadMainSuccess, (state, { payload }) => ({
    ...state,
    mainMenu: payload,
  })),
  on(MenuActions.loadWizardSuccess, (state, { payload }) => ({
    ...state,
    wizardMenu: payload,
  })),
  on(MenuActions.loadSetupSuccess, (state, { payload }) => ({
    ...state,
    setupMenu: payload,
  })),
  on(MenuActions.loadDashboardSuccess, (state, { payload }) => ({
    ...state,
    dashboardMenu: payload,
  })),
  on(MenuActions.updateChartDataset, (state, { payload }) => {
    const labelledData: DashboardTileDataset[] = payload.data.map(
      (dataset: DashboardTileDataset) => {
        const originalDataset: DashboardTileDataset = state.dashboardMenu.tiles
          .find((tile: DashboardTile) => tile.datasource?.tp === payload.id)
          .datasets.find((ds: DashboardTileDataset) => ds.id === dataset.id);

        return { ...originalDataset, ...dataset };
      }
    );

    return {
      ...state,
      websocketDatasets: {
        ...state.websocketDatasets,
        [payload.id]: labelledData,
      },
    };
  }),
  on(MenuActions.wizardStep, (state, { payload }) => ({
    ...state,
    wizardStep: payload,
  }))
);

export const MenuFeature = createFeature({
  name: 'menu',
  reducer: menuReducer,
});

export const {
  selectMenuState,
  selectMainMenu,
  selectWizardMenu,
  selectSetupMenu,
  selectDashboardMenu,
  selectWebsocketDatasets,
  selectWizardStep,
} = MenuFeature;

export const selectWizardStepMenu = createSelector(
  selectMenuState,
  (state: MenuState) => {
    let selectedMenu: MenuNode = state.wizardMenu[0];
    state.wizardMenu.forEach(menu => {
      if (menu.url.toLowerCase().includes(state.wizardStep.toLowerCase())) {
        selectedMenu = menu;
      }
    });
    return selectedMenu;
  }
);

export const selectPageScopesFromMenu = (url: string) =>
  createSelector(selectMainMenu, menu =>
    getScopesFromMenuConfigByUrl(menu, url)
  );

export const selectWebsocketDataset = (id: string) =>
  createSelector(selectWebsocketDatasets, datasets => datasets[id]);
