import { Injectable, Provider } from '@angular/core';
import { MatSortHeaderIntl } from '@angular/material/sort';

import { QtIntl } from './intl';

@Injectable()
export class QtSortHeaderIntl extends MatSortHeaderIntl implements QtIntl {
  sortLabel = 'Change sorting for';

  sortButtonLabel = (id: string) => {
    return `${this.sortLabel} ${id}`;
  };

  updateTranslations(translations: any) {
    this.sortLabel = translations['INTL_SORT_LABEL'];

    this.changes.next();
  }
  getTranslations(): string[] {
    return ['INTL_SORT_LABEL'];
  }
}

export const INTL_SORT_HEADER_PROVIDERS: Provider[] = [
  QtSortHeaderIntl,
  {
    provide: MatSortHeaderIntl,
    useExisting: QtSortHeaderIntl,
  },
  {
    provide: QtIntl,
    useExisting: QtSortHeaderIntl,
    multi: true,
  },
];
