import { Action } from '@ngrx/store';

import { type } from '@qtek/shared/utils';

export class NcinoActionTypes {
  static readonly CONNECT_NCINO_LIST = type('[Ncino] Connect ncino List');
  static readonly DISCONNECT_NCINO_LIST = type('[Ncino] Disconnect ncino List');

  static readonly GET_NCINO = type('[Ncino] Get ncino');
  static readonly IMPORT_NCINO = type('[Ncino] Import ncino');

  static readonly GET_NCINO_SUCCESS = type('[Ncino] Get ncino Success');
  static readonly GET_NCINO_FAILURE = type('[Ncino] Get ncino Failure');

  static readonly IMPORT_NCINO_SUCCESS = type('[Ncino] Import ncino Success');
  static readonly IMPORT_NCINO_FAILURE = type('[Ncino] Import ncino Failure');
}

export class ConnectNcinoList implements Action {
  readonly type = NcinoActionTypes.CONNECT_NCINO_LIST;

  constructor(public payload?: any) {}
}

export class DisconnectNcinoList implements Action {
  readonly type = NcinoActionTypes.DISCONNECT_NCINO_LIST;

  constructor(public payload?: any) {}
}

export class GetNcinoAction implements Action {
  type = NcinoActionTypes.GET_NCINO;

  constructor(public payload?: any) {}
}

export class ImportNcinoAction implements Action {
  type = NcinoActionTypes.IMPORT_NCINO;

  constructor(public ids: string[]) {}
}

export class GetNcinoSuccessAction implements Action {
  type = NcinoActionTypes.GET_NCINO_SUCCESS;

  constructor(public payload: any) {}
}

export class GetNcinoFailureAction implements Action {
  type = NcinoActionTypes.GET_NCINO_FAILURE;

  constructor(public payload: any) {}
}

export class ImportNcinoSuccessAction implements Action {
  type = NcinoActionTypes.IMPORT_NCINO_SUCCESS;

  constructor(public payload: any) {}
}

export class ImportNcinoFailureAction implements Action {
  type = NcinoActionTypes.IMPORT_NCINO_FAILURE;

  constructor(public payload: any) {}
}

export type NcinoActions =
  | ConnectNcinoList
  | DisconnectNcinoList
  | GetNcinoAction
  | GetNcinoSuccessAction
  | ImportNcinoAction
  | GetNcinoFailureAction;
