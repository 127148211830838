import { Injectable, NgModule } from '@angular/core';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { QtCoreModule } from '@qtek/libs/app-component';

import {
  CoreFeature,
  MenuEffects,
  MenuFeature,
  MetaEffects,
  MetaFeature,
  PaymentPlanEffects,
  PaymentPlanFeature,
  UserEffects,
  UserFeature,
} from '@qtek/libs/store';

import {
  APP_BUNDLE,
  APP_ENVIRONMENT,
  APP_VERSION,
  ENVIRONMENT_APP_NAME,
} from '@qtek/core/api-core';
import { adapterFactory, DateAdapter } from '@qtek/shared/utils';

import { BUNDLE, environment, VERSION } from '@env';
import { WEBSOCKET_BASE_URL } from '@qtek/core/websockets-core';
import { DebugTranslationsDI } from '@qtek/libs/translation-core';
import { HTTP_BASE_URL } from '@qtek/shared/models';

const ENVIRONMENT_APP_NAME_VALUE = environment.appName;
const APP_ENVIRONMENT_VALUE = environment;
const APP_VERSION_VALUE = VERSION;
const APP_BUNDLE_VALUE = BUNDLE;
const debugTranslation = environment.debugTranslations;

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

//  HTTP

const rest = environment.rest;
export const HTTP_BASE_URL_VALUE =
  rest.protocol +
  '://' +
  rest.hostname +
  (rest.port ? ':' + String(rest.port) : '') +
  (rest.path ? rest.path : '');

// WS
// export const WEBSOCKET_BASE_URL = new InjectionToken<string>('WEBSOCKET_BASE_URL');

const websocket = environment.websocket;

export const WEBSOCKET_BASE_URL_VALUE = websocket
  ? websocket.protocol +
    '://' +
    websocket.hostname +
    (websocket.port ? ':' + String(websocket.port) : '') +
    (websocket.path ? websocket.path : '')
  : '';

@NgModule({
  imports: [QtCoreModule],
  exports: [QtCoreModule],
  providers: [
    {
      provide: HTTP_BASE_URL,
      useValue: HTTP_BASE_URL_VALUE,
    },
    {
      provide: WEBSOCKET_BASE_URL,
      useValue: WEBSOCKET_BASE_URL_VALUE,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: DateAdapter,
      useFactory: adapterFactory,
    },
    {
      provide: ENVIRONMENT_APP_NAME,
      useValue: ENVIRONMENT_APP_NAME_VALUE,
    },
    {
      provide: APP_ENVIRONMENT,
      useValue: APP_ENVIRONMENT_VALUE,
    },
    {
      provide: APP_BUNDLE,
      useValue: APP_BUNDLE_VALUE,
    },
    {
      provide: APP_VERSION,
      useValue: APP_VERSION_VALUE,
    },
    {
      provide: DebugTranslationsDI,
      useValue: debugTranslation ?? false,
    },
    provideState(CoreFeature),
    provideState(UserFeature),
    provideState(MetaFeature),
    provideState(MenuFeature),
    provideState(PaymentPlanFeature),
    provideEffects([UserEffects, MetaEffects, MenuEffects, PaymentPlanEffects]),
  ],
})
export class CoreModule {}
