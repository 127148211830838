import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { entityName } from '@qtek/shared/models';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NoteCompanyService } from './note-company.service';
import { NoteUserService } from './note-user.service';
import { NoteService } from './note.service';

import {
  CreateNoteAction,
  CreateNoteCompanyFailureAction,
  CreateNoteCompanySuccessAction,
  CreateNoteFailureAction,
  CreateNoteSuccessAction,
  CreateNoteUserFailureAction,
  CreateNoteUserSuccessAction,
  DeleteNoteAction,
  DeleteNoteCompanyFailureAction,
  DeleteNoteCompanySuccessAction,
  DeleteNoteFailureAction,
  DeleteNoteSuccessAction,
  DeleteNoteUserFailureAction,
  DeleteNoteUserSuccessAction,
  GetNotesCompanyFailureAction,
  GetNotesCompanySuccessAction,
  GetNotesFailureAction,
  GetNotesSuccessAction,
  GetNotesUserFailureAction,
  GetNotesUserSuccessAction,
  NoteActionTypes,
  UpdateNoteAction,
  UpdateNoteCompanyFailureAction,
  UpdateNoteCompanySuccessAction,
  UpdateNoteFailureAction,
  UpdateNoteSuccessAction,
  UpdateNoteUserFailureAction,
  UpdateNoteUserSuccessAction,
} from './note.actions';

@Injectable()
export class NoteEffects {
  tagEntityName: entityName = 'tag';

  constructor(
    private actions$: Actions,
    private noteService: NoteService,
    private noteUserService: NoteUserService,
    private noteCompanyService: NoteCompanyService
  ) {}

  connectNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NoteActionTypes.CONNECT_NOTE),
      switchMap(({ payload }: any) =>
        this.noteService.subscribe({ ...payload, s: 'model' }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(NoteActionTypes.DISCONNECT_NOTE),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ res, op, sts, mysid, id }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new GetNotesSuccessAction({
                  mysid,
                  notes: res ?? [],
                  meta: sts?.meta,
                })
              : new GetNotesFailureAction(sts);
          case 'ins':
            return res
              ? new CreateNoteSuccessAction({ mysid, note: res ?? [] })
              : new CreateNoteFailureAction(sts);
          case 'upd':
            return res
              ? new UpdateNoteSuccessAction({ mysid, note: res ?? [] })
              : new UpdateNoteFailureAction(sts);
          case 'del':
            return id
              ? new DeleteNoteSuccessAction({ mysid, id })
              : new DeleteNoteFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  connectNoteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NoteActionTypes.CONNECT_NOTE_USER),
      switchMap(({ payload }: any) =>
        this.noteUserService.subscribe({ ...payload, s: 'model' }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(NoteActionTypes.DISCONNECT_NOTE_USER),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ res, op, sts, mysid, id }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new GetNotesUserSuccessAction({
                  mysid,
                  notes: res ?? [],
                  meta: sts?.meta,
                })
              : new GetNotesUserFailureAction(sts);
          case 'ins':
            return res
              ? new CreateNoteUserSuccessAction({ mysid, note: res ?? [] })
              : new CreateNoteUserFailureAction(sts);
          case 'upd':
            return res
              ? new UpdateNoteUserSuccessAction({ mysid, note: res ?? [] })
              : new UpdateNoteUserFailureAction(sts);
          case 'del':
            return id
              ? new DeleteNoteUserSuccessAction({ mysid, id })
              : new DeleteNoteUserFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  connectNoteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NoteActionTypes.CONNECT_NOTE_COMPANY),
      switchMap(({ payload }: any) =>
        this.noteCompanyService.subscribe({ ...payload, s: 'model' }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(NoteActionTypes.DISCONNECT_NOTE_COMPANY),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ res, op, sts, mysid, id }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new GetNotesCompanySuccessAction({
                  mysid,
                  notes: res ?? [],
                  meta: sts?.meta,
                })
              : new GetNotesCompanyFailureAction(sts);
          case 'ins':
            return res
              ? new CreateNoteCompanySuccessAction({ mysid, note: res ?? [] })
              : new CreateNoteCompanyFailureAction(sts);
          case 'upd':
            return res
              ? new UpdateNoteCompanySuccessAction({ mysid, note: res ?? [] })
              : new UpdateNoteCompanyFailureAction(sts);
          case 'del':
            return id
              ? new DeleteNoteCompanySuccessAction({ mysid, id })
              : new DeleteNoteCompanyFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  createNote$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateNoteAction>(NoteActionTypes.CREATE_NOTE),
        tap<CreateNoteAction>(({ payload }) =>
          this.noteService.createNote(
            payload.note,
            payload.refId,
            payload.mysid
          )
        )
      ),
    { dispatch: false }
  );

  updateNote$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateNoteAction>(NoteActionTypes.UPDATE_NOTE),
        tap<UpdateNoteAction>(({ payload }) =>
          this.noteService.updateNote(payload.note, payload.mysid)
        )
      ),
    { dispatch: false }
  );

  deleteNote$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteNoteAction>(NoteActionTypes.DELETE_NOTE),
        tap<DeleteNoteAction>(({ payload }) =>
          this.noteService.deleteNote(payload.id, payload.mysid)
        )
      ),
    { dispatch: false }
  );
}
