import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendResponse, DocNode } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private http: HttpClient) {}

  updateLogo(payload: any) {
    return this.http.put<BackendResponse<DocNode>>(
      '/api/v1/service/lgo',
      payload,
      { withCredentials: true }
    );
  }
}
