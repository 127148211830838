import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PopoverModule } from '@qtek/libs/popover';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import { AutoFocusDirective } from '@qtek/shared/directives';
import {
  IconPickerComponent,
  IconPickerDirective,
  IconPickerService,
} from './src';
import { ConvertToRowsPipe } from './src/convert-to-rows.pipe';
import { SearchIconPipe } from './src/search-icon.pipe';
import { TextDirective } from './src/text.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    PopoverModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslationCoreModule,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    AutoFocusDirective,
  ],
  providers: [IconPickerService],
  declarations: [
    IconPickerComponent,
    IconPickerDirective,
    TextDirective,
    SearchIconPipe,
    ConvertToRowsPipe,
  ],
  exports: [IconPickerDirective],
})
export class NgxIconPickerModule {}
