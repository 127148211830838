import { ConnectionPositionPair } from '@angular/cdk/overlay';

export enum PopoverPositionsENUM {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export type PopoverPositionLabel = keyof typeof PopoverPositionsENUM;

export function getPopoverPositionPairs(
  positions: PopoverPositionLabel[],
  arrowOffset = 0,
  panelOffset = 0
): ConnectionPositionPair[] {
  return positions.map((position: PopoverPositionLabel) => PopoverPositionConfigs[position](arrowOffset, panelOffset));
}

export const PopoverPositionConfigs = {
  [PopoverPositionsENUM.TOP_LEFT]: (arrowOffset: number, panelOffset: number) =>
    ({
      overlayX: 'start',
      overlayY: 'bottom',
      originX: 'center',
      originY: 'top',
      panelClass: ['bottom', 'left'],
      offsetX: -1 * arrowOffset,
      offsetY: -1 * panelOffset,
    } as ConnectionPositionPair),

  [PopoverPositionsENUM.TOP_CENTER]: (arrowOffset: number, panelOffset: number) =>
    ({
      overlayX: 'center',
      overlayY: 'bottom',
      originX: 'center',
      originY: 'top',
      panelClass: ['bottom', 'center'],
      offsetY: -1 * panelOffset,
    } as ConnectionPositionPair),

  [PopoverPositionsENUM.TOP_RIGHT]: (arrowOffset: number, panelOffset: number) =>
    ({
      overlayX: 'end',
      overlayY: 'bottom',
      originX: 'center',
      originY: 'top',
      panelClass: ['bottom', 'right'],
      offsetX: arrowOffset,
      offsetY: -1 * panelOffset,
    } as ConnectionPositionPair),

  [PopoverPositionsENUM.BOTTOM_CENTER]: (arrowOffset: number, panelOffset: number) =>
    ({
      overlayX: 'center',
      overlayY: 'top',
      originX: 'center',
      originY: 'bottom',
      panelClass: ['top', 'center'],
      offsetY: panelOffset,
    } as ConnectionPositionPair),

  [PopoverPositionsENUM.BOTTOM_LEFT]: (arrowOffset: number, panelOffset: number) =>
    ({
      overlayX: 'start',
      overlayY: 'top',
      originX: 'start',
      originY: 'bottom',
      panelClass: ['top', 'left'],
      offsetX: -1 * arrowOffset,
      offsetY: panelOffset,
    } as ConnectionPositionPair),

  [PopoverPositionsENUM.BOTTOM_RIGHT]: (arrowOffset: number, panelOffset: number) =>
    ({
      overlayX: 'end',
      overlayY: 'top',
      originX: 'center',
      originY: 'bottom',
      panelClass: ['top', 'right'],
      offsetX: arrowOffset,
      offsetY: panelOffset,
    } as ConnectionPositionPair),
};
