import { Injectable } from '@angular/core';
import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ETLMapService {
  entityName: WebsocketEntTypes = 'etlmap';
  wsManager = new WSEntityManager(this.wsService, this.entityName);

  constructor(private wsService: WebSocketService) {}

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  getEtlMapWS() {
    this.wsManager.query({ s: 'model', view: 'flat' });
  }
}
