<div
  fxLayout
  fxLayoutAlign="start center"
  fxLayoutGap="1rem"
>
  <mat-icon [matTooltip]="'GNR_DARK_THEME' | translate">
    bedtime
  </mat-icon>
  <mat-slide-toggle
    (change)="changeTheme($event)"
    [checked]="isDarkTheme$ | async"
  >
    {{ 'GNR_DARK_THEME' | translate }}
  </mat-slide-toggle>
</div>