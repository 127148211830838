import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  DocNode,
  DocumentType,
  PreUploadPayload,
  PreUploadState,
  PreUploadTokenRes,
} from '@qtek/shared/models';

export const DocumentActions = createActionGroup({
  source: 'Docs',
  events: {
    'Connect Documents List': props<{
      payload: { refId: string; _attrs: any };
    }>(),
    'Disconnect Documents List': emptyProps(),
    'Get Entity Documents WS': props<{ payload?: any }>(),
    'Get Item Documents': props<{
      payload?: { refId: string; type?: DocumentType };
    }>(),
    'Update Document WS': props<{ payload: { id: string; res: any } }>(),
    'Delete Document WS': props<{ payload: { id: string } }>(),
    'Get Entity Documents WS Success': props<{ payload: DocNode[] }>(),
    'Upload Documents WS Success': props<{ payload: DocNode }>(),
    'Delete Document WS Success': props<{ payload: DocNode }>(),
    'Update Document WS Success': props<{ payload: DocNode }>(),
    'Create Folder Success WS': props<{ payload: DocNode }>(),
    'Delete Folder Success WS': props<{ payload: DocNode }>(),
    'Update Folder Success WS': props<{ payload: DocNode }>(),
    'Upload Documents WS Failure': props<{ payload: any }>(),
    'Delete Document WS Failure': props<{ payload: DocNode }>(),
    'Update Document WS Failure': props<{ payload: any }>(),
    'Create Folder Failure WS': props<{ payload: DocNode }>(),
    'Delete Folder Failure WS': props<{ payload: DocNode }>(),
    'Update Folder Failure WS': props<{ payload: DocNode }>(),
    'Update Company Logo': props<{ payload: any }>(),
    'Update Company Logo Success': props<{ payload: DocNode }>(),
    'Update Company Logo Failure': props<{ payload?: any }>(),
    'Update Personal Avatar': props<{ payload: any }>(),
    'Update Personal Avatar Success': props<{ payload: DocNode }>(),
    'Update Personal Avatar Failure': props<{ payload?: any }>(),
    'Get Entity Documents': props<{
      payload?: { refId: string; type?: DocumentType; _v?: string };
    }>(),
    'Get Entity Documents Success': props<{ payload: DocNode[] }>(),
    'Get Entity Documents Failure': emptyProps(),
    'Get Logos': emptyProps(),
    'Get Logos Success': props<{ payload: string[] }>(),
    'Pre Upload Document': props<{
      payload: { data: PreUploadPayload; file: File };
    }>(),
    'Pre Upload Document Success': props<{
      payload: { res: PreUploadTokenRes; file: File };
    }>(),
    'Pre Upload Document Failure': props<{ payload?: any }>(),
    'Pre Upload Document Update': props<{
      payload: { tkn: string; toUpdate: Partial<PreUploadState> };
    }>(),
    'Pre Upload Document Delete': props<{ payload: string }>(),
    'Pre Upload Document Delete All By Ids': props<{ payload: string[] }>(),
    'Pre Upload Document Delete All By Ref': props<{ payload: string }>(),
    'Pre Upload Document Delete Success': props<{ payload?: string }>(),
    'Pre Upload Document Delete Failure': props<{ payload?: any }>(),
    'Upload Documents': props<{
      payload: { data: FormData; attr?: DocumentType; id?: string };
    }>(),
    'Upload Documents Success': props<{ payload: DocNode[] }>(),
    'Upload Documents Failure': props<{ payload?: any }>(),
    'Delete Document': props<{
      payload: { docId: string; attr: DocumentType; refId: string };
    }>(),
    'Delete Document Success': props<{
      payload: { docId: string; attr: DocumentType };
    }>(),
    'Delete Document Failure': props<{ payload?: any }>(),
    'Bulk Delete': props<{ payload?: any[] }>(),
    'Bulk Delete Success': props<{ payload: string[] }>(),
    'Bulk Delete Failure': props<{ payload?: any }>(),
    'Bulk Download': props<{ payload?: any[] }>(),
    'Bulk Download Success': props<{ payload: string[] }>(),
    'Bulk Download Failure': props<{ payload?: any }>(),
    'Send Document': props<{ payload: { id: string; destination: string } }>(),
    'Send Document Success': props<{
      payload: { response: any; id: string };
    }>(),
    'Send Document Failure': props<{ payload: any }>(),
    'Update Document Description': props<{
      payload: { id: string; description: string };
    }>(),
    'Update Document Description Success': props<{
      payload: { id: string; description: string };
    }>(),
    'Update Document Description Failure': props<{ payload: any }>(),
  },
});
