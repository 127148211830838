import { HttpRequest } from '@angular/common/http';

/**
 * Check request if it has header of given name.
 *
 * TODO(marcincichocki): remove when {@link HttpRequest}'s metadata will be available.
 *
 * ```typescript
 * const { newReq, hasMetadata: skipErrorHandling } = checkRequestMetadata(req, 'X-Skip-Error-Handling')
 *
 * return next.handle(newReq).pipe(
 *   catchError((response) => {
 *     if (skipErrorHandling) {
 *       return throwError(response);
 *     }
 *
 *      return this._handleError(response);
 *   })
 * )
 * ```
 *
 * @param req Request to be checked.
 * @param metadataKey Metadata key(header).
 * @returns Object with new request and flag that determines if metadata key is set.
 */
export function checkRequestMetadata(
  req: HttpRequest<any>,
  metadataKey: string
) {
  /**
   * Using custom headers as metadata isn't a thing yet.
   */
  const hasMetadata = req.headers.has(metadataKey);

  if (hasMetadata) {
    /**
     * Remove custom header so it will not be sent to server.
     *
     * This means that only one interceptor can use logic based on that header.
     * Might change when we move to actual metadata, as it is not sent to server.
     */
    const headers = req.headers.delete(metadataKey);
    const newReq = req.clone({ headers });

    return { newReq, hasMetadata: true };
  }

  return { newReq: req, hasMetadata: false };
}
