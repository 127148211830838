import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  createNgModule,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Message } from '@qtek/shared/models';
import { DynamicDrawer, OnlineService } from '@qtek/shared/services';

import { LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { HealthcheckCoreService } from '@qtek/libs/healthcheck-core';
import {
  AppState,
  CloseLoadingAction,
  GetDemoStepsAction,
  GetStepsAction,
  OpenLoadingAction,
  SetWizardIsDone,
  UpdateCurrentUserAction,
  WizardActionTypes,
  getMainCompany,
  getUserActions,
  isDemoDataDone,
  isWizardDone,
} from '@qtek/libs/store';
import { QtNetError } from '@qtek/shared/components';
import { UiTourService } from '@qtek/shared/services';
import {
  Observable,
  Subject,
  combineLatest,
  forkJoin,
  from,
  of,
  zip,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'qt-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['./layout.component.scss'],
  /** Provide service directly to destroy with component */
  providers: [UiTourService],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  actualStepNumber: number;
  maxStepNumber: number;
  isUiTourOpen: boolean;
  mini$: Observable<boolean>;
  wizardOpened: boolean;
  destroy$ = new Subject<void>();
  networkError$: Observable<QtNetError | null> = of(null);

  @ViewChild('childDiv', { static: true }) childDiv: ElementRef;
  @ViewChild('dynamicSidenav') dynamicSidenav: MatSidenav;
  @ViewChild(CdkPortalOutlet) outlet: CdkPortalOutlet;
  @ViewChild('tutorialTemplateRef') tutorialTemplateRef: TemplateRef<any>;

  constructor(
    public router: Router,
    public uiTourService: UiTourService,

    private idle: Idle,
    private actions$: Actions,
    private injector: Injector,
    private titleService: Title,
    private matDialog: MatDialog,
    private online: OnlineService,
    private store: Store<AppState>,
    private dynamicDrawer: DynamicDrawer,
    private observer: BreakpointObserver,
    private dialogService: LazyDialogLoader,
    private healthCheck: HealthcheckCoreService
  ) {}

  ngOnInit() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.mini$ = combineLatest([
      this.dynamicDrawer.showSidenav$,
      this.observer.observe(Breakpoints.XSmall),
    ]).pipe(
      map(([showSidenav, breakpoint]) =>
        Boolean(!breakpoint.matches && showSidenav)
      ),
      shareReplay()
    );

    this.startWizard();
    this.startWatch();

    /** Update title to company name. */
    this.store
      .pipe(select(getMainCompany), takeUntil(this.destroy$))
      .subscribe(company => {
        if (company?.name?.name) {
          this.titleService.setTitle(company.name.name);
        }
      });

    this.uiTourService.refreshObservable
      .pipe(takeUntil(this.destroy$), debounceTime(0))
      .subscribe(() => {
        this.actualStepNumber = this.uiTourService.uiStepIndex + 1;
        this.maxStepNumber = this.uiTourService.visibleSteps.length;
        this.isUiTourOpen =
          this.uiTourService.isOpen && this.uiTourService.wizardDone;
      });

    this.networkError$ = combineLatest([
      this.healthCheck.isWebSocketAlive$,
      this.healthCheck.isHealthyHttpConnection$,
      this.online.change$,
    ]).pipe(
      map(([isWSHealth, isHttpHealth, isOnline]) => {
        if (!isOnline) {
          return 'offline';
        }
        if (!isHttpHealth) {
          return 'http';
        }
        if (!isWSHealth) {
          return 'ws';
        }
        return null;
      }),
      distinctUntilChanged(),
      debounceTime(250)
    );
  }

  ngAfterViewInit() {
    this.dynamicDrawer.register({
      matDrawer: this.dynamicSidenav,
      cdkPortalOutlet: this.outlet,
    });

    this.uiTourService.templateRef = this.tutorialTemplateRef;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  next(): void {
    this.uiTourService.next();
  }

  back(): void {
    this.uiTourService.back();
  }

  finishUiTour() {
    const payload = {
      form: {},
      additionalPayload: {
        _attrv: 'usr',
        _uitut: this.uiTourService.progress,
      },
    };
    this.uiTourService.isTourOpen = false;
    this.store.dispatch(new UpdateCurrentUserAction(payload));
  }

  /**
   * Starts watcher for idle status.
   *
   * FIXME: this method should start only after wizard is completed.
   *
   * @private
   * @param {number} [idle=1500] Time in seconds to mark session as idle
   * @param {number} [timeout=10] Time in seconds to trigger timeout(after idle)
   * @memberof LayoutComponent
   */
  private startWatch(idle = 1500, timeout = 10): void {
    this.idle.setIdle(idle);
    this.idle.setTimeout(timeout);

    this.idle.onTimeout
      .pipe(
        take(1),
        mergeMap(() => this.router.navigateByUrl('/')),
        mergeMap(() => {
          const dialogsToClose = this.matDialog.openDialogs.filter(
            dialog => !dialog.disableClose
          );
          if (dialogsToClose) {
            dialogsToClose.forEach(dialog => dialog.close());
          }

          return this.dialogService.open('alert', {
            message: new Message('MSG_AUTH_IDLE_REDIRECT'),
          });
        })
      )
      .subscribe(() => {
        this.startWatch();
      });

    this.idle.watch();
  }

  /**
   * Load wizard module and display dialog.
   */
  private openWizard() {
    this.store.dispatch(new OpenLoadingAction());
    return from(import('@qtek/feature/wizard')).pipe(
      mergeMap(({ WizardModule: WizardModule }) => {
        const moduleRef = createNgModule(WizardModule, this.injector);
        this.wizardOpened = true;
        return moduleRef.instance.openWizard().pipe(
          tap(() => {
            this.store.dispatch(new CloseLoadingAction());
            this.wizardOpened = false;
          })
        );
      })
    );
  }

  private startWizard() {
    /** Load wizard and demo steps */
    this.store.dispatch(new GetStepsAction());
    this.store.dispatch(new GetDemoStepsAction());

    const wizardDone = this.store.pipe(select(isWizardDone));
    const demoDone = this.store.pipe(select(isDemoDataDone));
    const success = forkJoin([
      this.actions$.pipe(ofType(WizardActionTypes.GET_STEPS_SUCCESS), take(1)),
      this.actions$.pipe(
        ofType(WizardActionTypes.GET_DEMO_STEPS_SUCCESS),
        take(1)
      ),
    ]);

    success
      .pipe(
        mergeMap(() => zip(wizardDone, demoDone)),
        take(1),
        map(([wizardDone, demoDone]) => wizardDone && demoDone),
        mergeMap((done: boolean) => {
          if (!done) {
            return this.openWizard();
          } else {
            this.store.dispatch(new CloseLoadingAction());
            return of(null);
          }
        }),
        mergeMap(() => {
          this.uiTourService.wizardDone = true;
          this.uiTourService.canOpenTour = this.uiTourService.canOpenTourValue;

          return this.store.select(getUserActions);
        }),
        tap(() => this.store.dispatch(new SetWizardIsDone())),
        filter(value => value && !this.wizardOpened),
        take(1),
        filter(res => res[0] != null && res[0].params != null),
        map(res => res[0].params),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
