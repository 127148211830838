import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BackendResponse } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private http: HttpClient) {}

  /**
   * Return meta data of the application.
   *
   * @returns {Observable<BackendResponse>}
   *
   * @memberof MetaService
   */
  getMetaData(prms: Array<string> = []) {
    const params = new HttpParams().append('a', prms.join('-'));
    return this.http.get<BackendResponse>('/api/v1/service/meta', {
      params,
      withCredentials: true,
    });
  }

  /**
   * Get list of countries
   *
   */
  getMetaDataCountries() {
    return this.http.get<BackendResponse>(
      '/api/v1/service/meta?_attrv=ctrs&tp=pmt',
      { withCredentials: true }
    );
  }

  /**
   * Get meta info for country
   *
   */
  getMetaDataCountryInfo(id: string) {
    return this.http.get<BackendResponse>(
      `/api/v1/service/meta?_attrv=ctry&id=${id}`,
      { withCredentials: true }
    );
  }
}
