import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import {
  BackendResponse,
  PersonOutput,
  AccountOutput,
  Customer,
} from '@qtek/shared/models';

interface User {
  prs: PersonOutput;
  acn?: AccountOutput;
  rel?: Customer;
  sid?: string;
  logoDocId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseRequestOptions = {
    withCredentials: true,
  };

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  /**
   * Make request to auth server, and set cookie in on success.
   *
   * @param {*} payload
   * @returns
   *
   * @memberof AuthService
   */
  login(payload: any) {
    const params = this.getUrlParams();

    return this.http.post<BackendResponse<User>>(
      '/api/v1/service/auth',
      payload,
      {
        ...this.baseRequestOptions,
        params,
      }
    );
  }

  /**
   * Log out current user.
   *
   * @returns {Observable<any>}
   *
   * @memberof AuthService
   */
  logout(): Observable<any> {
    return this.http.get('/api/v1/service/logout', this.baseRequestOptions);
  }

  /**
   * Register new user
   *
   * TODO: Add types.
   *
   * @param {*} payload
   * @returns
   *
   * @memberof AuthService
   */
  register(payload: any) {
    const params = this.getUrlParams();

    return this.http.post<BackendResponse>('/api/v1/service/prs', payload, {
      params,
    });
  }

  /**
   * Reset password.
   *
   * @param {*} payload
   * @returns
   *
   * @memberof AuthService
   */
  resetPassword(payload: any) {
    return this.http.post<BackendResponse>('/api/v1/service/rst', payload);
  }

  /**
   * Send email with link to change password.
   *
   * @param {*} payload
   * @returns {Observable<BackendResponse>}
   *
   * @memberof AuthService
   */
  sendResetPasswordEmail(payload: any) {
    return this.http.post<BackendResponse>('/api/v1/service/rstinit', payload);
  }

  private getUrlParams(): HttpParams {
    const personId = this.route.snapshot.queryParams['prsId'];
    const rid = this.route.snapshot.queryParams['rid'];

    let params = new HttpParams();

    if (personId) {
      params = params.append('prsId', personId);
    }

    if (rid) {
      params = params.append('rid', rid);
    }

    return params;
  }

  getWsSessionInitToken() {
    return this.http.get<BackendResponse<{ wsTkn: string }>>(
      '/api/v1/service/wsTkn',
      this.baseRequestOptions
    );
  }
}
