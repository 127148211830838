/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from '@qtek/shared/models';

export const VERSION = new Version('1.36.56');

export const BUNDLE: Bundle = {
  date: 1743424207736,
  commitSha: 'e0a67b1c4afd01ceb0b176f89d648ed2d67bd553',
  version: VERSION,
};
