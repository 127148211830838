import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import * as DocumentsActions from '../documents/documents.actions';
import * as UserActions from '../user/user.actions';
import { CompanyService } from './company.service';
import { DemoDataService } from './demo-data.service';
import { DocumentsService } from './documents.service';
import { PersonService } from './person.service';
import * as WizardActions from './wizard.actions';
import { WizardService } from './wizard.service';

@Injectable()
export class WizardEffects {
  wizard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.WizardActionTypes.GET_STEPS),
      switchMap(() =>
        this.wizardService
          .getSteps()
          .pipe(
            concatMap(({ res }) =>
              from([
                new WizardActions.SetMaxPersonProgressAction(res.meta.progress[0].value),
                new WizardActions.SetMaxCompanyProgressAction(res.meta.progress[1] ? res.meta.progress[1].value : 0),
                new WizardActions.GetStepsSuccessAction(res.children),
              ])
            )
          )
      )
    )
  );

  demoData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.WizardActionTypes.GET_DEMO_STEPS),
      switchMap(() => this.demoDataService.getData()),
      map(({ res }) => new WizardActions.GetDemoStepsSuccessAction(res ? res.steps : undefined))
    )
  );

  prs1$ = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepPrs1Action>(WizardActions.WizardActionTypes.STEP_PRS_1),
      switchMap(({ payload }) => this.personService.updatePerson(payload.form, payload.additionalPayload)),
      map(({ res }) => new UserActions.UpdateCurrentUserSuccessAction(res)),
      catchError(({ sts }) => of(new UserActions.UpdateCurrentUserFailureAction(sts)))
    )
  );

  cmp1$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepCmp1Action>(WizardActions.WizardActionTypes.STEP_CMP_1),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(payload.additionalPayload, payload.form, payload.id)
      ),
      map(({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)),
      catchError(({ sts }) => of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts)))
    )
  );

  cmp2$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepCmp2Action>(WizardActions.WizardActionTypes.STEP_CMP_2),
      switchMap(({ payload }) =>
        this.documentsService.updateLogo(payload).pipe(
          map(({ res }) => new DocumentsActions.UpdateCompanyLogoSuccessAction(res)),
          catchError(({ sts }) => of(new DocumentsActions.UpdateCompanyLogoFailureAction(sts)))
        )
      )
    )
  );

  cmp64$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepCmp64Action>(WizardActions.WizardActionTypes.STEP_CMP_64),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(payload.additionalPayload, payload.form, payload.id)
      ),
      map(({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)),
      catchError(({ sts }) => of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts)))
    )
  );

  cmp8$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepCmp8Action>(WizardActions.WizardActionTypes.STEP_CMP_8),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(payload.additionalPayload, payload.form, payload.id)
      ),
      map(({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)),
      catchError(({ sts }) => of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts)))
    )
  );

  cmp260$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepCmp260Action>(WizardActions.WizardActionTypes.STEP_CMP_260),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(payload.additionalPayload, payload.form, payload.id)
      ),
      map(({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res))
    )
  );

  cmp1536$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepCmp1536Action>(WizardActions.WizardActionTypes.STEP_CMP_1536),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(payload.additionalPayload, payload.form, payload.id)
      ),
      map(({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)),
      catchError(({ sts }) => of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts)))
    )
  );

  cmp2048$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.StepCmp2048Action | WizardActions.StepCmp4096Action>(
        WizardActions.WizardActionTypes.STEP_CMP_2048,
        WizardActions.WizardActionTypes.STEP_CMP_4096
      ),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(payload.additionalPayload, payload.form, payload.id)
      ),
      map(({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)),
      catchError(({ sts }) => of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts)))
    )
  );

  savePromo$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<WizardActions.SavePromoAction>(WizardActions.WizardActionTypes.SAVE_PROMO),
      switchMap(({ payload }) => this.wizardService.savePromo(payload)),
      map(() => new WizardActions.SavePromoSuccessAction()),
      catchError(({ sts }) => of(new WizardActions.SavePromoFailureAction(sts)))
    )
  );

  constructor(
    private actions$: Actions,
    private wizardService: WizardService,
    private personService: PersonService,
    private companyService: CompanyService,
    private documentsService: DocumentsService,
    private demoDataService: DemoDataService
  ) {}
}
