import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as IntegrationsActions from './integrations.actions';
import { IntegrationsService } from './integrations.service';

@Injectable()
export class IntegrationsEffects {
  getCalendars$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.IntegrationsActionTypes.GET_CALENDARS),
      switchMap(() =>
        this.integrationsService.getAvailableCalendars().pipe(
          map((response) => new IntegrationsActions.GetCalendarsSuccessAction(response.res)),
          catchError(({ sts }) => of(new IntegrationsActions.GetCalendarsFailureAction(sts)))
        )
      )
    )
  );

  getCalendar$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.GetCalendarAction>(IntegrationsActions.IntegrationsActionTypes.GET_CALENDAR),
      switchMap(({ payload }) =>
        this.integrationsService.getVendorCalendar(payload).pipe(
          map((response) => {
            if (
              response.sts &&
              response.sts.sts === 'WARN' &&
              response.sts.attrs &&
              response.sts.attrs['google-approval-prompt-required']
            ) {
              return new IntegrationsActions.CalendarApprovalErrorAction({
                vnd: payload,
              });
            } else if (response.sts && response.sts.sts === 'WARN') {
              return new IntegrationsActions.CalendarAuthErrorAction(payload);
            } else {
              return new IntegrationsActions.GetCalendarSuccessAction(response.res);
            }
          }),
          catchError(({ sts }) => of(new IntegrationsActions.GetCalendarFailureAction(sts)))
        )
      )
    )
  );

  getClover$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.GetCloverAction>(IntegrationsActions.IntegrationsActionTypes.GET_CLOVER),
      switchMap(() =>
        this.integrationsService.getClover().pipe(
          map((response) => {
            return new IntegrationsActions.GetCloverSuccessAction(response.res);
          }),
          catchError(({ sts }) => of(new IntegrationsActions.GetCloverFailureAction(sts)))
        )
      )
    )
  );

  getSalesforce$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.GetSalesforceAction>(IntegrationsActions.IntegrationsActionTypes.GET_SALESFORCE),
      switchMap(() =>
        this.integrationsService.getSalesforce().pipe(
          map((response) => {
            return new IntegrationsActions.GetSalesforceSuccessAction(response.res);
          }),
          catchError(({ sts }) => of(new IntegrationsActions.GetSalesforceFailureAction(sts)))
        )
      )
    )
  );

  getMicrosoft$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.GetMicrosoftAction>(IntegrationsActions.IntegrationsActionTypes.GET_MICROSOFT),
      switchMap(() =>
        this.integrationsService.getMicrosoft().pipe(
          map((response) => {
            return new IntegrationsActions.GetMicrosoftSuccessAction(response.res);
          }),
          catchError(({ sts }) => of(new IntegrationsActions.GetMicrosoftFailureAction(sts)))
        )
      )
    )
  );

  connectCalendar$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.ConnectCalendarAction>(IntegrationsActions.IntegrationsActionTypes.CONNECT_CALENDAR),
      switchMap(({ payload }) =>
        this.integrationsService.connectCalendar(payload.vendor, payload.data).pipe(
          map(({ res }) => new IntegrationsActions.ConnectCalendarSuccessAction(res)),
          catchError(({ sts }) => of(new IntegrationsActions.ConnectCalendarFailureAction(sts)))
        )
      )
    )
  );

  disconnectCalendar$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.DisconnectCalendarAction>(
        IntegrationsActions.IntegrationsActionTypes.DISCONNECT_CALENDAR
      ),
      switchMap(({ payload }) =>
        this.integrationsService.disconnectCalendar(payload).pipe(
          map(
            () => new IntegrationsActions.DisconnectCalendarSuccessAction(payload),
            catchError(({ sts }) => of(new IntegrationsActions.DisconnectCalendarFailureAction(sts)))
          )
        )
      )
    )
  );

  updateCalendar$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.UpdateCalendarAction>(IntegrationsActions.IntegrationsActionTypes.UPDATE_CALENDAR),
      switchMap(({ payload }) =>
        this.integrationsService.updateCalendar(payload.vendor, payload.data).pipe(
          map((response) => {
            if (response.sts && response.sts.sts === 'WARN') {
              return new IntegrationsActions.CalendarAuthErrorAction(payload.vendor);
            } else {
              return new IntegrationsActions.UpdateCalendarSuccessAction(response.res);
            }
          }),
          catchError(({ sts }) => of(new IntegrationsActions.UpdateCalendarFailureAction(sts)))
        )
      )
    )
  );

  connectDebtX$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.ConnectDebtXAction>(IntegrationsActions.IntegrationsActionTypes.CONNECT_DEBTX),
      switchMap(({ payload }) =>
        this.integrationsService.connectDebtX(payload).pipe(
          map((response) => {
            if (response.sts && response.sts.sts === 'WARN') {
              return new IntegrationsActions.ConnectDebtXFailureAction(payload);
            } else {
              switch (response.res.status) {
                // case 'DEBTX_CONNECTED':
                //   return new IntegrationsActions.ConnectDebtXSuccessAction(response.res);
                // case 'DEBTX_WRONG_CREDENTIALS':
                //   return new IntegrationsActions.ConnectDebtXWrongCredentialsAction(response.res);
                // case 'DEBTX_MFA_REQUIRED':
                //   return new IntegrationsActions.ConnectDebtXMfaAction(response.res);
                case 'DEBTX_FAILURE':
                  return new IntegrationsActions.ConnectDebtXFailureAction(response);
                default:
                  return new IntegrationsActions.ConnectDebtXSuccessAction(response.res.status);
              }
            }
          }),
          catchError(res => of(new IntegrationsActions.ConnectDebtXFailureAction(res)))
        )
      )
    )
  );

  connectDebtXMfa$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.ConnectDebtXMfaAction>(IntegrationsActions.IntegrationsActionTypes.CONNECT_DEBTX_MFA),
      switchMap((action) =>
        this.integrationsService.connectDebtXMfa(action.mfaCode).pipe(
          map((response) => {
            if (response.sts && response.sts.sts === 'WARN') {
              return new IntegrationsActions.ConnectDebtXFailureAction(response)
            } else {
              return new IntegrationsActions.ConnectDebtXSuccessAction(response.res.status);
            }
          }),
          catchError(res => of(new IntegrationsActions.ConnectDebtXFailureAction(res)))
        )
      )
    )
  );

  getDebtXProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<IntegrationsActions.GetDebtXProfileAction>(IntegrationsActions.IntegrationsActionTypes.GET_DEBTX_PROFILE),
      switchMap(() =>
        this.integrationsService.getDebtX().pipe(
          map((response) => {
            return new IntegrationsActions.GetDebtXProfileSuccessAction(response.res.status, response.res.data);
          }),
          catchError(({ sts }) => of(new IntegrationsActions.GetDebtXProfileFailureAction(sts)))
        )
      )
    )
  );



  constructor(private actions$: Actions, private integrationsService: IntegrationsService) {}
}
