import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { WINDOW } from './window';

const BeforeInstallPrompt = 'beforeinstallprompt' as keyof WindowEventMap;
const AppInstalled = 'appinstalled' as keyof WindowEventMap;

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
export interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class Pwa {
  promptEvent: BeforeInstallPromptEvent;

  private beforeInstallPrompt = new BehaviorSubject(null);
  private appInstalled = new BehaviorSubject(null);

  readonly canInstall = combineLatest([
    this.beforeInstallPrompt.asObservable(),
    this.appInstalled.asObservable(),
  ]).pipe(
    map(([prompt, appInstalled]) => {
      return Boolean(prompt) && !appInstalled;
    })
  );

  constructor(@Inject(WINDOW) private window: Window) {}

  init() {
    this.window.addEventListener(BeforeInstallPrompt, (e: Event) => {
      e.preventDefault();
      this.promptEvent = e as BeforeInstallPromptEvent;

      this.beforeInstallPrompt.next(true);
    });

    window.addEventListener(AppInstalled, () => {
      this.appInstalled.next(true);
    });
  }

  prompt() {
    return this.promptEvent.prompt();
  }
}
