import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Injectable,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class Step {
  constructor(
    public url: RegExp,
    public description: string,
    public order: number
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class UiTourService implements OnDestroy {
  uiStep: string;
  uiStepIndex: number;
  isOpen: boolean;
  visibleSteps: Step[]; // steps visible on current page
  globalSteps: Step[]; // all steps
  refresh: Subject<void>;
  showButton: Subject<boolean>;
  tourOpen: ReplaySubject<boolean>;
  refreshObservable: Observable<any>;
  showButtonObservable: Observable<boolean>;
  tourOpenObservable: Observable<boolean>;
  wizardDone: boolean;
  currentUrl: string;
  canOpenTourValue: boolean;
  subscription: Subscription;
  overlayRef: OverlayRef;
  templateRef: TemplateRef<any>;
  progressValue: number;
  timeout: any;

  constructor(
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay
  ) {
    this.globalSteps = [];
    this.visibleSteps = [];
    this.uiStepIndex = 0;
    this.refresh = new Subject<void>();
    this.showButton = new Subject();
    this.tourOpen = new ReplaySubject(1);
    this.tourOpenObservable = this.tourOpen.asObservable();
    this.refreshObservable = this.refresh.asObservable();
    this.showButtonObservable = this.showButton
      .asObservable()
      .pipe(debounceTime(0));

    this.subscription = router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // check if component changed
        if (this.getUrlNoParams(event.url) !== this.currentUrl) {
          this.isTourOpen = false;
        }
      } else if (event instanceof NavigationEnd) {
        this.currentUrl = this.getUrlNoParams(event.url);
        this.initSteps();

        if (this.globalSteps.length) {
          this.visibleSteps = this.visibleSteps.concat(
            this.globalSteps.filter(step => step.url.test(this.router.url))
          );
          if (this.visibleSteps.length) {
            this.canOpenTour = true;
          }
          this.checkStep();
        }

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          if (
            !(this.progressValue & this.progress) &&
            this.visibleSteps.length &&
            this.canOpenTourValue &&
            !this.isOpen
          ) {
            this.openTour(this.templateRef);
          }
        }, 3000);
      }
    });
  }

  /** Open or close tour */
  set isTourOpen(value: boolean) {
    this.isOpen = value;

    this.tourOpen.next(value && this.wizardDone && this.canOpenTourValue);

    if (value) {
      this.checkStep();
    } else if (this.overlayRef) {
      this.overlayRef.detach();
    }

    this.refresh.next();
  }

  /** Tell if tour can be opened (show button) */
  set canOpenTour(value: boolean) {
    this.canOpenTourValue = value;

    this.showButton.next(value && this.wizardDone);
  }

  /**
   * Returns url without params
   *
   * @param url route url
   */
  getUrlNoParams(url: string): string {
    const paramsIndex = url.indexOf('?');
    if (paramsIndex !== -1) {
      return url.slice(0, paramsIndex);
    } else {
      return url;
    }
  }

  /** Opens tour */
  openTour(templateRef: TemplateRef<any>): void {
    if (this.canOpenTourValue && templateRef) {
      if (!this.overlayRef) {
        const config: OverlayConfig = this.getOverlayConfig();
        this.overlayRef = this.overlay.create(config);
        this.overlayRef.hostElement.style.zIndex = '9999';
        this.overlayRef.hostElement.style.pointerEvents = 'all';
      }
      const templatePortal = new TemplatePortal(
        templateRef,
        this.viewContainerRef
      );

      this.overlayRef.attach(templatePortal);

      this.isTourOpen = true;
    }
  }

  getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo({ x: 0, y: 0 })
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ])
        .withPush(false),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  /** Initialize tour */
  initSteps(): void {
    this.uiStepIndex = 0;
    this.visibleSteps = [];
  }

  addStep(
    description: string,
    regex: RegExp,
    visible: boolean,
    order = 1
  ): void {
    const newStep: Step = new Step(new RegExp(regex, 'i'), description, order);

    if (
      this.globalSteps.findIndex(step => step.description === description) ===
      -1
    ) {
      const sortedGlobal = [...this.globalSteps, newStep].sort(
        (step1, step2) => step1.order - step2.order
      );
      this.globalSteps = sortedGlobal;
    }

    if (
      visible &&
      this.visibleSteps.findIndex(step => step.description === description) ===
        -1
    ) {
      this.canOpenTour = true;
      const sortedVisible = [...this.visibleSteps, newStep].sort(
        (step1, step2) => step1.order - step2.order
      );
      this.visibleSteps = sortedVisible;
      this.checkStep();
    }
  }

  next(): void {
    if (this.uiStepIndex < this.visibleSteps.length - 1) {
      this.uiStepIndex++;
      this.uiStep = this.visibleSteps[this.uiStepIndex].description;
    } else {
      this.uiStepIndex = 0;
      this.uiStep = this.visibleSteps[this.uiStepIndex].description;
    }
    this.refresh.next();
  }

  back(): void {
    if (this.uiStepIndex > 0) {
      this.uiStepIndex--;
      this.uiStep = this.visibleSteps[this.uiStepIndex].description;
    } else {
      this.uiStepIndex = this.visibleSteps.length - 1;
      this.uiStep = this.visibleSteps[this.uiStepIndex].description;
    }
    this.refresh.next();
  }

  /** Check if current step is valid */
  checkStep(): void {
    this.uiStepIndex = this.visibleSteps.findIndex(
      step => step.description === this.uiStep
    );
    if (this.uiStepIndex === -1 && this.visibleSteps.length > 0) {
      this.next();
    } else if (!this.visibleSteps.length) {
      this.isTourOpen = false;
      this.canOpenTour = false;
    }
    this.refresh.next();
  }

  /** Returns progress for current route */
  get progress(): number {
    // TODO use directive input instead of route
    switch (true) {
      // dashboard
      case /\/{1}$/.test(this.router.url):
        return 1;

      // tables
      case /\/itms/i.test(this.router.url):
        return 2;

      // calendar
      case /\/cal/i.test(this.router.url):
        return 4;

      default:
        return 8;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
