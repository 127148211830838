import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WebsocketEntTypes,
  WebSocketService,
  WSEntityManager,
} from '@qtek/core/websockets-core';
import {
  BackendResponse,
  DocNode,
  DocumentType,
  Item,
  PreUploadPayload,
  PreUploadTokenRes,
} from '@qtek/shared/models';
import { EMPTY, Observable } from 'rxjs';
import { catchError, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    protected http: HttpClient,
    private websocketService: WebSocketService
  ) {}

  api = '/api/v1/model/docref';
  entityName: WebsocketEntTypes = 'docref';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  /* --- Websocket queries --- */

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, false);
  }

  getItemDocumentsWs(params?: any) {
    const { _v, ...rest } = params;
    this.wsManager.query({ prms: rest, view: _v });
  }

  updateDocumentWS(payload: any) {
    this.wsManager.update({ ...payload });
  }

  deleteDocumentWS(payload: any) {
    this.wsManager.delete({ id: payload.id });
  }

  /* --- Http requests --- */

  getDocumentsOfItem(refId: string, type: DocumentType) {
    return this.http
      .get<BackendResponse<DocNode[]>>(
        `${this.api}s/${refId}?_attrs=${type}&_v=flat`,
        {
          withCredentials: true,
        }
      )
      .pipe(catchError(() => EMPTY));
  }

  getDocumentsOfEntity(refId: string, type?: DocumentType) {
    return this.http
      .get<BackendResponse<DocNode[]>>(
        `${this.api}s/${refId}${type ? '?_attrs=' + type : ''}`,
        {
          withCredentials: true,
        }
      )
      .pipe(catchError(() => EMPTY));
  }

  deleteDocument(docId: string, refId: string) {
    return this.http.delete<BackendResponse<DocNode>>(
      `${this.api}/itm=${refId}/${docId}`,
      {
        withCredentials: true,
      }
    );
  }

  updateLogo(payload: any) {
    return this.http.put<BackendResponse<DocNode>>(
      '/api/v1/service/lgo',
      payload,
      { withCredentials: true }
    );
  }

  uploadDocuments(formData: FormData, type?: DocumentType) {
    if (type) formData.append('_attr', type.toString());
    return this.http.post<DocNode>('/api/v1/service/doc', formData, {
      withCredentials: true,
      reportProgress: true,
      observe: 'events',
    });
  }

  preUploadDocument(payload: PreUploadPayload) {
    return this.http.post<{ res: PreUploadTokenRes }>(
      '/api/v1/service/doc/tkn',
      payload,
      {
        withCredentials: true,
      }
    );
  }

  bulkDelete(payload: { _id: string; tp: 1 | 50 }[]) {
    return this.http.patch('/api/v1/service/docs/del', payload, {
      withCredentials: true,
    });
  }

  getLogoTemplates() {
    return this.http
      .get<BackendResponse<string[]>>('/api/v1/service/meta?_attrv=lgo', {
        withCredentials: true,
      })
      .pipe(catchError(() => EMPTY));
  }

  getPublicDocuments(entityId: string) {
    return this.http.get<BackendResponse<DocNode[]>>(
      `/api/v1/service/docs/${entityId}`,
      {
        withCredentials: true,
      }
    );
  }

  getItemDocuments(entityId: number): Observable<Item[]> {
    return this.http
      .get<BackendResponse<DocNode[]>>(
        `${this.api}/itm=${entityId}?_attrs=${DocumentType.ITEM_DOCUMENT}`,
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('res'));
  }

  sendDocument(docId: string, destination: string) {
    const params = new HttpParams().set('dst', btoa(destination));

    return this.http.post<BackendResponse>(
      `/api/v1/service/snd/doc/${docId}`,
      undefined,
      {
        withCredentials: true,
        params,
      }
    );
  }

  updateDocumentDescription(docId: string, description: string) {
    return this.http.post<BackendResponse>(
      `${this.api}/${docId}`,
      { desc: description },
      {
        withCredentials: true,
      }
    );
  }
}
