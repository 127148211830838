import { ElementRef, Injectable, InjectionToken, Injector, TemplateRef } from '@angular/core';

import { ComponentType, ConnectionPositionPair, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';

import { PopoverComponent } from '../components/popover/popover.component';
import { getPopoverPositionPairs, PopoverConfig, PopoverRef } from '../models';
import { take, tap } from 'rxjs';

const defaultConfig: PopoverConfig = {
  hasBackdrop: true,
  backdropClass: '',
  disableClose: false,
  panelClass: '',
  arrowOffset: 30,
  arrowSize: 20,
  positions: ['BOTTOM_CENTER', 'BOTTOM_LEFT', 'BOTTOM_RIGHT', 'TOP_CENTER', 'TOP_LEFT', 'TOP_RIGHT'],
};

/**
 * Injection token that can be used to access the data that was passed in to a popover.
 * */
export const POPOVER_DATA = new InjectionToken('popover.data1');

/**
 * Service to open and manage popovers.
 */
@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  currentPoppover: PopoverRef = undefined;

  constructor(private overlay: Overlay, private injector: Injector) {}

  closeCurrentPopover() {
    this.currentPoppover?.close();
  }

  open<D = any>(
    componentOrTemplate: ComponentType<any> | TemplateRef<any>,
    target: ElementRef | HTMLElement,
    config: Partial<PopoverConfig> = {}
  ): PopoverRef<D> {
    this.currentPoppover?.close();
    // TODO: fix default config not applying properly
    const popoverConfig: PopoverConfig = Object.assign({}, defaultConfig, config);

    const arrowSize = popoverConfig.arrowSize;
    const arrowOffset = popoverConfig.arrowOffset;
    const panelOffset = arrowSize / 2;

    // preferred positions, in order of priority
    const positions: ConnectionPositionPair[] = getPopoverPositionPairs(
      popoverConfig.positions,
      arrowOffset,
      panelOffset
    );

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(target)
      .withPush(false)
      .withFlexibleDimensions(false)
      .withPositions(positions);

    const overlayRef = this.overlay.create({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    const popoverRef = new PopoverRef(overlayRef, positionStrategy, popoverConfig);

    const popover = overlayRef.attach(
      new ComponentPortal(
        PopoverComponent,
        null,
        new PortalInjector(this.injector, new WeakMap<any, any>([[PopoverRef, popoverRef]]))
      )
    ).instance;

    if (componentOrTemplate instanceof TemplateRef) {
      // rendering a provided template dynamically
      popover.attachTemplatePortal(
        new TemplatePortal(componentOrTemplate, null, {
          $implicit: config.data,
          popover: popoverRef,
        })
      );
    } else {
      // rendering a provided component dynamically
      popover.attachComponentPortal(
        new ComponentPortal(
          componentOrTemplate,
          null,
          new PortalInjector(
            this.injector,
            new WeakMap<any, any>([
              [POPOVER_DATA, config.data],
              [PopoverRef, popoverRef],
            ])
          )
        )
      );
    }

    popoverRef
      .afterClosed()
      .pipe(
        take(1),
        tap(() => {
          popover.dispose();
        })
      )
      .subscribe();

    this.currentPoppover = popoverRef;
    return popoverRef;
  }
}
