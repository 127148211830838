<mat-menu #settingsMenu="matMenu" yPosition="above" class="context-menu">
  <ng-template matMenuContent>
    <ng-container *ngIf="userData$ | async as user">
      <div class="nav__account-info">
        <p class="nav__account-name">
          {{ user.name || ('GNR_LOGGED_IN' | translate) }}
        </p>
        <p *ngIf="user.company" class="nav__account-company">
          {{ user.company }}
        </p>
        <p class="nav__account-email" *ngIf="user.email">{{ user.email }}</p>
      </div>
    </ng-container>
    <ng-container>
      <ng-container *ngIf="pwa.canInstall | async">
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="pwa.prompt()">
          <mat-icon>get_app</mat-icon>
          <span>{{ 'FTR_WZD_ADD_TO_DESKTOP' | translate }}</span>
        </button>
      </ng-container>
      <button mat-menu-item [matMenuTriggerFor]="accessibilitySubMenu">
        <mat-icon>accessibility</mat-icon>
        <span>{{ 'GNR_ACCESSIBILITY' | translate }}</span>
      </button>
      <mat-divider></mat-divider>
      <a mat-menu-item (click)="handleLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'GNR_LOGOUT' | translate }}</span>
      </a>
    </ng-container>
  </ng-template>
</mat-menu>
<mat-menu #accessibilitySubMenu="matMenu" class="submenu context-menu">
  <ng-template matMenuContent>
    <div mat-menu-item fxLayout fxLayoutAlign="start center">
      <qt-keyboard-switch></qt-keyboard-switch>
    </div>
    <div mat-menu-item fxLayout fxLayoutAlign="start center">
      <qt-theme-switch></qt-theme-switch>
    </div>
  </ng-template>
</mat-menu>

<div class="nav__avatar-container" fxLayout fxLayoutAlign="start center">
  <button
    mat-icon-button
    class="nav__avatar-button"
    [matMenuTriggerFor]="settingsMenu"
    qtUiTour
    qtUiTourDescription="FTR_TOUR_SETUP_MENU"
    qtUiTourRoutePath="/cal"
    qtUiTourPosition="top"
    [qtUiTourElementFixed]="true"
    [hostElementOnPush]="true"
    aria-label="Open menu">
    <img
      *ngIf="personalAvatarUrl$ | async as avatarUrl; else placeholder"
      class="nav__avatar-img"
      [src]="avatarUrl"
      alt="Avatar" />

    <ng-template #placeholder>
      <mat-icon class="nav__avatar-icon">account_circle</mat-icon>
    </ng-template>
  </button>
  <div *ngIf="!isHiddenUserName">
    <div class="user" *ngIf="userData$ | async as user">
      <h3 class="user__title ellipsis" [matTooltip]="user.name">
        {{ user.name }}
      </h3>
      <small class="user__subtitle ellipsis" [matTooltip]="user.company">{{
        user.company
      }}</small>
    </div>
  </div>
</div>
