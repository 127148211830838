import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DocumentActivityActionTypes,
  UpdateDocumentActivity,
} from '../actions';

import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import * as DocumentsActivityActions from './document-activity.actions';
import { DocumentActivityService } from './document-activity.service';

@Injectable()
export class DocumentActivityEffects {
  connectActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActivityActions.SubscribeDocumentActivity>(
        DocumentActivityActionTypes.SUBSCRIBE_DOCUMENT_ACTIVITY
      ),
      switchMap(({ payload }) =>
        this.documentActivityService
          .subscribe({ q: payload.query, s: 'model', mysid: payload.mysid })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType<DocumentsActivityActions.UnsubscribeDocumentActivity>(
                  DocumentActivityActionTypes.UNSUBSCRIBE_DOCUMENT_ACTIVITY
                ),
                filter(action => payload?.mysid === action.payload?.mysid)
              )
            )
          )
      ),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'query':
            return new UpdateDocumentActivity(res || []);
          case 'ins':
            return new UpdateDocumentActivity(res);
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private documentActivityService: DocumentActivityService
  ) {}
}
