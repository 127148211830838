import { ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AriaLivePoliteness } from '@angular/cdk/a11y';

export class MatKeyboardConfig {
  politeness?: AriaLivePoliteness = 'assertive';
  announcementMessage? = '';
  viewContainerRef?: ViewContainerRef = null;
  duration? = 0;
  darkTheme?: any = null;
  isDebug? = false;
  ngControl?: NgControl;
}
