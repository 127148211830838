import { Directive, HostListener, Input, Optional } from '@angular/core';
import { PopoverRef } from '../models';

/**
 * Button that will close the current popover.
 */
@Directive({
  selector: '[qtPopoverClose]',
})
export class PopoverCloseDirective<T = any> {
  @Input('qtPopoverClose') popoverResult: T;

  constructor(@Optional() private popoverRef: PopoverRef<T>) {}

  @HostListener('click') onClick(): void {
    if (!this.popoverRef) {
      console.error('p1PopoverClose is not supported within a template');

      return;
    }

    this.popoverRef.close(this.popoverResult);
  }
}
