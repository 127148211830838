import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { BackendResponse, QtCalendarEvent } from '@qtek/shared/models';
import { TranslationCoreService } from '@qtek/libs/translation-core';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  entityName: WebsocketEntTypes = 'mtg';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  constructor(
    protected http: HttpClient,
    private websocketService: WebSocketService,
    private lang: TranslationCoreService
  ) {}

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>,
    withQuery = false
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, withQuery);
  }

  getEvents() {
    this.wsManager.query();
  }

  getEvent(id: string) {
    this.wsManager.get({ id });
  }

  addEvent(payload: QtCalendarEvent, destination?: any) {
    const params: any = {
      _attrv: 'lst',
    };
    if (destination) {
      params.lng = this.lang.getUserBrowserLanguageCode();
      params.dst = btoa(destination);
    }
    this.wsManager.create({ prms: params, res: payload });
  }

  removeEvent(id: string) {
    this.wsManager.delete({ id });
  }

  updateEvent(id: string, payload: QtCalendarEvent) {
    this.wsManager.update({
      res: payload,
      id: payload._id,
      prms: { _attrv: 'lst' },
    });
  }

  sendEvent(id: string, query: any) {
    const key = '_snd';
    const value = Object.keys(query)
      .filter(k => query[k])
      .join('.');
    const params = new HttpParams().set(key, value);

    return this.http.post<BackendResponse>(`/snd/api/v1/mtg/${id}`, undefined, {
      withCredentials: true,
      params,
    });
  }

  sendTableReady(mtgId: string) {
    const params = new HttpParams().set('cg', 'tblready');
    return this.http.post<BackendResponse>(`/snd/api/v1/mtg/${mtgId}`, null, {
      withCredentials: true,
      params,
    });
  }
}
