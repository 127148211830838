import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  CreateDealTeamAction,
  CreateDealTeamFailureAction,
  CreateDealTeamSuccessAction,
  DealTeamActionTypes,
  DeleteDealTeamAction,
  DeleteDealTeamFailureAction,
  DeleteDealTeamSuccessAction,
  GetDealTeamsFailureAction,
  GetDealTeamsSuccessAction,
  UpdateDealTeamAction,
  UpdateDealTeamFailureAction,
  UpdateDealTeamSuccessAction,
} from './deal-team.actions';
import { DealTeamService } from './deal-team.service';

@Injectable()
export class DealTeamEffects {
  constructor(private actions$: Actions, private dealTeamService: DealTeamService) {}

  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealTeamActionTypes.CONNECT_DEAL_TEAM),
      switchMap(({ payload }: any) =>
        this.dealTeamService.subscribe({ s: 'model', ...payload }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(DealTeamActionTypes.DISCONNECT_DEAL_TEAM),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ res, op, sts, mysid }: any) => {
        switch (op) {
          case 'query':
            return res ? new GetDealTeamsSuccessAction({ mysid, dealTeams: res }) : new GetDealTeamsFailureAction(sts);
          case 'ins':
            return res
              ? new CreateDealTeamSuccessAction({ mysid, dealTeam: res })
              : new CreateDealTeamFailureAction(sts);
          case 'upd':
            return res
              ? new UpdateDealTeamSuccessAction({ mysid, dealTeam: res })
              : new UpdateDealTeamFailureAction(sts);
          case 'del':
            return res
              ? new DeleteDealTeamSuccessAction({ mysid, dealTeam: res })
              : new DeleteDealTeamFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  createDealTeam$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateDealTeamAction>(DealTeamActionTypes.CREATE_DEAL_TEAM),
        tap<CreateDealTeamAction>(({ payload }) => this.dealTeamService.createDealTeam(payload.dealTeam))
      ),
    { dispatch: false }
  );

  updateDealTeam$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateDealTeamAction>(DealTeamActionTypes.UPDATE_DEAL_TEAM),
        tap<UpdateDealTeamAction>(({ payload }) => this.dealTeamService.updateDealTeam(payload.dealTeam, payload.id))
      ),
    { dispatch: false }
  );

  deleteItem$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteDealTeamAction>(DealTeamActionTypes.DELETE_DEAL_TEAM),
        tap<DeleteDealTeamAction>(({ payload }) => this.dealTeamService.deleteDealTeam(payload.id))
      ),
    { dispatch: false }
  );
}
