import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Agent, entityName } from '@qtek/shared/models';
import { HttpEntityManager } from '../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends HttpEntityManager<Agent, Agent> {
  entityName: entityName = 'agt';

  constructor(protected http: HttpClient) {
    super();
  }

  getAgents() {
    return this.getAll();
  }

  addAgent(payload: Agent, print?: boolean) {
    return this.updateAgent(payload, print);
  }

  updateAgent(payload: Agent, print?: boolean) {
    const id = btoa(payload.agtId);
    delete payload.agtId;
    return super.post(payload, id, print ? { e: 65536 } : {});
  }

  deleteAgent(payload: string) {
    return super.delete(btoa(payload));
  }
}
