/**
 * This file contains providers for APP_INITIALIZER token.
 *
 * Each funciton will be executed before application starts.
 */
import { APP_INITIALIZER, Provider } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import {
  AppState,
  LoadMetaGuiDomainAction,
  MetaActionTypes,
} from '@qtek/libs/store';
import { firstValueFrom } from 'rxjs';
import { Pwa } from './pwa.service';

/**
 * Load meta data and put it into the store.
 */
export function LoadMetaDataFactory(store: Store<AppState>, actions$: Actions) {
  return () => {
    store.dispatch(new LoadMetaGuiDomainAction());

    return firstValueFrom(
      actions$.pipe(ofType(MetaActionTypes.LOAD_META_SUCCESS), take(1))
    );
  };
}

export function initPromptEvent(pwa: Pwa) {
  return () => {
    pwa.init();
  };
}

export const APP_INITIALIZER_PROVIDERS: Provider[] = [
  // {
  //   provide: APP_INITIALIZER,
  //   multi: true,
  //   useFactory: LoadMetaDataFactory,
  //   deps: [Store, Actions],
  // },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: initPromptEvent,
    deps: [Pwa],
  },
];
