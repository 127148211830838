import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ProductSchemaService } from './product-schema.service';
import {
  GetSchemaSidsAction,
  GetSchemaSidsFailureAction,
  GetSchemaSidsSuccessAction,
  GetSchemaViewAction,
  GetSchemaViewFailureAction,
  GetSchemaViewSuccessAction,
  GetSchemasMetaAction,
  GetSchemasMetaFailureAction,
  GetSchemasMetaSuccessAction,
  SchemaActionTypes,
} from './product-schemas.actions';

@Injectable()
export class ProductSchemasEffects {
  constructor(private actions$: Actions, private schemaService: ProductSchemaService) {}

  schemasMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSchemasMetaAction>(SchemaActionTypes.GET_SCHEMAS_META),
      switchMap(() =>
        this.schemaService.getProductSchemaMeta().pipe(
          map(({ res }) => new GetSchemasMetaSuccessAction(res)),
          catchError(({ error }) => of(new GetSchemasMetaFailureAction(error)))
        )
      )
    )
  );

  schema$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSchemaViewAction>(SchemaActionTypes.GET_SCHEMA_VIEW),
      switchMap(({ payload }) =>
        this.schemaService.getProductSchemaById(payload).pipe(
          map((res: any) => new GetSchemaViewSuccessAction({ view: res, query: payload })),
          catchError(({ error }) => of(new GetSchemaViewFailureAction(error)))
        )
      )
    )
  );

  schemasBySids$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSchemaSidsAction>(SchemaActionTypes.GET_SCHEMA_SIDS),
      switchMap(({ payload }) =>
        this.schemaService.getSchemasBySids(payload).pipe(
          map(({ res }: any) => new GetSchemaSidsSuccessAction(res)),
          catchError(({ error }) => of(new GetSchemaSidsFailureAction(error)))
        )
      )
    )
  );
}
