export enum DeliveryStatusType {
  NOT_SENT = 0,
  SENT = 100,
  REJECTED_BY_USER = 400,
  DELIVERED = 500,
  PARTIALLY_DELIVERED = 550,
  FAILED = 900,
}

export const INVOICE_DELIVERY_STATUS = {
  [DeliveryStatusType.NOT_SENT]: {
    title: 'MSG_INV_NOT_SENT',
    message: 'MSG_INV_NOT_SENT_FULL',
    icon: 'no_sent',
    iconColor: '#bbb',
  },
  [DeliveryStatusType.SENT]: {
    title: 'MSG_INV_SENT',
    message: 'MSG_INV_SENT_FULL',
    icon: 'sent',
    iconColor: 'green',
  },
  [DeliveryStatusType.REJECTED_BY_USER]: {
    title: 'MSG_INV_REJECTED',
    message: 'MSG_INV_REJECTED_FULL',
    icon: 'rejected_by_user',
    iconColor: '#f44336',
  },
  [DeliveryStatusType.DELIVERED]: {
    title: 'MSG_INV_DELIVERED',
    message: 'MSG_INV_DELIVERED_FULL',
    icon: 'delivered',
    iconColor: 'green',
  },
  [DeliveryStatusType.PARTIALLY_DELIVERED]: {
    title: 'MSG_INV_PAR_DELIV',
    message: 'MSG_INV_PAR_DELIV_FULL',
    icon: 'partial_delivered',
    iconColor: '#ff8f00',
  },
  [DeliveryStatusType.FAILED]: {
    title: 'MSG_INV_SND_ERR',
    message: 'MSG_INV_SND_ERR_FULL',
    icon: 'failed',
    iconColor: '#f44336',
  },
};

export const INVITATION_DELIVERY_STATUS = {
  [DeliveryStatusType.NOT_SENT]: {
    title: 'MSG_CNT_NOT_SENT',
    message: 'MSG_CNT_NOT_SENT_FULL',
    icon: 'no_sent',
    iconColor: '#bbb',
  },
  [DeliveryStatusType.SENT]: {
    title: 'MSG_CNT_SENT',
    message: 'MSG_CNT_SENT_FULL',
    icon: 'sent',
    iconColor: 'green',
  },
  [DeliveryStatusType.REJECTED_BY_USER]: {
    title: 'MSG_CNT_REJECTED',
    message: 'MSG_CNT_REJECTED_FULL',
    icon: 'rejected_by_user',
    iconColor: '#f44336',
  },
  [DeliveryStatusType.DELIVERED]: {
    title: 'MSG_CNT_DELIVERED',
    message: 'MSG_CNT_DELIVERED_FULL',
    icon: 'delivered',
    iconColor: 'green',
  },
  [DeliveryStatusType.PARTIALLY_DELIVERED]: {
    title: 'MSG_CNT_PAR_DELIV',
    message: 'MSG_CNT_PAR_DELIV_FULL',
    icon: 'partial_delivered',
    iconColor: '#ff8f00',
  },
  [DeliveryStatusType.FAILED]: {
    title: 'MSG_CNT_SND_ERR',
    message: 'MSG_CNT_SND_ERR_FULL',
    icon: 'failed',
    iconColor: '#f44336',
  },
};

export const EVENT_DELIVERY_STATUS = {
  [DeliveryStatusType.NOT_SENT]: {
    title: 'MSG_CAL_NOT_SENT',
    message: 'MSG_CAL_NOT_SENT_FULL',
    icon: 'no_sent',
    iconColor: '#bbb',
  },
  [DeliveryStatusType.SENT]: {
    title: 'MSG_CAL_SENT',
    message: 'MSG_CAL_SENT_FULL',
    icon: 'sent',
    iconColor: 'green',
  },
  [DeliveryStatusType.REJECTED_BY_USER]: {
    title: 'MSG_CAL_REJECTED',
    message: 'MSG_CAL_REJECTED_FULL',
    icon: 'rejected_by_user',
    iconColor: '#f44336',
  },
  [DeliveryStatusType.DELIVERED]: {
    title: 'MSG_CAL_DELIVERED',
    message: 'MSG_CAL_DELIVERED_FULL',
    icon: 'delivered',
    iconColor: 'green',
  },
  [DeliveryStatusType.PARTIALLY_DELIVERED]: {
    title: 'MSG_CAL_PAR_DELIV',
    message: 'MSG_CAL_PAR_DELIV_FULL',
    icon: 'partial_delivered',
    iconColor: '#ff8f00',
  },
  [DeliveryStatusType.FAILED]: {
    title: 'MSG_CAL_SND_ERR',
    message: 'MSG_CAL_SND_ERR_FULL',
    icon: 'failed',
    iconColor: '#f44336',
  },
};

export const BOOKING_DELIVERY_STATUS = {
  [DeliveryStatusType.NOT_SENT]: {
    title: 'MSG_BOOKING_NOT_SENT',
    message: 'MSG_BOOKING_NOT_SENT_FULL',
    icon: 'no_sent',
    iconColor: '#bbb',
  },
  [DeliveryStatusType.SENT]: {
    title: 'MSG_BOOKING_SENT',
    message: 'MSG_BOOKING_SENT_FULL',
    icon: 'sent',
    iconColor: 'green',
  },
  [DeliveryStatusType.REJECTED_BY_USER]: {
    title: 'MSG_BOOKING_REJECTED',
    message: 'MSG_BOOKING_REJECTED_FULL',
    icon: 'rejected_by_user',
    iconColor: '#f44336',
  },
  [DeliveryStatusType.DELIVERED]: {
    title: 'MSG_BOOKING_DELIVERED',
    message: 'MSG_BOOKING_DELIVERED_FULL',
    icon: 'delivered',
    iconColor: 'green',
  },
  [DeliveryStatusType.PARTIALLY_DELIVERED]: {
    title: 'MSG_BOOKING_PAR_DELIV',
    message: 'MSG_BOOKING_PAR_DELIV_FULL',
    icon: 'partial_delivered',
    iconColor: '#ff8f00',
  },
  [DeliveryStatusType.FAILED]: {
    title: 'MSG_BOOKING_SND_ERR',
    message: 'MSG_BOOKING_SND_ERR_FULL',
    icon: 'failed',
    iconColor: '#f44336',
  },
};
