import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import { AppState, getCurrentPerson, getPreuploadDocuments } from '../index';
import {
  BulkDeleteAction,
  BulkDeleteFailureAction,
  BulkDeleteSuccessAction,
  DeleteWSDocumentFailureAction,
  DeleteWSDocumentSuccessAction,
  DocumentActionTypes,
  GetEntityDocumentsFailureAction,
  GetWSEntityDocumentsSuccessAction,
  SendDocumentAction,
  SendDocumentFailureAction,
  SendDocumentSuccessAction,
  UpdateDocumentDescriptionAction,
  UpdateDocumentDescriptionFailureAction,
  UpdateDocumentDescriptionSuccessAction,
  UpdateWSDocumentFailureAction,
  UpdateWSDocumentSuccessAction,
  UploadWSDocumentsFailureAction,
  UploadWSDocumentsSuccessAction,
} from '../actions';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { DocumentsService } from './documents.service';

import {
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
  HttpSentEvent,
} from '@angular/common/http';
import {
  DOCUMENT_UPLOAD_CANCELED,
  DOCUMENT_UPLOAD_COMPLETED,
  DOCUMENT_UPLOAD_STARTED,
  DocNode,
  PreUploadState,
} from '@qtek/shared/models';
import * as DocumentsActions from './documents.actions';

@Injectable()
export class DocumentsEffects {
  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActionTypes.CONNECT_DOCUMENTS_LIST),
      switchMap(({ payload }: any) =>
        this.documentsService.subscribe({ prms: payload }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(DocumentActionTypes.DISCONNECT_DOCUMENTS_LIST),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new GetWSEntityDocumentsSuccessAction(res)
              : new GetEntityDocumentsFailureAction();
          case 'ins':
            return res
              ? new UploadWSDocumentsSuccessAction(res)
              : new UploadWSDocumentsFailureAction(sts);
          case 'del':
            return res
              ? new DeleteWSDocumentSuccessAction(res)
              : new DeleteWSDocumentFailureAction(sts);
          case 'upd':
            return res
              ? new UpdateWSDocumentSuccessAction(res)
              : new UpdateWSDocumentFailureAction(sts);
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  update$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActionTypes.UPDATE_DOCUMENT_WS),
        tap(({ payload }) => this.documentsService.updateDocumentWS(payload))
      ),
    { dispatch: false }
  );

  delete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActionTypes.DELETE_DOCUMENT_WS),
        tap(({ payload }) => this.documentsService.deleteDocumentWS(payload))
      ),
    { dispatch: false }
  );

  uploadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActions.UploadDocumentsAction>(
        DocumentsActions.DocumentActionTypes.UPLOAD_DOCUMENTS
      ),
      mergeMap(({ payload }) =>
        this.documentsService.uploadDocuments(payload.data, payload.attr).pipe(
          filter((event: any) => event.type === HttpEventType.Response),
          map((event: HttpResponse<DocNode>) => {
            return new DocumentsActions.UploadDocumentsSuccessAction([
              {
                ...event.body,
                attr: payload.attr,
              },
            ]);
          }),
          catchError(({ sts }) =>
            of(new DocumentsActions.UploadDocumentsFailureAction(sts))
          )
        )
      )
    )
  );

  preUploadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActions.PreUploadDocumentAction>(
        DocumentsActions.DocumentActionTypes.PREUPLOAD_DOCUMENT
      ),
      mergeMap(({ payload }) => {
        const { data, file } = payload;
        return this.documentsService.preUploadDocument(data).pipe(
          map(({ res }) => {
            return new DocumentsActions.PreUploadDocumentSucessAction({
              res,
              file,
            });
          }),
          catchError(({ sts }) =>
            of(new DocumentsActions.PreUploadDocumentFailureAction(sts))
          )
        );
      })
    )
  );

  preUploadDocument2Stage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DocumentsActions.PreUploadDocumentSucessAction>(
          DocumentActionTypes.PREUPLOAD_DOCUMENT_SUCCESS
        ),
        mergeMap(({ payload }) => {
          const { res, file } = payload;

          const formData: FormData = new FormData();

          formData.append('data', file);
          formData.append('tkn', res.tkn);

          let timestamp = Date.now();
          let lastLoaded = 0;

          return this.documentsService.uploadDocuments(formData).pipe(
            takeUntil(
              this.store.select(getPreuploadDocuments).pipe(
                map(docs => docs.find(doc => doc.tkn === res?.tkn)),
                filter(
                  (doc: PreUploadState) =>
                    doc.status === DOCUMENT_UPLOAD_CANCELED
                )
              )
            ),
            filter(
              (event: any) =>
                event.type === HttpEventType.Response ||
                event.type === HttpEventType.UploadProgress ||
                event.type === HttpEventType.Sent
            ),
            map(
              (
                event: HttpResponse<DocNode> | HttpProgressEvent | HttpSentEvent
              ) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    this.store.dispatch(
                      new DocumentsActions.UpdatePreUploadDocumentAction({
                        tkn: res.tkn,
                        toUpdate: {
                          status: DOCUMENT_UPLOAD_STARTED,
                        },
                      })
                    );
                    return of(null);
                  case HttpEventType.UploadProgress:
                    const loaded = event.loaded;
                    const time = Date.now() - timestamp;
                    const percent = (loaded - lastLoaded) / 100;
                    const chunk = percent * file.size;
                    const speed = (chunk / (time / 1000)).toFixed(2);

                    timestamp = Date.now();
                    lastLoaded = loaded;

                    this.store.dispatch(
                      new DocumentsActions.UpdatePreUploadDocumentAction({
                        tkn: res.tkn,
                        toUpdate: {
                          progress: Math.round(
                            (100 * event.loaded) / file.size
                          ),
                          speed: parseFloat(speed),
                        },
                      })
                    );
                    return of(null);
                  case HttpEventType.Response:
                    this.store.dispatch(
                      new DocumentsActions.UpdatePreUploadDocumentAction({
                        tkn: res.tkn,
                        toUpdate: {
                          status: DOCUMENT_UPLOAD_COMPLETED,
                        },
                      })
                    );
                    this.store.dispatch(
                      new DocumentsActions.UpdateWSDocumentSuccessAction(
                        event.body
                      )
                    );
                    return of(null);

                  default:
                    return of(null);
                }
              }
            )
            // catchError(({ sts }) => of(new DocumentsActions.UploadDocumentsFailureAction(sts)))
          );
          // Number of concurrent uploads
        }, 5)
      ),
    { dispatch: false }
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActions.DeleteDocumentAction>(
        DocumentsActions.DocumentActionTypes.DELETE_DOCUMENT
      ),
      mergeMap(({ payload }) =>
        this.documentsService.deleteDocument(payload.docId, payload.refId).pipe(
          map(() => new DocumentsActions.DeleteDocumentSuccessAction(payload)),
          catchError(({ sts }) =>
            of(new DocumentsActions.DeleteDocumentFailureAction(sts))
          )
        )
      )
    )
  );

  getEntityDocumentsWs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DocumentsActions.GetEntityDocumentsWsAction>(
          DocumentsActions.DocumentActionTypes.GET_ENTITY_DOCUMENTS_WS
        ),
        tap(({ payload }) => this.documentsService.getItemDocumentsWs(payload))
      ),
    { dispatch: false }
  );

  getEntityDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActions.GetEntityDocumentsAction>(
        DocumentsActions.DocumentActionTypes.GET_ENTITY_DOCUMENTS
      ),
      withLatestFrom(this.store.pipe(select(getCurrentPerson))),
      mergeMap(([action, person]) => {
        const refId = action.payload?.refId || `prs=${person.prsId}`;

        return this.documentsService
          .getDocumentsOfEntity(refId, action.payload?.type)
          .pipe(
            map(
              ({ res }) =>
                new DocumentsActions.GetEntityDocumentsSuccessAction(res)
            ),
            catchError(() =>
              of(new DocumentsActions.GetEntityDocumentsFailureAction())
            )
          );
      })
    )
  );

  getItemDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActions.GetEntityDocumentsAction>(
        DocumentsActions.DocumentActionTypes.GET_ITEM_DOCUMENTS
      ),
      mergeMap(action => {
        return this.documentsService
          .getDocumentsOfItem(action.payload?.refId, action.payload?.type)
          .pipe(
            map(
              ({ res }) =>
                new DocumentsActions.GetWSEntityDocumentsSuccessAction(res)
            ),
            catchError(() =>
              of(new DocumentsActions.GetEntityDocumentsFailureAction())
            )
          );
      })
    )
  );

  getCreatorLogos$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActions.GetCreatorLogosAction>(
        DocumentsActions.DocumentActionTypes.GET_LOGOS
      ),
      mergeMap(() =>
        this.documentsService.getLogoTemplates().pipe(
          map(
            ({ res }) => new DocumentsActions.GetCreatorLogosSuccessAction(res)
          ),
          catchError(() =>
            of(new DocumentsActions.GetEntityDocumentsFailureAction())
          )
        )
      )
    )
  );

  updateLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DocumentsActions.UpdateCompanyLogoAction>(
        DocumentsActions.DocumentActionTypes.UPDATE_COMPANY_LOGO
      ),
      mergeMap(({ payload }) =>
        this.documentsService.updateLogo(payload).pipe(
          map(
            ({ res }) =>
              new DocumentsActions.UpdateCompanyLogoSuccessAction(res)
          ),
          catchError(({ sts }) =>
            of(new DocumentsActions.UpdateCompanyLogoFailureAction(sts))
          )
        )
      )
    )
  );

  sendDocument$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SendDocumentAction>(DocumentActionTypes.SEND_DOCUMENT),
      mergeMap(({ payload }) =>
        this.documentsService
          .sendDocument(payload.id, payload.destination)
          .pipe(
            map(
              ({ res }) =>
                new SendDocumentSuccessAction({
                  response: res,
                  id: payload.id,
                })
            ),
            catchError(({ sts }) => of(new SendDocumentFailureAction(sts)))
          )
      )
    )
  );

  updateDocDescription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateDocumentDescriptionAction>(
        DocumentActionTypes.UPDATE_DOCUMENT_DESC
      ),
      mergeMap(({ payload }) =>
        this.documentsService
          .updateDocumentDescription(payload.id, payload.description)
          .pipe(
            map(
              ({ res }) =>
                new UpdateDocumentDescriptionSuccessAction({
                  description: res,
                  id: payload.id,
                })
            ),
            catchError(({ sts }) =>
              of(new UpdateDocumentDescriptionFailureAction(sts))
            )
          )
      )
    )
  );

  bulkDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BulkDeleteAction>(DocumentActionTypes.BULK_DELETE),
      mergeMap(({ payload }) =>
        this.documentsService.bulkDelete(payload).pipe(
          map(response => {
            return new BulkDeleteSuccessAction(
              ((response as any).res as any[]).map(item => item._id)
            );
          }),
          catchError(error => of(new BulkDeleteFailureAction()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private documentsService: DocumentsService,
    private store: Store<AppState>
  ) {}
}
