import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { Store, select } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';

import { LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { PhoneType, Role, Staff } from '@qtek/shared/models';
import { AppState, getRoles } from '@qtek/libs/store';
import { Subject } from 'rxjs';

export const contactPreviewDialogConfig: MatDialogConfig = {
  panelClass: 'fullscreen',
  autoFocus: false,
};

export interface ContactPrevievDialogInput extends Staff {
  type: 'staff' | 'customer';
}

@Component({
  selector: 'qt-contact-preview-dialog',
  templateUrl: './contact-preview-dialog.component.html',
  styleUrls: ['./contact-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPreviewDialogComponent implements OnDestroy {
  roles: Role[];
  type: 'staff' | 'customer';
  destroy$ = new Subject<void>();

  get role(): string {
    const r = this.roles.find(role => role.id === this.data.rl.toString());
    return r ? r.name : '';
  }

  get relation(): string {
    switch (this.type) {
      case 'customer':
        return 'PTL_GRP_CUSTOMER';
      case 'staff':
        return 'GNR_STAFF';
    }
  }

  constructor(
    private mdDialogRef: MatDialogRef<ContactPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactPrevievDialogInput,
    public media: MediaObserver,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private dialogLoader: LazyDialogLoader
  ) {
    this.type = this.data.type;

    this.store
      .pipe(select(getRoles), take(1))
      .subscribe(roles => (this.roles = roles));

    this.store
      .pipe(
        select(state =>
          this.type === 'staff'
            ? state.relations.staff.find(r => r.prsId === this.data.prsId)
            : state.relations.customers.find(r => r.relId === this.data.relId)
        ),
        takeUntil(this.destroy$),
        filter(Boolean)
      )
      .subscribe(contact => {
        this.data = contact as ContactPrevievDialogInput;
      });
  }

  protected readonly PhoneType = PhoneType;

  onEdit(): void {
    if (this.type === 'staff') {
      this.dialogLoader.open('employee-dialog', this.data).subscribe();
    } else {
      this.dialogLoader.open('customer-dialog', this.data).subscribe();
    }
  }

  onInvite(): void {
    if (this.type === 'staff') {
      this.dialogLoader
        .open('invite-contact', {
          type: 'staff',
          relId: btoa(this.data._id),
          person: this.data.prs,
        })
        .subscribe();
    } else {
      this.dialogLoader
        .open('invite-contact', {
          type: 'customer',
          relId: this.data.relId,
          person: this.data.prs,
        })
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
