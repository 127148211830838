import { entityName } from '@qtek/shared/models';

/**
 * Prefix given object keys.
 *
 * @export
 * @param {entityName} entityName Entity name to be prefixed with.
 * @param {Record<string, unknown>} payload Data to be prefixed with entity name.
 * @returns {Record<string, unknown>} Prefixed object.
 */
export function prefix(
  entityName: entityName,
  payload: Record<string, unknown>
): Record<string, unknown> {
  return Object.keys(payload).reduce((prefixedPayload, payloadKey) => {
    (prefixedPayload as any)[`${entityName}.${payloadKey}`] = (payload as any)[
      payloadKey
    ];

    return prefixedPayload;
  }, {});
}
