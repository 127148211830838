import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ContentChildren,
  Directive,
  Input,
  QueryList,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

/* tslint:disable directive-selector use-host-property-decorator */
@Directive({
  selector: 'qt-dialog-title',
  host: { class: 'qt-dialog-title' },
  standalone: true,
})
export class DialogTitleDirective {}

@Directive({
  selector: 'qt-dialog-content',
  // host: { class: 'qt-dialog-content' },
  standalone: true,
})
export class DialogContentDirective {
  constructor(public el: ElementRef) {}
}

@Directive({
  selector: 'qt-dialog-actions',
  host: { class: 'qt-dialog-actions' },
  standalone: true,
})
export class DialogActionsDirective {}
/* tslint:enable directive-selector use-host-property-decorator */

/**
 * Wrapper for full screen dialog
 *
 * @export
 * @class DialogComponent
 */
@Component({
  selector: 'qt-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    NgIf,
    NgClass,
    DialogTitleDirective,
    DialogContentDirective,
    DialogActionsDirective,
    FlexLayoutModule,
    MatButtonModule,
  ]
})
export class DialogComponent {
  @Input() disableClose = false;
  @Input() color: 'primary' | 'accent' | 'warn' | null = 'primary';
  @Input() contentClass = '';

  @ContentChildren(DialogTitleDirective)
  dialogTitle: QueryList<DialogTitleDirective>;
  @ContentChildren(DialogContentDirective)
  dialogContent: QueryList<DialogContentDirective>;
  @ContentChildren(DialogActionsDirective)
  dialogActions: QueryList<DialogActionsDirective>;
  @ViewChild('contentDiv') contentDiv: ElementRef;
}
