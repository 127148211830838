<aside
  class="dialog"
  fxLayout="column"
>
  <mat-toolbar color="primary">
    <h2 class="dialog__title">
      <div
        class="title"
        fxLayout
        fxLayoutAlign="start center"
      >
        <qt-avatar
          *ngIf="media.isActive('gt-xs')"
          [name]="data.prs.fname"
          [statusCode]="data.sts"
        ></qt-avatar>
        <span>{{ data.prs.name }}</span>
      </div>
    </h2>
    <span fxFlex></span>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menurel"
      class="m-0"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menurel="matMenu">
      <button
        mat-menu-item
        (click)="onEdit()"
      >
        <mat-icon>mode_edit</mat-icon>
        <span>{{ 'CMD_EDIT' | translate }}</span>
      </button>
      <button
        mat-menu-item
        (click)="onInvite()"
      >
        <mat-icon>contact_mail</mat-icon>
        <span>{{ 'CMD_CNT_REINVITE' | translate }}</span>
      </button>
    </mat-menu>
    <button
      mat-icon-button
      matDialogClose
      class="m-0"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutGap="1rem"
      class="content"
    >
      <h2 class="mat-headline-6">{{ 'GNR_CNT_DETAILS' | translate }}:</h2>
      <div
        fxLayout="column"
        fxLayoutGap="1rem"
        ngClass.gt-xs="px-3"
      >
        <section
          *ngIf="data.acn?.name?.name"
          fxLayout
          fxLayoutGap="1rem"
          fxLayoutAlign="start center"
          [matTooltip]="'FTR_REL_CMP_NAME' | translate"
          matTooltipPosition="above"
        >
          <mat-icon class="material-icon-gray">domain</mat-icon>
          <span class="content__line">{{ data.acn.name.name }}</span>
        </section>
        <section
          fxLayout
          fxLayoutGap="1rem"
          fxLayoutAlign="start center"
          [matTooltip]="'GNR_REL' | translate"
          matTooltipPosition="above"
        >
          <mat-icon class="material-icon-gray">swap_horiz</mat-icon>
          <div fxLayout="column">
            <span class="content__line">{{ relation | translate }}</span>
            <span
              class="mat-caption"
              *ngIf="data.rl"
            >{{
              role | translate
              }}</span>
          </div>
        </section>
        <section
          *ngIf="data.acn?.adr && data.acn?.adr.adr"
          fxLayout
          fxLayoutGap="1rem"
          fxLayoutAlign="start center"
          [matTooltip]="'CMP_ADDRESS' | translate"
          matTooltipPosition="above"
        >
          <mat-icon class="material-icon-gray">place</mat-icon>
          <div fxLayout="column">
            <span class="content__line">{{ data.acn.adr.adr }}</span>
            <span
              class="content__line"
              *ngIf="data.acn.adr.adr2"
            >{{
              data.acn.adr.adr2
              }}</span>
            <span class="content__line">{{
              data.acn.adr.city + ' ' + data.acn.adr.st + ' ' + data.acn.adr.zip
              }}</span>
          </div>
        </section>
        <section
          *ngIf="data.email"
          fxLayout
          fxLayoutGap="1rem"
          fxLayoutAlign="start center"
          [matTooltip]="'GNR_EMAIL' | translate"
          matTooltipPosition="above"
        >
          <mat-icon class="material-icon-gray">email</mat-icon>
          <span class="content__line">{{ data.email }}</span>
        </section>
        <section
          *ngIf="data.prs.phns"
          fxLayout="column"
          fxLayoutGap="1rem"
          [matTooltip]="'GNR_PHNNO' | translate"
          matTooltipPosition="above"
        >
          <div
            fxLayout
            fxLayoutGap="1rem"
            fxLayoutAlign="start center"
            *ngFor="let phone of data.prs.phns; let index = index"
          >
            <mat-icon class="material-icon-gray">{{ phone.tp === PhoneType.MOBILE ? 'phone_iphone' : 'phone'
              }}</mat-icon>
            <span>{{ phone.phn | phone: phone.cnty:true }}</span>
            <!--            <span [ngClass]="{-->
            <!--                content__line: true,-->
            <!--                'content__line&#45;&#45;margin': index !== 0-->
            <!--              }">{{ phone.phn | phone: phone.cnty:true }}</span>-->
            <ng-container [ngSwitch]="phone.tp">
              <span
                *ngSwitchCase="100"
                class="mat-caption"
              >{{
                'GNR_CELL_PHONE' | translate
                }}</span>
              <span
                *ngSwitchCase="200"
                class="mat-caption"
              >{{
                'GNR_WORK_PHONE' | translate
                }}</span>
              <span
                *ngSwitchDefault
                class="mat-caption"
              >{{ phone.nck }}</span>
            </ng-container>
          </div>
        </section>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    fxLayout
    fxLayoutAlign="end"
  >
    <button
      mat-button
      matDialogClose
    >{{ 'CMD_CLOSE' | translate }}</button>
  </mat-dialog-actions>
</aside>