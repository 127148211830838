import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BackendResponse } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  constructor(private http: HttpClient) {}

  getSteps(): Observable<BackendResponse> {
    return this.http.get<BackendResponse>('/api/v1/service/config/wzd', {
      withCredentials: true,
    });
  }

  savePromo(payload: {
    vndId: string;
    promo: string;
  }): Observable<BackendResponse> {
    return this.http.post<BackendResponse>('/api/v1/e/relvnd', payload, {
      withCredentials: true,
    });
  }
}
