import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Club, ClubMember, ClubRoles } from '@qtek/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClubService {
  entityName: WebsocketEntTypes = 'clbmbr';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  myClubSID = 'myClubs';
  clubMembersSID = 'clubMembers';

  constructor(
    private http: HttpClient,
    private websocketService: WebSocketService
  ) {}

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, false);
  }

  subscribeWithQuery(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  getClubs(role?: ClubRoles) {
    this.wsManager.query({
      prms: {
        role,
      },
      mysid: this.myClubSID,
    });
  }

  addClub(club: Club) {
    return this.http.post('/api/v1/model/clb', club, { withCredentials: true });
  }

  updateClub(club: Club) {
    return this.http.put(
      '/api/v1/model/clb/' + club._id,
      { ...club, _id: undefined },
      { withCredentials: true }
    );
  }

  removeClub(id: string) {
    this.wsManager.delete({ id });
  }

  // ----------------

  getClubMembers(prms: any) {
    this.wsManager.query({ prms, mysid: this.clubMembersSID });
  }

  addClubMember(club: ClubMember) {
    this.wsManager.create({ res: club });
  }
  updateClubMember({ id, res }: { id: string; res: any }) {
    this.wsManager.update({ res, id });
  }

  removeClubMember(id: string) {
    this.wsManager.delete({ id });
  }
}
