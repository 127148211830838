import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BackendResponse } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class ProductSchemaService {
  constructor(private http: HttpClient) {}

  /**
   * Get product schema of product, which uses for item's settings, for ex. in item dialog
   *
   *
   *
   * @schema the product schema url from user.products[].schema
   * @returns {Observable<BackendResponse>}
   *
   */

  getProductSchemaById(schemaId: string): Observable<BackendResponse> {
    return this.http.get<BackendResponse>(
      '/api/v1/service/schema/' + btoa(schemaId),
      { withCredentials: true }
    );
  }

  getProductSchemaMeta(): Observable<BackendResponse> {
    return this.http.get<BackendResponse>('/api/v1/service/schemas', {
      withCredentials: true,
    });
  }

  getSchemasBySids(sids: string[]): Observable<BackendResponse> {
    return this.http.get<BackendResponse>(
      '/api/v1/service/schemas/' + btoa(sids.toString()),
      {
        withCredentials: true,
      }
    );
  }
}
