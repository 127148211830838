import { Inject, InjectionToken } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { createSelector } from 'reselect';

import * as fromCore from './core/core.reducer';
import * as fromDocumentActivity from './document-activity/document-activity.reducer';
import * as fromDocuments from './documents/documents.reducer';
import * as fromIntegrations from './integrations/integrations.reducer';
import * as fromMeta from './meta/meta.reducer';
import * as fromNcino from './ncino/ncino.reducer';
import * as fromProductSchemas from './product-schemes/product-schemas.reducer';
import * as fromTransactions from './transaction/transaction.reducer';
import * as fromUser from './user/user.reducer';
import * as fromWebDomains from './webdomains/webdomains.reducer';

export * from './meta-reducers';

export interface AppState {
  user: fromUser.State;
  meta: fromMeta.State;
  core: fromCore.State;
  documents: fromDocuments.State;
  integrations: fromIntegrations.State;
  productSchemas: fromProductSchemas.State;
  ncino: fromNcino.State;
  webDomains: fromWebDomains.State;
  documentActivity: fromDocumentActivity.State;
  transaction: fromTransactions.State;
}

export type BookingAppState = Pick<
  AppState,
  'user' | 'meta' | 'productSchemas'
>;

/**
 * Reducers used in every application.
 */
export const COMMON_REDUCERS: ActionReducerMap<Partial<AppState>> = {
  user: fromUser.reducer as ActionReducer<any, Action>,
  meta: fromMeta.reducer as ActionReducer<any, Action>,
  webDomains: fromWebDomains.reducer as ActionReducer<any, Action>,
};

/**
 * Combine default and application specyfic reducers. Use {@link APP_REDUCERS} token
 * to add reducers.
 */
export function getReducers(appReducers: Partial<ActionReducerMap<AppState>>) {
  return {
    ...COMMON_REDUCERS,
    ...appReducers,
  };
}

/**
 * Represent main reducer.
 */
export const REDUCERS = new InjectionToken<ActionReducerMap<Partial<AppState>>>(
  'REDUCERS'
);

/**
 * Application specific reducers.
 */
export const APP_REDUCERS = new InjectionToken<
  ActionReducerMap<Partial<AppState>>
>('APP_REDUCERS');

/**
 * Sets up base store providers.
 */
export const STORE_PROVIDERS = [
  {
    provide: APP_REDUCERS,
    useValue: {},
  },
  {
    provide: REDUCERS,
    useFactory: getReducers,
    deps: [[new Inject(APP_REDUCERS)]],
  },
];

/**
 * Selectors
 */

// Menu

// User
export const getUserState = (state: AppState) => state.user;
export const isAuthorized = createSelector(getUserState, fromUser.isAuthorized);
export const getCurrentPerson = createSelector(
  getUserState,
  fromUser.getCurrentPerson
);
export const getUserRelation = createSelector(
  getUserState,
  fromUser.getUserRelation
);
export const getMainCompany = createSelector(
  getUserState,
  fromUser.getMainCompany
);
export const getPersonProgress = createSelector(
  getUserState,
  fromUser.getPersonProgress
);
export const getDemoDataProgress = createSelector(
  getUserState,
  fromUser.getDemoDataProgress
);
export const getCompanyProgress = createSelector(
  getUserState,
  fromUser.getCompanyProgress
);
export const getTutorialProgress = createSelector(
  getUserState,
  fromUser.getTutorialProgress
);
export const getCurrentPersonLanguage = createSelector(
  getUserState,
  fromUser.getCurrentPersonLanguage
);
export const getCurrentPersonTz = createSelector(
  getUserState,
  fromUser.getCurrentPersonTz
);

export const getRelationType = createSelector(
  getUserState,
  fromUser.getRelationType
);
export const getUserCompanies = createSelector(
  getUserState,
  fromUser.getUserCompanies
);
export const isPersonalAccount = createSelector(
  getUserState,
  fromUser.isPersonalAccount
);

export const getUserActions = createSelector(
  getUserState,
  fromUser.getUserActions
);

// Meta
export const getMetaState = (state: AppState | BookingAppState) => state.meta;
export const getUnits = createSelector(getMetaState, fromMeta.getUnits);
export const getFrequencies = createSelector(
  getMetaState,
  fromMeta.getFrequencies
);
export const getUserRoles = createSelector(getMetaState, fromMeta.getUserRoles);
export const getCustomerRoles = createSelector(
  getMetaState,
  fromMeta.getCustomerRoles
);
export const getStaffRoles = createSelector(
  getMetaState,
  fromMeta.getStaffRoles
);
export const getVendorRoles = createSelector(
  getMetaState,
  fromMeta.getVendorRoles
);
export const getLanguages = createSelector(getMetaState, fromMeta.getLanguages);
export const getTitle = createSelector(getMetaState, fromMeta.getTitle);
export const getMobileTitle = createSelector(
  getMetaState,
  fromMeta.getMobileTitle
);
export const getCountryCode = createSelector(
  getMetaState,
  fromMeta.getCountryCode
);
export const getGuiDomain = createSelector(getMetaState, fromMeta.getGuiDomain);
export const getGuiOnlineBookDomain = createSelector(
  getMetaState,
  fromMeta.getGuiOnlineBookDomain
);
export const getGuiShrDomain = createSelector(
  getMetaState,
  fromMeta.getGuiShrDomain
);
export const getRoles = createSelector(getMetaState, fromMeta.getRoles);
export const getTimezones = createSelector(getMetaState, fromMeta.getTimezones);
export const getWsPingPong = createSelector(
  getMetaState,
  fromMeta.getWsPingPong
);
export const getCurrencies = createSelector(
  getMetaState,
  fromMeta.getCurrencies
);
export const getLicenceCmpId = createSelector(
  getMetaState,
  fromMeta.getLicenceCmpId
);
export const getLicenceWebId = createSelector(
  getMetaState,
  fromMeta.getLicenceWebId
);
export const getCountries = createSelector(getMetaState, fromMeta.getCountries);
export const getCountryInfo = createSelector(
  getMetaState,
  fromMeta.getCountryInfo
);
export const getFoodTypes = createSelector(getMetaState, fromMeta.getFoodTypes);
export const getStates = createSelector(getMetaState, fromMeta.getStates);
export const getToken = createSelector(getMetaState, fromMeta.getToken);
export const getUiTheme = createSelector(getMetaState, fromMeta.getUiTheme);
export const getPSMeta = createSelector(
  getMetaState,
  fromMeta.getPowerSearchMeta
);
export const getPSMetaByMID = (mid: string) =>
  createSelector(getPSMeta, psMeta => {
    return psMeta[mid];
  });

// Core
export const getCoreState = (state: AppState) => state.core;
export const getLoadingDisplay = createSelector(
  getCoreState,
  fromCore.getLoadingDisplay
);
export const getLoadingAnimation = createSelector(
  getCoreState,
  fromCore.getLoadingAnimation
);

// Web Domains
export const getWebDomainsState = (state: AppState) => state.webDomains;
export const getWebDomains = createSelector(
  getWebDomainsState,
  fromWebDomains.getWebDomains
);
export const getIsWebDomainsLoading = createSelector(
  getWebDomainsState,
  fromWebDomains.getIsWebDomainsLoading
);

// Documents
export const getDocumentState = (state: AppState) => state.documents;
export const getCompanyLogo = createSelector(
  getDocumentState,
  fromDocuments.getCompanyLogo
);
export const getCompanyLogoDocs = createSelector(
  getDocumentState,
  fromDocuments.getCompanyLogoDocs
);
export const getPersonalAvatar = createSelector(
  getDocumentState,
  fromDocuments.getPersonalAvatar
);
export const getCreatorLogos = createSelector(
  getDocumentState,
  fromDocuments.getCreatorLogos
);
export const getInvoiceAttachments = createSelector(
  getDocumentState,
  fromDocuments.getInvoiceAttachments
);
export const getPersonalDocuments = createSelector(
  getDocumentState,
  fromDocuments.getPersonalDocuments
);
export const getEntityDocuments = createSelector(
  getDocumentState,
  fromDocuments.getEntityDocuments
);
export const getDataRoomEntityDocuments = createSelector(
  getDocumentState,
  fromDocuments.getDataRoomEntityDocuments
);
export const getIsDataRoomEntityDocumentsLoading = createSelector(
  getDocumentState,
  fromDocuments.getIsDataRoomEntityDocumentsLoading
);
export const getBookingDocuments = createSelector(
  getDocumentState,
  fromDocuments.getBookingDocuments
);
export const getBookingQr = createSelector(
  getDocumentState,
  fromDocuments.getBookingQr
);
export const getFoodMenuDocuments = createSelector(
  getDocumentState,
  fromDocuments.getMenuDocuments
);
export const getExtrasDocuments = createSelector(
  getDocumentState,
  fromDocuments.getExtrasDocuments
);
export const getPreuploadDocuments = createSelector(
  getDocumentState,
  fromDocuments.getPreuploadDocuments
);

// Integrations
export const getIntegrationsState = (state: AppState) => state.integrations;
export const getAvailableCalendars = createSelector(
  getIntegrationsState,
  fromIntegrations.getAvailableCalendars
);
export const getGoogleCalendar = createSelector(
  getIntegrationsState,
  fromIntegrations.getGoogleCalendar
);
export const getAppleCalendar = createSelector(
  getIntegrationsState,
  fromIntegrations.getAppleCalendar
);
export const getMicrosoft = createSelector(
  getIntegrationsState,
  fromIntegrations.getMicrosoft
);
export const getDebtX = createSelector(
  getIntegrationsState,
  fromIntegrations.getDebtX
);
export const getClover = createSelector(
  getIntegrationsState,
  fromIntegrations.getClover
);
export const getSalesforce = createSelector(
  getIntegrationsState,
  fromIntegrations.getSalesforce
);

// ProductSchemas
export const getProductSchemasState = (state: AppState | BookingAppState) =>
  state.productSchemas;
export const getProductSchemaViews = createSelector(
  getProductSchemasState,
  fromProductSchemas.getSchemaViews
);
export const getSchemaSids = createSelector(
  getProductSchemasState,
  fromProductSchemas.getSchemasSids
);
export const getProductSchemasMeta = createSelector(
  getProductSchemasState,
  fromProductSchemas.getSchemasMeta
);

// Ncino
export const getNcinoState = (state: AppState) => state.ncino;
export const getNcino = createSelector(getNcinoState, fromNcino.getNcino);

// Document Activity
export const getDocumentActivityState = (state: AppState) =>
  state.documentActivity;
export const getDocumentActivity = createSelector(
  getDocumentActivityState,
  fromDocumentActivity.getDocumentActivity
);

// Transactions
export const getTransactionState = (state: AppState) => {
  return state.transaction;
};
export const getTransactions = createSelector(
  getTransactionState,
  fromTransactions.getTransactions
);
export const getTransactionsById = (mysid: string) =>
  createSelector(getTransactions, transactiions => {
    return transactiions[mysid] || [];
  });
