import { Action } from '@ngrx/store';

import { type } from '@qtek/shared/utils';

export class CoreActionTypes {
  static readonly OPEN_LOADING = type('[Core] Open loading');
  static readonly CLOSE_LOADING = type('[Core] Close loading');

  static readonly START_LOADING_ANIMATION = type(
    '[Core] Start loading animation'
  );
  static readonly STOP_LOADING_ANIMATION = type(
    '[Core] Stop loading animation'
  );
}

export class OpenLoadingAction implements Action {
  readonly type = CoreActionTypes.OPEN_LOADING;
}

export class CloseLoadingAction implements Action {
  readonly type = CoreActionTypes.CLOSE_LOADING;
}

export class StartLoadingAnimationAction implements Action {
  readonly type = CoreActionTypes.START_LOADING_ANIMATION;
}

export class StopLoadingAnimationAction implements Action {
  readonly type = CoreActionTypes.STOP_LOADING_ANIMATION;
}

export type CoreActions =
  | OpenLoadingAction
  | CloseLoadingAction
  | StartLoadingAnimationAction
  | StopLoadingAnimationAction;
