import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AccountOutput, entityName } from '@qtek/shared/models';
import { AppState } from '../index';

import { HttpEntityManager } from './../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends HttpEntityManager<AccountOutput, any> {
  entityName: entityName = 'acn';

  constructor(protected http: HttpClient, private store: Store<AppState>) {
    super();
  }

  updateCompany(
    additionalPayload: any = {},
    payload: any = {},
    companyId?: string
  ) {
    return super.put(payload, null, additionalPayload);
  }
}
