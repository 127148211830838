export abstract class QtIntl {
  /**
   * Update translation codes and emit change event.
   *
   * @param translations Object with all translations for current language.
   */
  abstract updateTranslations(translations: any): any;

  /**
   * Get translation codes to fetch from ws.
   *
   */
  abstract getTranslations(): string[];
}
