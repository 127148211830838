import { Injectable } from '@angular/core';
import { Icon, IconType } from './icon';
import { IconsPack } from './icons-pack';
import { MaterialIconsPack } from './pack/material-icons-pack';

@Injectable()
export class IconPickerService {
  private matIconsPack: IconsPack;

  constructor() {
    this.matIconsPack = new MaterialIconsPack();
  }

  getIcons(ipIconPacks: string[]): Icon[] {
    const icons: Icon[] = [];
    ipIconPacks.forEach((ipIconPack) => {
      if (ipIconPack === 'mat' || ipIconPack === 'all') {
        const matIcons = this.matIconsPack.getIcons().map((icon) => {
          icon.type = IconType.Material;
          return icon;
        });
        icons.push(...matIcons);
      }
    });
    return icons;
  }
}
