import { Action } from '@ngrx/store';

import {
  ItemSchemaMeta,
  ProductSchema,
  SchemaSidProperty,
} from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const SchemaActionTypes = {
  GET_SCHEMAS_META: type('[ProductSchema] Get Product Schemas Meta'),
  GET_SCHEMAS_META_SUCCESS: type(
    '[ProductSchema] Get Product Schemas Meta Success'
  ),
  GET_SCHEMAS_META_FAILURE: type(
    '[ProductSchema] Get Product Schemas Meta Failure'
  ),

  GET_SCHEMA_VIEW: type('[ProductSchema] Get Product Schema by query'),
  GET_SCHEMA_VIEW_SUCCESS: type(
    '[ProductSchema] Get Product Schema by query Success'
  ),
  GET_SCHEMA_VIEW_FAILURE: type(
    '[ProductSchema] Get Product Schema by query Failure'
  ),

  GET_SCHEMA_SIDS: type('[ProductSchema] Get SIDs Schema'),
  GET_SCHEMA_SIDS_SUCCESS: type('[ProductSchema] Get SID Schema Success'),
  GET_SCHEMA_SIDS_FAILURE: type('[ProductSchema] Get SID Schema Failure'),
};

export class GetSchemasMetaAction implements Action {
  type = SchemaActionTypes.GET_SCHEMAS_META;

  constructor(public payload?: any) {}
}

export class GetSchemasMetaFailureAction implements Action {
  type = SchemaActionTypes.GET_SCHEMAS_META_FAILURE;

  constructor(public payload?: any) {}
}

export class GetSchemasMetaSuccessAction implements Action {
  type = SchemaActionTypes.GET_SCHEMAS_META_SUCCESS;

  constructor(public payload: ItemSchemaMeta) {}
}

export class GetSchemaViewAction implements Action {
  type = SchemaActionTypes.GET_SCHEMA_VIEW;

  constructor(public payload: string) {}
}

export class GetSchemaViewFailureAction implements Action {
  type = SchemaActionTypes.GET_SCHEMA_VIEW_FAILURE;

  constructor(public payload?: any) {}
}

export class GetSchemaViewSuccessAction implements Action {
  type = SchemaActionTypes.GET_SCHEMA_VIEW_SUCCESS;

  constructor(public payload: { view: any; query: string }) {}
}

export class GetSchemaSidsAction implements Action {
  type = SchemaActionTypes.GET_SCHEMA_SIDS;

  constructor(public payload: string[]) {}
}

export class GetSchemaSidsFailureAction implements Action {
  type = SchemaActionTypes.GET_SCHEMA_SIDS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetSchemaSidsSuccessAction implements Action {
  type = SchemaActionTypes.GET_SCHEMA_SIDS_SUCCESS;

  constructor(public payload: { [key: string]: SchemaSidProperty[] }) {}
}

export type ProductSchemasActions =
  | GetSchemaViewAction
  | GetSchemaViewFailureAction
  | GetSchemaViewSuccessAction
  | GetSchemasMetaAction
  | GetSchemasMetaFailureAction
  | GetSchemasMetaSuccessAction
  | GetSchemaSidsAction
  | GetSchemaSidsFailureAction
  | GetSchemaSidsSuccessAction;
