import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { WizardActions } from './wizard.actions';
import { Step } from '@qtek/shared/models';

export interface WizardState {
  steps: Step[];
  demoSteps: any;
  maxPersonProgress: number | null;
  maxCompanyProgress: number | null;
  isWizardLoading: boolean;
  wizardDone: boolean;
}

const initialState: WizardState = {
  steps: [],
  demoSteps: null,
  isWizardLoading: false,
  maxPersonProgress: null,
  maxCompanyProgress: null,
  wizardDone: false,
};

export const WizardReducer = createReducer(
  initialState,
  on(WizardActions.isWizardDone, state => ({
    ...state,
    wizardDone: true,
  })),
  on(WizardActions.getStepsSuccess, (state, { payload }) => ({
    ...state,
    steps: payload,
  })),
  on(WizardActions.getDemoStepsSuccess, (state, { payload }) => ({
    ...state,
    demoSteps: payload,
  })),
  on(WizardActions.setMaxPersonProgress, (state, { payload }) => ({
    ...state,
    maxPersonProgress: payload,
  })),
  on(WizardActions.setMaxCompanyProgress, (state, { payload }) => ({
    ...state,
    maxCompanyProgress: payload,
  })),
  on(WizardActions.setLoading, (state, { payload }) => ({
    ...state,
    isWizardLoading: payload,
  }))
);

export const wizardFeature = createFeature({
  name: 'wizard',
  reducer: WizardReducer,
  extraSelectors: ({ selectWizardState }) => ({
    selectMaxDemoDataProgress: createSelector(
      selectWizardState,
      (state: WizardState) =>
        state.demoSteps
          ? Object.keys(state.demoSteps).reduce(
              (prev, curr) => prev | Number(state.demoSteps[curr]),
              0
            )
          : 0
    ),
  }),
});

export const {
  selectWizardDone,
  selectSteps,
  selectDemoSteps,
  selectMaxPersonProgress,
  selectMaxCompanyProgress,
  selectIsWizardLoading,
  selectMaxDemoDataProgress,
} = wizardFeature;
