import { createFeature, createReducer, on } from '@ngrx/store';
import { CoreActions } from './core.actions';

export interface CoreState {
  displayLoading: boolean;
  animationLoading: boolean;
}

const initialState: CoreState = {
  displayLoading: false,
  animationLoading: true,
};

export const coreReducer = createReducer(
  initialState,
  on(CoreActions.openLoading, state => ({ ...state, displayLoading: true })),
  on(CoreActions.closeLoading, state => ({ ...state, displayLoading: false })),
  on(CoreActions.startLoadingAnimation, state => ({
    ...state,
    animationLoading: true,
  })),
  on(CoreActions.stopLoadingAnimation, state => ({
    ...state,
    animationLoading: false,
  }))
);

export const CoreFeature = createFeature({
  name: 'core',
  reducer: coreReducer,
});

export const { selectDisplayLoading, selectAnimationLoading } = CoreFeature;
