import { OverlayContainer } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  NgZone,
  OnInit,
  Renderer2,
  Version,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  Event as RouterEvent,
} from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Environment, PersonOutput } from '@qtek/shared/models';
import {
  AppState,
  LoadMetaGuiDomainAction,
  getCurrentPerson,
  getGuiDomain,
  getLoadingDisplay,
} from '@qtek/libs/store';
import { Subscription, from } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { ApiCoreService } from '@qtek/core/api-core';
import {
  LocalStorageActions,
  LocalStorageManagementService,
} from '@qtek/core/local-storage-management';
import { ThemeManagementService } from '@qtek/core/theme-management';
import { WebSocketService } from '@qtek/core/websockets-core';
import { MetaCoreActions } from '@qtek/libs/meta-core';
import { SnackBarService } from '@qtek/libs/snack-bar-core';
import { TranslationCoreService } from '@qtek/libs/translation-core';
import { QtIntl } from './intl';
import { LoaderComponent } from './loader/loader.component';

// Extended components do not inherit metadata so we export it here.
export const APP_COMPONENT_DEFAULT_TEMPLATE = `
<div>
  <mat-progress-bar #progressBar mode="indeterminate" color="accent"></mat-progress-bar>
  <router-outlet></router-outlet>
</div>
`;

export const APP_COMPONENT_DEFAULT_STYLES = `
.mat-mdc-progress-bar {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
`;

@Directive()
export abstract class QtAppComponent implements OnInit, AfterViewInit {
  @ViewChild('progressBar', { read: ElementRef })
  progressBar: ElementRef;
  @HostBinding('class') className = '';

  themeSub: Subscription;
  // tslint:disable variable-name
  constructor(
    private _ngZone: NgZone,
    private _renderer: Renderer2,
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _snackBar: MatSnackBar,
    private _document: any,
    private _intls: QtIntl[],
    private _route: ActivatedRoute,
    private _viewContainerRef: ViewContainerRef,
    private _translateService: TranslationCoreService,
    private _overlay: OverlayContainer,
    private _apiCoreService: ApiCoreService,
    private _webSocketService: WebSocketService,
    private _localStorageManagementService: LocalStorageManagementService,
    private _themeManagementService: ThemeManagementService,
    private _snackbarService: SnackBarService,
    private _environment: Environment,
    private _version: Version
  ) {
    this._translateService.setLanguage('en_US');
    this._store.dispatch(new LoadMetaGuiDomainAction());
  }
  // tslint:enable variable-name

  ngOnInit() {
    this._store.dispatch(MetaCoreActions.loadMetaGuiOnlineBookDomain());
    this._store.dispatch(MetaCoreActions.loadMetaLanguages());
    this._store.dispatch(MetaCoreActions.loadMetaWebsocket());
    this._store.dispatch(LocalStorageActions.refreshLocalStorage());
    // create component on first occurrence
    this._store
      .select(getLoadingDisplay)
      .pipe(filter(Boolean), take(1))
      .subscribe(() => this.createLoaderComponent());

    this._store
      .pipe(select(getGuiDomain), filter(Boolean), take(1))
      .subscribe((domain: string) => {
        this.registerIcons(domain);
      });

    // replace material icons with symbols font
    const defaultFontSetClasses =
      this._matIconRegistry.getDefaultFontSetClass();
    const outlinedFontSetClasses = defaultFontSetClasses
      .filter(fontSetClass => fontSetClass !== 'material-icons')
      .concat(['material-symbols-outlined']);
    this._matIconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);

    // Change language
    this._store
      .pipe(
        select(getCurrentPerson),
        filter((person: PersonOutput) => Boolean(person?.lng))
      )
      .subscribe(
        // @ts-ignore
        person => {
          this._translateService.setLanguage(person.lng);
        }
      );

    this._translateService
      .getCachedLanguageSubject()
      .asObservable()
      .pipe(switchMap(() => from(this._intls)))
      .subscribe(intl => {
        this._translateService
          .getTranslations(intl.getTranslations())
          .subscribe(res => {
            intl.updateTranslations(res);
          });
      });

    // Open snackbar if action contains a message.
    this._actions$
      .pipe(
        filter<any>(({ message }) => Boolean(message)),
        switchMap(({ message }) => {
          return this._translateService.getTranslation(message.expression, {
            params: message.params,
          });
        })
      )
      .subscribe(msg => {
        this._snackBar.open(msg as string);
      });

    this.onInitCallback();
  }

  ngAfterViewInit(): void {
    this._router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  /**
   * Function that is called in OnInit hook
   */
  onInitCallback() {}

  private registerIcons(domain: string): void {
    let iconsPath: string;

    if (this._environment.production) {
      iconsPath = `${domain}/assets/img/icons/icons.svg?v=${this._version.full}`;
    } else {
      const { host } = this._document.location;
      iconsPath = `//${host}/assets/img/icons/icons.svg?v=${this._version.full}`;
    }

    const safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(iconsPath);

    this._matIconRegistry.addSvgIconSet(safeUrl);
  }

  private navigationInterceptor(event: RouterEvent): void {
    if (
      event instanceof NavigationStart ||
      event instanceof RouteConfigLoadStart
    ) {
      this.updateOpacity(1);
    } else if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError ||
      event instanceof RouteConfigLoadEnd
    ) {
      this.updateOpacity(0);
    }
  }

  private updateOpacity(value: 0 | 1): void {
    this._ngZone.runOutsideAngular(() => {
      this._renderer.setStyle(this.progressBar.nativeElement, 'opacity', value);
    });
  }

  private createLoaderComponent(): void {
    const componentRef =
      this._viewContainerRef.createComponent(LoaderComponent);
    componentRef.changeDetectorRef.detectChanges();
  }
}
