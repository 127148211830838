import { Observable } from 'rxjs';
import { accessLevel } from './access-level.model';
import { DocumentType } from './document.model';
import { LogoMeta } from './logo-meta.model';
import { Scopes } from './scopes.model';

export interface DocumentInfo {
  /**
   * document access level  0 | 100 | 200
   *
   * @type {accessLevel}
   * @memberof DocumentInfo
   */
  accs: accessLevel;

  /**
   * document id
   *
   * @type {string}
   * @memberof DocumentInfo
   */
  docId: string;

  /**
   * owner id
   *
   * @type {string}
   * @memberof DocumentInfo
   */
  refOwnId?: string;

  /**
   * document description
   *
   * @type {string}
   * @memberof DocumentInfo
   */
  desc?: string;

  /**
   * file type
   *
   * @type {string}
   * @memberof DocumentInfo
   */
  ctp: string;

  /**
   * document additional metadata
   *
   * @type {object}
   * @memberof DocumentInfo
   */
  meta?: {
    logoMeta?: LogoMeta;
  };

  /**
   * File name
   *
   * @type {string}
   * @memberof DocumentInfo
   */
  origfl: string;

  /**
   * Size in B
   *
   * @type {number}
   * @memberof DocumentInfo
   */
  size: number;

  ctgId?: number;
  ent?: string;
  pid?: string;
  creatorRefId?: string;
  fileName?: string;
  fileType?: string;
  exts?: string[];

  creator?: { name: string };
  _cts?: string;
  _scopes?: Scopes;
}

export interface DocumentOutput {
  attr: DocumentType;
  doc: DocumentInfo;
  desc?: string;
  refId?: string;
  ctgId?: string;
}

export interface DocumentUploadResponse {
  accs: accessLevel;
  bucket: string;
  creatorRefId: string;
  ctp: string;
  desc: string;
  exts: string[];
  id: string;
  mdsts: string;
  meta: object;
  origfl: string;
  size: number;
  sndts: string;
}
export interface DocumentOutput {
  attr: DocumentType;

  refId?: string;

  doc: DocumentInfo;
}

export type DocumentActivity = 0 | 10 | 50 | 100;
export type ActivityStatus = 0 | 1 | 999;
export interface DocumentActivityHistory {
  tp: DocumentActivity;
  sts: ActivityStatus;
  docId: string;
  usr: {
    prs: {
      name: string;
    };
    acn: {
      name: {
        name: string;
        ent: string;
      };
      lgoImg: string;
    };
  };
  _cts: string;
}

// type of docNode 1 = file, 50 = directory
export type DocType = 1 | 50;

export interface DocNode {
  pid?: string;
  tp: DocType;
  // name used for folders
  name?: string;
  // desc used for files
  desc?: string;
  attr?: DocumentType;
  path?: string;
  kds?: DocNode[];
  documentType?: string;
  refId?: string;
  exts?: string[];
  fileName?: string;
  fileType?: string;
  size?: number;
  sts?: DocStatus;
  uploadObs?: Observable<any>;
  _id: string;
  origfl?: string;
}

export const NOT_UPLOADED_DOC: DocStatus = 0;
export const UPLOADED_DOC: DocStatus = 100;
export const SYSTEM_GENERATED_DOC: DocStatus = 200;

export type DocStatus = 0 | 100 | 200;

export interface PreUploadPayload {
  action: number;
  refId: string;
  attr: DocumentType;
  access: accessLevel;
  docName: string;
  ctgId: string;
}

export interface PreUploadTokenRes {
  tkn: string;
  docIds: string[];
  refId: string;
}

export const DOCUMENT_UPLOAD_CREATED: DocumentUploadStatus = 0;
export const DOCUMENT_UPLOAD_STARTED: DocumentUploadStatus = 100;
export const DOCUMENT_UPLOAD_COMPLETED: DocumentUploadStatus = 200;
export const DOCUMENT_UPLOAD_CANCELED: DocumentUploadStatus = 999;

export type DocumentUploadStatus = 0 | 100 | 200 | 999;

export interface PreUploadState {
  tkn: string;
  docId: string;
  refId: string;
  status: DocumentUploadStatus;
  progress?: number;
  speed?: number;
  filename: string;
  filesize: number;
}

export interface CreateFolderStructureWS {
  etp: 'doc';
  name: string;
  pid: string | null;
  kds: CreateFolderStructureWS[];
}
