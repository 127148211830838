import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  endOfWeek,
  getDay,
  isWithinInterval,
  max,
  setHours,
  setMinutes,
  startOfDay,
  startOfMinute,
  startOfWeek,
  subMinutes,
} from 'date-fns';

import {
  EventAction,
  EventColor,
  GetDayViewArgs,
  GetWeekViewArgs,
  SECONDS_IN_DAY,
  ViewPeriod,
  WeekViewAllDayEvent,
  WeekViewHour,
  WeekViewHourSegment,
  WeekViewTimeEvent,
  getEventsInPeriod,
} from 'calendar-utils';

import {
  BIT_DELETE,
  BIT_EDIT_MTG_OTHER_ATTRIBUTES,
  Color,
  QtCalendarEvent,
} from '@qtek/shared/models';
import { adapterFactory as baseAdapterFactory } from 'calendar-utils/date-adapters/date-fns';
import {
  addMonths,
  addWeeks,
  getDate,
  getISOWeek,
  getYear,
  setDate,
  setMonth,
  setYear,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';

export function adapterFactory(): DateAdapter {
  return {
    ...baseAdapterFactory(),
    addWeeks,
    addMonths,
    subDays,
    subWeeks,
    subMonths,
    getISOWeek,
    setDate,
    setMonth,
    setYear,
    getDate,
    getYear,
  };
}

export type WeekStartsOnType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const SECONDS_IN_WEEK: number = SECONDS_IN_DAY * 7;

export abstract class DateAdapter {
  abstract addWeeks(date: Date | number, amount: number): Date;

  abstract addMonths(date: Date | number, amount: number): Date;

  abstract subDays(date: Date | number, amount: number): Date;

  abstract subWeeks(date: Date | number, amount: number): Date;

  abstract subMonths(date: Date | number, amount: number): Date;

  abstract getISOWeek(date: Date | number): number;

  abstract setDate(date: Date | number, dayOfMonth: number): Date;

  abstract setMonth(date: Date | number, month: number): Date;

  abstract setYear(date: Date | number, year: number): Date;

  abstract getDate(date: Date | number): number;

  abstract getMonth(date: Date | number): number;

  abstract getYear(date: Date | number): number;

  abstract addDays(date: Date | number, amount: number): Date;

  abstract addHours(date: Date | number, amount: number): Date;

  abstract addMinutes(date: Date | number, amount: number): Date;

  abstract addSeconds(date: Date | number, amount: number): Date;

  abstract differenceInDays(
    dateLeft: Date | number,
    dateRight: Date | number
  ): number;

  abstract differenceInMinutes(
    dateLeft: Date | number,
    dateRight: Date | number
  ): number;

  abstract differenceInSeconds(
    dateLeft: Date | number,
    dateRight: Date | number
  ): number;

  abstract endOfDay(date: Date | number): Date;

  abstract endOfMonth(date: Date | number): Date;

  abstract endOfWeek(
    date: Date | number,
    options?: { weekStartsOn?: WeekStartsOnType }
  ): Date;

  abstract getDay(date: Date | number): number;

  abstract isSameDay(
    dateLeft: Date | number,
    dateRight: Date | number
  ): boolean;

  abstract isSameMonth(
    dateLeft: Date | number,
    dateRight: Date | number
  ): boolean;

  abstract isSameSecond(
    dateLeft: Date | number,
    dateRight: Date | number
  ): boolean;

  abstract max(dates: Array<Date | number>): Date;

  abstract setHours(date: Date | number, hours: number): Date;

  abstract setMinutes(date: Date | number, minutes: number): Date;

  abstract startOfDay(date: Date | number): Date;

  abstract startOfMinute(date: Date | number): Date;

  abstract startOfMonth(date: Date | number): Date;

  abstract startOfWeek(
    date: Date | number,
    options?: { weekStartsOn?: WeekStartsOnType }
  ): Date;

  abstract getHours(date: Date | number): number;

  abstract getMinutes(date: Date | number): number;

  getTimezoneOffset(date: Date | number): number {
    return 0;
  }
}

export interface CalendarEvent<MetaType = any> {
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color?: EventColor;
  actions?: EventAction[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  meta?: MetaType;
}

export interface WeekViewHourSegmentWithDragOver extends WeekViewHourSegment {
  dragOver?: boolean;
}

export interface WeekViewHourWithDragOver extends WeekViewHour {
  segments: WeekViewHourSegmentWithDragOver[];
}

export enum CalendarEventTimesChangedEventType {
  Drag = 'drag',
  Drop = 'drop',
  Resize = 'resize',
}

export interface CalendarEventTimesChangedEvent<MetaType = any> {
  type: CalendarEventTimesChangedEventType;
  event: CalendarEvent<MetaType>;
  newStart: Date;
  newEnd?: Date;
  allDay?: boolean;
}

export interface QtWeekViewEvent extends WeekViewAllDayEvent {
  top: number;
  height: number;
  conflicts?: QtWeekViewEvent[];
  conflictsAmount?: number;
  criticalConflicts?: QtWeekViewEvent[];
  criticalConflictsAmount?: number;
  criticalConflictsOffset?: number;
}

export interface DayView {
  events: WeekViewTimeEvent[];
  width: number;
  allDayEvents: CalendarEvent[];
  period: ViewPeriod;
}

export interface QtDayViewEvent {
  event: CalendarEvent;
  height: number;
  width: number;
  top: number;
  left: number;
  startsBeforeDay: boolean;
  endsAfterDay: boolean;
  conflicts?: QtDayViewEvent[];
  conflictsAmount?: number;
  criticalConflicts?: QtDayViewEvent[];
  criticalConflictsAmount?: number;
  criticalConflictsOffset?: number;
}

export interface QtWeekViewEventRow {
  row: QtWeekViewEvent[];
}

export interface QtWeekView {
  period: ViewPeriod;
  multipleDayEventRows: QtWeekViewEventRow[];
  oneDayEvents: QtWeekViewEvent[];
}

export interface QtDayView extends DayView {
  events: QtDayViewEvent[];
  allDayEvents: CalendarEvent[];
  period: ViewPeriod;
}

function getWeekViewEventOffset(
  dateAdapter: DateAdapter,
  {
    event,
    startOfWeek: startOfWeekDate,
    excluded,
    precision,
  }: {
    event: CalendarEvent;
    startOfWeek: Date;
    excluded: number[];
    precision: 'minutes' | 'days';
  }
): number {
  const { differenceInDays, startOfDay, differenceInSeconds } = dateAdapter;
  if (event.start < startOfWeekDate) {
    return 0;
  }

  let offset = 0;

  switch (precision) {
    case 'days':
      offset =
        differenceInDays(startOfDay(event.start), startOfWeekDate) *
        SECONDS_IN_DAY;
      break;
    case 'minutes':
      offset = differenceInSeconds(event.start, startOfWeekDate);
      break;
  }

  offset -= getExcludedSeconds(dateAdapter, {
    startDate: startOfWeekDate,
    seconds: offset,
    excluded,
    precision,
  });

  return Math.abs(offset / SECONDS_IN_DAY);
}

export function getWeekViewEventSpan(
  dateAdapter: DateAdapter,
  {
    event,
    offset,
    startOfWeekDate,
    excluded,
    precision = 'days',
  }: {
    event: CalendarEvent;
    offset: number;
    startOfWeekDate: Date;
    excluded: number[];
    precision?: 'minutes' | 'days';
  }
): number {
  let span: number = SECONDS_IN_DAY;
  const begin: Date = max([event.start, startOfWeekDate]);

  if (event.end) {
    switch (precision) {
      case 'minutes':
        span = differenceInSeconds(event.end, begin);
        break;
      default:
        span =
          differenceInDays(
            addDays(endOfDay(subMinutes(event.end, 1)), 1),
            begin
          ) * SECONDS_IN_DAY;
        break;
    }
  }

  const offsetSeconds: number = offset * SECONDS_IN_DAY;
  const totalLength: number = offsetSeconds + span;

  // the best way to detect if an event is outside the week-view
  // is to check if the total span beginning (from startOfWeekDay or event start) exceeds 7days
  if (totalLength > SECONDS_IN_WEEK) {
    span = SECONDS_IN_WEEK - offsetSeconds;
  }

  span -= getExcludedSeconds(dateAdapter, {
    startDate: begin,
    seconds: span,
    excluded,
    precision,
  });

  return span / SECONDS_IN_DAY;
}

export function getWeekView(
  dateAdapter: DateAdapter,
  {
    events = [],
    viewDate,
    weekStartsOn,
    excluded = [],
    precision = 'days',
    absolutePositionedEvents = false,
    hourSegments,
    dayStart,
    segmentHeight,
  }: GetWeekViewArgs & GetDayViewArgs
): QtWeekView {
  if (!events) {
    events = [];
  }
  const startOfViewWeek: Date = startOfWeek(viewDate, {
    weekStartsOn: weekStartsOn as WeekStartsOnType,
  });
  const endOfViewWeek: Date = endOfWeek(viewDate, {
    weekStartsOn: weekStartsOn as WeekStartsOnType,
  });
  const maxRange: number = 7 - excluded.length;
  const eventsInPeriod = getEventsInPeriod(dateAdapter, {
    events,
    periodStart: startOfViewWeek,
    periodEnd: endOfViewWeek,
  });

  let eventsMapped: QtWeekViewEvent[] = eventsInPeriod
    .map(event => {
      const eventStart: Date = event.start;
      const eventEnd: Date = event.end || eventStart;
      const hourHeightModifier: number = (hourSegments * segmentHeight) / 60;
      const startOfView: Date = setMinutes(
        setHours(startOfDay(eventStart), dayStart.hour),
        dayStart.minute
      );

      let top = 0;
      const hoursInDay = getHoursInDay(eventStart);
      if (hoursInDay.length !== 24) {
        let skipHour;
        for (let i = 0; i < hoursInDay.length; i++) {
          if (hoursInDay[i].getHours() !== i) {
            skipHour = i;
            break;
          }
        }

        if (eventStart.getHours() > skipHour) {
          const timeChangeOffset = (24 - hoursInDay.length) * 60;
          top += timeChangeOffset;
        }
      }
      top += differenceInMinutes(eventStart, startOfView);
      top *= hourHeightModifier;

      let height: number = differenceInMinutes(eventEnd, eventStart);
      if (!event.end) {
        height = segmentHeight;
      } else {
        height *= hourHeightModifier;
      }
      if (height === 0) {
        height = 30 * hourHeightModifier;
      }

      const offset: number = getWeekViewEventOffset(dateAdapter, {
        event,
        startOfWeek: startOfViewWeek,
        excluded,
        precision,
      });
      const span: number = getWeekViewEventSpan(dateAdapter, {
        event,
        offset,
        startOfWeekDate: startOfViewWeek,
        excluded,
        precision,
      });
      return { event, offset, span, top, height };
    })
    .filter(e => e.offset < maxRange)
    .filter(e => e.span > 0)
    .map(entry => ({
      event: entry.event,
      offset: entry.offset,
      span: entry.span,
      startsBeforeWeek: entry.event.start < startOfViewWeek,
      endsAfterWeek:
        (subMinutes(entry.event.end, 1) || entry.event.start) > endOfViewWeek,
      top: entry.top,
      height: entry.height,
    }))
    .sort((itemA, itemB): number => {
      const startSecondsDiff: number = differenceInSeconds(
        itemA.event.start,
        itemB.event.start
      );
      if (startSecondsDiff === 0) {
        return differenceInSeconds(
          itemB.event.end || itemB.event.start,
          itemA.event.end || itemA.event.start
        );
      }
      return startSecondsDiff;
    });

  const multipleDayEventRows: QtWeekViewEventRow[] = [];
  const allocatedEvents: QtWeekViewEvent[] = [];
  const eventsGroupped: QtWeekViewEvent[][] = [];

  for (const event of eventsMapped) {
    if (event.span === 1 && !event.startsBeforeWeek && !event.endsAfterWeek) {
      if (!eventsGroupped[event.offset]) {
        eventsGroupped[event.offset] = [event];
      } else {
        eventsGroupped[event.offset].push(event);
      }
    }
  }

  eventsMapped = eventsMapped
    .map(event => {
      if (event.span !== 1 || event.startsBeforeWeek || event.endsAfterWeek) {
        return event;
      } else if (!event.event.meta.mtgId) {
        return {
          ...event,
          conflictsAmount: 0,
          criticalConflictsAmount: 0,
        };
      } else {
        const conflicts = eventsGroupped[event.offset].filter(
          e =>
            e.event !== event.event &&
            isWithinInterval(event.event.start, {
              start: addMinutes(
                e.event.start,
                e.event.meta.dr >= 30 ? 29 : (e.event.meta.dr || 30) - 1
              ),
              end: addMinutes(e.event.start, (e.event.meta.dr || 30) - 1),
            })
        );

        const criticalConflicts = eventsGroupped[event.offset].filter(
          e =>
            e.event !== event.event &&
            (isWithinInterval(event.event.start, {
              start: e.event.start,
              end: addMinutes(
                e.event.start,
                e.event.meta.dr >= 30 ? 29 : (e.event.meta.dr || 30) - 1
              ),
            }) ||
              isWithinInterval(addMinutes(event.event.start, 29), {
                start: e.event.start,
                end: addMinutes(
                  e.event.start,
                  e.event.meta.dr >= 30 ? 29 : (e.event.meta.dr || 30) - 1
                ),
              }))
        );

        return {
          ...event,
          conflicts,
          conflictsAmount: conflicts.length,
          criticalConflicts,
          criticalConflictsAmount: criticalConflicts.length,
          criticalConflictsOffset: 0,
        };
      }
    })
    .map((event, index, array) => {
      if (event.conflictsAmount && !event.criticalConflictsAmount) {
        const sameAmount = array.find(
          e =>
            e.conflictsAmount === event.conflictsAmount &&
            e.event.meta.mtgId !== event.event.meta.mtgId
        );

        if (
          sameAmount &&
          event.conflicts.findIndex(
            e => e.event.meta.mtgId === sameAmount.event.meta.mtgId
          ) !== -1 &&
          array.indexOf(sameAmount) < index
        ) {
          return { ...event, conflictsAmount: event.conflictsAmount + 1 };
        }
      } else if (event.criticalConflictsAmount) {
        let criticalConflictsOffset = 0;
        for (const conflict of event.criticalConflicts) {
          if (
            array.findIndex(
              e =>
                e.event.meta.mtgId === conflict.event.meta.mtgId &&
                e.event.meta.multiEventType ===
                  conflict.event.meta.multiEventType
            ) > index
          ) {
            criticalConflictsOffset++;
          }
        }

        return { ...event, criticalConflictsOffset };
      }
      return event;
    });

  const oneDayEvents = eventsMapped.filter(
    event => event.span === 1 && !event.startsBeforeWeek && !event.endsAfterWeek
  );
  const multipleDayEvents = eventsMapped.filter(
    event => event.span !== 1 || event.startsBeforeWeek || event.endsAfterWeek
  );

  multipleDayEvents.forEach((event: QtWeekViewEvent, index: number) => {
    if (allocatedEvents.indexOf(event) === -1) {
      allocatedEvents.push(event);
      let rowSpan: number = event.span + event.offset;

      const otherMultipleDayEvents = multipleDayEvents
        .slice(index + 1)
        .filter(nextEvent => {
          if (
            nextEvent.offset >= rowSpan &&
            rowSpan + nextEvent.span <= 7 &&
            allocatedEvents.indexOf(nextEvent) === -1
          ) {
            const nextEventOffset: number = nextEvent.offset - rowSpan;
            if (!absolutePositionedEvents) {
              nextEvent.offset = nextEventOffset;
            }
            rowSpan += nextEvent.span + nextEventOffset;
            allocatedEvents.push(nextEvent);
            return true;
          }
          return false;
        });

      multipleDayEventRows.push({
        row: [event, ...otherMultipleDayEvents],
      });
    }
  });

  return {
    oneDayEvents,
    multipleDayEventRows,
    period: {
      events: eventsInPeriod,
      start: startOfViewWeek,
      end: endOfViewWeek,
    },
  };
}

export function calculateExcludedSeconds({
  precision,
  day,
  dayStart,
  dayEnd,
  startDate,
  endDate,
}: {
  day: number;
  startDate: Date;
  endDate: Date;
  dayStart: number;
  dayEnd: number;
  precision?: 'minutes' | 'days';
}): number {
  if (precision === 'minutes') {
    if (day === dayStart) {
      return differenceInSeconds(endOfDay(startDate), startDate) + 1;
    } else if (day === dayEnd) {
      return differenceInSeconds(endDate, startOfDay(endDate)) + 1;
    }
  }

  return SECONDS_IN_DAY;
}

export function getExcludedSeconds(
  dateAdapter: DateAdapter,
  {
    startDate,
    seconds,
    excluded,
    precision = 'days',
  }: {
    startDate: Date;
    seconds: number;
    excluded: number[];
    precision?: 'minutes' | 'days';
  }
): number {
  if (excluded.length < 1) {
    return 0;
  }
  const endDate: Date = addSeconds(startDate, seconds - 1);
  const dayStart: number = getDay(startDate);
  const dayEnd: number = getDay(addSeconds(endDate, 0));
  let result = 0; // Calculated in seconds
  let current: Date = startDate;

  while (current < endDate) {
    const day: number = getDay(current);

    if (excluded.some(excludedDay => excludedDay === day)) {
      result += calculateExcludedSeconds({
        dayStart,
        dayEnd,
        day,
        precision,
        startDate,
        endDate,
      });
    }

    current = addDays(current, 1);
  }

  return result;
}

export class QtCalendarDragHelper {
  constructor(
    private dragContainerElement: HTMLElement,
    draggableElement: HTMLElement
  ) {
    this.startPosition = draggableElement.getBoundingClientRect();
  }

  startPosition: ClientRect;

  validateDrag({ x, y }: { x: number; y: number }): boolean {
    const newRect: ClientRect = Object.assign({}, this.startPosition, {
      left: this.startPosition.left + x,
      right: this.startPosition.right + x,
      top: this.startPosition.top + y,
      bottom: this.startPosition.bottom + y,
    });

    const dragContainer = this.dragContainerElement.getBoundingClientRect();
    return (
      Math.ceil(dragContainer.left) <= Math.ceil(newRect.left) &&
      Math.ceil(newRect.left) <= Math.ceil(dragContainer.right) &&
      Math.ceil(dragContainer.left) <= Math.ceil(newRect.right) &&
      Math.ceil(newRect.right) <= Math.ceil(dragContainer.right) &&
      newRect.top <= dragContainer.bottom &&
      dragContainer.top <= newRect.bottom &&
      (newRect.bottom <= dragContainer.bottom ||
        dragContainer.top <= newRect.top)
    );
  }

  validateDragWithoutTopAndBottom({ x, y }: { x: number; y: number }): boolean {
    const newRect: ClientRect = Object.assign({}, this.startPosition, {
      left: this.startPosition.left + x,
      right: this.startPosition.right + x,
      top: this.startPosition.top + y,
      bottom: this.startPosition.bottom + y,
    });

    const dragContainer = this.dragContainerElement.getBoundingClientRect();

    return (
      Math.ceil(dragContainer.left) <= Math.ceil(newRect.left) &&
      Math.ceil(newRect.left) <= Math.ceil(dragContainer.right) &&
      Math.ceil(dragContainer.left) <= Math.ceil(newRect.right) &&
      Math.ceil(newRect.right) <= Math.ceil(dragContainer.right)
    );
  }
}

export class QtCalendarResizeHelper {
  constructor(
    private resizeContainerElement: HTMLElement,
    private minWidth?: number
  ) {}

  validateResize({ rectangle }: { rectangle: ClientRect }): boolean {
    if (this.minWidth && rectangle.width < this.minWidth) {
      return false;
    }

    return isInside(
      this.resizeContainerElement.getBoundingClientRect(),
      rectangle
    );
  }
}

export function isInside(outer: ClientRect, inner: ClientRect): boolean {
  return (
    outer.left <= inner.left &&
    inner.left <= outer.right &&
    outer.left <= inner.right &&
    inner.right <= outer.right &&
    inner.top <= outer.bottom &&
    outer.top <= inner.bottom &&
    (inner.bottom <= outer.bottom || outer.top <= inner.top)
  );
}

export function getEventTitle(event: QtCalendarEvent, fallBackName: string) {
  if (event && event.name) {
    return event.name;
  } else if (!event) {
    return '';
  }

  let itemName;
  let customerName;
  const customers = event.pars
    ? event.pars.filter(participant => !participant.gr)
    : [];

  if (event.pars && customers.length) {
    customerName = customers[0].name;
  }

  if (event.itms && event.itms.length) {
    itemName = event.itms[0].name;
  }

  if (customerName && itemName) {
    return `${itemName}, ${customerName}`;
  } else if (customerName) {
    return customerName;
  } else if (itemName) {
    return itemName;
  } else {
    return fallBackName;
  }
}

export const DEFAULT_EVENT_COLOR: Color = {
  background: '#3498DB',
  font: '#FFFFFF',
};

/**
 * Check if user has permission for given action.
 *
 * @param eventPermission Event permission for current user.
 * @param actionType Type of action that should be checked.
 */
export function checkPermissions(
  eventPermission: any,
  actionType: string
): boolean {
  // tslint:disable: no-bitwise
  switch (actionType) {
    case 'delete': {
      return Boolean(eventPermission & BIT_DELETE);
    }

    case 'edit': {
      return Boolean(eventPermission & BIT_EDIT_MTG_OTHER_ATTRIBUTES);
    }

    default:
      return false;
  }
}

export function getDayView(
  dateAdapter: DateAdapter,
  {
    events = [],
    viewDate,
    hourSegments,
    dayStart,
    dayEnd,
    segmentHeight,
  }: Partial<GetDayViewArgs>
): QtDayView {
  if (!events) {
    events = [];
  }

  const startOfView: Date = setMinutes(
    setHours(startOfDay(viewDate), dayStart.hour),
    dayStart.minute
  );
  const endOfView: Date = setMinutes(
    setHours(startOfMinute(endOfDay(viewDate)), dayEnd.hour),
    dayEnd.minute
  );
  const eventsInPeriod = getEventsInPeriod(dateAdapter, {
    events: events.filter((event: CalendarEvent) => !event.allDay),
    periodStart: startOfView,
    periodEnd: endOfView,
  });

  const dayViewEvents: QtDayViewEvent[] = eventsInPeriod
    .sort((eventA: CalendarEvent, eventB: CalendarEvent) => {
      return eventA.start.valueOf() - eventB.start.valueOf();
    })
    .map((event: CalendarEvent) => {
      const eventStart: Date = event.start;
      const eventEnd: Date = event.end || eventStart;
      const startsBeforeDay: boolean = eventStart < startOfView;
      const endsAfterDay: boolean = eventEnd > endOfView;
      const hourHeightModifier: number = (hourSegments * segmentHeight) / 60;

      let top = 0;
      if (eventStart > startOfView) {
        top += differenceInMinutes(eventStart, startOfView);
      }
      top *= hourHeightModifier;

      const startDate: Date = startsBeforeDay ? startOfView : eventStart;
      const endDate: Date = endsAfterDay ? endOfView : eventEnd;
      let height: number = differenceInMinutes(endDate, startDate);
      if (!event.end) {
        height = segmentHeight;
      } else {
        height *= hourHeightModifier;
      }
      if (height === 0) {
        height = 30 * hourHeightModifier;
      }

      const dayEvent: QtDayViewEvent = {
        event,
        height,
        top,
        startsBeforeDay,
        endsAfterDay,
        width: 0,
        left: 0,
      };

      return dayEvent;
    })
    .filter((dayEvent: QtDayViewEvent) => dayEvent.height > 0)
    .map((event, index, array) => {
      const criticalConflicts = array.filter(
        (e, i) =>
          (e.top >= event.top &&
            e.top <= event.top + segmentHeight / 3 &&
            i !== index) ||
          (event.top >= e.top &&
            event.top <= e.top + segmentHeight / 3 &&
            i !== index)
      );

      const conflicts = array.filter(
        (e, i) =>
          event.top > e.top && event.top < e.top + e.height && i !== index
      );

      return {
        ...event,
        conflicts,
        criticalConflicts,
        conflictsAmount: conflicts.length,
        criticalConflictsAmount: criticalConflicts.length,
        criticalConflictsOffset: 0,
      };
    })
    .map((event, index, array) => {
      if (event.criticalConflictsAmount) {
        let criticalConflictsOffset = 0;
        for (const conflict of event.criticalConflicts) {
          if (
            index >
            array.findIndex(
              e => e.event.meta.mtgId === conflict.event.meta.mtgId
            )
          ) {
            criticalConflictsOffset++;
          }
        }

        return { ...event, criticalConflictsOffset };
      } else if (event.conflictsAmount) {
        const sameAmount = array.find(
          e =>
            e.conflictsAmount === event.conflictsAmount &&
            e.event.meta.mtgId !== event.event.meta.mtgId
        );

        if (
          sameAmount &&
          event.conflicts.findIndex(
            e => e.event.meta.mtgId === sameAmount.event.meta.mtgId
          ) !== -1 &&
          array.indexOf(sameAmount) < index
        ) {
          return { ...event, conflictsAmount: event.conflictsAmount + 1 };
        }
      }
      return event;
    });

  const allDayEvents: CalendarEvent[] = getEventsInPeriod(dateAdapter, {
    events: events.filter((event: CalendarEvent) => event.allDay),
    periodStart: startOfDay(startOfView),
    periodEnd: endOfDay(endOfView),
  });

  return {
    events: dayViewEvents,
    allDayEvents,
    width: 0,
    period: {
      events: eventsInPeriod,
      start: startOfView,
      end: endOfView,
    },
  };
}

export function getHoursInDay(date: Date): Date[] {
  let start = startOfDay(date);
  const hours = [start];
  while (start.getHours() < 23) {
    start = addHours(start, 1);
    hours.push(start);
  }
  return hours;
}

export function getWeekViewHourGrid({
  viewDate,
  hourSegments,
  dayStart,
  dayEnd,
}: any): WeekViewHourWithDragOver[] {
  const hours: WeekViewHourWithDragOver[] = [];

  const startOfView = setMinutes(
    setHours(startOfDay(viewDate), dayStart.hour),
    dayStart.minute
  );
  const endOfView = setMinutes(
    setHours(startOfMinute(endOfDay(viewDate)), dayEnd.hour),
    dayEnd.minute
  );
  const segmentDuration = 60 / hourSegments;
  const startOfViewDay = startOfDay(viewDate);
  const hoursInDay = getHoursInDay(viewDate);

  if (hoursInDay.length !== 24) {
    return getWeekViewHourGrid({
      viewDate: addDays(viewDate, 1),
      hourSegments,
      dayStart,
      dayEnd,
    });
  }

  for (let i = 0; i < 24; i++) {
    const segments: WeekViewHourSegmentWithDragOver[] = [];
    for (let j = 0; j < hourSegments; j++) {
      const date = addMinutes(addHours(startOfViewDay, i), j * segmentDuration);
      if (date >= startOfView && date < endOfView) {
        segments.push({
          date,
          isStart: j === 0,
          dragOver: false,
        } as WeekViewHourSegmentWithDragOver);
      }
    }
    if (segments.length > 0) {
      hours.push({ segments });
    }
  }

  return hours;
}

export function filterWeekStarts(args: number): number {
  if (args === 7) {
    args = 0;
    return args;
  } else {
    return args;
  }
}
