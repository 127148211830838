import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { AppState } from '../index';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { getMainCompany } from './../index';
import { NcinoService } from './ncino.service';

import * as NcinoActions from './ncino.actions';

@Injectable()
export class NcinoEffects {
  subscribeNcino$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NcinoActions.NcinoActionTypes.CONNECT_NCINO_LIST),
        switchMap(() => this.store.pipe(select(getMainCompany), take(1)))
      ),
    { dispatch: false }
  );

  getNcino$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NcinoActions.GetNcinoAction>(NcinoActions.NcinoActionTypes.GET_NCINO),
      switchMap<NcinoActions.GetNcinoAction, Observable<Action>>(() =>
        this.ncinoService.getNcinoList().pipe(
          map(({ res }) => new NcinoActions.GetNcinoSuccessAction(res)),
          catchError(({ sts }) => of(new NcinoActions.GetNcinoFailureAction(sts)))
        )
      )
    )
  );

  importNcino$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NcinoActions.ImportNcinoAction>(NcinoActions.NcinoActionTypes.IMPORT_NCINO),
      mergeMap<NcinoActions.ImportNcinoAction, Observable<any>>((payload) =>
        this.ncinoService.postNcino(payload.ids).pipe(
          map(({ res }) => new NcinoActions.ImportNcinoSuccessAction(res)),
          catchError(({ sts }) => of(new NcinoActions.ImportNcinoFailureAction(sts)))
        )
      )
    )
  );

  constructor(private store: Store<AppState>, private actions$: Actions, private ncinoService: NcinoService) {}
}
