import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { AuthUserActions } from '@qtek/libs/auth-user';
import { AppState } from '../index';

import { CompanyService } from './company.service';
import { PersonService } from './person.service';
import { UserStoreService } from './user.service';
import {
  DeleteCompanyAction,
  DeleteCompanyFailureAction,
  DeleteCompanySuccessAction,
  GetCurrentUserAction,
  GetCurrentUserCompaniesAction,
  GetCurrentUserCompaniesFailureAction,
  GetCurrentUserCompaniesSuccessAction,
  GetCurrentUserFailureAction,
  GetCurrentUserMultiCompanyAction,
  GetCurrentUserSuccessAction,
  UpdateCurrentUserAction,
  UpdateCurrentUserCompanyAction,
  UpdateCurrentUserCompanyFailureAction,
  UpdateCurrentUserCompanySuccessAction,
  UpdateCurrentUserFailureAction,
  UpdateCurrentUserSuccessAction,
  UserActionTypes,
} from './user.actions';

@Injectable()
export class UserEffects {
  currentUser$: Observable<
    | GetCurrentUserSuccessAction
    | GetCurrentUserFailureAction
    | GetCurrentUserMultiCompanyAction
  > = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCurrentUserAction>(UserActionTypes.GET_CURRENT_USER),
      switchMap(({ payload }) =>
        this.userService.getCurrentUser(payload).pipe(
          map(res => {
            if (res.res.rels && !res.res.cmp) {
              return new GetCurrentUserMultiCompanyAction(res.res);
            } else {
              this.store.dispatch(
                AuthUserActions.getUserSuccess({ user: res.res })
              );
              return new GetCurrentUserSuccessAction(res.res);
            }
          }),
          catchError(res => {
            this.store.dispatch(
              AuthUserActions.getUserFailure({ error: new Error('not logged') })
            );
            return of(new GetCurrentUserFailureAction(res?.sts));
          })
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCurrentUserAction>(UserActionTypes.UPDATE_CURRENT_USER),
      concatMap(({ payload }) => {
        return this.personService
          .updatePerson(payload.form, payload.additionalPayload)
          .pipe(
            map(({ res }) => new UpdateCurrentUserSuccessAction(res)),
            catchError(({ sts }) => of(new UpdateCurrentUserFailureAction(sts)))
          );
      })
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCurrentUserCompanyAction>(
        UserActionTypes.UPDATE_CURRENT_USER_COMPANY
      ),
      switchMap(({ payload }) =>
        this.companyService
          .updateCompany(payload.additionalPayload, payload.form, payload.id)
          .pipe(
            map(({ res }) => new UpdateCurrentUserCompanySuccessAction(res)),
            catchError(({ sts }) =>
              of(new UpdateCurrentUserCompanyFailureAction(sts))
            )
          )
      )
    )
  );

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCurrentUserCompaniesAction>(
        UserActionTypes.GET_CURRENT_USER_COMPANIES
      ),
      switchMap(() =>
        this.companyService.getCompanies().pipe(
          map(({ res }) => new GetCurrentUserCompaniesSuccessAction(res)),
          catchError(({ error }) =>
            of(new GetCurrentUserCompaniesFailureAction(error.sts))
          )
        )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCompanyAction>(UserActionTypes.DELETE_CURRENT_USER_COMPANY),
      switchMap(({ payload }) =>
        this.companyService.removeCompany(payload.id, payload.force).pipe(
          map(({ res }) => new DeleteCompanySuccessAction(res.relId)),
          catchError(({ sts }) => of(new DeleteCompanyFailureAction(sts)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserStoreService,
    private personService: PersonService,
    private companyService: CompanyService,
    private store: Store<AppState>
  ) {}
}
