export enum ScopeType {
  // Top level action scopes
  ACTION_REQUEST_ACCESS = -10,
  ADD = 1,
  EDIT = 2,
  DELETE = 3,
  VIEW = 4,
  SHARE = 10,
  INVITE = 15,
  UPLOAD = 20,

  DOWNLOAD = 30,
}

export interface Scopes {
  e?: number[];
  a?: {
    '*'?: number[];
    [key: string]: number[];
  };
  v?: {
    'itmdta.views.form_admin.partners': number[];
  };
  r: {
    [key: string]: {
      s: number[];
    };
  };
  roles?: {
    par: number;
  };
}
