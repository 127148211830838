import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import * as EventActions from './calendar.actions';

import { WebSocketService, passWhenAlive } from '@qtek/core/websockets-core';
import { CalendarService } from './calendar.service';

@Injectable()
export class CalendarEffects {
  connect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<EventActions.ConnectEventsAction>(
        EventActions.CalendarActionTypes.CONNECT_EVENTS
      ),
      switchMap(({ payload }) =>
        this.calendarService
          .subscribe({
            prms: {
              _attrv: 'lst',
              walkins: '',
              ...payload,
            },
          })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType<EventActions.DisconnectEventsAction>(
                  EventActions.CalendarActionTypes.DISCONNECT_EVENTS
                ),
                filter(action => payload?.mysid === action.payload?.mysid)
              )
            )
          )
      ),
      map((data: any) => {
        switch (data.op) {
          case 'query':
            return data.res
              ? new EventActions.GetEventsSuccessAction(data.res)
              : new EventActions.GetEventsFailureAction(data.sts);
          case 'get':
            return new EventActions.GetEventSuccessAction(data.res);
          case 'ins':
            return new EventActions.AddEventSuccessAction(data.res);
          case 'upd':
            return new EventActions.UpdateEventSuccessAction(data.res);
          case 'del':
            return new EventActions.DeleteEventSuccessAction(data.res);
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  connectWithCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EventActions.SubscribeEventsAction>(
        EventActions.CalendarActionTypes.SUBSCRIBE_EVENTS
      ),
      switchMap(({ payload }) =>
        this.calendarService
          .subscribe(
            {
              ...payload,
              s: 'model',
            },
            true
          )
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType<EventActions.UnsubscribeEventsAction>(
                  EventActions.CalendarActionTypes.UNSUBSCRIBE_EVENTS
                ),
                filter(action => payload?.mysid === action.payload?.mysid)
              )
            )
          )
      ),
      map((data: any) => {
        switch (data.op) {
          case 'query':
            return data.res
              ? new EventActions.GetEventsSuccessAction(data.res)
              : new EventActions.GetEventsFailureAction(data.sts);
          case 'get':
            return new EventActions.GetEventSuccessAction(data.res);
          case 'ins':
            return new EventActions.AddEventSuccessAction(data.res);
          case 'upd':
            return new EventActions.UpdateEventSuccessAction(data.res);
          case 'del':
            return new EventActions.DeleteEventSuccessAction(data.res);
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  events$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.CalendarActionTypes.GET_EVENTS),
        tap(() => this.calendarService.getEvents())
      ),
    { dispatch: false }
  );

  event$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EventActions.GetEventAction>(
          EventActions.CalendarActionTypes.GET_EVENT
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.calendarService.getEvent(payload))
      ),
    { dispatch: false }
  );

  addEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EventActions.AddEventAction>(
          EventActions.CalendarActionTypes.ADD_EVENT
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload, dst }) => this.calendarService.addEvent(payload, dst))
      ),
    { dispatch: false }
  );

  updateEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EventActions.UpdateEventAction>(
          EventActions.CalendarActionTypes.UPDATE_EVENT
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) =>
          this.calendarService.updateEvent(payload.id, payload.event)
        )
      ),
    { dispatch: false }
  );

  deleteEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EventActions.DeleteEventAction>(
          EventActions.CalendarActionTypes.DELETE_EVENT
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.calendarService.removeEvent(payload))
      ),
    { dispatch: false }
  );

  sendEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<EventActions.SendEventAction>(
        EventActions.CalendarActionTypes.SEND_EVENT
      ),
      passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
      switchMap(({ payload }) =>
        this.calendarService.sendEvent(payload.id, payload.query).pipe(
          map(({ res }) => new EventActions.SendEventSuccessAction(res)),
          catchError(({ sts }) =>
            of(new EventActions.SendEventFailureAction(sts))
          )
        )
      )
    )
  );

  sendTableReady$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<EventActions.SendWalkInTableReadyAction>(
        EventActions.CalendarActionTypes.SEND_WALK_IN_READY
      ),
      passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
      switchMap(({ payload }) =>
        this.calendarService.sendTableReady(payload).pipe(
          map(
            ({ res }) => new EventActions.SendWalkInTableReadyActionSuccess(res)
          ),
          catchError(({ sts }) =>
            of(new EventActions.SendWalkInTableReadyActionFailure(sts))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private calendarService: CalendarService,
    private webSocketService: WebSocketService
  ) {}
}
