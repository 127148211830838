import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MetaActions = createActionGroup({
  source: 'Meta',
  events: {
    'Load Meta': props<{ payload?: string[] }>(),
    'Load Meta Success': props<{ payload: any }>(),
    'Load Meta Failure': props<{ payload?: any }>(),
    'Load Meta Countries': props<{ payload?: any }>(),
    'Load Meta Countries Success': props<{
      payload: Array<{ id: string; nm: string }>;
    }>(),
    'Load Meta Countries Failure': props<{ payload?: any }>(),
    'Load Meta Country': props<{ payload: string }>(),
    'Load Meta Country Success': props<{ payload: string[] }>(),
    'Load Meta Country Failure': props<{ payload?: any }>(),
    'Load Meta Timezones': emptyProps(),
    'Load Meta Units': props<{ payload?: any }>(),
    'Load Meta Frequencies': props<{ payload?: any }>(),
    'Load Meta Roles': emptyProps(),
    'Load Meta Languages': emptyProps(),
    'Load Meta Title': emptyProps(),
    'Load Meta Mobile Title': props<{ payload?: any }>(),
    'Load Meta Gui Domain': emptyProps(),
    'Load Meta Gui Online Book Domain': emptyProps(),
    'Load Meta Gui Shr Domain': props<{ payload?: any }>(),
    'Load Meta Country Code': emptyProps(),
    'Load Meta Websocket': props<{ payload?: any }>(),
    'Load Meta Currency': emptyProps(),
    'Load Meta Company License': emptyProps(),
    'Load Meta States': props<{ payload?: any }>(),
    'Load Meta Token Success': props<{ payload: string }>(),
    'Load Meta Ui Theme': props<{ payload?: any }>(),
    'Load Meta Ui Theme Success': props<{ payload?: any }>(),
    'Set Meta Ui Theme': props<{ payload: { isDarkTheme: boolean } }>(),
    'Subscribe Meta Power Search Data': props<{ payload: string }>(),
    'Unsubscribe Meta Power Search Data': props<{ payload: string }>(),
    'Update Meta Power Search Data': props<{
      payload: { mid: string; data: string };
    }>(),
    'Update Meta Power Search Data Success': props<{
      payload?: { mid: string; data?: string };
    }>(),
    'Update Meta Power Search Data Failure': props<{ payload?: any }>(),
  },
});
