import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { PaymentPlan, PaymentPlanInput, Message } from '@qtek/shared/models';

export const PaymentPlanActions = createActionGroup({
  source: 'PaymentPlan',
  events: {
    'Get PaymentPlans': props<{ payload: string }>(),
    'Get PaymentPlans Success': props<{ payload: PaymentPlan[] }>(),
    'Get PaymentPlans Failure': props<{ error: any }>(),

    'Update PaymentPlan': props<{ payload: PaymentPlanInput }>(),
    'Update PaymentPlan Success': props<{
      payload: PaymentPlan[];
      message: Message;
    }>(),
    'Update PaymentPlan Failure': props<{ error: any }>(),
  },
});
