// View permission bits
export const BIT_VIEW_ACCESS: calendarAccess = 2;
export const BIT_VIEW_CANCELED_ACCESS: calendarAccess = 4;
export const BIT_RESERVED_FOR_FUTURE_NO_1: calendarAccess = 8;

// Create
export const BIT_CREATE: calendarAccess = 16;
export const BIT_RESERVED_FOR_FUTURE_NO_2: calendarAccess = 32;

// Edit permission bits
export const BIT_EDIT_MTG_TIME: calendarAccess = 64;
export const BIT_EDIT_MTG_STAFF: calendarAccess = 128;
export const BIT_EDIT_MTG_SERVICE: calendarAccess = 256;
export const BIT_RESERVED_FOR_FUTURE_NO_3: calendarAccess = 512;
export const BIT_RESERVED_FOR_FUTURE_NO_4: calendarAccess = 1024;
export const BIT_RESERVED_FOR_FUTURE_NO_5: calendarAccess = 2048;
export const BIT_EDIT_MTG_OTHER_ATTRIBUTES: calendarAccess = 4096;

// Remove Cancel permisison bits
export const BIT_CANCEL: calendarAccess = 8192;
export const BIT_DELETE: calendarAccess = 16384;

export type calendarAccess =
  | 2
  | 4
  | 8
  | 16
  | 32
  | 64
  | 128
  | 256
  | 512
  | 1024
  | 2048
  | 4096
  | 8192
  | 16384;
