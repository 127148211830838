import { NgClass, NgIf, SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HTTP_BASE_URL } from '@qtek/core/api-core';
import { StatusCode } from '@qtek/shared/models';

@Component({
  selector: 'qt-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexLayoutModule, NgClass, MatTooltipModule, NgIf, SlicePipe],
})
export class AvatarComponent {
  @Input() name: string;
  @Input() isSmall = false;
  @Input() bigImage = false;
  @Input() thumbnailSize: 'md' | 'sm' = undefined;
  @Input() statusCode: StatusCode;
  @Input()
  set imageId(value: any) {
    if (value) {
      this.imageUrl = `${this.baseUrl}/api/v1/service/doc/${value}${
        this.thumbnailSize ? '?thb=' + this.thumbnailSize : ''
      }`;
    }
  }

  imageUrl = '';

  get isStatusActive(): boolean {
    return this.statusCode !== StatusCode.PENDING;
  }

  constructor(@Inject(HTTP_BASE_URL) private baseUrl: string) {}
}
