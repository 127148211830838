import { Injectable, NgModule } from '@angular/core';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';

import { QtCoreModule } from '@qtek/libs/app-component';

import { APP_REDUCERS, AppState } from '@qtek/libs/store';
import { COMMON_EFFECTS } from '@qtek/libs/store/common';

import {
  APP_BUNDLE,
  APP_ENVIRONMENT,
  APP_VERSION,
  ENVIRONMENT_APP_NAME,
  HTTP_BASE_URL,
} from '@qtek/core/api-core';
import * as fromAgency from '@qtek/libs/store/agency/agency.reducer';
import * as fromAgents from '@qtek/libs/store/agents/agents.reducer';
import * as fromBooking from '@qtek/libs/store/booking/booking.reducer';
import * as fromCalendar from '@qtek/libs/store/calendar/calendar.reducer';
import * as fromCategory from '@qtek/libs/store/category/category.reducer';
import * as fromClubs from '@qtek/libs/store/club/club.reducer';
import * as fromCore from '@qtek/libs/store/core/core.reducer';
import * as fromDealTeam from '@qtek/libs/store/deal-team/deal-team.reducer';
import * as fromDocumentActivity from '@qtek/libs/store/document-activity/document-activity.reducer';
import * as fromDocumentsMarkup from '@qtek/libs/store/document-markup/document-markup.reducer';
import * as fromDocuments from '@qtek/libs/store/documents/documents.reducer';
import {
  AgencyEffects,
  AgentsEffects,
  BookingEffects,
  CalendarEffects,
  CategoryEffects,
  ClubEffects,
  CoreEffects,
  DealTeamEffects,
  DocumentActivityEffects,
  DocumentMarkupEffects,
  DocumentsEffects,
  IntegrationsEffects,
  ItemEffects,
  MenuEffects,
  NcinoEffects,
  NoteEffects,
  ParticipantsEffects,
  PaymentPlanEffects,
  ProductSchemasEffects,
  TransactionEffects,
  UploadCenterEffects,
  WizardEffects,
} from '@qtek/libs/store/effects';
import * as fromIntegrations from '@qtek/libs/store/integrations/integrations.reducer';
import * as fromItem from '@qtek/libs/store/item/item.reducer';
import * as fromMenu from '@qtek/libs/store/menu/menu.reducer';
import * as fromNcino from '@qtek/libs/store/ncino/ncino.reducer';
import * as fromNotes from '@qtek/libs/store/note/note.reducer';
import * as fromParticipants from '@qtek/libs/store/participants/participants.reducer';
import * as fromPaymentPlan from '@qtek/libs/store/payment-plan/payment-plan.reducer';
import * as fromProductSchemas from '@qtek/libs/store/product-schemes/product-schemas.reducer';
import * as fromTransaction from '@qtek/libs/store/transaction/transaction.reducer';
import * as fromUploadCenter from '@qtek/libs/store/upload-center/upload-center.reducer';
import * as fromWizard from '@qtek/libs/store/wizard/wizard.reducer';
import { adapterFactory, DateAdapter } from '@qtek/shared/utils';

import { BUNDLE, environment, VERSION } from '@env';
import { WEBSOCKET_BASE_URL } from '@qtek/core/websockets-core';
import { DebugTranslationsDI } from '@qtek/libs/translation-core';

const ENVIRONMENT_APP_NAME_VALUE = environment.appName;
const APP_ENVIRONMENT_VALUE = environment;
const APP_VERSION_VALUE = VERSION;
const APP_BUNDLE_VALUE = BUNDLE;
const debugTranslation = environment.debugTranslations;

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

//  HTTP

const rest = environment.rest;
export const HTTP_BASE_URL_VALUE =
  rest.protocol +
  '://' +
  rest.hostname +
  (rest.port ? ':' + String(rest.port) : '') +
  (rest.path ? rest.path : '');

// WS
// export const WEBSOCKET_BASE_URL = new InjectionToken<string>('WEBSOCKET_BASE_URL');

const websocket = environment.websocket;

export const WEBSOCKET_BASE_URL_VALUE = websocket
  ? websocket.protocol +
    '://' +
    websocket.hostname +
    (websocket.port ? ':' + String(websocket.port) : '') +
    (websocket.path ? websocket.path : '')
  : '';

export const appReducers: Partial<ActionReducerMap<AppState, any>> = {
  documents: fromDocuments.reducer,
  category: fromCategory.reducer,
  item: fromItem.reducer,
  calendar: fromCalendar.reducer,
  booking: fromBooking.reducer,
  integrations: fromIntegrations.reducer,
  wizard: fromWizard.reducer,
  menu: fromMenu.reducer,
  paymentPlan: fromPaymentPlan.reducer,
  agents: fromAgents.reducer,
  clubs: fromClubs.reducer,
  participants: fromParticipants.reducer,
  productSchemas: fromProductSchemas.reducer,
  ncino: fromNcino.reducer,
  documentMarkups: fromDocumentsMarkup.reducer,
  notes: fromNotes.noteReducer,
  dealTeams: fromDealTeam.dealTeamReducer,
  documentActivity: fromDocumentActivity.reducer,
  agency: fromAgency.reducer,
  transaction: fromTransaction.reducer,
  uploadCenter: fromUploadCenter.reducer,
  core: fromCore.reducer,
};

const appEffects = [
  DocumentsEffects,
  CategoryEffects,
  ItemEffects,
  CalendarEffects,
  BookingEffects,
  IntegrationsEffects,
  WizardEffects,
  MenuEffects,
  PaymentPlanEffects,
  AgentsEffects,
  ProductSchemasEffects,
  DocumentMarkupEffects,
  ParticipantsEffects,
  ClubEffects,
  NcinoEffects,
  NoteEffects,
  DealTeamEffects,
  DocumentActivityEffects,
  AgencyEffects,
  TransactionEffects,
  UploadCenterEffects,
  CoreEffects,
];

@NgModule({
  imports: [
    QtCoreModule,
    EffectsModule.forRoot([...COMMON_EFFECTS, ...appEffects]),
  ],
  exports: [QtCoreModule],
  providers: [
    {
      provide: HTTP_BASE_URL,
      useValue: HTTP_BASE_URL_VALUE,
    },
    {
      provide: WEBSOCKET_BASE_URL,
      useValue: WEBSOCKET_BASE_URL_VALUE,
    },
    {
      provide: APP_REDUCERS,
      useValue: appReducers,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: DateAdapter,
      useFactory: adapterFactory,
    },
    {
      provide: ENVIRONMENT_APP_NAME,
      useValue: ENVIRONMENT_APP_NAME_VALUE,
    },
    {
      provide: APP_ENVIRONMENT,
      useValue: APP_ENVIRONMENT_VALUE,
    },
    {
      provide: APP_BUNDLE,
      useValue: APP_BUNDLE_VALUE,
    },
    {
      provide: APP_VERSION,
      useValue: APP_VERSION_VALUE,
    },
    {
      provide: DebugTranslationsDI,
      useValue: debugTranslation ?? false,
    },
  ],
})
export class CoreModule {}
