import { InjectionToken, Version } from '@angular/core';
import { Bundle, Environment } from '@qtek/shared/models';

export const ENVIRONMENT_APP_NAME = new InjectionToken<string>(
  'ENVIRONMENT_APP_NAME'
);

export const APP_ENVIRONMENT = new InjectionToken<Environment>(
  'APP_ENVIRONMENT'
);

export const APP_BUNDLE = new InjectionToken<Bundle>('APP_BUNDLE');

export const APP_VERSION = new InjectionToken<Version>('APP_VERSION');

export enum RelationGroup {
  USER = 0,
  VENDOR = 100,
  CUSTOMER = 200,
  STAFF = 300,
  EMPLOYER = 400,
}

export interface MetaResponse {
  unts?: Record<string, string>;
  frq?: Record<string, string>;
  lngs?: Record<string, string>;
  ptl?: {
    title1: string;
    title2: string;
    guiDomain: string;
    guiOnlineBookDomain: string;
    guiShrDomain: string;
    authGuiDomain: string;
    docGuiDomain: string;
  };
  phns?: {
    cntrs: {
      cur?: string;
      id: string;
      cd: string;
      cid?: string;
      pid?: string;
      mask?: string;
    }[];
  };
  tzs?: Record<string, string[]>;
  wsPingPong?: number;
  licCmp?: string;
  licWebId?: string;
  //TODO not used
  // adr: { stateForCountry: string }
  ptlRoleGroups?: Record<RelationGroup, Record<string, Record<string, string>>>;
  colors?: Record<string, { background: string; font: string }>;
  token?: string;
}

export interface BEStatus {
  sts?: 'OK' | 'ERR' | 'WARN';
  attrs?: {
    [key: string]: {
      main: boolean;
      sts: string;
      msg: string;
      prms?: string[];
    };
  };
}

export interface BEResponse<Y = unknown> {
  sts?: BEStatus;
  res?: Y;
}
