import {
  Directive,
  HostListener,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Optional,
  Self,
} from '@angular/core';
import { MatKeyboardRef } from '../classes';
import { NgControl } from '@angular/forms';
import { KeyboardComponent } from '../components';
import { KeyboardService } from '../services/keyboard.service';
import { Subscription } from 'rxjs';

export const KEYBOARD_STORAGE_KEY = 'qt_KEYBOARD_INPUT';

@Directive({
  selector: 'input[qtKeyboard], textarea[qtKeyboard]',
})
export class KeyboardDirective implements OnDestroy {
  public keyboardRef: MatKeyboardRef<KeyboardComponent>;
  public subscriptions: Subscription = new Subscription();
  public type: string;

  @Input() qtKeyboard = 'US Standard';
  @Input() darkTheme: boolean;
  @Input() duration: number;
  @Input() isDebug: boolean;

  @Output() enterClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() capsClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() altClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() shiftClick: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('focus', ['$event'])
  public showKeyboard(): void {
    if (!localStorage.getItem(KEYBOARD_STORAGE_KEY)) {
      return;
    }
    if (this.elementRef?.nativeElement) {
      this.type = this.elementRef.nativeElement.type;
      (this.elementRef.nativeElement as HTMLInputElement).setAttribute(
        'type',
        'text'
      );
    }

    this.keyboardRef = this.keyboardService.open(this.qtKeyboard, {
      darkTheme: this.darkTheme,
      duration: this.duration,
      isDebug: this.isDebug,
    });

    this.keyboardRef.instance.setInputInstance(this.elementRef);

    if (this.control) {
      this.keyboardRef.instance.attachControl(this.control.control);
    }

    this.subscriptions.add(
      this.keyboardRef.instance.enterClick.subscribe(() =>
        this.enterClick.next()
      )
    );
    this.subscriptions.add(
      this.keyboardRef.instance.capsClick.subscribe(() => this.capsClick.next())
    );
    this.subscriptions.add(
      this.keyboardRef.instance.altClick.subscribe(() => this.altClick.next())
    );
    this.subscriptions.add(
      this.keyboardRef.instance.shiftClick.subscribe(() =>
        this.shiftClick.next()
      )
    );
  }

  @HostListener('blur', ['$event'])
  public hideKeyboard(): void {
    if (this.elementRef?.nativeElement) {
      (this.elementRef.nativeElement as HTMLInputElement).setAttribute(
        'type',
        this.type
      );
    }

    if (this.keyboardRef) {
      this.keyboardRef.dismiss();
    }
    this.subscriptions.unsubscribe();
  }

  constructor(
    public elementRef: ElementRef,
    public keyboardService: KeyboardService,
    @Optional() @Self() public control?: NgControl
  ) {}

  ngOnDestroy() {
    this.hideKeyboard();
  }
}
