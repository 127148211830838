import { Injectable, Provider } from '@angular/core';
import { MatStepperIntl } from '@angular/material/stepper';

import { QtIntl } from './intl';

@Injectable()
export class QtStepperIntl extends MatStepperIntl implements QtIntl {
  updateTranslations(translations: any) {
    this.optionalLabel = translations['INTL_STEPPER_OPTIONAL'];

    this.changes.next();
  }
  getTranslations(): string[] {
    return ['INTL_STEPPER_OPTIONAL'];
  }
}

export const INTL_STEPPER_PROVIDERS: Provider[] = [
  QtStepperIntl,
  {
    provide: MatStepperIntl,
    useExisting: QtStepperIntl,
  },
  {
    provide: QtIntl,
    useExisting: QtStepperIntl,
    multi: true,
  },
];
