import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KeyboardModule } from '@qtek/libs/keyboard';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import { WsErrorHandlerModule } from '@qtek/libs/ws-error-handler';
import {
  KeyboardSwitchComponent,
  QtNetworkErrorComponent,
  ThemeSwitchComponent,
} from '@qtek/shared/components';
import { UiTourDirective } from '@qtek/shared/directives';
import * as fromComponent from './components';
import { AvatarMenuComponent, BookingAvatarMenuComponent } from './components';
import * as fromGuards from './guards';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    KeyboardModule,
    WsErrorHandlerModule,
    UiTourDirective,
    TranslationCoreModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatListModule,
    KeyboardSwitchComponent,
    ThemeSwitchComponent,
    QtNetworkErrorComponent,
  ],
  declarations: [...fromComponent.components],
  providers: [...fromGuards.guards],
  exports: [AvatarMenuComponent, BookingAvatarMenuComponent],
})
export class LayoutModule {}
