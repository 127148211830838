import { CoreActionTypes, CoreActions } from './core.actions';

export interface State {
  displayLoading: boolean;
  animationLoading: boolean;
}

const initialState: State = {
  displayLoading: false,
  animationLoading: true,
};

export function reducer(state = initialState, action: CoreActions): State {
  switch (action.type) {
    case CoreActionTypes.OPEN_LOADING: {
      return {
        ...state,
        displayLoading: true,
      };
    }

    case CoreActionTypes.CLOSE_LOADING: {
      return {
        ...state,
        displayLoading: false,
      };
    }

    case CoreActionTypes.START_LOADING_ANIMATION: {
      return {
        ...state,
        animationLoading: true,
      };
    }

    case CoreActionTypes.STOP_LOADING_ANIMATION: {
      return {
        ...state,
        animationLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoadingDisplay = (state: State) => state.displayLoading;
export const getLoadingAnimation = (state: State) => state.animationLoading;
