/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from '@qtek/shared/models';

export const VERSION = new Version('1.35.4');

export const BUNDLE: Bundle = {
  date: 1734103998794,
  commitSha: '9133efbc7b2878518efa5383edd12d4233f0c465',
  version: VERSION,
};
