import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { HTTP_BASE_URL } from '@qtek/core/api-core';
import { WsErrorHandlerModule } from '@qtek/libs/ws-error-handler';
import {
  BaseUrlInterceptor,
  ErrorHandlerInterceptor,
  OnlineInterceptor,
  RevalidateInterceptor,
  XsrfInterceptor,
} from './interceptors';

@NgModule({
  imports: [HttpClientModule, WsErrorHandlerModule],
  providers: [
    {
      provide: HTTP_BASE_URL,
      useValue: '',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RevalidateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OnlineInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true,
    },
  ],
})
export class HttpInterceptorsModule {
  constructor(
    @SkipSelf()
    @Optional()
    parentModule: HttpInterceptorsModule
  ) {
    // Safety measure to prevent doubled interceptors.
    if (parentModule) {
      throw new Error(
        '[qtek] HttpInterceptorsModule should be loaded once per application.'
      );
    }
  }
}
