import { CdkPortalOutlet, Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

interface DynamicDrawerConfig {
  /** Instance of {@link MatDrawer}. Used to open or close drawer. */
  matDrawer: MatDrawer;

  /** Place in drawer where dynamic content will be rendered.  */
  cdkPortalOutlet: CdkPortalOutlet;
}

/**
 * Allow to display drawer with dynamic content.
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicDrawer implements DynamicDrawerConfig {
  readonly matDrawer: MatDrawer;
  readonly cdkPortalOutlet: CdkPortalOutlet;
  readonly showSidenav$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  /**
   * Set drawer and outlet.
   *
   * @param config Contains instance of drawer and outlet to be used.
   */
  register(config: DynamicDrawerConfig) {
    Object.assign(this, config);
  }

  /**
   * Attach portal and open drawer.
   *
   * @param portal Portal to be attached.
   */
  open<T>(portal: Portal<T>) {
    if (!this.matDrawer || !this.cdkPortalOutlet) {
      throw new Error('[qtek] Drawer or outlet is not set.');
    }

    if (this.cdkPortalOutlet.hasAttached()) {
      return null;
    }

    this.cdkPortalOutlet.attach(portal);

    /** Detach portal when drawer is closed. */
    this.matDrawer.openedChange
      .pipe(
        filter(v => !v),
        take(1)
      )
      .subscribe(() => {
        this.cdkPortalOutlet.detach();
      });

    return this.matDrawer.open();
  }

  /**
   * Close drawer.
   */
  close() {
    return this.matDrawer.close().then(() => {
      if (this.cdkPortalOutlet.hasAttached()) {
        this.cdkPortalOutlet.detach();
      }
    });
  }
}
