import { Action } from '@ngrx/store';

import { Transaction, Message } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const TransactionActionTypes = {
  SUBSCRIBE_TRANSACTIONS: type('[Transaction] Subscribe Transactions'),
  UNSUBSCRIBE_TRANSACTIONS: type('[Transaction] Unsubscribe Transactions'),

  GET_TRANSACTIONS_FAILURE: type('[Transaction] GET Transactions Failure'),
  GET_TRANSACTIONS_SUCCESS: type('[Transaction] GET Transactions Success'),

  ADD_TRANSACTION_SUCCESS: type('[Transaction] ADD Transactions Success'),
  ADD_TRANSACTION_FAILURE: type('[Transaction] ADD Transactions Failure'),
};

export class SubscribeTransactionsAction implements Action {
  type = TransactionActionTypes.SUBSCRIBE_TRANSACTIONS;

  constructor(
    public payload?: { prms: { itmId: string }; view?: string; mysid?: string }
  ) {}
}

export class UnsubscribeTransactionsAction implements Action {
  type = TransactionActionTypes.UNSUBSCRIBE_TRANSACTIONS;

  constructor(public payload?: { mysid?: string }) {}
}

export class GetTransactionsFailureAction implements Action {
  type = TransactionActionTypes.GET_TRANSACTIONS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetTransactionsSuccessAction implements Action {
  type = TransactionActionTypes.GET_TRANSACTIONS_SUCCESS;

  constructor(public payload: { res: Transaction[]; mysid: string }) {}
}

export class AddTransactionFailureAction implements Action {
  type = TransactionActionTypes.ADD_TRANSACTION_FAILURE;

  constructor(public payload?: any) {}
}

export class AddTransactionSuccessAction implements Action {
  type = TransactionActionTypes.ADD_TRANSACTION_SUCCESS;

  constructor(public payload: { res: Transaction; mysid: string }) {}
}

export type TransactionActions =
  | SubscribeTransactionsAction
  | UnsubscribeTransactionsAction
  | GetTransactionsFailureAction
  | GetTransactionsSuccessAction
  | AddTransactionFailureAction
  | AddTransactionSuccessAction;
