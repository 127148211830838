/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from '@qtek/shared/models';

export const VERSION = new Version('1.36.10');

export const BUNDLE: Bundle = {
  date: 1739565657852,
  commitSha: 'a4ffcea04f66827788cd353bf2ce4cec30d8383e',
  version: VERSION,
};
