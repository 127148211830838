import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { Store, select } from '@ngrx/store';
import {
  NEVER,
  Observable,
  Subject,
  Subscription,
  fromEvent,
  merge,
  timer,
} from 'rxjs';
import { delay, skipUntil, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { AppState, getTutorialProgress } from '@qtek/libs/store';
import { TranslationCoreService } from '@qtek/libs/translation-core';
import { UiTourService } from '@qtek/shared/services';

interface Position {
  position: PositionPlacement;
  offset?: number;
}

type PositionPlacement = 'top' | 'right' | 'bottom' | 'left';

const TRIANGLE_HEIGHT = 15;

@Directive({
  selector: '[qtUiTour]',
  standalone: true,
})
export class UiTourDirective
  implements AfterViewInit, AfterViewChecked, OnDestroy, OnInit
{
  /**
   * Translation code of step's description
   */
  @Input() qtUiTourDescription: string;

  /**
   * Position of the step
   */
  @Input() qtUiTourOrder: number;

  /**
   * Params for translation
   */
  @Input() qtUiTourDescriptionParams: string[];

  /**
   * Picture to show in card
   */
  @Input() qtUiTourPicture: string;

  /**
   * Function to run
   */
  @Input() qtUiTourAction: () => void;

  /**
   * Optional selector for overlay element
   */
  @Input() overlaySelector: string;

  /**
   * Optional position of box
   */
  @Input() qtUiTourPosition: PositionPlacement;

  /**
   * Whether element has fixed position
   */
  @Input() qtUiTourElementFixed: boolean;

  /**
   * Optional regexp for route path
   * where step should be displayed
   */
  @Input() qtUiTourRoutePath: RegExp | string;

  /**
   * Query selector for container element with scroll
   */
  @Input() qtUiTourScrollElement = '.full-height-content';

  /**
   * We need to know if host element has onPush detection
   * because ngAfterViewChecked won't work
   */
  @Input() hostElementOnPush: boolean;

  hidden: boolean;
  routeRegexp: RegExp;
  boxReady: Subject<void>;
  boxReadyObservable: Observable<any>;
  description = '';
  layoutContainer: Element;
  isScrolled: boolean;
  refresh: Subject<void>;
  refreshObservable: Observable<any>;
  scrollSubscription: Subscription;
  tourActive: boolean;
  destroy$: Subject<void> = new Subject();

  constructor(
    private el: ElementRef,
    private uiTourService: UiTourService,
    private router: Router,
    private store: Store<AppState>,
    private media: MediaObserver,
    private translateService: TranslationCoreService
  ) {
    this.boxReady = new Subject<void>();
    this.refresh = new Subject<void>();
    this.refreshObservable = this.refresh.asObservable();
    this.boxReadyObservable = this.boxReady.asObservable();
  }

  get uiStepContent(): HTMLElement {
    return document.querySelector('.ui-guide-step') as HTMLElement;
  }

  get overlayElement(): HTMLElement {
    return document.querySelector('#tour-overlay') as HTMLElement;
  }

  ngOnInit() {
    this.routeRegexp =
      this.qtUiTourRoutePath === '/'
        ? new RegExp(/\/{1}$/)
        : new RegExp(this.qtUiTourRoutePath || /[a-zA-Z0-9]{1,}/, 'i');

    this.translateService
      .getTranslation(this.qtUiTourDescription, {
        params: this.qtUiTourDescriptionParams,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(translation => {
        this.description = translation || '';
      });

    this.uiTourService.tourOpenObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe(open => {
        this.tourActive = open;
        if (this.tourActive) {
          this.waitForBox();
        }
      });

    this.uiTourService.refreshObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.isScrolled = false));

    this.store
      .pipe(select(getTutorialProgress), takeUntil(this.destroy$))
      .subscribe(progress => {
        this.uiTourService.progressValue = progress;
      });

    if (this.routeRegexp.test(this.router.url)) {
      this.uiTourService.addStep(
        this.qtUiTourDescription,
        this.routeRegexp,
        true,
        this.qtUiTourOrder
      );
    } else {
      this.uiTourService.addStep(
        this.qtUiTourDescription,
        this.routeRegexp,
        false,
        this.qtUiTourOrder
      );
    }
  }

  ngAfterViewInit() {
    timer(0, 100)
      .pipe(
        takeUntil(timer(5000)),
        takeWhile(() => !this.layoutContainer),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.layoutContainer = document.querySelector(
          this.qtUiTourScrollElement
        );
        if (!this.layoutContainer) {
          this.layoutContainer = document.querySelector('.layout__content');
        }
        if (this.layoutContainer) {
          merge(
            this.uiTourService.refreshObservable,
            this.refreshObservable,
            this.hostElementOnPush ? fromEvent(window, 'resize') : NEVER
          )
            .pipe(
              delay(50),
              tap(() => {
                if (this.hostElementOnPush) {
                  this.checkVisibility();
                }
              }),
              skipUntil(this.boxReadyObservable),
              takeUntil(this.destroy$)
            )
            .subscribe(() => {
              this.checkVisibility();
              this.setUiStepNavigator();
            });
        }
      });
  }

  ngAfterViewChecked() {
    if (this.tourActive && this.routeRegexp.test(this.router.url)) {
      this.refresh.next();
    }
  }

  waitForBox(): void {
    if (this.qtUiTourAction) {
      this.qtUiTourAction();
    }

    timer(0, 100)
      .pipe(
        takeUntil(timer(5000)),
        takeUntil(this.boxReadyObservable),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.uiStepContent && this.layoutContainer) {
          this.boxReady.next();
          this.setUiStepNavigator();
        }
      });
  }

  checkVisibility(): boolean {
    const element = this.overlaySelector
      ? (this.el.nativeElement as HTMLElement).querySelector(
          this.overlaySelector
        )
      : (this.el.nativeElement as HTMLElement);

    const elementRect = element.getBoundingClientRect();

    if (
      elementRect &&
      elementRect.width > 0 &&
      elementRect.height > 0 &&
      elementRect.left + elementRect.width > 0 &&
      elementRect.right + elementRect.width > 0 &&
      (this.el.nativeElement.style.visibility !== 'hidden' ||
        window.getComputedStyle(this.el.nativeElement).visibility === 'visible')
    ) {
      if (this.hidden) {
        this.hidden = false;
        this.uiTourService.addStep(
          this.qtUiTourDescription,
          this.routeRegexp,
          true,
          this.qtUiTourOrder
        );
      }
      return true;
    } else {
      if (
        this.uiTourService.visibleSteps.findIndex(
          step => step.description === this.qtUiTourDescription
        ) !== -1
      ) {
        this.uiTourService.visibleSteps =
          this.uiTourService.visibleSteps.filter(
            step => step.description !== this.qtUiTourDescription
          );
        this.hidden = true;
        this.uiTourService.checkStep();
      }
      return false;
    }
  }

  setUiStepNavigator(): void {
    if (
      this.uiTourService.isOpen &&
      this.layoutContainer &&
      this.qtUiTourDescription === this.uiTourService.uiStep &&
      this.uiStepContent
    ) {
      this.setPosition();
      this.setOverlay();
      if (!this.scrollSubscription || this.scrollSubscription.closed) {
        this.scrollSubscription = fromEvent(this.layoutContainer, 'scroll')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.setUiStepNavigator();
          });
      }
    } else if (
      this.scrollSubscription &&
      !this.scrollSubscription.closed &&
      this.qtUiTourDescription !== this.uiTourService.uiStep
    ) {
      this.scrollSubscription.unsubscribe();
    }
  }

  setOverlay(): void {
    const overlay = this.overlayElement;
    if (overlay) {
      const elRect = this.getElementRect();
      overlay.style.top = `${elRect.top - 2}px`;
      overlay.style.left = `${elRect.left - 2}px`;
      overlay.style.height = `${elRect.height + 4}px`;
      overlay.style.width = `${elRect.width + 4}px`;
    }
  }

  getElementRect(): DOMRect {
    const element = this.overlaySelector
      ? (this.el.nativeElement as HTMLElement).querySelector(
          this.overlaySelector
        )
      : (this.el.nativeElement as HTMLElement);
    return element.getBoundingClientRect();
  }

  setPosition({ position = '', offset = 0 } = {}): void {
    const contentElement = document.getElementById('ui-guide-step__content');
    const pictureElement: HTMLImageElement = document.getElementById(
      'ui-guide-step__content-picture'
    ) as HTMLImageElement;

    if (contentElement.innerHTML !== this.description) {
      contentElement.innerHTML = this.description;
    }

    if (this.qtUiTourPicture) {
      pictureElement.src = this.qtUiTourPicture;
      pictureElement.style.display = 'block';
    } else {
      pictureElement.src = '';
      pictureElement.style.display = 'none';
    }

    const elRect = this.getElementRect();

    switch (position || this.qtUiTourPosition) {
      case 'top': {
        if (position || typeof this.checkPositionTop(elRect) === 'boolean') {
          this.setPostionTop(elRect, offset);
        } else {
          this.setPosition(this.findPosition());
        }
        break;
      }

      case 'right': {
        if (this.media.isActive('xs')) {
          this.setPosition(this.findPosition());
        } else {
          if (
            position ||
            typeof this.checkPositionRight(elRect) === 'boolean'
          ) {
            this.setPositionRight(elRect, offset);
          } else {
            this.setPosition(this.findPosition());
          }
        }
        break;
      }

      case 'bottom': {
        if (position || typeof this.checkPositionBottom(elRect) === 'boolean') {
          this.setPostionBottom(elRect, offset);
        } else {
          this.setPosition(this.findPosition());
        }
        break;
      }

      case 'left': {
        if (this.media.isActive('xs')) {
          this.setPosition(this.findPosition());
        } else {
          if (position || typeof this.checkPositionLeft(elRect) === 'boolean') {
            this.setPositionLeft(elRect, offset);
          } else {
            this.setPosition(this.findPosition());
          }
        }
        break;
      }

      default: {
        if (typeof this.checkPositionBottom(elRect) === 'boolean') {
          this.setPostionBottom(elRect);
        } else {
          this.setPosition(this.findPosition());
        }
      }
    }
  }

  findPosition(): Position {
    const elRect = this.getElementRect();
    let right = { offset: Infinity } as any;
    let left = { offset: Infinity } as any;

    const bottom = {
      position: 'bottom' as PositionPlacement,
      offset: this.checkPositionBottom(elRect),
    };

    if (typeof bottom.offset === 'boolean') {
      return { position: bottom.position };
    }

    const top = {
      position: 'top' as PositionPlacement,
      offset: this.checkPositionTop(elRect),
    };

    if (typeof top.offset === 'boolean') {
      return { position: top.position };
    }

    if (!this.media.isActive('xs')) {
      right = {
        position: 'right',
        offset: this.checkPositionRight(elRect),
      };

      if (typeof right.offset === 'boolean') {
        return { position: right.position };
      }

      left = {
        position: 'left',
        offset: this.checkPositionLeft(elRect),
      };

      if (typeof left.offset === 'boolean') {
        return { position: left.position };
      }
    }

    return [bottom, top, right, left].find(
      element =>
        element.offset ===
        Math.min(
          bottom.offset as number,
          top.offset as number,
          left.offset,
          right.offset
        )
    );
  }

  checkPositionTop(elRect: any): boolean | number {
    const box = this.uiStepContent.getBoundingClientRect();

    if (
      elRect.top +
        (this.qtUiTourElementFixed ? 0 : this.layoutContainer.scrollTop) >
      box.height + TRIANGLE_HEIGHT
    ) {
      return true;
    } else {
      const offset =
        box.height -
        (elRect.top +
          (this.qtUiTourElementFixed ? 0 : this.layoutContainer.scrollTop)) +
        TRIANGLE_HEIGHT;

      return offset > elRect.height ? elRect.height - 1 : offset - 1;
    }
  }

  checkPositionBottom(elRect: any): boolean | number {
    const box = this.uiStepContent.getBoundingClientRect();

    if (
      (!this.qtUiTourElementFixed &&
        this.layoutContainer.getBoundingClientRect().top +
          this.layoutContainer.scrollHeight -
          (elRect.bottom + this.layoutContainer.scrollTop) >
          box.height + TRIANGLE_HEIGHT) ||
      (this.qtUiTourElementFixed &&
        window.innerHeight - elRect.bottom > box.height + TRIANGLE_HEIGHT)
    ) {
      return true;
    } else if (this.qtUiTourElementFixed) {
      const offset =
        box.height - (window.innerHeight - elRect.bottom) + TRIANGLE_HEIGHT;

      return offset > elRect.height ? elRect.height : offset;
    } else {
      const offset =
        box.height -
        (this.layoutContainer.getBoundingClientRect().top +
          this.layoutContainer.scrollHeight -
          (elRect.bottom + this.layoutContainer.scrollTop)) +
        TRIANGLE_HEIGHT;

      return offset > elRect.height ? elRect.height : offset;
    }
  }

  checkPositionRight(elRect: any): boolean | number {
    if (
      elRect.right + this.uiStepContent.offsetWidth + TRIANGLE_HEIGHT <
      window.innerWidth
    ) {
      return true;
    } else {
      const offset =
        elRect.right +
        this.uiStepContent.offsetWidth +
        TRIANGLE_HEIGHT -
        window.innerWidth;

      return offset < elRect.width ? offset : elRect.width;
    }
  }

  checkPositionLeft(elRect: any): boolean | number {
    if (elRect.left > this.uiStepContent.offsetWidth + TRIANGLE_HEIGHT) {
      return true;
    } else {
      const offset =
        this.uiStepContent.offsetWidth + TRIANGLE_HEIGHT - elRect.left;

      return offset < elRect.width ? offset : elRect.width;
    }
  }

  setPostionTop(elRect: any, offset = 0): void {
    const leftOffset =
      elRect.left + this.uiStepContent.offsetWidth - window.innerWidth;
    this.uiStepContent.style.top = `${
      elRect.top - this.uiStepContent.clientHeight - TRIANGLE_HEIGHT + offset
    }px`;
    this.uiStepContent.style.left = `${
      elRect.left -
      (leftOffset > 0 ? leftOffset + 10 : elRect.left > 10 ? 10 : 0)
    }px`;
    this.scrollToPosition('top', elRect);
  }

  setPostionBottom(elRect: any, offset = 0): void {
    const leftOffset =
      elRect.left + this.uiStepContent.offsetWidth - window.innerWidth;
    this.uiStepContent.style.top = `${
      elRect.bottom + TRIANGLE_HEIGHT - offset
    }px`;
    this.uiStepContent.style.left = `${
      elRect.left -
      (leftOffset > 0 ? leftOffset + 10 : elRect.left > 10 ? 10 : 0)
    }px`;
    this.scrollToPosition('bottom', elRect);
  }

  setPositionRight(elRect: any, offset = 0): void {
    this.uiStepContent.style.top = `${
      elRect.top + (elRect.height < 50 ? -20 : elRect.height / 3)
    }px`;
    this.uiStepContent.style.left = `${
      elRect.right + TRIANGLE_HEIGHT - offset
    }px`;

    this.scrollToPosition('right', elRect);
  }

  setPositionLeft(elRect: any, offset = 0): void {
    this.uiStepContent.style.top = `${
      elRect.top + (elRect.height < 50 ? -20 : elRect.height / 3)
    }px`;
    this.uiStepContent.style.left = `${
      elRect.left - (this.uiStepContent.clientWidth + TRIANGLE_HEIGHT) + offset
    }px`;

    this.scrollToPosition('left', elRect);
  }

  scrollToPosition(position: string, elRect: any): void {
    if (!this.isScrolled && !this.qtUiTourElementFixed) {
      this.isScrolled = true;
      const layoutRect = this.layoutContainer.getBoundingClientRect();
      switch (position) {
        case 'right':
        case 'left':
          if (
            elRect.top - layoutRect.top < 0 ||
            elRect.bottom > window.innerHeight
          ) {
            this.layoutContainer.scrollTop += elRect.top - layoutRect.top;
          }
          break;
        case 'top':
          if (
            elRect.top - layoutRect.top <
              this.uiStepContent.offsetHeight + TRIANGLE_HEIGHT ||
            elRect.bottom > window.innerHeight
          ) {
            this.layoutContainer.scrollTop +=
              elRect.top -
              layoutRect.top -
              (this.uiStepContent.offsetHeight + TRIANGLE_HEIGHT);
          }
          break;
        case 'bottom':
          if (
            elRect.bottom +
              (this.uiStepContent.offsetHeight + TRIANGLE_HEIGHT) >
              window.innerHeight ||
            elRect.top - layoutRect.top < 0
          ) {
            this.layoutContainer.scrollTop +=
              elRect.bottom +
              (this.uiStepContent.offsetHeight + TRIANGLE_HEIGHT) -
              window.innerHeight;
          }
      }
    }
  }

  ngOnDestroy() {
    this.uiTourService.globalSteps = this.uiTourService.globalSteps.filter(
      step => step.description !== this.qtUiTourDescription
    );

    this.uiTourService.visibleSteps = this.uiTourService.visibleSteps.filter(
      step => step.description !== this.qtUiTourDescription
    );

    this.uiTourService.checkStep();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
