import { Routes } from '@angular/router';
import { DialogNames, LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { defaultDialogConfig } from './default-config';
import { contactPreviewDialogConfig } from './module-dialogs/contact-preview-dialog/contact-preview-dialog.component';
import { employeeDialogConfig } from './standalone-dialogs/employee-dialog/employee-dialog.component';
import { informationDialogConfig } from './standalone-dialogs/information-dialog/information-dialog.component';
import { inviteContactDialogConfig } from './standalone-dialogs/invite-contact-dialog/invite-contact-dialog.component';

export const dialogRoutes: Routes = [
  //
  // STANDALONE   ////////////////////////////////////
  //
  {
    path: DialogNames.alert,
    canActivate: [LazyDialogLoader],
    data: { dialogConfig: { width: '600px', autoFocus: false } },
    loadComponent: () =>
      import('./standalone-dialogs/alert-dialog/alert-dialog.component').then(
        c => c.AlertDialogComponent
      ),
  },
  {
    path: DialogNames.confirm,
    canActivate: [LazyDialogLoader],
    data: { dialogConfig: { autoFocus: false } },
    loadComponent: () =>
      import(
        './standalone-dialogs/confirm-dialog/confirm-dialog.component'
      ).then(c => c.ConfirmDialogComponent),
  },

  {
    path: DialogNames.selectPlan,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        disableClose: true,
        closeOnNavigation: false,
        width: '600px',
        panelClass: 'fullscreen',
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/plan-select-dialog/plan-select-dialog.component'
      ).then(c => c.PlanSelectDialogComponent),
  },

  {
    path: DialogNames.imageCrop,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        width: '600px',
        panelClass: 'fullscreen',
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/image-crop-dialog/image-crop-dialog.component'
      ).then(c => c.ImageCropDialogComponent),
  },

  {
    path: DialogNames.gallery,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        panelClass: 'fullscreen',
      },
    },
    loadComponent: () =>
      import('./standalone-dialogs/gallery/gallery.component').then(
        c => c.GalleryComponent
      ),
  },
  {
    path: DialogNames.colorPicker,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        panelClass: 'fullscreen',
        width: '600px',
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/color-picker-dialog/color-picker-dialog.component'
      ).then(c => c.ColorPickerDialogComponent),
  },

  {
    path: DialogNames.secureConfirm,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        autoFocus: false,
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/secure-confirm-dialog/secure-confirm-dialog.component'
      ).then(c => c.SecureConfirmDialogComponent),
  },

  {
    path: DialogNames.numericKeyboard,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        width: '550px',
        height: '90vh',
        maxHeight: '700px',
        panelClass: 'fullscreen',
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/numeric-keyboard-dialog/numeric-keyboard-dialog.component'
      ).then(c => c.NumericKeyboardDialogComponent),
  },

  {
    path: DialogNames.deliveryDialog,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        minWidth: '600px',
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/delivery-dialog/delivery-dialog.component'
      ).then(c => c.DeliveryDialogComponent),
  },

  {
    path: DialogNames.createItem,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        panelClass: 'fullscreen',
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/create-item-dialog/create-item-dialog.component'
      ).then(c => c.CreateItemDialogComponent),
  },
  {
    path: DialogNames.employeeDialog,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        ...employeeDialogConfig,
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/employee-dialog/employee-dialog.component'
      ).then(c => c.EmployeeDialogComponent),
  },
  {
    path: DialogNames.inviteContact,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        ...inviteContactDialogConfig,
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/invite-contact-dialog/invite-contact-dialog.component'
      ).then(c => c.InviteContactDialogComponent),
  },
  {
    path: DialogNames.customerInvite,
    canActivate: [LazyDialogLoader],
    // data: {
    //   dialogConfig: {},
    // },
    loadComponent: () =>
      import(
        './standalone-dialogs/customer-invite/customer-invite.component'
      ).then(c => c.CustomerInviteComponent),
  },
  {
    path: DialogNames.promptData,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        autoFocus: true,
        maxWidth: '100%',
        width: '700px',
      },
    },
    loadComponent: () =>
      import('./standalone-dialogs/prompt-dialog/prompt-dialog.component').then(
        c => c.PromptDialogComponent
      ),
  },
  {
    path: DialogNames.customerDialog,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        panelClass: 'fullscreen',
        width: '800px',
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/customer-dialog/customer-dialog.component'
      ).then(c => c.CustomerDialogComponent),
  },
  {
    path: DialogNames.noteDialog,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        ...defaultDialogConfig,
        width: 'auto',
        maxWidth: '95vw',
        height: '85vh',
        disableClose: true,
      },
    },
    loadComponent: () =>
      import('./standalone-dialogs/note-dialog/note-dialog.component').then(
        c => c.NoteDialogComponent
      ),
  },
  {
    path: DialogNames.informationDialog,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        ...informationDialogConfig,
      },
    },
    loadComponent: () =>
      import(
        './standalone-dialogs/information-dialog/information-dialog.component'
      ).then(c => c.InformationDialogComponent),
  },

  //
  // MODULE       ////////////////////////////////////
  //
  {
    path: DialogNames.contactDialog,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        panelClass: 'fullscreenAll',
      },
    },
    loadChildren: () =>
      import('./module-dialogs/contact-dialog/contact-dialog.module').then(
        m => m.ContactDialogModule
      ),
  },
  {
    path: DialogNames.contactPreview,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        ...contactPreviewDialogConfig,
      },
    },
    loadChildren: () =>
      import(
        './module-dialogs/contact-preview-dialog/contact-preview-dialog.module'
      ).then(m => m.ContactPreviewDialogModule),
  },
];
