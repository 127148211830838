export enum DocumentType {
  PERSONAL_AVATAR = 500,
  PERSONAL_DOCUMENTS = 600,
  COMPANY_LOGO = 1000,
  COMPANY_BOOKING_BACKGROUND = 1100,
  COMPANY_BOOKING_QR = 1200,
  APARTMENT_DOCUMENTS = 2000,
  APARTMENT_ADD_PICTURES = 2001,
  LEASE_MOVE_IN_PICTURES = 3000,
  APPLICATION_PHOTO_ID = 4000,
  APPLICATION_PAYSTUB_1 = 4001,
  APPLICATION_PAYSTUB_2 = 4002,
  INVOICE_SENDABLE_DOCUMENT = 5000,
  ESTIMATE_SENDABLE_DOCUMENT = 5001,
  WCO_SENDABLE_DOCUMENT = 5002,
  CHANGE_ORDER_REQUEST_SENDABLE_DOCUMENT = 5003,
  INVOICE_ATTACHMENT_DOCUMENT = 5100,
  ESTIMATE_ATTACHMENT_DOCUMENT = 5101,
  WCO_ATTACHMENT_DOCUMENT = 5102,
  FOOD_MENU_ATTACHMENT_DOCUMENT = 5200,
  PERSONAL_GOVERMENT_ID = 6001,
  MEETING_SENDABLE_DOCUMENT = 7000,
  MEETING_ATTACHMENT_DOCUMENT = 7100,
  TU_CREDIT_REPORT = 15000,
  CREDIT_REPORT_SCORE_IMAGE = 15100,
  SERVICE_IMAGE = 6000,
  SERVICE_EXTRA_IMAGE = 6010,
  ITEM_DOCUMENT = 6020,
  FINANCIAL_TRANSACTIONS = 6200,
  CATEGORY_IMAGE = 8000,
  TEMPLATE_IMAGE = 9000,
  // and higher
  PROCESSOR = 90000000000,
}
