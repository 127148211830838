import { InjectionToken } from '@angular/core';
import { KeyboardClassKey } from '../enums/keyboard-class-key.enum';
import { KeyboardIcons } from '../interfaces';

const MAT_KEYBOARD_ICONS = new InjectionToken<KeyboardIcons>(
  'keyboard-icons.config'
);
const keyboardIcons: KeyboardIcons = {
  [KeyboardClassKey.Bksp]: 'keyboard_backspace',
  [KeyboardClassKey.Caps]: 'keyboard_capslock',
  [KeyboardClassKey.Enter]: 'keyboard_return',
  [KeyboardClassKey.Shift]: 'keyboard_arrow_up',
  [KeyboardClassKey.Space]: ' ',
  [KeyboardClassKey.Tab]: 'keyboard_tab',
};

export { KeyboardIcons, MAT_KEYBOARD_ICONS, keyboardIcons };
