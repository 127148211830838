import { Injectable, Provider } from '@angular/core';
import { QtIntl } from './intl';

@Injectable()
export class QtInstantTranslations implements QtIntl {
  updateTranslations(translations: any) {
    return translations;
  }

  getTranslations(): string[] {
    return [
      'GNR_NO_NAME',
      'GNR_PHNNO',
      'GNR_EMAIL',
      'GNR_PHN_LANDLINE',
      'GNR_PHN_MOBILE',
      'GNR_PLUS_ONE_DAY',
      'FTR_BOOKING_URL_COPIED',
      'FTR_BOOKING_CODE_COPIED',
      'FTR_BOOKING_DISCARD_CHNGS',
      'CMD_BACK',
      'GNR_ALL_ACCOUNTS',
      'FTR_ITM_SHOW_BOOKING',
      'FTR_ITM_PICKUP',
      'FTR_ITM_DELIVERY',
      'FTR_ITM_CATERING',
      'GNR_ADVERTISING',
      'GNR_MINUTES',
      'GNR_HOURS',
      'GNR_LIST',
      'GNR_DAY',
      'GNR_WEEK',
      'GNR_MONTH',
      'FTR_PLAN_START',
      'GNR_PAYMENT_COMPLETE',
      'GNR_PAYMENT_CANCELED',
      'GNR_DELIVERY_FEE',
      'GNR_MIN_DELIVERY_PRICE',
      'GNR_NOW',
      'GNR_ALL',
    ];
  }
}

export const INTL_INSTANT_TRANSLATIONS_PROVIDERS: Provider[] = [
  QtInstantTranslations,
  {
    provide: QtIntl,
    useExisting: QtInstantTranslations,
    multi: true,
  },
];
