import { Action } from '@ngrx/store';

import { Message } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const UserActionTypes = {
  GET_CURRENT_USER: type('[User] Load'),
  GET_CURRENT_USER_SUCCESS: type('[User] Load Success'),
  GET_CURRENT_USER_FAILURE: type('[User] Load Failure'),

  UPDATE_CURRENT_USER: type('[User] Update'),
  UPDATE_CURRENT_USER_SUCCESS: type('[User] Update Success'),
  UPDATE_CURRENT_USER_FAILURE: type('[User] Update Failure'),
  UPDATE_CURRENT_USER_COMPANY: type('[User] Update User Company'),
  UPDATE_CURRENT_USER_COMPANY_SUCCESS: type(
    '[User] Update User Company Success'
  ),
  UPDATE_CURRENT_USER_COMPANY_FAILURE: type(
    '[User] Update User Company Failure'
  ),

  CREATE_COMPANY: type('[User] Create Company'),
  CREATE_COMPANY_SUCCESS: type('[User] Create Company Success'),
  CREATE_COMPANY_FAILURE: type('[User] Create Company Failure'),

  GET_CURRENT_USER_MULTI_COMPANY: type('[User] Multi company'),
  GET_CURRENT_USER_COMPANIES: type('[User] Get companies'),
  GET_CURRENT_USER_COMPANIES_SUCCESS: type('[User] Get companies Success'),
  GET_CURRENT_USER_COMPANIES_FAILURE: type('[User] Get companies Failure'),

  DELETE_CURRENT_USER_COMPANY: type('[User] Delete Company'),
  DELETE_CURRENT_USER_COMPANY_SUCCESS: type('[User] Delete Company Success'),
  DELETE_CURRENT_USER_COMPANY_FAILURE: type('[User] Delete Company Failure'),
};

export class GetCurrentUserAction implements Action {
  type = UserActionTypes.GET_CURRENT_USER;

  constructor(
    public payload?: {
      params?: Object;
      url?: string;
      skipRevalidateErrorHandling?: boolean;
    }
  ) {}
}

export class GetCurrentUserSuccessAction implements Action {
  type = UserActionTypes.GET_CURRENT_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class GetCurrentUserFailureAction implements Action {
  type = UserActionTypes.GET_CURRENT_USER_FAILURE;

  constructor(public payload: any) {}
}

export class GetCurrentUserMultiCompanyAction implements Action {
  type = UserActionTypes.GET_CURRENT_USER_MULTI_COMPANY;

  constructor(public payload: any) {}
}

export class UpdateCurrentUserAction implements Action {
  type = UserActionTypes.UPDATE_CURRENT_USER;

  constructor(public payload: any) {}
}

export class UpdateCurrentUserSuccessAction implements Action {
  type = UserActionTypes.UPDATE_CURRENT_USER_SUCCESS;
  message = new Message('GNR_PRS_INFO_SAVED');

  constructor(public payload: any) {}
}

export class UpdateCurrentUserFailureAction implements Action {
  type = UserActionTypes.UPDATE_CURRENT_USER_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateCurrentUserCompanyAction implements Action {
  type = UserActionTypes.UPDATE_CURRENT_USER_COMPANY;

  constructor(
    public payload: { form?: any; id?: string; additionalPayload?: any }
  ) {}
}

export class UpdateCurrentUserCompanySuccessAction implements Action {
  type = UserActionTypes.UPDATE_CURRENT_USER_COMPANY_SUCCESS;
  message = new Message('GNR_CMP_INFO_SAVED');

  constructor(public payload: any) {}
}

export class UpdateCurrentUserCompanyFailureAction implements Action {
  type = UserActionTypes.UPDATE_CURRENT_USER_COMPANY_FAILURE;

  constructor(public payload: any) {}
}

export class GetCurrentUserCompaniesAction implements Action {
  type = UserActionTypes.GET_CURRENT_USER_COMPANIES;

  constructor(public payload?: any) {}
}

export class GetCurrentUserCompaniesSuccessAction implements Action {
  type = UserActionTypes.GET_CURRENT_USER_COMPANIES_SUCCESS;

  constructor(public payload: any) {}
}

export class GetCurrentUserCompaniesFailureAction implements Action {
  type = UserActionTypes.GET_CURRENT_USER_COMPANIES_FAILURE;

  constructor(public payload: any) {}
}

export class DeleteCompanyAction implements Action {
  readonly type = UserActionTypes.DELETE_CURRENT_USER_COMPANY;

  constructor(
    public payload: {
      id: string;
      force?: boolean;
    }
  ) {}
}

export class DeleteCompanySuccessAction implements Action {
  readonly type = UserActionTypes.DELETE_CURRENT_USER_COMPANY_SUCCESS;
  message = new Message('GNR_ACCOUNT_DELETED');

  constructor(public payload: string) {}
}

export class DeleteCompanyFailureAction implements Action {
  readonly type = UserActionTypes.DELETE_CURRENT_USER_COMPANY_FAILURE;

  constructor(public payload?: any) {}
}

export type UserActions =
  | GetCurrentUserAction
  | GetCurrentUserSuccessAction
  | GetCurrentUserFailureAction
  | UpdateCurrentUserAction
  | UpdateCurrentUserSuccessAction
  | UpdateCurrentUserFailureAction
  | UpdateCurrentUserCompanyAction
  | UpdateCurrentUserCompanySuccessAction
  | UpdateCurrentUserCompanyFailureAction
  | GetCurrentUserMultiCompanyAction
  | GetCurrentUserCompaniesAction
  | GetCurrentUserCompaniesSuccessAction
  | GetCurrentUserCompaniesFailureAction
  | DeleteCompanyAction
  | DeleteCompanySuccessAction
  | DeleteCompanyFailureAction;
