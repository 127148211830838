import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Step } from '@qtek/shared/models';

export const WizardActions = createActionGroup({
  source: 'Wizard',
  events: {
    'Is Wizard Done': emptyProps(),
    'Get Steps': emptyProps(),
    'Get Steps Success': props<{ payload: Step[] }>(),
    'Get Demo Steps': emptyProps(),
    'Get Demo Steps Success': props<{ payload: any }>(),
    'Set Max Person Progress': props<{ payload: number }>(),
    'Set Max Company Progress': props<{ payload: number }>(),
    'Step Prs 1': props<{ payload: any }>(),
    'Step Cmp 1': props<{ payload: any }>(),
    'Step Cmp 2': props<{ payload: any }>(),
    'Step Cmp 8': props<{ payload: any }>(),
    'Step Cmp 64': props<{ payload: any }>(),
    'Step Cmp 260': props<{ payload: any }>(),
    'Step Cmp 1536': props<{ payload: any }>(),
    'Step Cmp 2048': props<{ payload: any }>(),
    'Step Cmp 4096': props<{ payload: any }>(),
    'Set Loading': props<{ payload: boolean }>(),
    'Save Promo': props<{ vndId: string; promo: string }>(),
    'Save Promo Success': props<{ payload?: any }>(),
    'Save Promo Failure': props<{ payload?: any }>(),
  },
});
