import { Injectable, NgModule } from '@angular/core';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';

import { QtCoreModule } from '@qtek/libs/app-component';

import { APP_REDUCERS, AppState } from '@qtek/libs/store';
import { COMMON_EFFECTS } from '@qtek/libs/store/common';

import {
  APP_BUNDLE,
  APP_ENVIRONMENT,
  APP_VERSION,
  ENVIRONMENT_APP_NAME,
  HTTP_BASE_URL,
} from '@qtek/core/api-core';
import * as fromCore from '@qtek/libs/store/core/core.reducer';
import * as fromDocumentActivity from '@qtek/libs/store/document-activity/document-activity.reducer';
import * as fromDocuments from '@qtek/libs/store/documents/documents.reducer';
import {
  CoreEffects,
  DocumentActivityEffects,
  DocumentsEffects,
  IntegrationsEffects,
  NcinoEffects,
  ProductSchemasEffects,
  TransactionEffects,
} from '@qtek/libs/store/effects';
import * as fromIntegrations from '@qtek/libs/store/integrations/integrations.reducer';
import * as fromNcino from '@qtek/libs/store/ncino/ncino.reducer';
import * as fromProductSchemas from '@qtek/libs/store/product-schemes/product-schemas.reducer';
import * as fromTransaction from '@qtek/libs/store/transaction/transaction.reducer';
import { adapterFactory, DateAdapter } from '@qtek/shared/utils';

import { BUNDLE, environment, VERSION } from '@env';
import { WEBSOCKET_BASE_URL } from '@qtek/core/websockets-core';
import { DebugTranslationsDI } from '@qtek/libs/translation-core';

const ENVIRONMENT_APP_NAME_VALUE = environment.appName;
const APP_ENVIRONMENT_VALUE = environment;
const APP_VERSION_VALUE = VERSION;
const APP_BUNDLE_VALUE = BUNDLE;
const debugTranslation = environment.debugTranslations;

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

//  HTTP

const rest = environment.rest;
export const HTTP_BASE_URL_VALUE =
  rest.protocol +
  '://' +
  rest.hostname +
  (rest.port ? ':' + String(rest.port) : '') +
  (rest.path ? rest.path : '');

// WS
// export const WEBSOCKET_BASE_URL = new InjectionToken<string>('WEBSOCKET_BASE_URL');

const websocket = environment.websocket;

export const WEBSOCKET_BASE_URL_VALUE = websocket
  ? websocket.protocol +
    '://' +
    websocket.hostname +
    (websocket.port ? ':' + String(websocket.port) : '') +
    (websocket.path ? websocket.path : '')
  : '';

export const appReducers: Partial<ActionReducerMap<AppState, any>> = {
  documents: fromDocuments.reducer,
  integrations: fromIntegrations.reducer,
  productSchemas: fromProductSchemas.reducer,
  ncino: fromNcino.reducer,
  documentActivity: fromDocumentActivity.reducer,
  transaction: fromTransaction.reducer,
  core: fromCore.reducer,
};

const appEffects = [
  DocumentsEffects,
  IntegrationsEffects,
  ProductSchemasEffects,
  NcinoEffects,
  DocumentActivityEffects,
  TransactionEffects,
  CoreEffects,
];

@NgModule({
  imports: [
    QtCoreModule,
    EffectsModule.forRoot([...COMMON_EFFECTS, ...appEffects]),
  ],
  exports: [QtCoreModule],
  providers: [
    {
      provide: HTTP_BASE_URL,
      useValue: HTTP_BASE_URL_VALUE,
    },
    {
      provide: WEBSOCKET_BASE_URL,
      useValue: WEBSOCKET_BASE_URL_VALUE,
    },
    {
      provide: APP_REDUCERS,
      useValue: appReducers,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: DateAdapter,
      useFactory: adapterFactory,
    },
    {
      provide: ENVIRONMENT_APP_NAME,
      useValue: ENVIRONMENT_APP_NAME_VALUE,
    },
    {
      provide: APP_ENVIRONMENT,
      useValue: APP_ENVIRONMENT_VALUE,
    },
    {
      provide: APP_BUNDLE,
      useValue: APP_BUNDLE_VALUE,
    },
    {
      provide: APP_VERSION,
      useValue: APP_VERSION_VALUE,
    },
    {
      provide: DebugTranslationsDI,
      useValue: debugTranslation ?? false,
    },
  ],
})
export class CoreModule {}
