import { ActionReducer } from '@ngrx/store';
import { AccountOutput, PersonOutput } from '@qtek/shared/models';
import { UserActionTypes, UserActions } from './user.actions';

// TODO: Add interface for each property.
export interface State {
  prs: PersonOutput;
  acn?: AccountOutput;
  rel?: any;
  cmps?: any[];
  actions?: Array<{
    params: {
      sts: number;
      vndId: string;
    };
  }>;
}

const initialState: State = {
  prs: null,
  cmps: [],
};

export const reducer: ActionReducer<State, UserActions> = (
  state = initialState,
  { type, payload }: UserActions
): State => {
  switch (type) {
    case UserActionTypes.GET_CURRENT_USER_SUCCESS: {
      return payload == null
        ? initialState
        : {
            ...state,
            ...payload,
          };
    }
    case UserActionTypes.UPDATE_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        prs: payload || state.prs,
        actions: payload ? payload.actions : state.actions,
      };
    }

    case UserActionTypes.UPDATE_CURRENT_USER_COMPANY_SUCCESS: {
      let index, newCmps;

      if (payload == null) {
        return {
          ...state,
        };
      }

      if (payload.name) {
        const name = payload.name.name;
        index = state.cmps.findIndex(cmp => cmp.cmpId === payload.cmpId);
        if (index !== -1) {
          newCmps = state.cmps.slice();
          newCmps[index] = {
            ...state.cmps[index],
            acn: {
              ...state.cmps[index].acn,
              name: {
                ...state.cmps[index].acn.name,
                name,
              },
            },
          };
        }
      }

      return {
        ...state,
        acn: payload ? payload : state.acn,
        cmps: index >= 0 ? newCmps : state.cmps,
        actions: payload ? payload.actions : state.actions,
      };
    }

    case UserActionTypes.GET_CURRENT_USER_COMPANIES_SUCCESS: {
      return {
        ...state,
        cmps: payload || [],
      };
    }

    case UserActionTypes.DELETE_CURRENT_USER_COMPANY_SUCCESS: {
      return {
        ...state,
        cmps: state.cmps.filter(cmp => cmp.relId !== payload),
      };
    }

    default: {
      return state;
    }
  }
};

export const isAuthorized = ({ prs }: State) => {
  if (!prs) {
    return false;
  }

  return Boolean(prs.prsId);
};
export const getCurrentPerson = (state: State) => state.prs;
export const getMainCompany = (state: State) => state.acn;
export const getPersonProgress = (state: State) =>
  state.prs && state.prs.bitMsks && state.prs.bitMsks._prgs
    ? state.prs.bitMsks._prgs
    : 0;
export const getCompanyProgress = (state: State) =>
  state.acn && state.acn.bitMsks && state.acn.bitMsks._prgs
    ? state.acn.bitMsks._prgs
    : 0;
export const getDemoDataProgress = (state: State) =>
  state.acn && state.acn.bitMsks && state.acn.bitMsks._ddb
    ? state.acn.bitMsks._ddb
    : 0;
export const getTutorialProgress = (state: State) =>
  state.prs && state.prs.bitMsks && state.prs.bitMsks._uitut
    ? state.prs.bitMsks._uitut
    : undefined;
export const getCurrentPersonLanguage = (state: State) => state.prs?.lng;
export const getRelationType = (state: State) =>
  state.rel ? state.rel.rl : null;
export const getUserRelation = (state: State) => state.rel;
export const getUserCompanies = (state: State) => state.cmps;
export const isPersonalAccount = (state: State) =>
  state.acn && (state.acn.tp === 2000 || !state.acn.tp);
export const getUserActions = (state: State) => state.actions;
export const getCurrentPersonTz = (state: State) => state.prs?.tz;
