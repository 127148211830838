import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  BackendResponse,
  PaymentPlan,
  PaymentPlanInput,
  PlanDetails,
} from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class PaymentPlanService {
  constructor(private http: HttpClient) {}

  getPlans(id: string) {
    return this.http.get<BackendResponse<PaymentPlan[]>>(
      `/api/v1/service/4sale/${id}`,
      { withCredentials: true }
    );
  }

  selectPlan(payload: PaymentPlanInput) {
    return this.http.post<BackendResponse<PlanDetails>>(
      '/api/v1/e/por',
      payload,
      {
        withCredentials: true,
      }
    );
  }
}
