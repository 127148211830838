import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { WebSocketService } from '@qtek/core/websockets-core';
import { DashboardTiles } from '@qtek/shared/models';
import { switchMap, map, mergeMap, filter, takeUntil, tap } from 'rxjs';
import { MenuActions } from './menu.actions';
import { MenuService } from './menu.service';

@Injectable()
export class MenuEffects {
  main$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.loadMain),
      switchMap(() =>
        this.menuService
          .getMainMenu()
          .pipe(
            map(({ res }) =>
              MenuActions.loadMainSuccess({ payload: res.children })
            )
          )
      )
    )
  );

  setup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.loadSetup),
      switchMap(() =>
        this.menuService
          .getSetupMenu()
          .pipe(
            map(({ res }) =>
              MenuActions.loadSetupSuccess({ payload: res.children })
            )
          )
      )
    )
  );

  dashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.loadDashboard),
      switchMap(() =>
        this.menuService
          .getDashboardMenu()
          .pipe(
            map((tiles: DashboardTiles) =>
              MenuActions.loadDashboardSuccess({ payload: tiles })
            )
          )
      )
    )
  );

  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.connectDashboard),
      mergeMap(({ payload }) =>
        this.webSocketService.getWebSocket$().pipe(
          filter(data => data.mysid === payload),
          takeUntil(this.actions$.pipe(ofType(MenuActions.disconnectDashboard)))
        )
      ),
      map((data: any) => {
        switch (data.op) {
          case 'upds':
            return MenuActions.updateChartDataset({
              payload: {
                data: (data as any).res,
                id: data.mysid,
              },
            });
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  subscribe$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MenuActions.connectDashboard),
        tap(({ payload }) => {
          this.menuService.subscribeDashboard({
            id: payload,
            mysid: payload,
            s: 'report',
          });
        })
      ),
    { dispatch: false }
  );

  disconnect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MenuActions.disconnectDashboard),
        tap(({ payload }) =>
          this.menuService.unsubscribeDashboard({ mysid: payload })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private menuService: MenuService,
    private webSocketService: WebSocketService
  ) {}
}
