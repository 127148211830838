import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { HideLoaderGuard } from '@qtek/libs/app-component';

import { dialogRoutes } from '@qtek/libs/dialog-routes';
import { AuthGuard, LayoutComponent } from '@qtek/libs/layout';

export function caseInsensitiveMatcher(path: string, url: UrlSegment[]) {
  return url.length === 1 && url[0].path.toLowerCase() === path.toLowerCase()
    ? { consumed: url }
    : null;
}

const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('@qtek/feature/about').then(m => m.AboutModule),
    canActivate: [HideLoaderGuard],
  },
  {
    path: 'auth',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@qtek/libs/auth-user').then(c => c.AuthRedirectComponent),
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: 'dialog',
    children: dialogRoutes,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@qtek/feature/dashboard').then(m => m.DashboardModule),
        pathMatch: 'full',
      },
      {
        path: 'cal',
        loadChildren: () =>
          import('@qtek/feature/calendar').then(m => m.CalendarModule),
      },
      {
        path: 'stp',
        loadChildren: () =>
          import('@qtek/feature/setup').then(m => m.SetupModule),
        data: { breadcrumb: { label: 'GNR_SETUP' } },
      },
      {
        path: 'rels',
        loadChildren: () =>
          import('@qtek/feature/relations').then(m => m.RelationsModule),
      },
      {
        path: 'itms',
        loadChildren: () =>
          import('@qtek/feature/items').then(m => m.ItemsModule),
      },
      {
        path: 'deal/:itemId',
        loadChildren: () =>
          import('@qtek/feature/item-dialog').then(m => m.ItemDialogModule),
      },
      {
        path: 'clbs',
        loadChildren: () =>
          import('@qtek/feature/deal-clubs').then(m => m.DealClubsModule),
      },
      {
        path: 'pars',
        loadChildren: () =>
          import('@qtek/feature/deal-book').then(m => m.DealBookModule),
      },
      {
        path: 'tools',
        loadChildren: () =>
          import('@qtek/feature/tools').then(m => m.ToolsModule),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('@qtek/feature/report').then(m => m.ReportModule),
      },
      {
        path: 'orgs',
        loadChildren: () =>
          import('@qtek/feature/organizations').then(
            m => m.OrganizationsModule
          ),
        data: { breadcrumb: { label: 'GNR_ALL_ACCOUNTS' } },
      },
    ],
  },
  {
    path: 'error',
    loadChildren: () => import('@qtek/feature/error').then(m => m.ErrorModule),
    canActivate: [HideLoaderGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('@qtek/feature/not-found').then(m => m.NotFoundModule),
    canActivate: [HideLoaderGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
