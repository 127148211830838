import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FlexLayoutModule, MediaObserver } from '@ngbracket/ngx-layout';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';

import {
  AppState,
  CreateEmployeeAction,
  CreateEmployeeSuccessAction,
  LoadMetaLanguagesAction,
  LoadMetaRolesAction,
  RelationsActionTypes,
  UpdateContactDetailsSuccessAction,
  UpdateEmployeeAction,
  UpdateEmployeeSuccessAction,
  getCurrentPersonLanguage,
  getLanguages,
  getRelationType,
  getStaffRoles,
  getUserState,
} from '@qtek/libs/store';
import { CustomValidators } from '@qtek/shared/utils';
import { UserService } from '@qtek/shared/services';

import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  AccountOutput,
  LanguageOption,
  Person,
  Role,
  Staff,
} from '@qtek/shared/models';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import {
  DialogActionsDirective,
  DialogComponent,
  DialogContentDirective,
  DialogTitleDirective,
  ErrorComponent,
} from '@qtek/shared/components';
import { fullscreenClass, minWidth } from '../../default-config';
import {
  InviteContactDialogComponent,
  inviteContactDialogConfig,
} from '../invite-contact-dialog/invite-contact-dialog.component';

export const employeeDialogConfig: MatDialogConfig = {
  panelClass: fullscreenClass,
  minWidth,
};

@Component({
  selector: 'qt-employee-dialog',
  templateUrl: './employee-dialog.component.html',
  styleUrls: ['./employee-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogComponent,
    DialogTitleDirective,
    DialogContentDirective,
    DialogActionsDirective,
    TranslationCoreModule,
    MatIconModule,
    ErrorComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    NgIf,
    NgFor,
    AsyncPipe,
    MatCheckboxModule,
  ],
})
export class EmployeeDialogComponent implements OnInit, OnDestroy {
  /**
   * List of roles for customer/vendor
   *
   * @type {Observable<Role[]>}
   * @memberof EmployeeDialogComponent
   */
  roles$: Observable<Role[]>;

  /**
   * Determines if form is in customer/vendor or linking stage
   *
   * @type {boolean}
   * @memberof EmployeeDialogComponent
   */
  link = false;

  /**
   * List of companies that are related to email
   *
   * @type {AccountOutput[]}
   * @memberof EmployeeDialogComponent
   */
  companies: AccountOutput[];

  /**
   * Holds email control, that is used to determine relationships and form mode
   *
   * @type {ExtendedFormControl}
   * @memberof EmployeeDialogComponent
   */
  email: UntypedFormControl;
  roles: UntypedFormControl;
  active: UntypedFormControl;
  edit: boolean;

  isAccountExists = false;
  userService: UserService;
  inviteLanguage: UntypedFormControl;
  currentLanguageName: Observable<string>;
  languages$: Observable<LanguageOption[]>;
  destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: Staff,
    private dialogRef: MatDialogRef<EmployeeDialogComponent>,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private actions$: Actions,
    private injector: Injector,
    private matDialog: MatDialog,
    public media: MediaObserver
  ) {
    this.store.dispatch(new LoadMetaRolesAction());
    this.store.dispatch(new LoadMetaLanguagesAction());
    this.userService = injector.get(UserService);

    this.edit = !!this.dialogData?._id;
  }

  ngOnInit(): void {
    this.languages$ = this.store.pipe(select(getLanguages));
    this.roles$ = this.store.pipe(select(getStaffRoles));

    this.createForm();

    this.actions$
      .pipe(
        ofType<CreateEmployeeSuccessAction>(
          RelationsActionTypes.CREATE_EMPLOYEE_SUCCESS
        ),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(({ payload }) => {
        this.dialogRef.close(payload);
      });

    this.actions$
      .pipe(
        ofType<UpdateContactDetailsSuccessAction>(
          RelationsActionTypes.UPDATE_CONTACT_DETAILS_SUCCESS
        ),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          new UpdateEmployeeAction({
            form: {
              rl: Number(this.roles.value),
            },
            usrId: this.dialogData._id,
          })
        );
      });

    this.actions$
      .pipe(
        ofType<UpdateEmployeeSuccessAction>(
          RelationsActionTypes.UPDATE_EMPLOYEE_SUCCESS
        ),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(({ payload }) => {
        this.dialogRef.close(payload);
      });

  }

  /**
   * Determines if form is valid
   *
   * @readonly
   * @type {boolean}
   * @memberof EmployeeDialogComponent
   */
  get isValid(): boolean {
    return this.isEmailValidAndLinked
      ? true
      : this.isAccountExists
      ? true
      : this.isAllAttributes;
  }

  get isEmailValidAndLinked() {
    return this.email.valid && this.link;
  }

  get isRolesInAddingMode() {
    return this.edit ? true : this.roles.valid;
  }

  get isAllAttributes() {
    return (
      this.isRolesInAddingMode && (this.email.valid || this.email.disabled)
    );
  }

  get personalData(): Person {
    return {
      lng: this.inviteLanguage
        ? this.inviteLanguage.value
        : this.dialogData.prs.lng,
    };
  }

  get payload(): Staff {
    return {
      email: this.email.value,
      rl: Number(this.roles.value),
      prs: this.personalData,
    };
  }

  /**
   * Set up forms and controls
   *
   *
   * @memberof EmployeeDialogComponent
   */
  createForm(): void {
    this.email = this.fb.control('', [CustomValidators.email]);

    this.roles = this.fb.control('20');

    this.active = this.fb.control(true);
    // preventing from deactivating currently logged-in user
    this.store
      .pipe(select(getUserState), takeUntil(this.destroy$))
      .subscribe((state) => {
        if (!this.edit || state.rel._id === this.dialogData._id) this.active.disable()
      });

    if (this.edit) {
      this.setDataForEditMode();
    } else {
      this.store
        .pipe(select(getCurrentPersonLanguage), take(1))
        .subscribe(lang => {
          this.inviteLanguage = new UntypedFormControl(lang);
          this.currentLanguageName = combineLatest([
            this.inviteLanguage.valueChanges.pipe(startWith(lang)),
            this.store.pipe(select(getLanguages)),
          ]).pipe(
            map(([id, langs]) => langs.find(lang => lang.id === id).name)
          );
        });
    }
  }

  setDataForEditMode(): void {
    if (this.dialogData.email) {
      this.email.setValue(this.dialogData.email);
      this.userService
        .checkUniqueEmailForLogged(this.dialogData.email)
        .subscribe(res => {
          this.isAccountExists = res.exist;
          this.checkIfAccountExists(this.dialogData.email);
          this.email.reset({
            value: this.dialogData.email,
            disabled: true,
          });
        });
    }

    this.store.pipe(select(getRelationType), take(1)).subscribe(prl => {
      if (prl === 20) {
        this.roles.disable();
      }
    });

    if (this.dialogData.rl) {
      this.roles.setValue(this.dialogData.rl.toString());
    }

    this.active.reset(this.dialogData.sts < 900);
  }

  checkIfAccountExists(email: string): void {
    this.userService.checkUniqueEmailForLogged(email).subscribe(res => {
      this.isAccountExists = res.exist;
    });
  }

  openIviteContactDialog(payload: Staff): void {
    this.matDialog.open(InviteContactDialogComponent, {
      ...inviteContactDialogConfig,
      data: {
        type: 'staff',
        person: payload.prs,
        relId: btoa(payload._id),
      },
    });

    console.log(payload);

    this.dialogRef.close(payload);
  }

  /**
   * Check email linked status
   *
   *
   * @memberof EmployeeDialogComponent
   */
  checkEmail(emailControl: UntypedFormControl): void {
    if (emailControl.valid) {
      this.userService
        .checkUniqueEmailForLogged(emailControl.value)
        .subscribe(res => {
          this.link = res.exist;
        });
    } else {
      this.link = false;
    }
  }

  onSubmit(): void {
    if (!this.isValid) {
      this.email.markAsDirty();
      this.roles.markAsDirty();
      this.active.markAsDirty();
      return;
    }

    if (this.edit) {
      this.editExistingEmployee();
    } else {
      this.saveNewEmployee();
    }
  }

  editExistingEmployee(): void {
    this.store.dispatch(
      new UpdateEmployeeAction({
        form: {
          rl: Number(this.roles.value),
          sts: this.active.value === false ? 900 : 100
        },
        usrId: this.dialogData._id,
      })
    );
  }

  saveNewEmployee(): void {
    if (this.link && this.roles.invalid) {
      this.roles.markAsDirty();
      return;
    }
    this.store.dispatch(new CreateEmployeeAction(this.payload));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
