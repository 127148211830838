import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EmbeddedViewRef,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { MatKeyboardConfig } from '../../configs';
import {
  KeyboardAnimationState,
  KeyboardAnimationTransition,
} from '../../enums';

export const SHOW_ANIMATION = `${AnimationDurations.ENTERING} ${AnimationCurves.DECELERATION_CURVE}`;
export const HIDE_ANIMATION = `${AnimationDurations.EXITING} ${AnimationCurves.ACCELERATION_CURVE}`;

@Component({
  selector: 'qt-keyboard-container',
  templateUrl: './keyboard-container.component.html',
  styleUrls: ['./keyboard-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  animations: [
    trigger('state', [
      state(
        `${KeyboardAnimationState.Visible}`,
        style({ transform: 'translateY(0%)' })
      ),
      transition(
        `${KeyboardAnimationTransition.Hide}`,
        animate(HIDE_ANIMATION)
      ),
      transition(
        `${KeyboardAnimationTransition.Show}`,
        animate(SHOW_ANIMATION)
      ),
    ]),
  ],
})
export class KeyboardContainerComponent
  extends BasePortalOutlet
  implements OnDestroy
{
  @ViewChild(CdkPortalOutlet, { static: true })
  private portalOutlet: CdkPortalOutlet;

  @HostBinding('@state')
  private animationState: KeyboardAnimationState = KeyboardAnimationState.Void;

  @HostBinding('attr.role')
  attrRole = 'alert';

  private destroyed = false;
  onExit: Subject<void> = new Subject();
  onEnter: Subject<void> = new Subject();
  keyboardConfig: MatKeyboardConfig;

  constructor(
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent) {
    event.preventDefault();
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this.portalOutlet.hasAttached()) {
      throw Error(
        'Attempting to attach keyboard content after content is already attached'
      );
    }

    return this.portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal(): EmbeddedViewRef<any> {
    throw Error('Not yet implemented');
  }

  @HostListener('@state.done', ['$event'])
  onAnimationEnd(event: AnimationEvent) {
    const { fromState, toState } = event;

    if (
      (toState === KeyboardAnimationState.Void &&
        fromState !== KeyboardAnimationState.Void) ||
      toState.startsWith('hidden')
    ) {
      this.completeExit();
    }

    if (toState === KeyboardAnimationState.Visible) {
      // Note: we shouldn't use `this` inside the zone callback,
      // because it can cause a memory leak.
      const onEnter = this.onEnter;

      this.ngZone.run(() => {
        onEnter.next();
        onEnter.complete();
      });
    }
  }

  enter() {
    if (!this.destroyed) {
      this.animationState = KeyboardAnimationState.Visible;
      this.changeDetectorRef.detectChanges();
    }
  }

  exit(): Observable<void> {
    this.animationState = KeyboardAnimationState.Hidden;
    return this.onExit;
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.completeExit();
  }

  private completeExit() {
    this.ngZone.onMicrotaskEmpty
      .asObservable()
      .pipe(first())
      .subscribe(() => {
        this.onExit.next();
        this.onExit.complete();
      });
  }
}
