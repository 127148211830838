import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  SubWebsocketMessageRequest,
  UnsubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { BackendResponse, DashboardTiles, Menu } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private baseRequestOptions = {
    withCredentials: true,
  };

  constructor(
    private http: HttpClient,
    private websocketService: WebSocketService
  ) {}

  entityName: WebsocketEntTypes = 'dsh';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  subscribeDashboard(payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>) {
    const subMessage: SubWebsocketMessageRequest = {
      ent: this.entityName,
      op: 'sub',
      ...payload,
    };
    this.wsManager.sendWsMessage(subMessage);
  }

  unsubscribeDashboard(
    payload: Omit<UnsubWebsocketMessageRequest, 'ent' | 'op'>
  ) {
    const unsubMessage: UnsubWebsocketMessageRequest = {
      ent: this.entityName,
      op: 'unsub',
      ...payload,
    };
    this.wsManager.sendWsMessage(unsubMessage);
  }

  /**
   * Send request to get main menu of application.
   *
   * @returns {Observable<BackendResponse<Menu>>} Return array of menu entries.
   *
   * @memberof MenuService
   */
  getMainMenu(): Observable<BackendResponse<Menu>> {
    return this.http.get<BackendResponse<Menu>>(
      '/api/v1/service/config/appmenu',
      this.baseRequestOptions
    );
  }

  /**
   * Send request to get setup module.
   *
   * @returns {Observable<BackendResponse<Menu>>} Return array of menu entries.
   *
   * @memberof MenuService
   */
  getSetupMenu(): Observable<BackendResponse<Menu>> {
    return this.http.get<BackendResponse<Menu>>(
      '/api/v1/service/config/stp',
      this.baseRequestOptions
    );
  }

  /**
   * Send request to get dashboard module.
   *
   * @returns {Observable<BackendResponse<Menu>>} Return array of menu entries.
   *
   * @memberof MenuService
   */
  getDashboardMenu(): Observable<DashboardTiles> {
    return this.http.get<DashboardTiles>(
      `/api/v1/service/dashboard`,
      this.baseRequestOptions
    );
  }
}
