import { HttpClient, HttpParams } from '@angular/common/http';

import { BackendResponse, entityName } from '@qtek/shared/models';
import { prefix } from '@qtek/shared/utils';

import { Observable } from 'rxjs';
/**
 * Manage http requests for entities.
 *
 * @export
 * @abstract
 * @class EntityManager
 * @template I
 * @template O
 */
export abstract class HttpEntityManager<I, O> {
  constructor(private isModelApi?: boolean) {}

  /**
   * Entity name used to generate correct url to resource.
   *
   * @protected
   * @abstract
   * @type {entityName}
   * @memberof EntityService
   */
  protected abstract entityName: entityName;
  protected abstract http: HttpClient;

  /**
   * Options to be included in every request.
   *
   * @protected
   * @memberof EntityService
   */
  protected baseRequestOptions = {
    withCredentials: true,
  };

  /**
   * Get data about {@link O} for given id
   *
   * @param {(string | number)} [id] Id of entity instance
   * @param {*} [query]
   * @returns {Observable<BackendResponse<O>>}
   *
   * @memberof EntityManager
   */
  get(id?: string | number, query?: any): Observable<BackendResponse<O>> {
    const params = this.searchParams(query);

    return this.http.get<BackendResponse<O>>(this.getUrl(id), {
      ...this.baseRequestOptions,
      params,
    });
  }

  /**
   * Get list of entity.
   *
   * @param {(string | number)} [id]
   * @param {*} [query]
   * @returns {Observable<BackendResponse<O[]>>}
   *
   * @memberof EntityManager
   */
  getAll(id?: string | number, query?: any): Observable<BackendResponse<O[]>> {
    const params = this.searchParams(query);

    return this.http.get<BackendResponse<O[]>>(this.getUrl(id, true), {
      ...this.baseRequestOptions,
      params,
    });
  }

  /**
   *
   *
   * @param {*} payload
   * @param {(string | number)} [id]
   * @returns {Observable<BackendResponse<O>>}
   *
   * @memberof EntityManager
   */
  put(
    payload: any,
    id?: string | number,
    query?: any
  ): Observable<BackendResponse<O>> {
    const params = this.searchParams(query);

    return this.http.put<BackendResponse<O>>(this.getUrl(id), payload, {
      ...this.baseRequestOptions,
      params,
    });
  }

  /**
   *
   * @param {*} payload
   * @param {(string | number)} [id]
   * @returns {Observable<BackendResponse<O>>}
   *
   * @memberof EntityManager
   */
  post(
    payload: any,
    id?: string | number,
    query?: any
  ): Observable<BackendResponse<O>> {
    const params = this.searchParams(query);

    return this.http.post<BackendResponse<O>>(this.getUrl(id), payload, {
      ...this.baseRequestOptions,
      params,
    });
  }

  /**
   * Remove entity for given id.
   *
   * @param {(string | number)} [id]
   * @returns {Observable<BackendResponse<O>>}
   *
   * @memberof EntityManager
   */
  delete(id?: string | number, query?: any): Observable<BackendResponse<O>> {
    const params = this.searchParams(query);

    return this.http.delete<BackendResponse<O>>(this.getUrl(id), {
      ...this.baseRequestOptions,
      params,
    });
  }

  /**
   * Base url to resource.
   *
   * @readonly
   * @protected
   * @type {string}
   * @memberof EntityService
   */
  protected get baseURL(): string {
    return `/api/v1/model/${this.entityName}`;
  }

  protected get baseModelURL(): string {
    return `/api/v1/model/${this.entityName}`;
  }

  /**
   * Generate entity key.
   *
   * @protected
   * @param {string} [entityName]
   * @param {string} [entityId]
   * @param {...string[]} ids
   * @returns {string}
   *
   * @memberof EntityManager
   */
  protected entityKey(
    entityName?: string,
    entityId?: string,
    ...ids: string[]
  ): string {
    const entityKey = [];

    if (entityName && entityId) {
      entityKey.push(`${entityName}=${entityId}`);
    }

    if (ids.length) {
      entityKey.push(ids.join('.'));
    }

    return entityKey.join('.');
  }

  /**
   * Return search parmas ready to be injected to request options.
   *
   * @protected
   * @param {{ [key: string]: any }} query Key value pairs to be transformed into query string.
   * @returns {HttpParams}
   *
   * @memberof EntityManager
   */
  protected searchParams(query: { [key: string]: any } = {}): HttpParams {
    let params = new HttpParams();

    Object.keys(query).forEach(queryKey => {
      params = params.set(queryKey, query[queryKey]);
    });

    return params;
  }

  /**
   * Wraps {@link prefix} util function, so we dont have to import it in every
   * service that will inherit from this class. Automatically add entityId.
   *
   * @protected
   * @param {Object} payload
   * @returns {Object}
   *
   * @memberof EntityManager
   */
  protected prefix(payload: Record<string, unknown>): Record<string, unknown> {
    return prefix(this.entityName, payload);
  }

  /**
   * Return path to resource based on id
   *
   * @protected
   * @param {(string | number)} [id] Id of resource
   * @param {boolean} [multi] Determines if request is meant to retrive an array
   * @returns {string} Endpoint url
   *
   * @memberof EntityManager
   */
  protected getUrl(id?: string | number, multi?: boolean): string {
    let path;

    switch (typeof id) {
      case 'number': {
        path = id;
        break;
      }

      case 'string': {
        path = id;
        break;
      }
    }
    return `${this.isModelApi ? this.baseModelURL : this.baseURL}${
      multi ? 's' : ''
    }${path !== undefined ? `/${path}` : ''}`;
  }
}
