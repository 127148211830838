import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendResponse } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class NcinoService {
  constructor(private http: HttpClient) {}

  getNcinoList() {
    return this.http.get<BackendResponse>('/api/v1/vnd/sf/loans/ncino', {
      withCredentials: true,
    });
  }

  postNcino(ids: string[]) {
    return this.http.post<BackendResponse>(`/api/v1/vnd/sf/loan/ncino`, ids, {
      withCredentials: true,
    });
  }
}
