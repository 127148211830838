import { MenuNode, Scopes, ScopeType } from '@qtek/shared/models';

export function hasScope(scopes: number[], scopeToCheck: ScopeType): boolean {
  return Array.isArray(scopes) && scopes.includes(scopeToCheck);
}

export function hasNotViewScopes(scopes: number[]): boolean {
  const CRUD_ARR = [
    ScopeType.ADD,
    ScopeType.DELETE,
    ScopeType.EDIT,
    ScopeType.SHARE,
  ];
  return CRUD_ARR.some(type => scopes.indexOf(type) >= 0);
}

export function hasActionRequestAccessScope(scopes: Scopes): boolean {
  return scopes.e?.includes(ScopeType.ACTION_REQUEST_ACCESS);
}

export function hasAddScope(scopes: number[]): boolean {
  return Array.isArray(scopes) && scopes.includes(ScopeType.ADD);
}

export function hasEditScope(scopes: number[]): boolean {
  return Array.isArray(scopes) && scopes.includes(ScopeType.EDIT);
}

export function hasDeleteScope(scopes: number[]): boolean {
  return Array.isArray(scopes) && scopes.includes(ScopeType.DELETE);
}

export function hasShareScope(scopes: number[]): boolean {
  return Array.isArray(scopes) && scopes.includes(ScopeType.SHARE);
}

export function hasViewScope(scopes: number[]): boolean {
  return Array.isArray(scopes) && scopes.includes(ScopeType.VIEW);
}

export function hasHistoricalAccessScope(scopes: number[]): boolean {
  return Array.isArray(scopes) && scopes.includes(ScopeType.DOWNLOAD);
}

// ------ Attribute scopes
export function hasAttrAddScope(scopes: Scopes, attr: string): boolean {
  if (Array.isArray(scopes?.a?.[attr]))
    return scopes.a[attr].includes(ScopeType.ADD);
  if (Array.isArray(scopes?.a?.['*']))
    return scopes.a['*'].includes(ScopeType.ADD);
  if (Array.isArray(scopes?.e)) return scopes.e.includes(ScopeType.ADD);
  return false;
}

export function hasAttrEditScope(scopes: Scopes, attr: string): boolean {
  if (Array.isArray(scopes?.a?.[attr]))
    return scopes.a[attr].includes(ScopeType.EDIT);
  if (Array.isArray(scopes?.a?.['*']))
    return scopes.a['*'].includes(ScopeType.EDIT);
  if (Array.isArray(scopes?.e)) return scopes.e.includes(ScopeType.EDIT);
  return false;
}

export function hasAttrDeleteScope(scopes: Scopes, attr: string): boolean {
  if (Array.isArray(scopes?.a?.[attr]))
    return scopes.a[attr].includes(ScopeType.DELETE);
  if (Array.isArray(scopes?.a?.['*']))
    return scopes.a['*'].includes(ScopeType.DELETE);
  if (Array.isArray(scopes?.e)) return scopes.e.includes(ScopeType.DELETE);
  return false;
}

export function hasAttrShareScope(scopes: Scopes, attr: string): boolean {
  if (Array.isArray(scopes?.a?.[attr]))
    return scopes.a[attr].includes(ScopeType.SHARE);
  if (Array.isArray(scopes?.a?.['*']))
    return scopes.a['*'].includes(ScopeType.SHARE);
  if (Array.isArray(scopes?.e)) return scopes.e.includes(ScopeType.SHARE);
  return false;
}

export function hasAttrViewScope(scopes: Scopes, attr: string): boolean {
  if (Array.isArray(scopes?.a?.[attr]))
    return scopes.a[attr].includes(ScopeType.VIEW);
  if (Array.isArray(scopes?.a?.['*']))
    return scopes.a['*'].includes(ScopeType.VIEW);
  if (Array.isArray(scopes?.e)) return scopes.e.includes(ScopeType.VIEW);
  return false;
}
// ------

export function getScopesFromMenuConfigByUrl(
  menu: MenuNode[],
  url: string
): any | null {
  url = url.split('/').pop().split('?').shift();
  return findUrlInMenu(menu, url)?.scopes;
}

function findUrlInMenu(menu: MenuNode[], url: string): MenuNode | null {
  for (const node of menu) {
    if (node.url === url) {
      return node;
    } else if (node.children) {
      return findUrlInMenu(node.children, url);
    }
  }
  return null;
}

export const scopeUtils = {
  hasScope,
  hasNotViewScopes,
  hasActionRequestAccessScope,
  hasAddScope,
  hasEditScope,
  hasDeleteScope,
  hasShareScope,
  hasViewScope,
  hasAttrAddScope,
  hasAttrEditScope,
  hasAttrDeleteScope,
  hasAttrShareScope,
  hasAttrViewScope,
};
