import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LazyDialogLoader } from './services';

@NgModule({
  imports: [CommonModule, MatDialogModule],
  providers: [{ provide: MatDialogRef, useValue: {} }, LazyDialogLoader],
})
export class DialogLoaderModule {
  constructor(private dialogLoader: LazyDialogLoader) {
    // preload components that are required on app start, be aware to load any bulky components, only strongly required
    dialogLoader.preloadDialog('alert');
  }
}
