import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Store, select } from '@ngrx/store';
import { locale } from 'moment';
import 'moment/locale/es-us';
import 'moment/locale/pl';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AppState, selectCurrentPerson } from '@qtek/libs/store';
import { PersonOutput } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class QtDateAdapter extends MomentDateAdapter {
  private localeReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // tslint:disable member-ordering
  localeReady$: Observable<boolean> = this.localeReady
    .asObservable()
    .pipe(filter<boolean>(Boolean));

  publicLocale: any;

  constructor(
    @Optional()
    @Inject(MAT_DATE_LOCALE)
    dateLocale: string,
    private store: Store<AppState>
  ) {
    super(dateLocale);

    this.store
      .pipe(
        select(selectCurrentPerson),
        filter((person: PersonOutput) => Boolean(person?.lng))
      )
      .subscribe((person: PersonOutput) => {
        let localVar;
        if (person.lng === 'pl_PL') {
          localVar = person.lng.slice(0, person.lng.indexOf('_'));
        } else {
          localVar = person.lng.replace('_', '-');
        }
        super.setLocale(localVar);
        locale(localVar);
        this.publicLocale = localVar;
        this.localeReady.next(true);
      });
  }
}
