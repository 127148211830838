import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnlineService } from '@qtek/shared/services';
import { EMPTY, Observable } from 'rxjs';

import { QtHttpInterceptor } from '../qtek-interceptor';

@Injectable()
export class OnlineInterceptor implements QtHttpInterceptor {
  readonly qtek = true;

  constructor(private online: OnlineService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (!this.online.isOnline()) {
    //   this.notifyUserAboutOfflineState();

    //   return EMPTY;
    // }

    return next.handle(req);
  }

  notifyUserAboutOfflineState() {
    console.log('detected offline');
  }
}
