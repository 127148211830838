import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectMainMenu, selectPermissions } from '@qtek/libs/store';
import { filter, map, Observable, take, tap } from 'rxjs';
import { PermissionType } from '../data/permission.data';
import { extractFinalUrl } from '../utils/permissions.utils';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private store = inject(Store);
  private router = inject(Router);
  firstAllowedMenuItem = '';
  permissions$ = this.store
    .select(selectPermissions)
    .pipe(filter(permissions => !!permissions.size));

  isAllowed$(
    url: string | string[],
    type: PermissionType | PermissionType[]
  ): Observable<boolean> {
    return this.permissions$.pipe(
      map(permissions => {
        const permittedRoutes = this.getPermittedFeatures(permissions, type);
        const urls = Array.isArray(url) ? url : [url];

        return urls.some(finalUrl =>
          permittedRoutes.some(route =>
            route.includes(extractFinalUrl(finalUrl))
          )
        );
      })
    );
  }

  isCurrentActionAllowed$(type: PermissionType): Observable<boolean> {
    const url = this.router.url;
    return this.isAllowed$(url, type);
  }

  getFirstMenuRoute(): Observable<string> {
    return this.store.select(selectMainMenu).pipe(
      take(1),
      filter(menu => !!menu.length),
      map(
        menu =>
          menu.reduce((routes, { children }) => {
            routes = [...routes, ...children.map(({ url }) => url)];
            return routes;
          }, [])[0]
      ),
      tap(firstRoute => (this.firstAllowedMenuItem = firstRoute))
    );
  }

  getPermittedFeatures(
    permissions: Set<string>,
    types: PermissionType | PermissionType[]
  ): string[] {
    const typesArray = Array.isArray(types) ? types : [types];
    const permittedRoutes = typesArray.reduce((acc, type) => {
      return acc.concat(this.getPermittedFeaturesForType(permissions, type));
    }, [] as string[]);

    return permittedRoutes;
  }

  private getPermittedFeaturesForType(
    permissions: Set<string>,
    type: PermissionType
  ): string[] {
    return [...permissions]
      .filter(permission => permission.split('.')[2] === type)
      .map(permission => permission.split('.')[1])
      .filter(Boolean);
  }
}
