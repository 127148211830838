import { MatKeyboardConfig } from '../configs';
import { LocaleMap, KeyboardLayouts, KeyboardLayout } from '../interfaces';

export function applyConfigDefaults(
  config: MatKeyboardConfig
): MatKeyboardConfig {
  return Object.assign(new MatKeyboardConfig(), config);
}

export function applyAvailableLayouts(layouts: KeyboardLayouts): LocaleMap {
  const availableLocales: LocaleMap = {};

  Object.keys(layouts)
    .filter((layout: string) => 'lang' in layouts[layout])
    .forEach((layout: string) => {
      layouts[layout].lang.forEach((lang: string) => {
        availableLocales[lang] = layout;
      });
    });

  return availableLocales;
}

export function mapLocale(locale: string, layouts: KeyboardLayouts): string {
  const availableLocales: LocaleMap = applyAvailableLayouts(layouts);

  let layout: string;
  const country = locale.split('-').shift();

  if (availableLocales[country]) {
    layout = availableLocales[locale];
  }

  if (availableLocales[locale]) {
    layout = availableLocales[locale];
  }

  if (!layout) {
    throw Error(`No layout found for locale ${locale}`);
  }

  return layout;
}

export function getLayoutForLocale(
  locale: string,
  layouts: KeyboardLayouts
): KeyboardLayout {
  return layouts[mapLocale(locale, layouts)];
}
