import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ErrorSnackBarData, SnackBarService } from '@qtek/libs/snack-bar-core';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthUserActions } from '@qtek/libs/auth-user';
import { Message } from '@qtek/shared/models';
import { AppState } from '../index';
import { PermissionsActions } from '../permission';
import { CompanyService } from './company.service';
import { PersonService } from './person.service';
import { UserActions } from './user.actions';
import { UserStoreService } from './user.service';

@Injectable()
export class UserEffects {
  currentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCurrentUser),
      switchMap(({ payload }) =>
        this.userService.getCurrentUser(payload).pipe(
          map(res => {
            if (res.res.rels && !res.res.cmp) {
              return UserActions.getCurrentUserMultiCompany({
                payload: res.res,
              });
            } else {
              this.store.dispatch(
                AuthUserActions.getUserSuccess({ user: res.res })
              );

              if (res.res?.lic?.features) {
                this.store.dispatch(
                  PermissionsActions.setPermissions({
                    permissions: res.res.lic.features,
                  })
                );
              } else {
                const snackBarData: ErrorSnackBarData = {
                  type: 'ErrorMessage',
                  message: 'GNR_MISSED_PERMISSIONS',
                };
                this.snackBar.showSnackBar(snackBarData);
                this.router.navigate(['error']);
              }
              return UserActions.getCurrentUserSuccess({ payload: res.res });
            }
          }),
          catchError(res => {
            this.store.dispatch(
              AuthUserActions.getUserFailure({ error: new Error('not logged') })
            );
            return of(UserActions.getCurrentUserFailure({ payload: res?.sts }));
          })
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateCurrentUser),
      concatMap(({ payload }) =>
        this.personService
          .updatePerson(payload.form, payload.additionalPayload)
          .pipe(
            map(({ res }) =>
              UserActions.updateCurrentUserSuccess({
                payload: res,
                message: new Message('GNR_PRS_INFO_SAVED'),
              })
            ),
            catchError(({ sts }) =>
              of(UserActions.updateCurrentUserFailure({ payload: sts }))
            )
          )
      )
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateCurrentUserCompany),
      switchMap(({ payload }) =>
        this.companyService
          .updateCompany(payload.additionalPayload, payload.form, payload.id)
          .pipe(
            map(({ res }) =>
              UserActions.updateCurrentUserCompanySuccess({
                payload: res,
                message: new Message('GNR_CMP_INFO_SAVED'),
              })
            ),
            catchError(({ sts }) =>
              of(UserActions.updateCurrentUserCompanyFailure({ payload: sts }))
            )
          )
      )
    )
  );

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCurrentUserCompanies),
      switchMap(() =>
        this.companyService.getCompanies().pipe(
          map(({ res }) =>
            UserActions.getCurrentUserCompaniesSuccess({ payload: res })
          ),
          catchError(({ error }) =>
            of(
              UserActions.getCurrentUserCompaniesFailure({ payload: error.sts })
            )
          )
        )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteCurrentUserCompany),
      switchMap(({ payload }) =>
        this.companyService.removeCompany(payload.id, payload.force).pipe(
          map(({ res }) =>
            UserActions.deleteCurrentUserCompanySuccess({
              payload: res.relId,
              message: new Message('GNR_ACCOUNT_DELETED'),
            })
          ),
          catchError(({ sts }) =>
            of(UserActions.deleteCurrentUserCompanyFailure({ payload: sts }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserStoreService,
    private personService: PersonService,
    private companyService: CompanyService,
    private store: Store<AppState>,
    private snackBar: SnackBarService,
    private router: Router
  ) {}
}
