import { Transaction } from '@qtek/shared/models';
import {
  TransactionActions,
  TransactionActionTypes,
} from './transaction.actions';

export interface State {
  transactions: Record<string, Transaction[]>;
}

const initialState: State = {
  transactions: {},
};

export function reducer(
  state = initialState,
  { type, payload }: TransactionActions
): State {
  switch (type) {
    case TransactionActionTypes.GET_TRANSACTIONS_SUCCESS: {
      const { mysid, res } = payload;
      return {
        ...state,
        transactions: { ...state.transactions, [mysid]: res },
      };
    }

    case TransactionActionTypes.ADD_TRANSACTION_SUCCESS: {
      const { mysid, res } = payload;

      return {
        ...state,
        transactions: {
          ...state.transactions,
          [mysid]: [res].concat(state.transactions[mysid]),
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const getTransactions = (state: State) => state.transactions;
