import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AppState,
  getCountryCode,
  getCurrencies,
  getFrequencies,
  getGuiDomain,
  getGuiOnlineBookDomain,
  getGuiShrDomain,
  getLanguages,
  getMobileTitle,
  getRoles,
  getStates,
  getTimezones,
  getTitle,
  getUnits,
  getWsPingPong,
} from '../index';
import * as fromMeta from './meta.actions';
import { MetaService } from './meta.service';
import { PowerSearchService } from './power-search.service';

@Injectable()
export class MetaEffects {
  meta$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMeta.MetaActionTypes.LOAD_META),
      mergeMap(({ payload }) =>
        this.metaService.getMetaData(payload).pipe(
          map(({ res }) => new fromMeta.LoadMetaSuccessAction(res)),
          catchError(err => of(new fromMeta.LoadMetaFailureAction(err)))
        )
      )
    )
  );

  metaCountry$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMeta.LoadMetaCountryAction>(
        fromMeta.MetaActionTypes.LOAD_META_COUNTRY
      ),
      switchMap(({ payload }) =>
        this.metaService.getMetaDataCountryInfo(payload).pipe(
          map(({ res }) => new fromMeta.LoadMetaCountrySuccessAction(res)),
          catchError(err => of(new fromMeta.LoadMetaCountryFailureAction(err)))
        )
      )
    )
  );

  metaCountries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMeta.LoadMetaCountriesAction>(
        fromMeta.MetaActionTypes.LOAD_META_COUNTRIES
      ),
      switchMap(() =>
        this.metaService.getMetaDataCountries().pipe(
          map(({ res }) => new fromMeta.LoadMetaCountriesSuccessAction(res)),
          catchError(err =>
            of(new fromMeta.LoadMetaCountriesFailureAction(err))
          )
        )
      )
    )
  );

  metaFoodTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMeta.LoadMetaFoodTypesAction>(
        fromMeta.MetaActionTypes.LOAD_META_FOOD_TYPES
      ),
      switchMap(() =>
        this.metaService.getFoodTypes().pipe(
          map(({ res }) => new fromMeta.LoadMetaFoodTypesSuccessAction(res)),
          catchError(err =>
            of(new fromMeta.LoadMetaFoodTypesFailureAction(err))
          )
        )
      )
    )
  );

  metaSetUiTheme: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromMeta.SetMetaUiThemeAction>(
          fromMeta.MetaActionTypes.SET_META_UI_THEME
        ),
        map(({ payload }) => {
          localStorage.setItem(
            'uiTheme',
            payload.isDarkTheme ? 'dark' : 'light'
          );
        })
      ),
    { dispatch: false }
  );

  metaLoadUiTheme: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMeta.LoadMetaUiThemeAction>(
        fromMeta.MetaActionTypes.LOAD_META_UI_THEME,
        ROOT_EFFECTS_INIT
      ),
      switchMap(() => {
        const theme = localStorage.getItem('uiTheme');
        return of(new fromMeta.LoadMetaUiThemeActionSuccess(theme === 'dark'));
      })
    )
  );

  private metaEffect = <T extends Action>(
    type: string,
    selector: any,
    params?: string[]
  ) =>
    this.actions$.pipe(
      ofType<T>(type),
      withLatestFrom(this.store.pipe(select(selector))),
      mergeMap(([, data]) => {
        return (data ? Boolean(data.toString()) : false)
          ? EMPTY
          : this.metaService.getMetaData(params).pipe(
              map(({ res }) => new fromMeta.LoadMetaSuccessAction(res)),
              catchError(err => of(new fromMeta.LoadMetaFailureAction(err)))
            );
      })
    );

  metaTimezones$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaTimezonesAction>(
      fromMeta.MetaActionTypes.LOAD_META_TIMEZONES,
      getTimezones,
      ['tzs']
    )
  );

  metaUnits$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaUnitsAction>(
      fromMeta.MetaActionTypes.LOAD_META_UNITS,
      getUnits,
      ['unts']
    )
  );

  metaFrequencies$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaFrequenciesAction>(
      fromMeta.MetaActionTypes.LOAD_META_FREQUENCIES,
      getFrequencies,
      ['frq']
    )
  );

  metaRoles$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaRolesAction>(
      fromMeta.MetaActionTypes.LOAD_META_ROLES,
      getRoles,
      ['ptlRoleGroups']
    )
  );

  metaLanguages$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaLanguagesAction>(
      fromMeta.MetaActionTypes.LOAD_META_LANGUAGES,
      getLanguages,
      ['lngs']
    )
  );

  metaTitle$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaTitleAction>(
      fromMeta.MetaActionTypes.LOAD_META_TITLE,
      getTitle,
      ['ptl']
    )
  );

  metaMobileTitle$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaMobileTitleAction>(
      fromMeta.MetaActionTypes.LOAD_META_MOBILE_TITLE,
      getMobileTitle,
      ['ptl']
    )
  );

  metaGuiDomain$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaGuiDomainAction>(
      fromMeta.MetaActionTypes.LOAD_META_GUI_DOMAIN,
      getGuiDomain,
      ['ptl']
    )
  );

  metaGuiOnlineBookDomain$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaGuiOnlineBookDomainAction>(
      fromMeta.MetaActionTypes.LOAD_META_GUI_ONLINE_BOOK_DOMAIN,
      getGuiOnlineBookDomain,
      ['ptl']
    )
  );

  metaGuiShrDomain$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaGuiShrDomainAction>(
      fromMeta.MetaActionTypes.LOAD_META_GUI_SHR_DOMAIN,
      getGuiShrDomain,
      ['ptl']
    )
  );

  metaPhones$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaCountryCodeAction>(
      fromMeta.MetaActionTypes.LOAD_META_COUNTRY_CODE,
      getCountryCode,
      ['phns']
    )
  );

  metaWebsocket$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaWebsocketAction>(
      fromMeta.MetaActionTypes.LOAD_META_WEBSOCKET,
      getWsPingPong,
      ['wsPingPong']
    )
  );

  metaCurrency$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaCurrencyAction>(
      fromMeta.MetaActionTypes.LOAD_META_CURRENCY,
      getCurrencies,
      ['curs']
    )
  );

  metaCompanyLicense$: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaCompanyLicenseAction>(
      fromMeta.MetaActionTypes.LOAD_META_CMP_LICENSE,
      getUnits,
      ['licCmp']
    )
  );

  metStates: Observable<Action> = createEffect(() =>
    this.metaEffect<fromMeta.LoadMetaCompanyLicenseAction>(
      fromMeta.MetaActionTypes.LOAD_META_STATES,
      getStates,
      ['adr']
    )
  );

  connectPSMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMeta.MetaActionTypes.SUBSCRIBE_PS_META),
      switchMap(({ payload }: any) =>
        this.psService.subscribe({ prms: { mid: payload }, s: 'model' }).pipe(
          map(value => ({ ...value, mid: payload })),
          takeUntil(
            this.actions$.pipe(
              ofType(fromMeta.MetaActionTypes.UNSUBSCRIBE_PS_META)
            )
          )
        )
      ),
      map(({ res, op, sts, mid }: any) => {
        switch (op) {
          case 'query':
            return new fromMeta.UpdatePSMetaSuccessAction(
              res?.[0] ? res[0] : { mid }
            );
          case 'upd':
            return res
              ? new fromMeta.UpdatePSMetaSuccessAction(res)
              : new fromMeta.UpdatePSMetaFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  updatePSMeta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromMeta.UpdatePSMetaAction>(
          fromMeta.MetaActionTypes.UPDATE_PS_META
        ),
        tap<fromMeta.UpdatePSMetaAction>(({ payload }) =>
          this.psService.updateMeta({ res: payload })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private metaService: MetaService,
    private psService: PowerSearchService,
    private store: Store<AppState>
  ) {}
}
