import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WebsocketEntTypes,
  WebSocketService,
  WSEntityManager,
} from '@qtek/core/websockets-core';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  entityName: WebsocketEntTypes = 'itmtra';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  constructor(private websocketService: WebSocketService) {}

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }
}
