import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthService } from './auth.service';

import { AppState, getGuiDomain } from '..';
import {
  CloseLoadingAction,
  GetCurrentUserSuccessAction,
  OpenLoadingAction,
} from '../actions';

import { ENVIRONMENT_APP_NAME } from '@qtek/core/api-core';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT_APP_NAME) private envAppName: string,
    private store: Store<AppState>
  ) {}

  wsInitToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getWSInitToken),
      mergeMap(() =>
        this.authService.getWsSessionInitToken().pipe(
          map(({ res }) =>
            AuthActions.getWSInitTokenSuccess({ payload: res.wsTkn })
          ),
          catchError(() => of(AuthActions.getWSInitTokenError()))
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      tap(() => this.store.dispatch(new OpenLoadingAction())),
      mergeMap(({ payload }) =>
        this.authService.login(payload).pipe(
          tap(res => {
            if (res.sts?.sts === 'WARN' && (!res.res.acn || !res.res.prs)) {
              this.router.navigate(['/']);
            } else {
              let { t } = this.route.snapshot.queryParams;
              const { returnUrl } = this.route.snapshot.queryParams;
              if (t && typeof t === 'string') {
                const decodeMap: any = {
                  '-': '+',
                  _: '/',
                  '.': '=',
                };

                t = t.replace(/[-_.]/g, ch => decodeMap[ch]);

                return this.router.navigateByUrl(decodeURIComponent(atob(t)));
              }
              this.router.navigateByUrl(returnUrl || '');
            }
            return null;
          }),
          mergeMap(res => {
            if (res.sts?.sts === 'WARN' || !res.res.acn || !res.res.prs) {
              return [AuthActions.loginOk()];
            }
            return [
              AuthActions.loginOk(),
              new GetCurrentUserSuccessAction({ payload: res.res }),
            ];
          }),
          catchError(() => {
            this.store.dispatch(new CloseLoadingAction());
            return of(AuthActions.loginError());
          })
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(({ queryParams, skipRedirect }) =>
        this.authService.logout().pipe(
          finalize(() => {
            this.matDialog.closeAll();

            if (!skipRedirect) {
              this.router.navigate(
                [this.envAppName === 'booking' ? '' : 'auth'],
                {
                  queryParams: queryParams || null,
                  queryParamsHandling: 'merge',
                }
              );
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      exhaustMap(({ payload }) => {
        this.store.dispatch(new OpenLoadingAction());

        return this.authService.register(payload).pipe(
          concatMap(() =>
            this.authService.login({
              email: payload.email,
              passwd: payload.passwd,
              mfa_code: 'Test7777',
            })
          ),
          mergeMap(({ res }) => [
            new GetCurrentUserSuccessAction({ payload: res }),
            AuthActions.registerOk(),
          ]),
          tap(() => {
            if (this.envAppName === 'booking') {
              this.store
                .pipe(select(getGuiDomain), filter(Boolean), take(1))
                .subscribe(domain => (this.document.location.href = domain));
            } else {
              return this.router.navigateByUrl(
                decodeURIComponent(
                  this.route.snapshot.queryParams['returnUrl'] || ''
                )
              );
            }
            return null;
          }),
          catchError(() => {
            this.store.dispatch(new CloseLoadingAction());
            return of(AuthActions.registerError());
          })
        );
      })
    )
  );

  resetPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPasswordEmail),
      switchMap(({ payload }) =>
        this.authService.sendResetPasswordEmail(payload).pipe(
          map(() => AuthActions.resetPasswordEmailSuccess()),
          catchError(() => of(AuthActions.resetPasswordEmailFailure()))
        )
      )
    )
  );
}
