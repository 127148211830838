import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as momentTimezone from 'moment-timezone';

import { WebDomain, entityName } from '@qtek/shared/models';
import { getGMTOffset } from '@qtek/shared/utils';
import { HttpEntityManager } from './../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class WebDomainsService extends HttpEntityManager<WebDomain, WebDomain> {
  entityName: entityName = 'acndom';

  constructor(protected http: HttpClient) {
    super(true);
  }

  getWebDomains() {
    return super.getAll();
  }

  createWebDomain(webDomain: WebDomain) {
    return super.post(webDomain, undefined, {
      gmtOffset: getGMTOffset(),
      tzGuess: btoa(momentTimezone.tz.guess()),
    });
  }

  deleteWebDomain(domain: string) {
    return super.delete(domain);
  }
}
