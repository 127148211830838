import { Action } from '@ngrx/store';

import {
  Message,
  IntegratedCalendar,
  CalendarIntegrationsVendor,
  SalesforceInfo,
  MicrosoftInfo,
  DebtXProfile,
} from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class IntegrationsActionTypes {
  static readonly GET_CALENDARS = type('[Integrations] Get Calendars');
  static readonly GET_CALENDARS_FAILURE = type(
    '[Integrations] Get Calendars Failure'
  );
  static readonly GET_CALENDARS_SUCCESS = type(
    '[Integrations] Get Calendars Success'
  );
  static readonly GET_CALENDAR = type('[Integrations] Get Calendar');
  static readonly GET_CALENDAR_FAILURE = type(
    '[Integrations] Get Calendar Failure'
  );
  static readonly GET_CALENDAR_SUCCESS = type(
    '[Integrations] Get Calendar Success'
  );
  static readonly GET_CLOVER = type('[Integrations] Get Clover');
  static readonly GET_CLOVER_FAILURE = type(
    '[Integrations] Get Clover Failure'
  );
  static readonly GET_CLOVER_SUCCESS = type(
    '[Integrations] Get Clover Success'
  );
  static readonly GET_SALESFORCE = type('[Integrations] Get Salesforce');
  static readonly GET_SALESFORCE_FAILURE = type(
    '[Integrations] Get Salesforce Failure'
  );
  static readonly GET_SALESFORCE_SUCCESS = type(
    '[Integrations] Get Salesforce Success'
  );
  static readonly GET_MICROSOFT = type('[Integrations] Get Microsoft');
  static readonly GET_MICROSOFT_FAILURE = type(
    '[Integrations] Get Microsoft Failure'
  );
  static readonly GET_MICROSOFT_SUCCESS = type(
    '[Integrations] Get Microsoft Success'
  );
  static readonly CONNECT_CALENDAR = type('[Integrations] Connect Calendar');
  static readonly CONNECT_CALENDAR_FAILURE = type(
    '[Integrations] Connect Calendar Failure'
  );
  static readonly CONNECT_CALENDAR_SUCCESS = type(
    '[Integrations] Connect Calendar Success'
  );
  static readonly UPDATE_CALENDAR = type('[Integrations] Update Calendar');
  static readonly UPDATE_CALENDAR_FAILURE = type(
    '[Integrations] Update Calendar Failure'
  );
  static readonly UPDATE_CALENDAR_SUCCESS = type(
    '[Integrations] Update Calendar Success'
  );
  static readonly DISCONNECT_CALENDAR = type(
    '[Integrations] Disconnect Calendar'
  );
  static readonly DISCONNECT_CALENDAR_FAILURE = type(
    '[Integrations] Disconnect Calendar Failure'
  );
  static readonly DISCONNECT_CALENDAR_SUCCESS = type(
    '[Integrations] Disconnect Calendar Success'
  );
  static readonly CALENDAR_AUTH_ERROR = type(
    '[Integrations] Calendar Auth Error'
  );
  static readonly CALENDAR_APPROVAL_ERROR = type(
    '[Integrations] Calendar Approval Error'
  );
  static readonly GET_DEBTX_PROFILE = type('[Integrations] Get DebtX');
  static readonly GET_DEBTX_PROFILE_FAILURE = type(
    '[Integrations] Get DebtX Failure'
  );
  static readonly GET_DEBTX_PROFILE_SUCCESS = type(
    '[Integrations] Get DebtX Success'
  );
  static readonly CONNECT_DEBTX = type('[Integrations] Connect DebtX');
  static readonly CONNECT_DEBTX_FAILURE = type(
    '[Integrations] Connect DebtX Failure'
  );
  static readonly CONNECT_DEBTX_SUCCESS = type(
    '[Integrations] Connect DebtX Success'
  );
  static readonly CONNECT_DEBTX_WRONG_CREDS = type(
    '[Integrations] Connect DebtX Wrong Credentials'
  );
  static readonly CONNECT_DEBTX_MFA = type('[Integrations] Connect DebtX MFA');
}

export class GetCalendarsAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CALENDARS;

  constructor(public payload?: any) {}
}

export class GetCalendarsFailureAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CALENDARS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetCalendarsSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CALENDARS_SUCCESS;

  constructor(public payload: CalendarIntegrationsVendor[]) {}
}

export class GetCalendarAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CALENDAR;

  constructor(public payload: CalendarIntegrationsVendor) {}
}

export class GetCalendarFailureAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CALENDAR_FAILURE;

  constructor(public payload?: any) {}
}

export class GetCalendarSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CALENDAR_SUCCESS;

  constructor(public payload: IntegratedCalendar) {}
}

export class GetCloverAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CLOVER;

  constructor(public payload?: any) {}
}

export class GetCloverFailureAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CLOVER_FAILURE;

  constructor(public payload?: any) {}
}

export class GetCloverSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.GET_CLOVER_SUCCESS;

  constructor(public payload: { expStr: string }) {}
}

export class GetSalesforceAction implements Action {
  readonly type = IntegrationsActionTypes.GET_SALESFORCE;

  constructor(public payload?: any) {}
}

export class GetSalesforceFailureAction implements Action {
  readonly type = IntegrationsActionTypes.GET_SALESFORCE_FAILURE;

  constructor(public payload?: any) {}
}

export class GetSalesforceSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.GET_SALESFORCE_SUCCESS;

  constructor(public payload: SalesforceInfo) {}
}

export class GetMicrosoftAction implements Action {
  readonly type = IntegrationsActionTypes.GET_MICROSOFT;

  constructor(public payload?: any) {}
}

export class GetMicrosoftFailureAction implements Action {
  readonly type = IntegrationsActionTypes.GET_MICROSOFT_FAILURE;

  constructor(public payload?: any) {}
}

export class GetMicrosoftSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.GET_MICROSOFT_SUCCESS;

  constructor(public payload: MicrosoftInfo) {}
}

export class ConnectCalendarAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_CALENDAR;

  constructor(
    public payload: {
      vendor: CalendarIntegrationsVendor;
      data: IntegratedCalendar;
    }
  ) {}
}

export class ConnectCalendarFailureAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_CALENDAR_FAILURE;

  constructor(public payload?: any) {}
}

export class ConnectCalendarSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_CALENDAR_SUCCESS;
  message = new Message('FTR_INTEGRATIONS_CONNECTED');

  constructor(public payload: IntegratedCalendar) {}
}

export class UpdateCalendarAction implements Action {
  readonly type = IntegrationsActionTypes.UPDATE_CALENDAR;

  constructor(
    public payload: {
      vendor: CalendarIntegrationsVendor;
      data: IntegratedCalendar;
    }
  ) {}
}

export class UpdateCalendarFailureAction implements Action {
  readonly type = IntegrationsActionTypes.UPDATE_CALENDAR_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateCalendarSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.UPDATE_CALENDAR_SUCCESS;
  message = new Message('FTR_INTEGRATIONS_UPDATED');

  constructor(public payload: IntegratedCalendar) {}
}

export class DisconnectCalendarAction implements Action {
  readonly type = IntegrationsActionTypes.DISCONNECT_CALENDAR;

  constructor(public payload: CalendarIntegrationsVendor) {}
}

export class DisconnectCalendarFailureAction implements Action {
  readonly type = IntegrationsActionTypes.DISCONNECT_CALENDAR_FAILURE;

  constructor(public payload?: any) {}
}

export class DisconnectCalendarSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.DISCONNECT_CALENDAR_SUCCESS;
  message = new Message('FTR_INTEGRATIONS_DISCONNECTED');

  constructor(public payload: CalendarIntegrationsVendor) {}
}

export class CalendarAuthErrorAction implements Action {
  readonly type = IntegrationsActionTypes.CALENDAR_AUTH_ERROR;

  constructor(public payload: CalendarIntegrationsVendor) {}
}

export class CalendarApprovalErrorAction implements Action {
  readonly type = IntegrationsActionTypes.CALENDAR_APPROVAL_ERROR;

  constructor(public payload: { vnd: CalendarIntegrationsVendor }) {}
}

export class GetDebtXProfileAction implements Action {
  readonly type = IntegrationsActionTypes.GET_DEBTX_PROFILE;

  constructor(public payload?: any) {}
}

export class GetDebtXProfileFailureAction implements Action {
  readonly type = IntegrationsActionTypes.GET_DEBTX_PROFILE_FAILURE;

  constructor(public payload?: any) {}
}

export class GetDebtXProfileSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.GET_DEBTX_PROFILE_SUCCESS;

  constructor(
    public status: string,
    public data: DebtXProfile
  ) {}
}

export class ConnectDebtXAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_DEBTX;

  constructor(
    public payload: {
      username?: string;
      password?: string;
    }
  ) {}
}

export class ConnectDebtXFailureAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_DEBTX_FAILURE;

  constructor(public payload?: any) {}
}

export class ConnectDebtXSuccessAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_DEBTX_SUCCESS;

  constructor(public status: string) {}
}

export class ConnectDebtXWrongCredentialsAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_DEBTX_WRONG_CREDS;

  constructor(public status: string) {}
}

export class ConnectDebtXMfaAction implements Action {
  readonly type = IntegrationsActionTypes.CONNECT_DEBTX_MFA;

  constructor(public mfaCode: string) {}
}

export type IntegrationsActions =
  | GetCalendarsAction
  | GetCalendarsFailureAction
  | GetCalendarsSuccessAction
  | GetCalendarAction
  | GetCalendarFailureAction
  | GetCalendarSuccessAction
  | ConnectCalendarAction
  | ConnectCalendarFailureAction
  | ConnectCalendarSuccessAction
  | UpdateCalendarAction
  | UpdateCalendarFailureAction
  | UpdateCalendarSuccessAction
  | DisconnectCalendarAction
  | DisconnectCalendarFailureAction
  | DisconnectCalendarSuccessAction
  | CalendarAuthErrorAction
  | CalendarApprovalErrorAction
  | GetCloverAction
  | GetCloverSuccessAction
  | GetCloverFailureAction
  | GetSalesforceAction
  | GetSalesforceSuccessAction
  | GetSalesforceFailureAction
  | GetMicrosoftAction
  | GetMicrosoftSuccessAction
  | GetMicrosoftFailureAction
  | GetDebtXProfileAction
  | GetDebtXProfileSuccessAction
  | GetDebtXProfileFailureAction
  | ConnectDebtXAction
  | ConnectDebtXSuccessAction
  | ConnectDebtXFailureAction
  | ConnectDebtXMfaAction
  | ConnectDebtXWrongCredentialsAction;
