import { Injectable } from '@angular/core';
import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Note, NoteValue } from '@qtek/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoteUserService {
  constructor(private websocketService: WebSocketService) {}

  entityName: WebsocketEntTypes = 'notusr';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  createNote(note: NoteValue, refId: string, mysid: string) {
    this.wsManager.create({ res: { refId, ...note }, mysid });
  }

  updateNote(note: Note, mysid: string) {
    this.wsManager.update({ res: note, id: note._id, mysid });
  }

  deleteNote(id: string, mysid: string) {
    this.wsManager.delete({ id, mysid });
  }
}
