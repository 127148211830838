import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

const XSRF_COOKIE_NAME = 'xt';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.cookieService.check(XSRF_COOKIE_NAME)) {
      const xsrfToken = this.cookieService.get(XSRF_COOKIE_NAME);
      const xsrfReq = request.clone({
        setHeaders: {
          'X-XSRF-TOKEN': xsrfToken,
        },
      });
      return next.handle(xsrfReq);
    }
    return next.handle(request);
  }
}
