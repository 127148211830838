<div
  fxLayout
  [ngClass]="{ avatar: !isSmall, 'avatar-small': isSmall }"
  [matTooltip]="name"
  *ngIf="imageUrl === ''"
>
  <span
    [ngClass]="{
      avatar__letter: !isSmall,
      'avatar-small__letter': isSmall,
      avatar__image: !bigImage,
      'avatar__image--big': bigImage
    }"
    *ngIf="name"
  >{{ name | slice: 0:1 }}</span>
  <span
    class="avatar__status pending"
    *ngIf="!isStatusActive"
  ></span>
</div>
<img
  *ngIf="imageUrl !== ''"
  [src]="imageUrl"
  [ngClass]="{ avatar__image: !bigImage, 'avatar__image--big': bigImage }"
  alt="Avatar"
/>