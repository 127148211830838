import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import * as TransactionActions from './transaction.actions';
import { TransactionService } from './transactions.service';

@Injectable()
export class TransactionEffects {
  constructor(private actions$: Actions, private transactionsService: TransactionService) {}

  connectWithCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.TransactionActionTypes.SUBSCRIBE_TRANSACTIONS),
      switchMap(({ payload }: any) =>
        this.transactionsService.subscribe({ s: 'model', ...payload }).pipe(
          takeUntil(this.actions$.pipe(ofType(TransactionActions.TransactionActionTypes.UNSUBSCRIBE_TRANSACTIONS))),
          filter((action: any) => payload?.mysid === action.payload?.mysid)
        )
      ),
      filter((data: any) => data.op),
      map(({ res, op, sts, mysid }) => {
        switch (op) {
          case 'query':
            return res
              ? new TransactionActions.GetTransactionsSuccessAction({ res: res || [], mysid })
              : new TransactionActions.GetTransactionsFailureAction(sts);

          case 'ins':
            return res
              ? new TransactionActions.AddTransactionSuccessAction({ res: res, mysid })
              : new TransactionActions.AddTransactionFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );
}
