import { Provider } from '@angular/core';

import { INTL_PAGINATOR_PROVIDERS } from './paginator-intl';
import { INTL_SORT_HEADER_PROVIDERS } from './sort-header-intl';
import { INTL_DATEPICKER_PROVIDERS } from './datepicker-intl';
import { INTL_STEPPER_PROVIDERS } from './stepper-intl';
import { INTL_INSTANT_TRANSLATIONS_PROVIDERS } from './instantTranslations-intl';

export * from './intl';

export const INTL_PROVIDERS: Provider[] = [
  ...INTL_PAGINATOR_PROVIDERS,
  ...INTL_SORT_HEADER_PROVIDERS,
  ...INTL_DATEPICKER_PROVIDERS,
  ...INTL_STEPPER_PROVIDERS,
  ...INTL_INSTANT_TRANSLATIONS_PROVIDERS,
];
