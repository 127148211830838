import { Injectable } from '@angular/core';
import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealTeamService {
  constructor(private wsService: WebSocketService) {}

  entityName: WebsocketEntTypes = 'parcnt';
  wsManager = new WSEntityManager(this.wsService, this.entityName);

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  createDealTeam(dealTeam: any): void {
    this.wsManager.create({ res: dealTeam });
  }

  updateDealTeam(dealTeam: any, id: string): void {
    this.wsManager.update({ id, res: dealTeam });
  }

  deleteDealTeam(id: string): void {
    this.wsManager.delete({ id });
  }
}
