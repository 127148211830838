import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LoginModel, RegisterPayload } from '@qtek/shared/models';

/**
 * Auth Actions
 */
export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    Login: props<{ payload: LoginModel }>(),
    'Login Ok': emptyProps(),
    'Login Error': emptyProps(),

    Logout: props<{ queryParams?: any; skipRedirect?: true }>(),
    Register: props<{ payload: RegisterPayload }>(),
    'Register Ok': emptyProps(),
    'Register Error': emptyProps(),

    'Get WS Init Token': emptyProps(),
    'Get WS Init Token Success': props<{ payload: string }>(),
    'Get WS Init Token Error': emptyProps(),

    'Reset Password Email': props<{ payload: any }>(),
    'Reset Password Email Success': emptyProps(),
    'Reset Password Email Failure': emptyProps(),
  },
});
