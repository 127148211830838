<div
  class="qt-keyboard-wrapper"
  [class.dark-theme]="darkTheme$ | async"
  [class.debug]="isDebug$ | async">
  <nav class="qt-keyboard-layout">
    <div class="qt-keyboard-row" *ngFor="let row of layout.keys">
      <ng-container *ngFor="let key of row">
        <qt-keyboard-key
          class="qt-keyboard-col"
          *ngIf="getModifiedKey(key)"
          [key]="getModifiedKey(key)"
          [active]="isActive(key)"
          [input]="inputInstance | async"
          [control]="$any(control)"
          (enterClick)="onEnterClick()"
          (capsClick)="onCapsClick()"
          (altClick)="onAltClick()"
          (shiftClick)="onShiftClick()"></qt-keyboard-key>
      </ng-container>
    </div>
  </nav>
</div>
