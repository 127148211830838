<!-- DESKTOP -->
<ng-container *ngIf="mini; else mobile">
  <ng-container *ngTemplateOutlet="nav"></ng-container>
  <span fxFlex></span>
  <footer>
    <ng-template [ngTemplateOutlet]="user"></ng-template>
    <a
      mat-icon-button
      [@trigger]="sidenav.opened"
      (click)="sidenav.toggle()"
      [matTooltip]="
        (sidenav.opened ? 'FTR_MENU_COLLAPSE' : 'FTR_MENU_EXPAND') | translate
      "
      matTooltipPosition="after">
      <mat-icon svgIcon="right-arrow"></mat-icon>
    </a>
  </footer>
</ng-container>

<!-- Mobile sidenav -->
<ng-template #mobile>
  <ng-container *ngTemplateOutlet="nav"></ng-container>
  <span fxFlex></span>
  <ng-template [ngTemplateOutlet]="user"></ng-template>
</ng-template>

<!-- nav reference -->
<ng-template #nav>
  <mat-toolbar class="mat-toolbar--small">
    <div fxLayout="column" fxLayoutAlign="space-around center">
      <a routerLink="/" (click)="sidenav.opened && sidenav.close()">
        <mat-icon class="sidenav-mini__logo" svgIcon="main-logo"></mat-icon>
      </a>
    </div>
    <h1 *ngIf="!mini || sidenav.opened" class="sidenav-mini__mobile-title">
      {{ mobileTitle | async | translate }}
    </h1>
  </mat-toolbar>
  <mat-nav-list class="nav">
    <ng-container *ngFor="let menuEntry of menu | async; index as i">
      <p class="nav__header mat-subtitle-2" *ngIf="!mini || sidenav.opened">
        {{ menuEntry.title | translate }}
      </p>
      <a
        mat-list-item
        [ngClass]="!mini || sidenav.opened ? 'nav__link-opened' : 'nav__link'"
        *ngFor="let subMenu of menuEntry.children"
        routerLink="/{{ subMenu.url }}"
        routerLinkActive="active-list-item"
        [routerLinkActiveOptions]="!subMenu.url ? { exact: true } : $any({})"
        (click)="sidenav.opened && sidenav.close()"
        [matTooltipDisabled]="!mini"
        matTooltip="{{ menuEntry.title | translate }}: {{
          subMenu.title | translate
        }}"
        matTooltipPosition="right"
        matTooltipShowDelay="100"
        style="width: inherit !important">
        <mat-icon
          matListItemIcon
          *ngIf="subMenu.icon.ent === 'google'; else customIcon">
          {{ subMenu.icon.name }}
        </mat-icon>

        <ng-template #customIcon>
          <mat-icon matListItemIcon [svgIcon]="subMenu.icon.name"></mat-icon>
        </ng-template>

        <p
          class="nav__link__title"
          matListItemTitle
          *ngIf="!mini || sidenav.opened">
          {{ subMenu.title | translate }}
        </p>
      </a>
    </ng-container>
  </mat-nav-list>
</ng-template>

<ng-template #user>
  <qt-avatar-menu
    *ngIf="!media.isActive('gt-xs')"
    [isPersonalAccount]="personalAccount$ | async"
    [sidenav]="sidenav"></qt-avatar-menu>
</ng-template>
