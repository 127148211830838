/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from '@qtek/shared/models';

export const VERSION = new Version('1.34.24');

export const BUNDLE: Bundle = {
  date: 1731692288263,
  commitSha: 'c10a309760f962c007c9a7df68c6b217b3555ca4',
  version: VERSION,
};
