import { ExtractedObject } from '@qtek/shared/models';

export function extractKeys(
  payload: Record<string, unknown>
): ExtractedObject[] {
  return Object.keys(payload).map(id => ({
    id,
    name: (payload as any)[id],
  }));
}
