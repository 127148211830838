import { EntityMeta } from '@qtek/shared/models';
import { DealActionTypes, DealsActions } from './deals.actions';

export interface DealsState {
  deals: Record<string, any[]>;
  dealsMeta: EntityMeta;
}

const initialState: DealsState = {
  deals: {},
  dealsMeta: new EntityMeta(),
};

export function dealsReducer(
  state = initialState,
  action: DealsActions
): DealsState {
  const { type, payload } = action;
  switch (type) {
    case DealActionTypes.GET_DEALS_SUCCESS: {
      return {
        ...state,
        deals: {
          ...state.deals,
          [payload.mysid]: payload.deals,
        },
        dealsMeta: payload.meta || { ...state.dealsMeta },
      };
    }
    case DealActionTypes.CREATE_DEAL_SUCCESS: {
      return {
        ...state,
        deals: {
          ...state.deals,
          [payload.mysid]: [
            ...(state.deals[payload.mysid] || []),
            payload.deal,
          ],
        },
      };
    }
    case DealActionTypes.DELETE_DEAL_SUCCESS: {
      return {
        ...state,
        deals: {
          ...state.deals,
          [payload.mysid]: [
            ...(state.deals[payload.mysid] || []).filter(
              deal => deal._id !== payload.id
            ),
          ],
        },
      };
    }

    case DealActionTypes.UPDATE_DEAL_SUCCESS: {
      const newArr = state.deals[payload.mysid].slice();
      const index = newArr.findIndex(
        (deal: any) => deal._id === payload.deal._id
      );
      newArr[index] = payload.deal;

      return {
        ...state,
        deals: {
          ...state.deals,
          [payload.mysid]: newArr,
        },
      };
    }

    // -----------------------------
    // DEFAULT

    default: {
      return state;
    }
  }
}

export const getDeals = (state: DealsState) => state.deals;
export const getDealsMeta = (state: DealsState) => state.dealsMeta;