import { Environment } from '@qtek/shared/models';

export const environment: Environment = {
  production: false,
  title: 'synply-dev',
  rest: {
    protocol: 'https',
    hostname: 'api.dev.synply.click',
    path: '',
  },
  websocket: {
    protocol: 'wss',
    hostname: 'wsapi.dev.synply.click',
    path: '',
  },
  gtagId: 'GTM-W48L2BH',
  debugTranslations: true,
};
