import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  ValidationErrors,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { TranslationCoreService } from '@qtek/libs/translation-core';
import { BehaviorSubject, EMPTY, Observable, merge } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

const KEYS = {
  min: 'ERR_MIN_VALUE',
  max: 'ERR_MAX_VALUE',
  required: 'ERR_FIELD_REQUIRED',
  email: 'ERR_WRONG_EMAIL',
  minlength: 'ERR_MIN_LENGTH',
  maxlength: 'ERR_MAX_LENGTH',
  pattern: 'ERR_NOT_MATCH_PATTERN',
  emailOrPhone: 'ERR_WRONG_EMAIL_OR_PHONE',

  // CUSTOM
  mobilePhone: 'ERR_WRONG_PHONE',
  password: 'ERR_USR_INV_PWD',
  passwordsNotMatch: 'ERR_USR_INV_PWDS',
  match: 'ERR_NOT_EQUAL',
  taxId: 'ERR_WRONG_TAX_ID',
  alreadyLinked: 'ERR_REL_EMAIL_LINKED',
  atLeastOne: 'ERR_1_FLD_REQUIRED',
  uniqueEmail: 'ERR_USED_EMAIL',
  integer: 'ERR_WRONG_INT',
  customerNotExists: 'GNR_CNT_UNKNOWN',
  amountToMuch: 'ERR_AMOUNT_TO_MUCH',
  address: 'ERR_ADDRESS_NOT_FOUND',
  checkbox: 'ERR_NOT_CHECKED',
  matDatepickerParse: 'GNR_WRONG_DT',
  time: 'ERR_WRONG_TIME',
  invalidValue: 'ERR_IVALID_INPUT',
  bookingIdExists: 'ERR_BOOKING_ID_EXISTS',
  emailNotMatch: 'ERR_EMAIL_NOT_EQUAL',
  emailMatch: 'ERR_EMAIL_EQUAL',
  emailNoExist: 'ERR_EMAIL_NOEXISTS',
  emailExist: 'ERR_CNT_EXIST',
  avlailabilityRequired: 'ERR_AVL_REQUIRED',
  wrongUrl: 'ERR_WRONG_URL',
  wrongEmailOrPassword: 'ERR_WRONG_EMAIL_OR_PWDS',
};

// tslint:disable no-input-rename
@Component({
  selector: 'mat-error',
  templateUrl: './error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe],
})
export class ErrorComponent implements OnChanges, OnInit {
  /** Is control invalid */
  isInvalid: Observable<boolean>;

  /** Control which will be used to get errors from */
  control$: BehaviorSubject<AbstractControl> = new BehaviorSubject(null);

  /** Stream of error messages. */
  errorMessage: Observable<string>;

  /** Path to form control within form group. */
  @Input() for: string | Array<string | number> = '';

  /** Standalone form control. Overrides `for` input */
  @Input('control') formControl: AbstractControl;

  /** Instance of {@link MatFormFieldControl} which can be used to get status updates. */
  @Input() formFieldControl: MatFormFieldControl<any>;

  /**
   * We are showing only one error at the time.
   *
   * This input allow to override which error is being displayed
   * when there is more than one.
   */
  @Input() key: string;

  constructor(
    @Optional() private form: FormGroupDirective,
    private translate: TranslationCoreService
  ) {}

  ngOnChanges(): void {
    this.control$.next(
      this.formControl
        ? this.formControl
        : this.for
          ? this.form.control.get(this.for)
          : this.form.control
    );
  }

  ngOnInit() {
    this.errorMessage = merge(
      this.control$,
      this.control$.pipe(switchMap(control => control.statusChanges)),
      this.formFieldControl ? this.formFieldControl.stateChanges : EMPTY,
      this.translate.selectedLang$
    ).pipe(switchMap(() => this.getMessage(this.control$.value.errors)));

    this.isInvalid = this.control$.pipe(
      switchMap(control => control.statusChanges.pipe(startWith('INVALID'))),
      map(status => status === 'INVALID')
    );
  }

  /**
   * Get error message based on provided {@link ValidationErrors}.
   *
   * @param errors Object with errors.
   */
  private getMessage(errors: ValidationErrors) {
    if (!errors) {
      return EMPTY;
    }

    /**
     * Get translation code for provided key if it exists,
     * otherwise get translation code for first error.
     */
    const errorKey = this.key in errors ? this.key : Object.keys(errors)[0];
    const key = (KEYS as any)[errorKey] || 'ERR_IVALID_INPUT';
    const params = this.normalizeParams(errorKey, errors[errorKey]);

    return this.translate.getTranslation(key, {
      lang: this.translate.selectedLang,
      params: params,
    }) as Observable<string>;
  }

  /**
   * Transform error options to format which parser can understand.
   *
   * @param errorKey Name of error.
   * @param errorConfig Config provided with the error.
   */
  private normalizeParams(errorKey: string, errorConfig: any) {
    switch (errorKey) {
      case 'min': {
        return [errorConfig.min, errorConfig.actual];
      }

      case 'max': {
        return [errorConfig.max, errorConfig.actual];
      }

      case 'maxlength':
      case 'minlength': {
        return [errorConfig.requiredLength, errorConfig.actualLength];
      }

      case 'pattern': {
        return [errorConfig.requiredPattern, errorConfig.actualValue];
      }

      default: {
        return typeof errorConfig === 'object' ? errorConfig : undefined;
      }
    }
  }
}
