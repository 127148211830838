import {
  CalendarIntegrationsVendor,
  DebtXProfile,
  IntegratedCalendar,
  MicrosoftInfo,
  SalesforceInfo,
} from '@qtek/shared/models';
import {
  IntegrationsActions,
  IntegrationsActionTypes,
} from './integrations.actions';

export interface State {
  calendars?: CalendarIntegrationsVendor[];
  appleCalendar?: IntegratedCalendar;
  googleCalendar?: IntegratedCalendar;
  microsoft?: MicrosoftInfo;
  salesforce?: SalesforceInfo;
  debtx?: DebtXProfile;
  clover?: { expStr: string };
}

const initialState: State = {};

export function reducer(
  state = initialState,
  action: IntegrationsActions
): State {
  switch (action.type) {
    case IntegrationsActionTypes.GET_CLOVER_SUCCESS: {
      return {
        ...state,
        clover: action.payload,
      };
    }

    case IntegrationsActionTypes.GET_SALESFORCE_SUCCESS: {
      return {
        ...state,
        salesforce: action.payload,
      };
    }

    case IntegrationsActionTypes.GET_MICROSOFT_SUCCESS: {
      return {
        ...state,
        microsoft: action.payload,
      };
    }

    case IntegrationsActionTypes.GET_DEBTX_PROFILE_SUCCESS: {
      return {
        ...state,
        calendars: (state.calendars || []).filter(
          calendar => calendar !== CalendarIntegrationsVendor.DEBTX
        ),
        debtx:
          action.status === 'DEBTX_CONNECTED'
            ? action.data
            : ({} as DebtXProfile),
      };
    }

    case IntegrationsActionTypes.GET_CALENDARS_SUCCESS: {
      return {
        ...state,
        calendars: action.payload || [],
      };
    }

    case IntegrationsActionTypes.UPDATE_CALENDAR_SUCCESS:
    case IntegrationsActionTypes.CONNECT_CALENDAR_SUCCESS:
    case IntegrationsActionTypes.GET_CALENDAR_SUCCESS: {
      if (!action.payload) {
        return state;
      }

      const calendars = (state.calendars || []).slice();

      if (!calendars.some(cal => cal === action.payload.vnd)) {
        calendars.push(action.payload.vnd);
      }

      switch (action.payload.vnd) {
        case CalendarIntegrationsVendor.APPLE:
          return {
            ...state,
            appleCalendar: action.payload,
            calendars,
          };

        case CalendarIntegrationsVendor.GOOGLE:
          return {
            ...state,
            googleCalendar: action.payload,
            calendars,
          };
      }
      return state;
    }

    case IntegrationsActionTypes.CALENDAR_AUTH_ERROR:
    case IntegrationsActionTypes.DISCONNECT_CALENDAR_SUCCESS: {
      if (!action.payload) {
        return state;
      }

      const vendor = action.payload;
      const calendars = (state.calendars || [])
        .slice()
        .filter((calendar: CalendarIntegrationsVendor) => calendar !== vendor);

      switch (vendor) {
        case CalendarIntegrationsVendor.APPLE:
          return {
            ...state,
            appleCalendar: null,
            calendars,
          };

        case CalendarIntegrationsVendor.GOOGLE:
          return {
            ...state,
            googleCalendar: null,
            calendars,
          };
        case CalendarIntegrationsVendor.MICROSOFT:
          return {
            ...state,
            microsoft: null,
            calendars,
          };
        case CalendarIntegrationsVendor.SALESFORCE:
          return {
            ...state,
            salesforce: null,
            calendars,
          };
        case CalendarIntegrationsVendor.DEBTX:
          return {
            ...state,
            debtx: null,
            calendars,
          };

        default: {
          return state;
        }
      }
    }

    case IntegrationsActionTypes.CONNECT_DEBTX_SUCCESS: {
      const calendars =
        action.status === 'DEBTX_CONNECTED'
          ? (state.calendars || []).concat([CalendarIntegrationsVendor.DEBTX])
          : (state.calendars || []).filter(
              (calendar: CalendarIntegrationsVendor) =>
                calendar !== CalendarIntegrationsVendor.DEBTX
            );

      return {
        ...state,
        calendars,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAvailableCalendars = (state: State) => state.calendars;
export const getAppleCalendar = (state: State) => state.appleCalendar;
export const getGoogleCalendar = (state: State) => state.googleCalendar;
export const getMicrosoft = (state: State) => state.microsoft;
export const getClover = (state: State) => state.clover;
export const getSalesforce = (state: State) => state.salesforce;
export const getDebtX = (state: State) => state.debtx;
