import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import {
  KeyboardComponent,
  KeyboardContainerComponent,
  KeyboardKeyComponent,
} from './components';
import {
  MAT_KEYBOARD_DEADKEYS,
  MAT_KEYBOARD_ICONS,
  MAT_KEYBOARD_LAYOUTS,
  keyboardDeadkeys,
  keyboardIcons,
  keyboardLayouts,
} from './configs';
import { KeyboardDirective } from './directives';
import { KeyboardService } from './services/keyboard.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    MatCommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    KeyboardContainerComponent,
    KeyboardKeyComponent,
    KeyboardComponent,
    KeyboardDirective,
  ],
  providers: [
    KeyboardService,
    // LIVE_ANNOUNCER_PROVIDER,
    { provide: MAT_KEYBOARD_DEADKEYS, useValue: keyboardDeadkeys },
    { provide: MAT_KEYBOARD_ICONS, useValue: keyboardIcons },
    { provide: MAT_KEYBOARD_LAYOUTS, useValue: keyboardLayouts },
  ],
  exports: [
    KeyboardComponent,
    KeyboardContainerComponent,
    KeyboardKeyComponent,
    KeyboardDirective,
  ],
})
export class KeyboardModule {}
