export enum CalendarIntegrationsVendor {
  APPLE = 'ap',
  GOOGLE = 'gl',
  MICROSOFT = 'ms',
  SALESFORCE = 'sf',
  DEBTX = 'debtx',
}

export interface IntegratedCalendar {
  /**
   * Account id (gmail address or appleID)
   *
   */
  id?: string;

  /**
   * Vendor id of calendar service provider
   *
   */
  vnd?: CalendarIntegrationsVendor;

  /**
   * Id of calendar where calio bookings are synced up (booking calendar)
   *
   */
  bkId?: string;

  /**
   * Value indicating if syncing to external calendar is on or off
   *
   */
  bkSync?: boolean;

  /**
   *  List of calendars available on the account
   *
   */
  cals?: Array<{
    /**
     * Calendar Id
     *
     */
    id: string;

    /**
     * Displayed name of the calendar
     *
     */
    displayName: string;

    /**
     * Value indicating if the calendar can be removed by API client
     *
     */
    removable: boolean;
  }>;

  /**
   * Apple access token
   *
   */
  at?: string;

  /**
   * Synced calendars
   *
   */
  syncedIds?: any[];
}
