export const DialogNames = {
  alert: 'alert',
  confirm: 'confirm',
  imageCrop: 'imageCrop',
  selectPlan: 'selectPlan',
  companyManage: 'company-manage',
  gallery: 'gallery',
  secureConfirm: 'secure-confirm',
  numericKeyboard: 'numeric-keyboard',
  colorPicker: 'color-picker',
  deliveryDialog: 'delivery-dialog',
  createItem: 'create-item',
  employeeDialog: 'employee-dialog',
  inviteContact: 'invite-contact',
  customerInvite: 'customer-invite',
  promptData: 'prompt-data',
  customerDialog: 'customer-dialog',
  noteDialog: 'note-dialog',
  informationDialog: 'information-dialog',
  contactDialog: 'contact-dialog',
  contactPreview: 'contact-preview',
} as const;

export type DialogName = (typeof DialogNames)[keyof typeof DialogNames];
