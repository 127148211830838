<mat-menu #settingsMenu="matMenu" yPosition="above" class="context-menu">
  <ng-template matMenuContent>
    <ng-container *ngIf="isBookingMenu; else lfeMenu">
      <a mat-menu-item (click)="onCollectionOpen()">
        <mat-icon>collections</mat-icon>
        <span> {{ 'GNR_MY_COLLECTION' | translate }} </span>
      </a>
      <a mat-menu-item (click)="onFramesOpen()">
        <mat-icon>apps</mat-icon>
        <span> {{ 'GNR_MY_FRAMES' | translate }} </span>
      </a>
      <a mat-menu-item (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span> {{ 'GNR_LOGOUT' | translate }} </span>
      </a>
    </ng-container>
    <ng-template #lfeMenu>
      <div mat-menu-item fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>account_box</mat-icon>
        <div
          fxLayout="column"
          class="menu-name"
          fxLayoutAlign="none"
          *ngIf="userData$ | async as user">
          <span *ngIf="user.company; else userName" class="menu-name__line">
            {{ user.company }}
          </span>
          <ng-template #userName>
            <span class="menu-name__line">{{ user.name }} </span>
          </ng-template>
          <span class="menu-name__line menu-name__line--small">
            {{ user.email }}
          </span>
        </div>
      </div>
      <!-- <a mat-menu-item routerLink="/stp" (click)="closeSideNave()">
        <mat-icon>settings</mat-icon>
        <span>{{ 'GNR_SETUP' | translate }}</span>
      </a> -->
      <ng-container *ngIf="pwa.canInstall | async">
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="pwa.prompt()">
          <mat-icon>get_app</mat-icon>
          <span>{{ 'FTR_WZD_ADD_TO_DESKTOP' | translate }}</span>
        </button>
      </ng-container>
      <button mat-menu-item [matMenuTriggerFor]="accessibilitySubMenu">
        <mat-icon>accessibility</mat-icon>
        <span>{{ 'GNR_ACCESSIBILITY' | translate }}</span>
      </button>
      <mat-divider></mat-divider>
      <a mat-menu-item (click)="handleLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'GNR_LOGOUT' | translate }}</span>
      </a>
    </ng-template>
  </ng-template>
</mat-menu>
<mat-menu #accessibilitySubMenu="matMenu" class="submenu context-menu">
  <ng-template matMenuContent>
    <div mat-menu-item fxLayout fxLayoutAlign="start center">
      <qt-keyboard-switch></qt-keyboard-switch>
    </div>
    <div mat-menu-item fxLayout fxLayoutAlign="start center">
      <qt-theme-switch></qt-theme-switch>
    </div>
  </ng-template>
</mat-menu>

<div class="nav__avatar-container" fxLayout fxLayoutAlign="start center">
  <button
    mat-icon-button
    class="nav__avatar-button"
    [matMenuTriggerFor]="settingsMenu"
    qtUiTour
    qtUiTourDescription="FTR_TOUR_SETUP_MENU"
    qtUiTourRoutePath="/cal"
    qtUiTourPosition="top"
    [qtUiTourElementFixed]="true"
    [hostElementOnPush]="true"
    aria-label="Open menu">
    <mat-icon class="nav__avatar-icon" *ngIf="!personalAvatarUrl"
      >account_circle</mat-icon
    >
    <img
      *ngIf="personalAvatarUrl"
      class="nav__avatar-img"
      [src]="personalAvatarUrl"
      alt="Avatar" />
  </button>
  <div *ngIf="!isHiddenUserName">
    <div class="user" *ngIf="userData$ | async as user">
      <h3 class="user__title ellipsis" [matTooltip]="user.name">
        {{ user.name }}
      </h3>
      <small class="user__subtitle ellipsis" [matTooltip]="user.company">{{
        user.company
      }}</small>
    </div>
  </div>
</div>
