import { createFeature, createReducer, on } from '@ngrx/store';
import { Customer, EntityMeta, Staff, Vendor } from '@qtek/shared/models';
import { RelationsActions } from './relations.actions';

export interface RelationState {
  customers: Customer[];
  staff: Staff[];
  isStaffLoading: boolean;
  vendors: Vendor[];
  staffMeta: EntityMeta | null;
}

const initialState: RelationState = {
  customers: [],
  staff: [],
  isStaffLoading: false,
  vendors: [],
  staffMeta: null,
};

const relationsReducer = createReducer(
  initialState,
  on(RelationsActions.getStaff, state => ({
    ...state,
    isStaffLoading: true,
  })),
  on(RelationsActions.getStaffSuccess, (state, { payload, meta }) => ({
    ...state,
    staff: payload || [],
    isStaffLoading: false,
    staffMeta: meta || null,
  })),
  on(RelationsActions.getStaffFailure, state => ({
    ...state,
    isStaffLoading: false,
  })),
  on(RelationsActions.createEmployeeSuccess, (state, { payload }) => ({
    ...state,
    staff: [payload].concat(state.staff),
  })),
  on(RelationsActions.deleteEmployeeSuccess, (state, { payload }) => ({
    ...state,
    staff: state.staff.filter(relation => relation.relId !== payload),
  })),
  on(RelationsActions.updateEmployeeSuccess, (state, { payload }) => {
    const newRelations = state.staff.slice();
    const index = newRelations.findIndex(
      relation => relation.prsId === payload.prsId
    );
    newRelations[index] = payload;

    return {
      ...state,
      staff: newRelations,
    };
  }),
  on(RelationsActions.updateContactDetailsSuccess, (state, { payload }) => {
    if (payload.type === 'staff') {
      const index = state.staff.findIndex(
        relation => relation.prsId === payload.data.prsId
      );

      const newRelations = state.staff.slice();

      if (index !== -1) {
        newRelations[index].prs = {
          ...newRelations[index].prs,
          ...payload.data,
        };
      }

      return {
        ...state,
        staff: newRelations,
      };
    } else {
      const index = state.customers.findIndex(
        relation => relation.prs.prsId === payload.data.prsId
      );

      const newRelations = state.customers.slice();

      if (index !== -1) {
        newRelations[index].prs = {
          ...newRelations[index].prs,
          ...payload.data,
        };
      }

      return {
        ...state,
        customers: newRelations,
      };
    }
  }),
  on(RelationsActions.getRelationsDemoSuccess, (state, { payload }) => ({
    ...state,
    customers: state.customers.concat(payload || []),
  }))
);

export const RelationsFeature = createFeature({
  name: 'relations',
  reducer: relationsReducer,
});

export const {
  selectCustomers,
  selectStaff,
  selectIsStaffLoading,
  selectVendors,
  selectStaffMeta,
} = RelationsFeature;
