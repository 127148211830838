import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadCenterDocumentsService {
  entityName: WebsocketEntTypes = 'docref';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  constructor(
    private websocketService: WebSocketService,
    protected http: HttpClient
  ) {}

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }
}
