import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Message } from '@qtek/shared/models';
import { switchMap, map, catchError, of, mergeMap } from 'rxjs';
import { PaymentPlanActions } from './payment-plan.actions';
import { PaymentPlanService } from './payment-plan.service';
import { MenuActions } from '../actions';

@Injectable()
export class PaymentPlanEffects {
  getPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentPlanActions.getPaymentPlans),
      switchMap(({ payload }) =>
        this.planService.getPlans(payload).pipe(
          map(({ res }) =>
            PaymentPlanActions.getPaymentPlansSuccess({ payload: res || [] })
          ),
          catchError(error =>
            of(PaymentPlanActions.getPaymentPlansFailure({ error }))
          )
        )
      )
    )
  );

  updatePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentPlanActions.updatePaymentPlan),
      switchMap(({ payload }) =>
        this.planService.selectPlan(payload).pipe(
          mergeMap(() => this.planService.getPlans('synplyStore')),
          map(({ res }) =>
            PaymentPlanActions.updatePaymentPlanSuccess({
              payload: res || [],
              message: new Message('FTR_PLAN_UPDATED'),
            })
          ),
          catchError(error =>
            of(PaymentPlanActions.updatePaymentPlanFailure({ error }))
          )
        )
      )
    )
  );

  updatePlanSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentPlanActions.updatePaymentPlanSuccess),
      switchMap(() => [MenuActions.loadMain, MenuActions.loadSetup()])
    )
  );

  constructor(
    private actions$: Actions,
    private planService: PaymentPlanService
  ) {}
}
