import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppState, isPersonalAccount } from '@qtek/libs/store';
import { AppBarService, ToolbarConfig } from '@qtek/shared/services';

@Component({
  selector: 'qt-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Output() toggle: EventEmitter<void> = new EventEmitter<void>();

  /** Whether navigation is in mini mode. */
  @Input() mini: boolean;

  /** Hold active toolbar config. */
  toolbarConfig: ToolbarConfig;
  personalAccount$: Observable<boolean>;
  destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    public appBar: AppBarService,
    public media: MediaObserver,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.personalAccount$ = this.store.pipe(select(isPersonalAccount));
    this.appBar.configChange
      .pipe(
        filter(value => !(value === null && this.toolbarConfig === null)),
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        this.toolbarConfig = value;

        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
