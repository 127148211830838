<aside class="dialog" fxLayout="column">
  <mat-toolbar [color]="color" *ngIf="dialogTitle.length">
    <h2 class="dialog__title">
      <ng-content select="[qtDialogTitle]"></ng-content>
    </h2>
    <span fxFlex></span>
    <button
      mat-icon-button
      matDialogClose
      color="primary"
      *ngIf="!disableClose">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content
    #contentDiv
    *ngIf="dialogContent.length"
    [ngClass]="contentClass">
    <ng-content select="[qtDialogContent]"></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout fxLayoutAlign="end" *ngIf="dialogActions.length">
    <ng-content select="[qtDialogActions]"></ng-content>
  </mat-dialog-actions>
</aside>
