import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { APP_ENVIRONMENT } from '@qtek/core/api-core';
import {
  AppState,
  MetaActions,
  selectGuiDomain,
  selectMainMenu,
  selectMobileTitle,
  selectTitle,
} from '@qtek/libs/store';
import { Environment, MenuNode } from '@qtek/shared/models';

const ROTATE_ANIMATION = trigger('trigger', [
  state(
    '0',
    style({
      transform: 'rotate(0deg)',
    })
  ),
  state(
    '1',
    style({
      transform: 'rotate(180deg)',
    })
  ),
  transition('1 <=> 0', animate('250ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

@Component({
  selector: 'qt-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ROTATE_ANIMATION],
  host: {
    class: 'sidenav-mini',
  },
  standalone: false,
})
export class SidenavComponent implements OnInit, OnDestroy {
  /** Whether mini nav variant should be visible. */
  @Input() mini: boolean;

  /** Title of the application. */
  title: Observable<string>;
  mobileTitle: Observable<string>;

  /** App navigation menu. */
  menu: Observable<MenuNode[]> = this.store.select(selectMainMenu);
  destroy$ = new Subject<void>();

  constructor(
    public sidenav: MatSidenav,
    private store: Store<AppState>,
    private iconRegistry: MatIconRegistry,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    private sanitizer: DomSanitizer,
    public media: MediaObserver,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    this.store.dispatch(MetaActions.loadMetaTitle());
  }

  ngOnInit() {
    this.title = this.store.pipe(select(selectTitle));
    this.mobileTitle = this.store.pipe(select(selectMobileTitle));

    this.store
      .pipe(select(selectGuiDomain), filter(Boolean), take(1))
      .subscribe((domain: string) => {
        this.registerLogo(domain);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Register main logo icon.
   */
  private registerLogo(domain: string): void {
    let logo: string;

    if (this.environment.production) {
      logo = `${domain}/assets/img/logo.svg`;
    } else {
      logo = `//${this.document.location.host}/assets/img/logo.svg`;
    }

    const url = this.sanitizer.bypassSecurityTrustResourceUrl(logo);

    this.iconRegistry.addSvgIcon('main-logo', url);
  }
}
