import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import {
  KeyboardDeadkeys,
  KeyboardIcons,
  MAT_KEYBOARD_DEADKEYS,
  MAT_KEYBOARD_ICONS,
} from '../../configs';
import { KeyboardClassKey } from '../../enums';

export const VALUE_NEWLINE = '\n\r';
export const VALUE_SPACE = ' ';
export const VALUE_TAB = '\t';

@Component({
  selector: 'qt-keyboard-key',
  templateUrl: './keyboard-key.component.html',
  styleUrls: ['./keyboard-key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class KeyboardKeyComponent implements OnInit {
  private deadkeyKeys: string[] = [];
  private iconKeys: string[] = [];

  active$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  pressed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input()
  key: string | KeyboardClassKey;

  @Input()
  set active(active: boolean) {
    this.active$.next(active);
  }

  get active(): boolean {
    return this.active$.getValue();
  }

  @Input()
  set pressed(pressed: boolean) {
    this.pressed$.next(pressed);
  }

  get pressed(): boolean {
    return this.pressed$.getValue();
  }

  @Input()
  input?: ElementRef;

  @Input()
  control?: UntypedFormControl;

  @Output()
  genericClick = new EventEmitter<MouseEvent>();

  @Output()
  enterClick = new EventEmitter<MouseEvent>();

  @Output()
  bkspClick = new EventEmitter<MouseEvent>();

  @Output()
  capsClick = new EventEmitter<MouseEvent>();

  @Output()
  altClick = new EventEmitter<MouseEvent>();

  @Output()
  shiftClick = new EventEmitter<MouseEvent>();

  @Output()
  spaceClick = new EventEmitter<MouseEvent>();

  @Output()
  tabClick = new EventEmitter<MouseEvent>();

  @Output()
  keyClick = new EventEmitter<MouseEvent>();

  get lowerKey(): string {
    return `${this.key}`.toLowerCase();
  }

  get charCode(): number {
    return `${this.key}`.charCodeAt(0);
  }

  get isClassKey(): boolean {
    return this.key in KeyboardClassKey;
  }

  get isDeadKey(): boolean {
    return this.deadkeyKeys.some(
      (deadKey: string) => deadKey === `${this.key}`
    );
  }

  get hasIcon(): boolean {
    return this.iconKeys.some((iconKey: string) => iconKey === `${this.key}`);
  }

  get icon(): string {
    return this.icons[this.key];
  }

  get cssClass(): string {
    const classes = [];

    if (this.hasIcon) {
      classes.push('qt-keyboard-key-modifier');
      classes.push(`qt-keyboard-key-${this.lowerKey}`);
    }

    if (this.isDeadKey) {
      classes.push('qt-keyboard-key-deadkey');
    }

    return classes.join(' ');
  }

  get inputValue(): string {
    if (this.control && typeof this.control.value === 'string') {
      return this.control.value;
    } else if (
      this.input &&
      this.input.nativeElement &&
      this.input.nativeElement.value
    ) {
      return this.input.nativeElement.value;
    } else {
      return '';
    }
  }

  set inputValue(inputValue: string) {
    if (this.control) {
      this.control.setValue(inputValue);
    } else if (this.input && this.input.nativeElement) {
      this.input.nativeElement.value = inputValue;
    }
  }

  constructor(
    @Inject(MAT_KEYBOARD_DEADKEYS) private deadkeys: KeyboardDeadkeys,
    @Inject(MAT_KEYBOARD_ICONS) private icons: KeyboardIcons
  ) {}

  ngOnInit() {
    this.deadkeyKeys = Object.keys(this.deadkeys);
    this.iconKeys = Object.keys(this.icons);
  }

  onClick(event: MouseEvent) {
    this.triggerKeyEvent();
    this.genericClick.emit(event);
    const value = this.inputValue;
    const caret = this.input ? this.getCursorPosition() : 0;

    let char: string;
    switch (this.key) {
      case KeyboardClassKey.Alt:
      case KeyboardClassKey.AltGr:
      case KeyboardClassKey.AltLk:
        this.altClick.emit(event);
        break;

      case KeyboardClassKey.Bksp:
        this.deleteSelectedText();
        this.bkspClick.emit(event);
        break;

      case KeyboardClassKey.Caps:
        this.capsClick.emit(event);
        break;

      case KeyboardClassKey.Enter:
        if (this.isTextarea()) {
          char = VALUE_NEWLINE;
        } else {
          this.enterClick.emit(event);
          // TODO: trigger submit / onSubmit / ngSubmit properly (for the time being this has to be handled by the user himself)
          // this.input.nativeElement.form.submit();
        }
        break;

      case KeyboardClassKey.Shift:
        this.shiftClick.emit(event);
        break;

      case KeyboardClassKey.Space:
        char = VALUE_SPACE;
        this.spaceClick.emit(event);
        break;

      case KeyboardClassKey.Tab:
        char = VALUE_TAB;
        this.tabClick.emit(event);
        break;

      default:
        // the key is not mapped or a string
        char = `${this.key}`;
        this.keyClick.emit(event);
        break;
    }

    if (char && this.input) {
      this.replaceSelectedText(char);
      this.setCursorPosition(caret + 1);
    }
  }

  private deleteSelectedText(): void {
    const value = this.inputValue;
    let caret = this.input ? this.getCursorPosition() : 0;
    let selectionLength = this.getSelectionLength();
    if (selectionLength === 0) {
      if (caret === 0) {
        return;
      }

      caret--;
      selectionLength = 1;
    }

    if (value?.slice) {
      const headPart = value.slice(0, caret);
      const endPart = value.slice(caret + selectionLength);
      this.inputValue = [headPart, endPart].join('');
    }

    this.setCursorPosition(caret);
  }

  private replaceSelectedText(char: string): void {
    const value = this.inputValue;
    const caret = this.input ? this.getCursorPosition() : 0;
    const selectionLength = this.getSelectionLength();

    if (value?.slice) {
      const headPart = value.slice(0, caret);
      const endPart = value.slice(caret + selectionLength);

      this.inputValue = [headPart, char, endPart].join('');
    }
  }

  private triggerKeyEvent(): Event {
    const keyboardEvent = new KeyboardEvent('keydown');

    return keyboardEvent;
  }

  private getCursorPosition(): number {
    if (!this.input) {
      return null;
    }

    if ('selectionStart' in this.input.nativeElement) {
      // Standard-compliant browsers
      return this.input.nativeElement.selectionStart;
    } else if ('selection' in window.document) {
      // IE
      this.input.nativeElement.focus();
      const sel = (window.document['selection'] as any).createRange();
      const selLen = (window.document['selection'] as any).createRange().text
        .length;
      sel.moveStart('character', -this.control.value.length);

      return sel.text.length - selLen;
    }

    return null;
  }

  private getSelectionLength(): number {
    if (!this.input) {
      return null;
    }

    if ('selectionEnd' in this.input.nativeElement) {
      // Standard-compliant browsers
      return (
        this.input.nativeElement.selectionEnd -
        this.input.nativeElement.selectionStart
      );
    }

    if ('selection' in window.document) {
      // IE
      this.input.nativeElement.focus();

      return (window.document['selection'] as any).createRange().text.length;
    }
    return null;
  }

  private setCursorPosition(position: number): boolean {
    if (!this.input) {
      return null;
    }

    this.inputValue = this.control?.value;

    if ('createTextRange' in this.input.nativeElement) {
      const range = this.input.nativeElement.createTextRange();
      range.move('character', position);
      range.select();
      return true;
    } else {
      if (
        this.input.nativeElement.selectionStart ||
        this.input.nativeElement.selectionStart === 0
      ) {
        this.input.nativeElement.focus();
        this.input.nativeElement.setSelectionRange(position, position);
        return true;
      } else {
        this.input.nativeElement.focus();
        return false;
      }
    }
  }

  private isTextarea(): boolean {
    return (
      this.input &&
      this.input.nativeElement &&
      this.input.nativeElement.tagName === 'TEXTAREA'
    );
  }
}
