import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';

@Injectable({
  providedIn: 'root',
})
export class DocumentActivityService {
  constructor(
    protected http: HttpClient,
    private websocketService: WebSocketService
  ) {}
  entityName: WebsocketEntTypes = 'docacs';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }
}
