import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  WEBSOCKET_BASE_URL,
  WebSocketService,
} from '@qtek/core/websockets-core';
import { HealthcheckCoreService } from '@qtek/libs/healthcheck-core';
import { MetaCoreFeature } from '@qtek/libs/meta-core';
import {
  AuthActions,
  AuthFeature,
  AuthState,
  MenuActions,
  UserActions,
} from '@qtek/libs/store';
import { WsErrorHandlerService } from '@qtek/libs/ws-error-handler';
import { isNonNullable } from '@qtek/shared/utils';
import { Observable, of, pipe } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AuthState>,
    private actions$: Actions,
    private wsService: WebSocketService,
    private healthCheckCoreService: HealthcheckCoreService,
    @Inject(WEBSOCKET_BASE_URL) private websocketBaseUrl: string,
    private wsErrorHandler: WsErrorHandlerService
  ) {}

  connectToWebsocket() {
    this.store.dispatch(AuthActions.getWSInitToken());

    return this.actions$.pipe(
      ofType(
        AuthActions.getWSInitTokenSuccess,
        AuthActions.getWSInitTokenError
      ),
      take(1),
      mergeMap(action => {
        if (action.type === AuthActions.getWSInitTokenError.type) {
          return of(null);
        }
        return of(action);
      }),
      mergeMap(() => this.store.select(AuthFeature.selectWsInitToken)),
      tap(wsTkn => {
        // reconnect ws with tkn
        this.wsService.initWebsocketConnection(
          this.store
            .select(MetaCoreFeature.selectWsPingPong)
            .pipe(filter(isNonNullable)),
          this.wsErrorHandler.errorsHandler.bind(this.wsErrorHandler),
          { wsTkn }
        );
        this.healthCheckCoreService.registerCheckHealthSubscription(
          this.store
            .select(MetaCoreFeature.selectWsPingPong)
            .pipe(filter(isNonNullable)),
          of('/api/v1/service/meta')
        );
      })
    );
  }

  getCompanies() {
    return pipe(
      tap(() =>
        this.store.dispatch(
          UserActions.getCurrentUserCompanies({ payload: {} })
        )
      ),
      mergeMap(() =>
        this.actions$.pipe(
          ofType(
            UserActions.getCurrentUserCompaniesSuccess,
            UserActions.getCurrentUserCompaniesFailure
          ),
          take(1)
        )
      ),
      map(() => true)
    );
  }

  handleUserAction(state: RouterStateSnapshot, route: ActivatedRouteSnapshot) {
    return pipe(
      mergeMap(
        (
          action:
            | ReturnType<typeof UserActions.getCurrentUserFailure>
            | ReturnType<typeof UserActions.getCurrentUserSuccess>
            | ReturnType<typeof UserActions.getCurrentUserMultiCompany>
        ) => {
          const initWebsocketConnection = this.websocketBaseUrl
            ? this.connectToWebsocket()
            : of(null);
          if (action.type === UserActions.getCurrentUserFailure.type) {
            const returnUrl = state.url !== '/' ? state.url : '';
            const queryParams = returnUrl ? { returnUrl } : null;

            this.store.dispatch(
              AuthActions.logout({ queryParams: { ...queryParams } })
            );
            return of(false);
          }

          return initWebsocketConnection.pipe(this.getCompanies());
        }
      )
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.store.dispatch(
      UserActions.getCurrentUser({
        payload: { skipRevalidateErrorHandling: true },
      })
    );
    /** Fetch app navigation. */
    this.store.dispatch(MenuActions.loadMain());

    return this.actions$.pipe(
      ofType(
        UserActions.getCurrentUserFailure,
        UserActions.getCurrentUserSuccess,
        UserActions.getCurrentUserMultiCompany
      ),
      take(1),
      this.handleUserAction(state, route),
      mergeMap(() =>
        this.wsService.init$.pipe(
          take(1),
          map(() => true)
        )
      )
    );
  }
}
