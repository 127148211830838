import { ItemSchemaMeta, SchemaSidProperty } from '@qtek/shared/models';
import {
  ProductSchemasActions,
  SchemaActionTypes,
} from './product-schemas.actions';

export interface State {
  productSchemaViews: { [key: string]: any };
  productSchemasMeta: ItemSchemaMeta;
  schemasSid: { [key: string]: SchemaSidProperty[] };
}

function getLocalStorageSids(): { [key: string]: SchemaSidProperty[] } {
  const localStorageSids = localStorage.getItem('schemaSids');
  return localStorageSids ? JSON.parse(localStorageSids) : {};
}

function updateLocalStorageSids(sids: { [key: string]: SchemaSidProperty[] }) {
  return localStorage.setItem('schemaSids', JSON.stringify(sids));
}

const initialState: State = {
  productSchemaViews: {},
  productSchemasMeta: null,
  // TODO: implement correctly with clean on version change
  // schemasSid: getLocalStorageSids(),
  schemasSid: {},
};

export function reducer(
  state = initialState,
  { type, payload }: ProductSchemasActions
): State {
  switch (type) {
    case SchemaActionTypes.GET_SCHEMAS_META_SUCCESS: {
      return {
        ...state,
        productSchemasMeta: payload || null,
      };
    }

    case SchemaActionTypes.GET_SCHEMA_VIEW_SUCCESS: {
      return {
        ...state,
        productSchemaViews: {
          ...state.productSchemaViews,
          [payload.query]: payload.view,
        },
      };
    }

    case SchemaActionTypes.GET_SCHEMA_SIDS_SUCCESS: {
      const newSchemasSid = { ...state.schemasSid, ...payload };
      // TODO: implement correctly with clean on version change
      // updateLocalStorageSids(newSchemasSid);
      return {
        ...state,
        schemasSid: newSchemasSid,
      };
    }

    default: {
      return state;
    }
  }
}

export const getSchemaViews = (state: State) => state.productSchemaViews;
export const getSchemasMeta = (state: State) => state.productSchemasMeta;
export const getSchemasSids = (state: State) => state.schemasSid;
