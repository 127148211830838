<qt-dialog>
  <qt-dialog-title>
    <ng-container *ngIf="!edit">{{
      'GNR_ADD_EMPLOYEE' | translate
    }}</ng-container>
    <ng-container *ngIf="edit">{{ 'CMD_EDIT' | translate }}</ng-container>
  </qt-dialog-title>
  <qt-dialog-content class="customer-form__content">
    <form class="customer-form" novalidate fxLayout="column">
      <div fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
        <mat-icon class="material-icon-gray" *ngIf="media.isActive('gt-sm')"
          >email</mat-icon
        >
        <mat-form-field fxFlex>
          <input
            qtKeyboard
            qtInputScroll
            cdkFocusInitial
            matInput
            type="text"
            [placeholder]="'GNR_EMAIL' | translate"
            [formControl]="email"
            (blur)="checkEmail(email)"
            autocapitalize="none"
            [required]="!edit" />
          <mat-error [control]="email"></mat-error>
        </mat-form-field>
      </div>
      <section>
        <div
          fxLayout.gt-xs="row"
          fxLayout="column"
          fxLayoutGap="0px"
          fxLayoutGap.gt-xs="1rem"
          fxLayoutAlign.gt-xs="start center"
        >
          <mat-icon class="material-icon-gray" *ngIf="media.isActive('gt-sm')"
            >swap_horiz</mat-icon
          >
          <mat-form-field fxFlex>
            <mat-select
              [required]="!edit"
              [placeholder]="'GNR_OCCUPATION' | translate"
              [formControl]="roles">
              <mat-option *ngFor="let role of roles$ | async" [value]="role.id">
                {{ role.name | translate }}
              </mat-option>
            </mat-select>
            <mat-error [control]="roles"></mat-error>
          </mat-form-field>
        </div>
      </section>
      <div *ngIf="edit" fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
        <mat-icon class="material-icon-gray" *ngIf="media.isActive('gt-sm')"
        >person_check</mat-icon>
        <mat-checkbox [formControl]="active">{{ 'GNR_ACTIVE' | translate }}</mat-checkbox>
      </div>
    </form>
    <mat-expansion-panel
      #panel
      *ngIf="!edit && !link"
      class="mat-elevation-z0 language-form"
      [hideToggle]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="material-icon-gray language-form__indicator">
            <mat-icon *ngIf="panel.expanded; else collapsed"
              >keyboard_arrow_up</mat-icon
            >
            <ng-template #collapsed>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </ng-template>
          </div>
          <span
            >{{ 'GNR_LANG_PREF' | translate }} ({{
              currentLanguageName | async
            }})</span
          >
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-select
          [placeholder]="'GNR_LNG_PREFERED' | translate"
          [formControl]="inviteLanguage">
          <mat-option
            *ngFor="let languge of languages$ | async"
            [value]="languge.id">
            {{ languge.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>
  </qt-dialog-content>
  <qt-dialog-actions>
    <button mat-button matDialogClose>{{ 'GNR_CANCEL' | translate }}</button>
    <button mat-button (click)="onSubmit()">
      <ng-container *ngIf="!edit; else editSubmit">{{
        'GNR_INVITE' | translate
      }}</ng-container>
      <ng-template #editSubmit>{{ 'CMD_SAVE' | translate }}</ng-template>
    </button>
  </qt-dialog-actions>
</qt-dialog>
