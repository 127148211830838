import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { UploadCenterDocumentsService } from './documents.service';
import { ETLMapService } from './etl-map.service';
import {
  DeleteUploadCenterDocumentFailureAction,
  DeleteUploadCenterDocumentSuccessAction,
  GetETLSuccessAction,
  GetUploadCenterDocumentsFailureAction,
  GetUploadCenterDocumentsSuccessAction,
  UpdateUploadCenterDocumentsFailureAction,
  UpdateUploadCenterDocumentsSuccessAction,
  UploadCenterActionTypes,
  UploadUploadCenterDocumentsFailureAction,
  UploadUploadCenterDocumentsSuccessAction,
} from './upload-center.actions';

@Injectable()
export class UploadCenterEffects {
  connectDocs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadCenterActionTypes.SUBSCRIBE_DOCUMENTS),
      switchMap(({ payload }: any) =>
        this.documentsService.subscribe({ ...payload }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(UploadCenterActionTypes.UNSUBSCRIBE_DOCUMENTS),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ op, res, sts, mysid, id }: any) => {
        console.log(op, res, sts);

        switch (op) {
          case 'query':
            return res
              ? new GetUploadCenterDocumentsSuccessAction({ res, mysid })
              : new GetUploadCenterDocumentsFailureAction(sts);
          case 'ins':
            return res
              ? new UploadUploadCenterDocumentsSuccessAction({ res, mysid })
              : new UploadUploadCenterDocumentsFailureAction(sts);
          case 'del':
            return res
              ? new DeleteUploadCenterDocumentSuccessAction({ id, mysid })
              : new DeleteUploadCenterDocumentFailureAction(sts);
          case 'upd':
            return res
              ? new UpdateUploadCenterDocumentsSuccessAction({ res, mysid })
              : new UpdateUploadCenterDocumentsFailureAction(sts);
          default:
            return {
              type: '[UploadCenter] Unknown operation',
            };
        }
      })
    )
  );

  // ETL MAP

  connectETL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadCenterActionTypes.SUBSCRIBE_ETL_MAP),
      mergeMap(({ payload }: any) =>
        this.etlmapService.subscribe(payload).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(UploadCenterActionTypes.UNSUBSCRIBE_ETL_MAP),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'query':
            return new GetETLSuccessAction(res);

          default:
            return {
              type: '[UploadCenter] Unknown ETL map operation',
            };
        }
      })
    )
  );

  queryEtlMap$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UploadCenterActionTypes.GET_ETL_MAP),
        tap(({ payload }) => this.etlmapService.getEtlMapWS())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private documentsService: UploadCenterDocumentsService,
    private etlmapService: ETLMapService
  ) {}
}
