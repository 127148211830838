import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import { WebSocketService, passWhenAlive } from '@qtek/core/websockets-core';

import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { AppState, getMainCompany } from '../index';
import * as ClubActions from './club.actions';
import { ClubService } from './club.service';

@Injectable()
export class ClubEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private clubService: ClubService,
    private webSocketService: WebSocketService
  ) {}

  //   ---------------------- CLUB ----------------------

  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClubActions.ClubActionTypes.CONNECT_CLUBS),
      mergeMap(() => this.store.pipe(select(getMainCompany), take(1))),
      mergeMap(acn =>
        this.clubService
          .subscribe({
            prms: { acnId: acn?._id },
            mysid: this.clubService.myClubSID,
          })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(ClubActions.ClubActionTypes.DISCONNECT_CLUBS)
              )
            )
          )
      ),
      map(({ res, op, sts }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new ClubActions.GetClubsSuccessAction(res || [], sts.meta)
              : new ClubActions.GetClubsFailureAction(sts);
          case 'ins':
            return new ClubActions.CreateClubSuccessAction(res);
          case 'upd':
            return new ClubActions.UpdateClubWSSuccessAction(res);
          case 'del':
            return new ClubActions.DeleteClubSuccessAction(res);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  clubs$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClubActions.ClubActionTypes.GET_CLUBS),
        tap((action: ClubActions.GetClubsAction) =>
          this.clubService.getClubs(action.role)
        )
      ),
    { dispatch: false }
  );

  createClub$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ClubActions.CreateClubAction>(
          ClubActions.ClubActionTypes.CREATE_CLUB
        ),
        switchMap<ClubActions.CreateClubAction, Observable<any>>(
          ({ payload }) => this.clubService.addClub(payload)
        )
      ),
    { dispatch: false }
  );

  updateClub$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ClubActions.UpdateClubAction>(
        ClubActions.ClubActionTypes.UPDATE_CLUB
      ),
      switchMap<ClubActions.UpdateClubAction, Observable<any>>(({ payload }) =>
        this.clubService.updateClub(payload).pipe(
          map(({ res }: any) => new ClubActions.UpdateClubSuccessAction(res)),
          catchError(() => of(new ClubActions.UpdateClubFailureAction()))
        )
      )
    )
  );

  deleteClub$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ClubActions.DeleteClubAction>(
          ClubActions.ClubActionTypes.DELETE_CLUB
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap<ClubActions.DeleteClubAction>(({ payload }) =>
          this.clubService.removeClub(payload)
        )
      ),
    { dispatch: false }
  );

  //   ---------------------- CLUB MEMBERS ----------------------

  connectClubMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClubActions.ClubActionTypes.CONNECT_CLUB_MEMBERS),
      mergeMap(({ payload }) =>
        this.clubService
          .subscribe({ prms: payload, mysid: this.clubService.clubMembersSID })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(ClubActions.ClubActionTypes.DISCONNECT_CLUB_MEMBERS)
              )
            )
          )
      ),
      map((data: any) => {
        switch (data.op) {
          case 'query':
            return new ClubActions.GetClubMembersSuccessAction(data.res || []);
          case 'ins':
            return new ClubActions.CreateClubMemberSuccessAction(data.res);
          case 'upd':
            return new ClubActions.UpdateClubMemberSuccessAction(data.res);
          case 'del':
            return new ClubActions.DeleteClubMemberSuccessAction(data.res);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  getClubMembers$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClubActions.ClubActionTypes.GET_CLUB_MEMBERS),
        tap(({ payload }) => this.clubService.getClubMembers(payload))
      ),
    { dispatch: false }
  );

  createClubMember$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ClubActions.CreateClubMemberAction>(
          ClubActions.ClubActionTypes.CREATE_CLUB_MEMBER
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap<ClubActions.CreateClubMemberAction>(({ payload }) =>
          this.clubService.addClubMember(payload)
        )
      ),
    { dispatch: false }
  );

  updateClubMember$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ClubActions.UpdateClubMemberAction>(
          ClubActions.ClubActionTypes.UPDATE_CLUB_MEMBER
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap<ClubActions.UpdateClubMemberAction>(({ payload }) =>
          this.clubService.updateClubMember(payload)
        )
      ),
    { dispatch: false }
  );

  deleteClubMember$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ClubActions.DeleteClubMemberAction>(
          ClubActions.ClubActionTypes.DELETE_CLUB_MEMBER
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap<ClubActions.DeleteClubMemberAction>(({ payload }) =>
          this.clubService.removeClubMember(payload)
        )
      ),
    { dispatch: false }
  );
}
