import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  MenuNode,
  DashboardTiles,
  DashboardTileDataset,
} from '@qtek/shared/models';

export const MenuActions = createActionGroup({
  source: 'Menu',
  events: {
    'Load Main': emptyProps(),
    'Load Main Success': props<{ payload: MenuNode[] }>(),
    'Load Wizard': emptyProps(),
    'Load Wizard Success': props<{ payload: MenuNode[] }>(),
    'Load Setup': emptyProps(),
    'Load Setup Success': props<{ payload: MenuNode[] }>(),
    'Load Dashboard': emptyProps(),
    'Load Dashboard Success': props<{ payload: DashboardTiles }>(),
    'Connect Dashboard': props<{ payload: string }>(),
    'Disconnect Dashboard': props<{ payload: string }>(),
    'Update Chart Dataset': props<{
      payload: { data: DashboardTileDataset[]; id: string };
    }>(),
    'Wizard Step': props<{ payload: string }>(),
  },
});
