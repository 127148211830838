import { Injectable } from '@angular/core';
import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  UpdWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PowerSearchService {
  constructor(private websocketService: WebSocketService) {}

  entityName: WebsocketEntTypes = 'mta';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  updateMeta(payload: Omit<UpdWebsocketMessageRequest, 'ent' | 'op'>) {
    this.wsManager.update(payload);
  }
}
