<mat-sidenav-container fullscreen>
  <!--
    Main sidenav. We change its zIndex because when dynamic sidenav is opened, backdrop
    would not cover main sidenav(which is in closed state). User can't click on main sidenav
    items while dynamic sidenav is opened.
  -->
  <mat-sidenav
    #sidenav
    mode="over"
    [autoFocus]="false"
    [class.mat-drawer-mini]="mini$ | async"
    [@.disabled]="mini$ | async"
    qtUiTour
    qtUiTourDescription="FTR_TOUR_NAV_MENU"
    qtUiTourRoutePath="/cal"
    qtUiTourPosition="right"
    [qtUiTourElementFixed]="true"
    [style.zIndex]="dynamicSidenav.opened ? 2 : null">
    <qt-sidenav [mini]="mini$ | async"></qt-sidenav>
  </mat-sidenav>

  <!-- Secondary sidenav that can be used to display dynamic content. -->
  <mat-sidenav #dynamicSidenav mode="over" position="end" [autoFocus]="false">
    <ng-template cdkPortalOutlet></ng-template>
  </mat-sidenav>

  <!-- MAIN CONTENT -->
  <mat-sidenav-content class="layout__wrapper">
    <qt-toolbar
      class="layout__toolbar"
      (toggleToolbar)="sidenav.toggle()"
      [mini]="mini$ | async"></qt-toolbar>
    <qt-network-error [error]="networkError$ | async"></qt-network-error>
    <main
      class="layout__content"
      [ngStyle]="{
        'background-color': router.url === '/' ? '#E7EFFD' : '#fff',
      }"
      #contentDiv>
      <div #childDiv>
        <router-outlet></router-outlet>
      </div>
      <span
        *ngIf="uiTourService.showButtonObservable | async"
        (click)="uiTourService.openTour(tutorialTemplateRef)"
        class="tour-button"
        [matTooltip]="'FTR_TOUR_CLICK_OPEN' | translate">
        <mat-icon aria-label="Open tutorial" class="tour-button__icon"
          >help_outline</mat-icon
        >
      </span>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- MOVE TO COMPONENT -->
<ng-template #tutorialTemplateRef>
  <div id="tour-overlay"></div>
  <mat-card class="ui-guide-step">
    <div fxLayout fxLayoutAlign="end">
      <button mat-icon-button (click)="finishUiTour()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-card-content class="mb-0 pt-1" fxLayout fxLayoutAlign="start center">
      <div id="ui-guide-step__content"></div>
      <img id="ui-guide-step__content-picture" />
    </mat-card-content>
    <mat-card-actions fxLayout fxLayoutAlign="end center">
      <div fxFlex class="ui-guide-step__counter">
        {{ actualStepNumber }} / {{ maxStepNumber }}
      </div>
      <button mat-button (click)="back()" *ngIf="actualStepNumber !== 1">
        <mat-icon>keyboard_arrow_left</mat-icon>
        {{ 'CMD_BACK' | translate }}
      </button>
      <button
        mat-button
        (click)="finishUiTour()"
        *ngIf="actualStepNumber === maxStepNumber; else nextButton">
        {{ 'CMD_FINISH' | translate }}
        <mat-icon>done_all</mat-icon>
      </button>
      <ng-template #nextButton>
        <button mat-button (click)="next()">
          {{ 'CMD_NEXT' | translate }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </ng-template>
    </mat-card-actions>
  </mat-card>
</ng-template>

<!-- <ng-container *ngIf="isUiTourOpen">

</ng-container> -->
