import { ActionReducer } from '@ngrx/store';
import {
  CountryCode,
  FoodType,
  Frequency,
  LanguageOption,
  RelationGroup,
  Role,
  Timezone,
  Unit,
} from '@qtek/shared/models';
import { extractKeys } from '@qtek/shared/utils';
import { MetaActions, MetaActionTypes } from './meta.actions';

export interface State {
  roles: {
    user: Role[];
    vendor: Role[];
    customer: Role[];
    staff: Role[];
  };
  units: Unit[];
  frequencies: Frequency[];
  languages: LanguageOption[];
  title1: string;
  title2: string;
  countryCode?: CountryCode[];
  guiShrDomain: string;
  guiDomain: string;
  timezones?: Timezone[];
  guiOnlineBookDomain: string;
  wsPingPong: number;
  curs?: string[];
  licCmp: string;
  countries: Array<{ id: string; nm: string }>;
  // TODO add interface
  countryInfo: any;
  foodTypes: FoodType[];
  states: {
    [key: string]: string[];
  };
  licWebId: string;
  token?: string;
  darkTheme?: boolean;
  powerSearchMeta: Record<string, any>;
}

const initialState: State = {
  roles: {
    user: [],
    vendor: [],
    customer: [],
    staff: [],
  },
  units: [],
  frequencies: [],
  languages: [],
  title1: '',
  title2: '',
  guiShrDomain: '',
  guiDomain: '',
  guiOnlineBookDomain: '',
  wsPingPong: null,
  licCmp: '',
  licWebId: '',
  countries: [],
  countryInfo: null,
  foodTypes: [],
  states: null,
  powerSearchMeta: {},
};

export const reducer: ActionReducer<State, MetaActions> = (
  state = initialState,
  { type, payload }: MetaActions
): State => {
  switch (type) {
    case MetaActionTypes.LOAD_META_SUCCESS: {
      return {
        ...state,
        ...(payload.unts && { units: extractKeys(payload.unts) as Unit[] }),
        ...(payload.frq && {
          frequencies: extractKeys(payload.frq) as Frequency[],
        }),
        ...(payload.ptlRoleGroups && {
          roles: [
            { id: RelationGroup.USER, name: 'user' },
            { id: RelationGroup.VENDOR, name: 'vendor' },
            { id: RelationGroup.CUSTOMER, name: 'customer' },
            { id: RelationGroup.STAFF, name: 'staff' },
          ].reduce((o: any, role) => {
            o[role.name] = Object.keys(
              payload.ptlRoleGroups[role.id].roles || {}
            ).map(id => ({
              id,
              name: payload.ptlRoleGroups[role.id].roles[id],
            }));

            return o;
          }, {}),
        }),
        ...(payload.lngs && {
          languages: extractKeys(payload.lngs) as LanguageOption[],
        }),
        ...(payload.ptl && {
          title1: payload.ptl.title1,
          title2: payload.ptl.title2,
          guiDomain: payload.ptl.guiDomain,
          guiOnlineBookDomain: payload.ptl.guiOnlineBookDomain,
          guiShrDomain: payload.ptl.guiShrDomain,
        }),
        ...(payload.phns &&
          payload.phns.cntrs && {
            countryCode: payload.phns.cntrs,
          }),
        ...(payload.tzs && {
          timezones: Object.keys(payload.tzs).map(key => ({
            timezone: key,
            names: payload.tzs[key],
          })),
        }),
        ...(payload.wsPingPong && {
          wsPingPong: payload.wsPingPong,
        }),
        ...(payload.curs && {
          curs: payload.curs,
        }),
        ...(payload.licCmp && {
          licCmp: payload.licCmp,
        }),
        ...(payload.licWebId && {
          licWebId: payload.licWebId,
        }),
        ...(payload.adr && {
          adr: payload.adr.stateForCountry,
        }),
      };
    }

    case MetaActionTypes.LOAD_META_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: payload || [],
      };
    }

    case MetaActionTypes.LOAD_META_TOKEN_SUCCESS: {
      return {
        ...state,
        token: payload,
      };
    }

    case MetaActionTypes.LOAD_META_COUNTRY_SUCCESS: {
      return {
        ...state,
        countryInfo: payload,
      };
    }

    case MetaActionTypes.LOAD_META_FOOD_TYPES_SUCCESS: {
      return {
        ...state,
        foodTypes: payload || [],
      };
    }

    case MetaActionTypes.SET_META_UI_THEME: {
      return {
        ...state,
        darkTheme: payload?.isDarkTheme,
      };
    }

    case MetaActionTypes.LOAD_META_UI_THEME_SUCCESS: {
      return {
        ...state,
        darkTheme: payload,
      };
    }

    case MetaActionTypes.UPDATE_PS_META_SUCCESS: {
      const { data, mid } = payload;
      return {
        ...state,
        powerSearchMeta: {
          ...state.powerSearchMeta,
          [mid]: data || '{}',
        },
      };
    }

    default: {
      return state;
    }
  }
};

export const getUnits = (state: State) => state.units;
export const getFrequencies = (state: State) => state.frequencies;
export const getUserRoles = (state: State) => state.roles.user;
export const getCustomerRoles = (state: State) => state.roles.customer;
export const getStaffRoles = (state: State) => state.roles.staff;
export const getVendorRoles = (state: State) => state.roles.vendor;
export const getLanguages = (state: State) => state.languages;
export const getTitle = (state: State) => state.title2;
export const getMobileTitle = (state: State) => state.title1;
export const getCountryCode = (state: State) => state.countryCode;
export const getGuiDomain = (state: State) => state.guiDomain;
export const getGuiShrDomain = (state: State) => state.guiShrDomain;
export const getRoles = (state: State) =>
  Object.keys(state.roles).reduce(
    (prev, curr) => prev.concat((state.roles as any)[curr]),
    []
  );
export const getTimezones = (state: State) => state.timezones;
export const getGuiOnlineBookDomain = (state: State) =>
  state.guiOnlineBookDomain;
export const getWsPingPong = (state: State) => state.wsPingPong;
export const getCurrencies = (state: State) => state.curs;
export const getLicenceCmpId = (state: State) => state.licCmp;
export const getLicenceWebId = (state: State) => state.licWebId;
export const getCountries = (state: State) => state.countries;
export const getCountryInfo = (state: State) => state.countryInfo;
export const getFoodTypes = (state: State) => state.foodTypes;
export const getStates = (state: State) => state.states;
export const getToken = (state: State) => state.token;
export const getUiTheme = (state: State) => state.darkTheme;
export const getPowerSearchMeta = (state: State) => state.powerSearchMeta;
