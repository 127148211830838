import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  AppState,
  NewRelationsActions,
  RelationsActions,
} from '@qtek/libs/store';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import {
  DialogActionsDirective,
  DialogComponent,
  DialogContentDirective,
  DialogTitleDirective,
} from '@qtek/shared/components';
import { Person } from '@qtek/shared/models';
import { CustomValidators } from '@qtek/shared/utils';
import { fullscreenClass, minWidth } from '../../default-config';

export interface InviteContactDialogInput {
  type: 'staff' | 'customer';
  relId?: string;
  person?: Person;
}

export const inviteContactDialogConfig: MatDialogConfig = {
  width: minWidth,
  panelClass: fullscreenClass,
};

@Component({
  selector: 'qt-invite-contact-dialog',
  templateUrl: './invite-contact-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslationCoreModule,
    DialogComponent,
    DialogTitleDirective,
    DialogContentDirective,
    DialogActionsDirective,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class InviteContactDialogComponent {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InviteContactDialogInput,
    private mdDialogRef: MatDialogRef<InviteContactDialogComponent>,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder
  ) {}

  createForm(): void {
    this.form = this.fb.group({
      sendingMethod: this.fb.group(
        {
          email: this.fb.control({
            value: this.checkEmail(),
            disabled: !this.checkEmail(),
          }),
          sms: this.fb.control({
            value: this.checkPhone(),
            disabled: !this.checkPhone(),
          }),
        },
        {
          validator: CustomValidators.atLeastOne,
        }
      ),
    });
  }

  checkEmail(): boolean {
    return this.data.person.email !== null;
  }

  checkPhone(): boolean {
    return this.data.person.phns && this.data.person.phns.length > 0;
  }

  onSubmit(): void {
    /**
     * hardcoded email instead of email or/and sms chosen in form
     */
    const query = {
      sendingMethod: {
        email: true,
        sms: false,
      },
    };
    const sendingMethod = 'email';

    if (this.data.type === 'customer') {
      this.store.dispatch(
        NewRelationsActions.sendInvitations({ query, relId: this.data.relId })
      );
    } else {
      RelationsActions.sendStaffInvitation({
        payload: { prsId: this.data.relId, query },
        params: [sendingMethod],
      });
    }

    this.mdDialogRef.close();
  }
}
