import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  createNgModule,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { HealthcheckCoreService } from '@qtek/libs/healthcheck-core';
import {
  AppState,
  CoreActions,
  UserActions,
  WizardActions,
  selectCompanyProgress,
  selectDemoDataProgress,
  selectMainCompany,
  selectMaxCompanyProgress,
  selectMaxDemoDataProgress,
  selectMaxPersonProgress,
  selectPersonProgress,
  selectUserActions,
} from '@qtek/libs/store';
import { QtNetError } from '@qtek/libs/ui';
import {
  DynamicDrawer,
  OnlineService,
  UiTourService,
} from '@qtek/shared/services';
import {
  Observable,
  Subject,
  combineLatest,
  forkJoin,
  from,
  of,
  timer,
  zip,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

const EXPAND_ANIMATION = trigger('size', [
  state(
    '1',
    style({
      width: '260px',
    })
  ),
  state(
    '0',
    style({
      width: '70px',
    })
  ),
  transition('1 <=> 0', [animate('250ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
]);

export const sideNavContainerAnimation = trigger('openCloseSidenavContent', [
  state(
    '1',
    style({
      'margin-left': '260px',
    })
  ),
  state(
    '0',
    style({
      'margin-left': '70px',
    })
  ),
  state(
    'mobile',
    style({
      'margin-left': '0',
    })
  ),
  transition('1 <=> 0', [animate('250ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
]);

@Component({
  selector: 'qt-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['./layout.component.scss'],
  /** Provide service directly to destroy with component */
  providers: [UiTourService],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [EXPAND_ANIMATION, sideNavContainerAnimation],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  actualStepNumber: number;
  maxStepNumber: number;
  isUiTourOpen: boolean;
  mini$: Observable<boolean>;
  wizardOpened: boolean;
  destroy$ = new Subject<void>();
  networkError$: Observable<QtNetError | null> = of(null);
  mode$: Observable<'side' | 'over'>;
  mobile$: Observable<boolean>;

  @ViewChild('childDiv', { static: true }) childDiv: ElementRef;
  @ViewChild('dynamicSidenav') dynamicSidenav: MatSidenav;
  @ViewChild(CdkPortalOutlet) outlet: CdkPortalOutlet;
  @ViewChild('tutorialTemplateRef') tutorialTemplateRef: TemplateRef<any>;
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    public router: Router,
    public uiTourService: UiTourService,
    private actions$: Actions,
    private injector: Injector,
    private titleService: Title,
    private online: OnlineService,
    private store: Store<AppState>,
    private dynamicDrawer: DynamicDrawer,
    private observer: BreakpointObserver,
    private healthCheck: HealthcheckCoreService
  ) {}

  ngOnInit() {
    this.mobile$ = this.observer.observe(Breakpoints.XSmall).pipe(
      map(breakpoint => Boolean(breakpoint.matches)),
      distinctUntilChanged(),
      shareReplay()
    );
    this.mini$ = combineLatest([
      this.dynamicDrawer.showSidenav$,
      this.mobile$,
    ]).pipe(
      map(([showSidenav, isMobile]) => Boolean(!isMobile && showSidenav)),
      shareReplay()
    );

    this.startWizard();

    /** Update title to company name. */
    this.store
      .pipe(select(selectMainCompany), takeUntil(this.destroy$))
      .subscribe(company => {
        if (company?.name?.name) {
          this.titleService.setTitle(company.name.name);
        }
      });

    this.uiTourService.refreshObservable
      .pipe(takeUntil(this.destroy$), debounceTime(0))
      .subscribe(() => {
        this.actualStepNumber = this.uiTourService.uiStepIndex + 1;
        this.maxStepNumber = this.uiTourService.visibleSteps.length;
        this.isUiTourOpen =
          this.uiTourService.isOpen && this.uiTourService.wizardDone;
      });

    const onlineStatus$ = this.online.change$.pipe(
      startWith(this.online.isOnline()),
      shareReplay()
    );

    const httpHealthCheck$ = this.healthCheck.isHealthyHttpConnection$.pipe(
      distinctUntilChanged(),
      switchMap(value => {
        if (value) {
          return of(value);
        }
        return timer(2000).pipe(map(() => value));
      })
    );

    const websocketHealthCheck$ = this.healthCheck.isWebSocketAlive$.pipe(
      distinctUntilChanged(),
      switchMap(value => {
        if (value) {
          return of(value);
        }
        return timer(2000).pipe(map(() => value));
      })
    );

    this.networkError$ = combineLatest([
      onlineStatus$,
      httpHealthCheck$,
      websocketHealthCheck$,
    ]).pipe(
      map(([isOnline, isHttpHealthy, isWsHealthy]) => {
        if (!isOnline) {
          return 'offline';
        }
        if (!isHttpHealthy) {
          return 'http';
        }
        if (!isWsHealthy) {
          return 'ws';
        }
        return null;
      }),
      distinctUntilChanged()
    );

    this.mode$ = this.mini$.pipe(map(bool => (bool ? 'side' : 'over')));
  }

  ngAfterViewInit() {
    this.dynamicDrawer.register({
      matDrawer: this.dynamicSidenav,
      cdkPortalOutlet: this.outlet,
    });

    this.uiTourService.templateRef = this.tutorialTemplateRef;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  next(): void {
    this.uiTourService.next();
  }

  back(): void {
    this.uiTourService.back();
  }

  finishUiTour() {
    const payload = {
      form: {},
      additionalPayload: {
        _attrv: 'usr',
        _uitut: this.uiTourService.progress,
      },
    };
    this.uiTourService.isTourOpen = false;
    this.store.dispatch(UserActions.updateCurrentUser({ payload }));
  }

  /**
   * Load wizard module and display dialog.
   */
  private openWizard() {
    return from(import('@qtek/libs/wizard')).pipe(
      mergeMap(({ WizardModule: WizardModule }) => {
        const moduleRef = createNgModule(WizardModule, this.injector);
        this.wizardOpened = true;
        return moduleRef.instance.openWizard().pipe(
          tap(() => {
            this.wizardOpened = false;
          })
        );
      })
    );
  }

  private startWizard() {
    /** Load wizard and demo steps */
    this.store.dispatch(WizardActions.getSteps());
    this.store.dispatch(WizardActions.getDemoSteps());

    const isWizardDone$ = this.getIsWizardDone();

    const isDemoDone$ = this.getIsDemoDone();
    const success$ = forkJoin([
      this.actions$.pipe(ofType(WizardActions.getStepsSuccess), take(1)),
      this.actions$.pipe(ofType(WizardActions.getDemoStepsSuccess), take(1)),
    ]);
    success$
      .pipe(
        mergeMap(() => zip(isWizardDone$, isDemoDone$)),
        take(1),
        map(([wizardDone, demoDone]) => wizardDone && demoDone),
        mergeMap((done: boolean) => {
          this.store.dispatch(CoreActions.closeLoading());
          if (!done) {
            return this.openWizard();
          } else {
            return of(null);
          }
        }),
        mergeMap(() => {
          this.uiTourService.wizardDone = true;
          this.uiTourService.canOpenTour = this.uiTourService.canOpenTourValue;

          return this.store.select(selectUserActions);
        }),
        tap(() => this.store.dispatch(WizardActions.isWizardDone())),
        filter(value => value && !this.wizardOpened),
        take(1),
        filter(res => res[0] != null && res[0].params != null),
        map(res => res[0].params),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getIsWizardDone(): Observable<boolean> {
    const personProgress$ = this.store.select(selectPersonProgress);
    const maxPersonProgress$ = this.store.select(selectMaxPersonProgress);
    const companyProgress$ = this.store.select(selectCompanyProgress);
    const maxCompanyProgress$ = this.store.select(selectMaxCompanyProgress);

    return combineLatest([
      personProgress$,
      maxPersonProgress$,
      companyProgress$,
      maxCompanyProgress$,
    ]).pipe(
      map(
        ([
          personProgress,
          maxPersonProgress,
          companyProgress,
          maxCompanyProgress,
        ]) => {
          const isPersonDone =
            (personProgress & maxPersonProgress) === maxPersonProgress;
          const isCompanyDone =
            (companyProgress & maxCompanyProgress) === maxCompanyProgress;
          return isPersonDone && isCompanyDone;
        }
      )
    );
  }

  private getIsDemoDone(): Observable<boolean> {
    const demoDataProgress$ = this.store.select(selectDemoDataProgress);
    const maxDemoDataProgress$ = this.store.select(selectMaxDemoDataProgress);

    return combineLatest([demoDataProgress$, maxDemoDataProgress$]).pipe(
      map(
        ([demoDataProgress, maxDemoDataProgress]) =>
          (demoDataProgress & maxDemoDataProgress) === maxDemoDataProgress
      )
    );
  }
}
