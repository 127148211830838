<qt-dialog>
  <qt-dialog-title>{{ 'GNR_INVITE' | translate }}</qt-dialog-title>
  <qt-dialog-content>
    <h2 class="mat-h2">{{ 'GNR_PRS_CONFIRM_SEND' | translate: [
      data.person?.name ? data.person.name : data.person?.email
      ] }}</h2>

    <!--    <form novalidate [formGroup]="form" fxLayout="column">-->
    <!--      <h2 class="mat-h2">{{ 'GNR_PRS_WANT_SEND' | translate }}</h2>-->
    <!--      <div-->
    <!--        class="mb-3"-->
    <!--        formGroupName="sendingMethod"-->
    <!--        fxLayout="column"-->
    <!--        fxLayoutGap="1rem"-->
    <!--      >-->
    <!--        <mat-checkbox formControlName="email">{{-->
    <!--          'GNR_EMAIL' | translate-->
    <!--        }}</mat-checkbox>-->
    <!--        <mat-checkbox formControlName="sms">{{-->
    <!--          'GNR_SMS' | translate-->
    <!--        }}</mat-checkbox>-->
    <!--        <mat-error class="mat-body" for="sendingMethod"></mat-error>-->
    <!--      </div>-->
    <!--    </form>-->
  </qt-dialog-content>
  <qt-dialog-actions>
    <button
      mat-button
      matDialogClose
    >{{ 'GNR_CANCEL' | translate }}</button>
    <!--    <button mat-button [qtSubmitIfValid]="form" (qtSubmitIfValid)="onSubmit()">-->
    <button
      mat-button
      (click)="onSubmit()"
    >
      {{ 'GNR_INVITE' | translate }}
    </button>
  </qt-dialog-actions>
</qt-dialog>