import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { LoadMainAction, LoadSetupAction } from '../actions';
import {
  GetPaymentPlansAction,
  GetPaymentPlansFailureAction,
  GetPaymentPlansSuccessAction,
  PaymentPlanActionTypes,
  UpdatePaymentPlanAction,
  UpdatePaymentPlanFailureAction,
  UpdatePaymentPlanSuccessAction,
} from './payment-plan.actions';
import { PaymentPlanService } from './payment-plan.service';

@Injectable()
export class PaymentPlanEffects {
  getPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPaymentPlansAction>(PaymentPlanActionTypes.GET_PAYMENT_PLANS),
      switchMap(({ payload }) =>
        this.planService.getPlans(payload).pipe(
          map(({ res }) => new GetPaymentPlansSuccessAction(res || [])),
          catchError((error) => of(new GetPaymentPlansFailureAction(error)))
        )
      )
    )
  );

  updatePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdatePaymentPlanAction>(PaymentPlanActionTypes.UPDATE_PAYMENT_PLAN),
      switchMap(({ payload }) =>
        this.planService.selectPlan(payload).pipe(
          mergeMap(() => this.planService.getPlans('synplyStore')),
          map(({ res }) => new UpdatePaymentPlanSuccessAction(res || [])),
          catchError((error) => of(new UpdatePaymentPlanFailureAction(error)))
        )
      )
    )
  );

  updatePlanSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentPlanActionTypes.UPDATE_PAYMENT_PLAN_SUCCESS),
      switchMap(() => [new LoadMainAction(), new LoadSetupAction()])
    )
  );

  constructor(private actions$: Actions, private planService: PaymentPlanService) {}
}
