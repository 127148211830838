import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import * as AgencyActions from './agency.actions';
import { ItemService } from './agency.service';

@Injectable()
export class AgencyEffects {
  constructor(private actions$: Actions, private itemService: ItemService) {}

  connectWithCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AgencyActions.SubscribeAgenciesAction>(AgencyActions.AgencyActionTypes.SUBSCRIBE_ITEM),
      mergeMap(({ payload }) =>
        this.itemService.subscribe(payload).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType<AgencyActions.UnsubscribeAgenciesAction>(AgencyActions.AgencyActionTypes.UNSUBSCRIBE_ITEM),
              filter((action) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ res, op, sts }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new AgencyActions.GetAgenciesSuccessAction({ res: res || [], meta: sts?.meta })
              : new AgencyActions.GetAgenciesFailureAction(sts);
          case 'get':
            return res
              ? new AgencyActions.GetAgencySuccessAction(res || [])
              : new AgencyActions.GetAgencyFailureAction(sts);
          case 'ins':
            return res
              ? new AgencyActions.CreateAgencySuccessAction(res || [])
              : new AgencyActions.CreateAgencyFailureAction(sts);
          case 'upd':
            return res
              ? new AgencyActions.UpdateAgencySuccessAction(res || [])
              : new AgencyActions.UpdateAgencyFailureAction(sts);
          case 'del':
            return res
              ? new AgencyActions.DeleteAgencySuccessAction(res || [])
              : new AgencyActions.DeleteAgencyFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  items$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AgencyActions.AgencyActionTypes.GET_ITEMS),
        tap(({ payload }) => this.itemService.getItems(payload))
      ),
    { dispatch: false }
  );

  getItemById$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AgencyActions.AgencyActionTypes.GET_ITEM),
        tap(({ payload }) => this.itemService.getItemById(payload))
      ),
    { dispatch: false }
  );

  createItem$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AgencyActions.CreateAgencyAction>(AgencyActions.AgencyActionTypes.CREATE_ITEM),
        tap(({ payload }) => this.itemService.createItem({ res: payload }))
      ),
    { dispatch: false }
  );

  updateItem$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AgencyActions.UpdateAgencyAction>(AgencyActions.AgencyActionTypes.UPDATE_ITEM),
        tap(({ payload }) => this.itemService.updateItem({ res: payload, id: payload.seqno }))
      ),
    { dispatch: false }
  );

  deleteItem$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AgencyActions.DeleteAgencyAction>(AgencyActions.AgencyActionTypes.DELETE_ITEM),
        tap(({ payload }) => this.itemService.deleteItem({ id: payload }))
      ),
    { dispatch: false }
  );
}
