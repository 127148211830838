import { createFeature, createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';

export interface AuthState {
  isLoading: boolean;
  wsInitToken: string | null;
}

const initialState: AuthState = {
  isLoading: false,
  wsInitToken: null,
};

export const authReducer = createReducer(
  initialState,
  on(
    AuthActions.getWSInitToken,
    (state): AuthState => ({
      ...state,
      wsInitToken: null,
    })
  ),
  on(
    AuthActions.getWSInitTokenSuccess,
    (state, { payload }): AuthState => ({
      ...state,
      wsInitToken: payload,
    })
  ),
  on(
    AuthActions.getWSInitTokenError,
    (state): AuthState => ({
      ...state,
      wsInitToken: null,
    })
  ),
  on(
    AuthActions.login,
    (state): AuthState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    AuthActions.loginOk,
    (state): AuthState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    AuthActions.loginError,
    (state): AuthState => ({
      ...state,
      isLoading: false,
    })
  )
);
export const AuthFeature = createFeature({
  name: 'auth',
  reducer: authReducer,
});

export const { selectIsLoading, selectWsInitToken } = AuthFeature;
