import { Environment } from '@qtek/shared/models';

export function startTracking(environment: Environment) {
  if (environment.gtagId) {
    startGoogleTagManagerTracking(environment);
  }

  if (environment.production) {
    startHotJarTracking();
  }
}

function startHotJarTracking(): void {
  const hotjarScript = document.createElement('script');
  const head = document.querySelector('head');

  hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2214244,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  head.insertBefore(hotjarScript, head.firstChild);
}

function startGoogleTagManagerTracking(environment: Environment): void {
  const gtagScript = document.createElement('script');
  const head = document.querySelector('head');

  gtagScript.innerHTML = `
  (function(w,d,s,l,i){
    w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;
    j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','${environment.gtagId}');`;
  head.insertBefore(gtagScript, head.firstChild);
}
