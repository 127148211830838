import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Store, select } from '@ngrx/store';
import { Pwa } from '@qtek/libs/app-component';
import { AuthUserActions } from '@qtek/libs/auth-user';
import {
  AppState,
  DocumentActions,
  selectCurrentPerson,
  selectMainCompany,
  selectPersonalAvatar,
  selectUserCompanies,
  selectUserRelation,
} from '@qtek/libs/store';
import { DocumentType, HTTP_BASE_URL } from '@qtek/shared/models';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'qt-avatar-menu',
  templateUrl: './avatar-menu.component.html',
  styleUrls: ['./avatar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AvatarMenuComponent implements OnInit {
  @Output()
  public framesOpen: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public logoutClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public personalInfoClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public sidenav: MatSidenav;

  @Input({ transform: coerceBooleanProperty })
  public isHiddenUserName = false;

  public cmpId: string;
  public personalAvatarUrl$ = this.getPersonalAvatar();

  public userData$ = combineLatest([
    this.store.select(selectCurrentPerson),
    this.store.select(selectMainCompany),
    this.store.select(selectUserRelation),
  ]).pipe(
    map(([prs, acn, rel]) => {
      this.cmpId = acn?._id;
      return {
        name: prs?.name || '',
        company: acn?.name?.name || '',
        email: prs?.email || rel?.email || '',
      };
    })
  );

  companies: Observable<any> = combineLatest([
    this.store.select(selectUserCompanies),
    this.store.select(selectMainCompany),
  ]).pipe(
    map(([companies, mainCompany]) => {
      return companies.filter(company => company.cmpId !== mainCompany._id);
    })
  );

  constructor(
    @Inject(HTTP_BASE_URL) private baseUrl: string,
    public pwa: Pwa,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      DocumentActions.getEntityDocuments({
        payload: {
          refId: 'prs',
          type: DocumentType.PERSONAL_AVATAR,
        },
      })
    );
  }

  getPersonalAvatar(): Observable<string> {
    return this.store.pipe(
      select(selectPersonalAvatar),
      filter(Boolean),
      map(docId => `${this.baseUrl}/api/v1/service/doc/${docId}`)
    );
  }

  getCompanyName(company: any): string {
    if (!company) {
      return '';
    }

    return company.cmp?.name?.name ? company.cmp.name.name : company.email;
  }

  getCompanyEmail(company: any): string {
    return company.email !== '' ? company.email : '';
  }

  closeSideNave(): void {
    if (this.sidenav && this.sidenav.opened) {
      this.sidenav.close();
    }
  }

  public onLogout(): void {
    this.logoutClicked.emit();
  }

  public onFramesOpen(): void {
    this.framesOpen.emit();
  }

  handleLogout(): void {
    this.store.dispatch(AuthUserActions.logout({}));
  }

  /**
   * Returns false if user first or last name is null or undefined
   *
   * @param {string} name
   * @private
   */
  private validateName(name: string): boolean {
    const trimmedName = name.trim();
    return (
      !trimmedName?.includes('null') && !trimmedName?.includes('undefined')
    );
  }
}
