import { Injectable, Provider } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';

import { QtIntl } from './intl';

@Injectable()
export class QtDatepickerIntl extends MatDatepickerIntl implements QtIntl {
  updateTranslations(translations: any) {
    this.calendarLabel = translations['INTL_CAL_CALENDAR'];
    this.openCalendarLabel = translations['INTL_CAL_OPEN'];
    this.prevMonthLabel = translations['INTL_CAL_PREVIOUS_M'];
    this.nextMonthLabel = translations['INTL_CAL_NEXT_M'];
    this.prevYearLabel = translations['INTL_CAL_PREVIOUS_Y'];
    this.nextYearLabel = translations['INTL_CAL_NEXT_Y'];
    this.prevMultiYearLabel = translations['INTL_CAL_PREV_MULTI_Y'];
    this.nextMultiYearLabel = translations['INTL_CAL_NEXT_MULTI_Y'];
    this.switchToMonthViewLabel = translations['INTL_CAL_SWITCH_TO_M'];
    this.switchToMultiYearViewLabel = translations['INTL_CAL_SWITCH_TO_Y'];

    this.changes.next();
  }
  getTranslations() {
    return [
      'INTL_CAL_CALENDAR',
      'INTL_CAL_OPEN',
      'INTL_CAL_PREVIOUS_M',
      'INTL_CAL_NEXT_M',
      'INTL_CAL_PREVIOUS_Y',
      'INTL_CAL_NEXT_Y',
      'INTL_CAL_PREV_MULTI_Y',
      'INTL_CAL_NEXT_MULTI_Y',
      'INTL_CAL_SWITCH_TO_M',
      'INTL_CAL_SWITCH_TO_Y',
    ];
  }
}

export const INTL_DATEPICKER_PROVIDERS: Provider[] = [
  QtDatepickerIntl,
  {
    provide: MatDatepickerIntl,
    useExisting: QtDatepickerIntl,
  },
  {
    provide: QtIntl,
    useExisting: QtDatepickerIntl,
    multi: true,
  },
];
