import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  Observable,
  switchMap,
  concatMap,
  from,
  map,
  catchError,
  of,
} from 'rxjs';
import { CompanyService } from './company.service';
import { DemoDataService } from './demo-data.service';
import { DocumentsService } from './documents.service';
import { PersonService } from './person.service';
import { WizardActions } from './wizard.actions';
import { WizardService } from './wizard.service';
import * as DocumentsActions from '../documents/documents.actions';
import * as UserActions from '../user/user.actions';

@Injectable()
export class WizardEffects {
  wizard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.getSteps),
      switchMap(() =>
        this.wizardService.getSteps().pipe(
          concatMap(({ res }) =>
            from([
              WizardActions.setMaxPersonProgress({
                payload: res.meta.progress[0].value,
              }),
              WizardActions.setMaxCompanyProgress({
                payload: res.meta.progress[1] ? res.meta.progress[1].value : 0,
              }),
              WizardActions.getStepsSuccess({ payload: res.children }),
            ])
          )
        )
      )
    )
  );

  demoData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.getDemoSteps),
      switchMap(() => this.demoDataService.getData()),
      map(({ res }) =>
        WizardActions.getDemoStepsSuccess({
          payload: res ? res.steps : undefined,
        })
      )
    )
  );

  prs1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepPrs1),
      switchMap(({ payload }) =>
        this.personService.updatePerson(payload.form, payload.additionalPayload)
      ),
      map(({ res }) => new UserActions.UpdateCurrentUserSuccessAction(res)),
      catchError(({ sts }) =>
        of(new UserActions.UpdateCurrentUserFailureAction(sts))
      )
    )
  );

  cmp1$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepCmp1),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(
          payload.additionalPayload,
          payload.form,
          payload.id
        )
      ),
      map(
        ({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)
      ),
      catchError(({ sts }) =>
        of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts))
      )
    )
  );

  cmp2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepCmp2),
      switchMap(({ payload }) =>
        this.documentsService.updateLogo(payload).pipe(
          map(
            ({ res }) =>
              new DocumentsActions.UpdateCompanyLogoSuccessAction(res)
          ),
          catchError(({ sts }) =>
            of(new DocumentsActions.UpdateCompanyLogoFailureAction(sts))
          )
        )
      )
    )
  );

  cmp64$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepCmp64),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(
          payload.additionalPayload,
          payload.form,
          payload.id
        )
      ),
      map(
        ({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)
      ),
      catchError(({ sts }) =>
        of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts))
      )
    )
  );

  cmp8$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepCmp8),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(
          payload.additionalPayload,
          payload.form,
          payload.id
        )
      ),
      map(
        ({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)
      ),
      catchError(({ sts }) =>
        of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts))
      )
    )
  );

  cmp260$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepCmp260),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(
          payload.additionalPayload,
          payload.form,
          payload.id
        )
      ),
      map(
        ({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)
      )
    )
  );

  cmp1536$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepCmp1536),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(
          payload.additionalPayload,
          payload.form,
          payload.id
        )
      ),
      map(
        ({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)
      ),
      catchError(({ sts }) =>
        of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts))
      )
    )
  );

  cmp2048$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.stepCmp2048, WizardActions.stepCmp4096),
      switchMap(({ payload }) =>
        this.companyService.updateCompany(
          payload.additionalPayload,
          payload.form,
          payload.id
        )
      ),
      map(
        ({ res }) => new UserActions.UpdateCurrentUserCompanySuccessAction(res)
      ),
      catchError(({ sts }) =>
        of(new UserActions.UpdateCurrentUserCompanyFailureAction(sts))
      )
    )
  );

  savePromo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.savePromo),
      switchMap(({ type, ...payload }) =>
        this.wizardService.savePromo(payload)
      ),
      map(() => WizardActions.savePromoSuccess({ payload: undefined })),
      catchError(({ sts }) =>
        of(WizardActions.savePromoFailure({ payload: sts }))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private wizardService: WizardService,
    private personService: PersonService,
    private companyService: CompanyService,
    private documentsService: DocumentsService,
    private demoDataService: DemoDataService
  ) {}
}
