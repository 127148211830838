import { Action } from '@ngrx/store';

import {
  DocNode,
  DocumentType,
  Message,
  PreUploadPayload,
  PreUploadState,
  PreUploadTokenRes,
} from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class DocumentActionTypes {
  static readonly CONNECT_DOCUMENTS_LIST = type(
    '[Docs] Connect Doduments List'
  );
  static readonly DISCONNECT_DOCUMENTS_LIST = type(
    '[Docs] Disconnect Doduments List'
  );

  static readonly GET_ENTITY_DOCUMENTS_WS = type(
    '[Docs] Get documents from refid via websockets'
  );

  static readonly GET_ITEM_DOCUMENTS = type(
    '[Docs] Get data room documents from refid via http'
  );

  static readonly UPDATE_DOCUMENT_WS = type('[Docs] Update document via WS');

  static readonly DELETE_DOCUMENT_WS = type('[Docs] Delete document via WS');

  static readonly GET_ENTITY_DOCUMENTS_WS_SUCCESS = type(
    '[Docs] Get documents from refid via WS success'
  );

  static readonly UPLOAD_DOCUMENTS_WS_SUCCESS = type(
    '[Docs] Upload documents via WS Success'
  );

  static readonly DELETE_DOCUMENT_WS_SUCCESS = type(
    '[Docs] Delete document via WS Success'
  );

  static readonly UPDATE_DOCUMENT_WS_SUCCESS = type(
    '[Docs] Update document via WS Success'
  );

  static readonly CREATE_FOLDER_SUCCESS_WS = type(
    '[Docs] Create folder via WS Success'
  );

  static readonly DELETE_FOLDER_SUCCESS_WS = type(
    '[Docs] Delete folder via WS Success'
  );

  static readonly UPDATE_FOLDER_SUCCESS_WS = type(
    '[Docs] Update folder via WS Success'
  );

  static readonly UPLOAD_DOCUMENTS_WS_FAILURE = type(
    '[Docs] Upload documents via WS Failure'
  );

  static readonly DELETE_DOCUMENT_WS_FAILURE = type(
    '[Docs] Delete document via WS Failure'
  );

  static readonly UPDATE_DOCUMENT_WS_FAILURE = type(
    '[Docs] Update document via WS Failure'
  );

  static readonly CREATE_FOLDER_FAILURE_WS = type(
    '[Docs] Create folder via WS Failure'
  );

  static readonly DELETE_FOLDER_FAILURE_WS = type(
    '[Docs] Delete folder via WS Failure'
  );

  static readonly UPDATE_FOLDER_FAILURE_WS = type(
    '[Docs] Update folder via WS Failure'
  );

  static readonly UPDATE_COMPANY_LOGO = type('[Docs] Update logo');
  static readonly UPDATE_COMPANY_LOGO_SUCCESS = type(
    '[Docs] Update logo Success'
  );
  static readonly UPDATE_COMPANY_LOGO_FAILURE = type(
    '[Docs] Update logo Failure'
  );
  static readonly UPDATE_PERSONAL_AVATAR = type(
    '[Docs] Update Personal Avatar'
  );
  static readonly UPDATE_PERSONAL_AVATAR_SUCCESS = type(
    '[Docs] Update Personal Avatar Success'
  );
  static readonly UPDATE_PERSONAL_AVATAR_FAILURE = type(
    '[Docs] Update Personal Avatar Failure'
  );
  static readonly GET_ENTITY_DOCUMENTS = type(
    '[Docs] Get documents from refid'
  );
  static readonly GET_ENTITY_DOCUMENTS_SUCCESS = type(
    '[Docs] Get documents from refid success'
  );
  static readonly GET_ENTITY_DOCUMENTS_FAILURE = type(
    '[Docs] Get documents from refid failure'
  );
  static readonly GET_LOGOS = type('[Docs] Get creator logos');
  static readonly GET_LOGOS_SUCCESS = type('[Docs] Get creator logos success');

  static readonly PREUPLOAD_DOCUMENT = type('[Docs] Pre Upload document meta');
  static readonly PREUPLOAD_DOCUMENT_SUCCESS = type(
    '[Docs] Pre Upload document meta SUCCESS'
  );
  static readonly PREUPLOAD_DOCUMENT_FAILURE = type(
    '[Docs] Pre Upload document meta FAILURE'
  );

  static readonly PREUPLOAD_DOCUMENT_UPDATE = type(
    '[Docs] UPDATE Pre Upload document meta'
  );

  static readonly PREUPLOAD_DOCUMENT_DELETE = type(
    '[Docs] DELETE Pre Upload document meta'
  );
  static readonly PREUPLOAD_DOCUMENT_DELETE_ALL_BY_IDS = type(
    '[Docs] DELETE all Pre Upload document meta by ids'
  );
  static readonly PREUPLOAD_DOCUMENT_DELETE_ALL_BY_REF = type(
    '[Docs] DELETE all Pre Upload document meta by refId'
  );
  static readonly PREUPLOAD_DOCUMENT_DELETE_SUCCESS = type(
    '[Docs] DELETE Pre Upload document meta SUCCESS'
  );
  static readonly PREUPLOAD_DOCUMENT_DELETE_FAILURE = type(
    '[Docs] DELETE DelePre Upload document meta FAILURE'
  );

  static readonly UPLOAD_DOCUMENTS = type('[Docs] Upload documents');
  static readonly UPLOAD_DOCUMENTS_SUCCESS = type(
    '[Docs] Upload documents Success'
  );
  static readonly UPLOAD_DOCUMENTS_FAILURE = type(
    '[Docs] Upload documents Failure'
  );
  static readonly DELETE_DOCUMENT = type('[Docs] Delete document');
  static readonly DELETE_DOCUMENT_SUCCESS = type(
    '[Docs] Delete document Success'
  );
  static readonly DELETE_DOCUMENT_FAILURE = type(
    '[Docs] Delete document Failure'
  );
  static readonly BULK_DELETE = type('[Docs] Bulk Delete');
  static readonly BULK_DELETE_SUCCESS = type('[Docs] Bulk Delete Success');
  static readonly BULK_DELETE_FAILURE = type('[Docs] Bulk Delete Failure');
  static readonly BULK_DOWNLOAD = type('[Docs] Bulk Download');
  static readonly BULK_DOWNLOAD_SUCCESS = type('[Docs] Bulk Download Success');
  static readonly BULK_DOWNLOAD_FAILURE = type('[Docs] Bulk Download Failure');

  static readonly SEND_DOCUMENT = type('[Document] Send Document');
  static readonly SEND_DOCUMENT_SUCCESS = type(
    '[Document] Send Document Success'
  );
  static readonly SEND_DOCUMENT_FAILURE = type(
    '[Document] Send Document Failure'
  );

  static readonly UPDATE_DOCUMENT_DESC = type(
    '[Document] Update Document Description'
  );
  static readonly UPDATE_DOCUMENT_DESC_SUCCESS = type(
    '[Document] Update Document Description Success'
  );
  static readonly UPDATE_DOCUMENT_DESC_FAILURE = type(
    '[Document] Update Document Description Failure'
  );
}

export class ConnectDocumentsListAction implements Action {
  readonly type = DocumentActionTypes.CONNECT_DOCUMENTS_LIST;
  constructor(public payload?: any) {}
}

export class DisconnectDocumentsListAction implements Action {
  readonly type = DocumentActionTypes.DISCONNECT_DOCUMENTS_LIST;

  constructor(public payload?: any) {}
}

export class GetEntityDocumentsWsAction implements Action {
  readonly type = DocumentActionTypes.GET_ENTITY_DOCUMENTS_WS;

  constructor(public payload?: any) {}
}

export class UpdateWSDocumentAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_DOCUMENT_WS;

  constructor(public payload: { id: string; res: any }) {}
}

export class DeleteWSDocumentAction implements Action {
  readonly type = DocumentActionTypes.DELETE_DOCUMENT_WS;

  constructor(public payload: { id: string }) {}
}

export class GetWSEntityDocumentsSuccessAction implements Action {
  readonly type = DocumentActionTypes.GET_ENTITY_DOCUMENTS_WS_SUCCESS;

  constructor(public payload: DocNode[]) {}
}

export class UploadWSDocumentsSuccessAction implements Action {
  readonly type = DocumentActionTypes.UPLOAD_DOCUMENTS_WS_SUCCESS;
  message = new Message('MSG_FILE_UPLOADED');

  constructor(public payload: DocNode) {}
}

export class UpdateWSDocumentSuccessAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_DOCUMENT_WS_SUCCESS;

  constructor(public payload: DocNode) {}
}

export class DeleteWSDocumentSuccessAction implements Action {
  readonly type = DocumentActionTypes.DELETE_DOCUMENT_WS_SUCCESS;
  message = new Message('MSG_FILE_DELETED');

  constructor(public payload: DocNode) {}
}

export class CreateFolderSuccessWSAction implements Action {
  readonly type = DocumentActionTypes.CREATE_FOLDER_SUCCESS_WS;
  message = new Message('MSG_FOLDER_CREATED');

  constructor(public payload: DocNode) {}
}

export class UpdateFolderSuccessWSAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_FOLDER_SUCCESS_WS;
  message = new Message('MSG_FOLDER_UPDATED');

  constructor(public payload: DocNode) {}
}

export class DeleteFolderSuccessWSAction implements Action {
  readonly type = DocumentActionTypes.DELETE_FOLDER_SUCCESS_WS;
  message = new Message('MSG_FOLDER_DELETED');

  constructor(public payload: DocNode) {}
}
// ws failures

export class UploadWSDocumentsFailureAction implements Action {
  readonly type = DocumentActionTypes.UPLOAD_DOCUMENTS_WS_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateWSDocumentFailureAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_DOCUMENT_WS_FAILURE;

  constructor(public payload: any) {}
}

export class DeleteWSDocumentFailureAction implements Action {
  readonly type = DocumentActionTypes.DELETE_DOCUMENT_WS_FAILURE;

  constructor(public payload: DocNode) {}
}

export class CreateFolderFailureWSAction implements Action {
  readonly type = DocumentActionTypes.CREATE_FOLDER_FAILURE_WS;

  constructor(public payload: DocNode) {}
}

export class UpdateFolderFailureWSAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_FOLDER_FAILURE_WS;

  constructor(public payload: DocNode) {}
}

export class DeleteFolderFailureWSAction implements Action {
  readonly type = DocumentActionTypes.DELETE_FOLDER_FAILURE_WS;

  constructor(public payload: DocNode) {}
}

export class PreUploadDocumentAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT;
  message = new Message('MSG_FILE_UPLOADING');

  constructor(public payload: { data: PreUploadPayload; file: File }) {}
}

export class UpdatePreUploadDocumentAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_UPDATE;

  constructor(
    public payload: { tkn: string; toUpdate: Partial<PreUploadState> }
  ) {}
}

export class PreUploadDocumentSucessAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_SUCCESS;

  constructor(public payload: { res: PreUploadTokenRes; file: File }) {}
}

export class PreUploadDocumentFailureAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_FAILURE;

  constructor(public payload?: any) {}
}

export class DeletePreUploadDocumentAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_DELETE;

  constructor(public payload: string) {}
}

export class DeletePreUploadAllByIdsDocumentAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_DELETE_ALL_BY_IDS;

  constructor(public payload: string[]) {}
}

export class DeletePreUploadAllByRefDocumentAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_DELETE_ALL_BY_REF;

  constructor(public payload: string) {}
}

export class DeletePreUploadDocumentSuccessAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_DELETE_SUCCESS;

  constructor(public payload?: string) {}
}

export class DeletePreUploadDocumentFailureAction implements Action {
  readonly type = DocumentActionTypes.PREUPLOAD_DOCUMENT_DELETE_FAILURE;
  message = new Message('MSG_FILE_UPLOADING');

  constructor(public payload?: any) {}
}

export class UploadDocumentsAction implements Action {
  readonly type = DocumentActionTypes.UPLOAD_DOCUMENTS;
  message = new Message('MSG_FILE_UPLOADING');

  constructor(public payload: { data: FormData; attr?: DocumentType }) {}
}

export class UploadDocumentsSuccessAction implements Action {
  readonly type = DocumentActionTypes.UPLOAD_DOCUMENTS_SUCCESS;
  message = new Message('MSG_FILE_UPLOADED');

  constructor(public payload: DocNode[]) {}
}

export class UploadDocumentsFailureAction implements Action {
  readonly type = DocumentActionTypes.UPLOAD_DOCUMENTS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetEntityDocumentsAction implements Action {
  readonly type = DocumentActionTypes.GET_ENTITY_DOCUMENTS;

  constructor(
    public payload?: { refId: string; type?: DocumentType; _v?: string }
  ) {}
}

export class GetItemDocumentsAction implements Action {
  readonly type = DocumentActionTypes.GET_ITEM_DOCUMENTS;

  constructor(public payload?: { refId: string; type?: DocumentType }) {}
}

export class GetEntityDocumentsSuccessAction implements Action {
  readonly type = DocumentActionTypes.GET_ENTITY_DOCUMENTS_SUCCESS;

  constructor(public payload: DocNode[]) {}
}

export class GetCreatorLogosAction implements Action {
  readonly type = DocumentActionTypes.GET_LOGOS;

  constructor(public payload?: any) {}
}

export class GetCreatorLogosSuccessAction implements Action {
  readonly type = DocumentActionTypes.GET_LOGOS_SUCCESS;

  constructor(public payload: string[]) {}
}

export class GetEntityDocumentsFailureAction implements Action {
  readonly type = DocumentActionTypes.GET_ENTITY_DOCUMENTS_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateCompanyLogoAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_COMPANY_LOGO;
  message = new Message('GNR_LGO_BEING_CREATED');

  constructor(public payload: any) {}
}

export class UpdateCompanyLogoSuccessAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_COMPANY_LOGO_SUCCESS;
  message = new Message('GNR_LGO_SAVED');

  constructor(public payload: DocNode) {}
}

export class UpdateCompanyLogoFailureAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_COMPANY_LOGO_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdatePersonalAvatarAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_PERSONAL_AVATAR;
  message = new Message('GNR_AVATAR_BEING_CREATED');

  constructor(public payload: any) {}
}

export class UpdatePersonalAvatarSuccessAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_PERSONAL_AVATAR_SUCCESS;
  message = new Message('GNR_AVATAR_SAVED');

  constructor(public payload: DocNode) {}
}

export class UpdatePersonalAvatarFailureAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_PERSONAL_AVATAR_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteDocumentAction implements Action {
  readonly type = DocumentActionTypes.DELETE_DOCUMENT;

  constructor(
    public payload: { docId: string; attr: DocumentType; refId: string }
  ) {}
}

export class DeleteDocumentSuccessAction implements Action {
  readonly type = DocumentActionTypes.DELETE_DOCUMENT_SUCCESS;
  message = new Message('MSG_FILE_DELETED');

  constructor(public payload: { docId: string; attr: DocumentType }) {}
}

export class DeleteDocumentFailureAction implements Action {
  readonly type = DocumentActionTypes.DELETE_DOCUMENT_FAILURE;

  constructor(public payload?: any) {}
}

export class BulkDeleteAction implements Action {
  readonly type = DocumentActionTypes.BULK_DELETE;

  constructor(public payload?: any[]) {}
}

export class BulkDeleteSuccessAction implements Action {
  readonly type = DocumentActionTypes.BULK_DELETE_SUCCESS;

  constructor(public payload: string[]) {}
}

export class BulkDeleteFailureAction implements Action {
  readonly type = DocumentActionTypes.BULK_DELETE_FAILURE;

  constructor() {}
}

export class BulkDownloadAction implements Action {
  readonly type = DocumentActionTypes.BULK_DOWNLOAD;

  constructor(public payload?: any[]) {}
}

export class BulkDownloadSuccessAction implements Action {
  readonly type = DocumentActionTypes.BULK_DOWNLOAD_SUCCESS;

  constructor(public payload: string[]) {}
}

export class BulkDownloadFailureAction implements Action {
  readonly type = DocumentActionTypes.BULK_DOWNLOAD_FAILURE;

  constructor() {}
}

export class SendDocumentAction implements Action {
  readonly type = DocumentActionTypes.SEND_DOCUMENT;
  message = new Message('GNR_DOC_SENDING');

  constructor(public payload: { id: string; destination: string }) {}
}

export class SendDocumentSuccessAction implements Action {
  readonly type = DocumentActionTypes.SEND_DOCUMENT_SUCCESS;

  constructor(public payload: { response: any; id: string }) {}
}

export class SendDocumentFailureAction implements Action {
  readonly type = DocumentActionTypes.SEND_DOCUMENT_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateDocumentDescriptionAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_DOCUMENT_DESC;
  message = new Message('GNR_DOC_SENDING');

  constructor(public payload: { id: string; description: string }) {}
}

export class UpdateDocumentDescriptionSuccessAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_DOCUMENT_DESC_SUCCESS;

  constructor(public payload: { id: string; description: string }) {}
}

export class UpdateDocumentDescriptionFailureAction implements Action {
  readonly type = DocumentActionTypes.UPDATE_DOCUMENT_DESC_FAILURE;

  constructor(public payload: any) {}
}

export type DocumentsAction =
  | DeleteWSDocumentAction
  | ConnectDocumentsListAction
  | DisconnectDocumentsListAction
  | GetEntityDocumentsWsAction
  | GetCreatorLogosAction
  | GetCreatorLogosSuccessAction
  | UpdateCompanyLogoAction
  | UpdateCompanyLogoSuccessAction
  | UpdateCompanyLogoFailureAction
  | UpdatePersonalAvatarAction
  | UpdatePersonalAvatarSuccessAction
  | UpdatePersonalAvatarFailureAction
  | UploadDocumentsAction
  | UploadDocumentsSuccessAction
  | UploadDocumentsFailureAction
  | GetEntityDocumentsAction
  | GetEntityDocumentsSuccessAction
  | DeleteDocumentAction
  | DeleteDocumentSuccessAction
  | DeleteDocumentFailureAction
  | GetEntityDocumentsFailureAction
  | SendDocumentAction
  | SendDocumentSuccessAction
  | GetWSEntityDocumentsSuccessAction
  | UploadWSDocumentsSuccessAction
  | UploadWSDocumentsFailureAction
  | DeleteWSDocumentSuccessAction
  | DeleteWSDocumentFailureAction
  | UpdateWSDocumentAction
  | UpdateWSDocumentSuccessAction
  | UpdateWSDocumentFailureAction
  | CreateFolderSuccessWSAction
  | CreateFolderFailureWSAction
  | UpdateFolderSuccessWSAction
  | UpdateFolderFailureWSAction
  | DeleteFolderSuccessWSAction
  | DeleteFolderFailureWSAction
  | SendDocumentFailureAction
  | BulkDeleteAction
  | BulkDeleteSuccessAction
  | BulkDeleteFailureAction
  | PreUploadDocumentAction
  | PreUploadDocumentSucessAction
  | PreUploadDocumentFailureAction
  | DeletePreUploadDocumentAction
  | DeletePreUploadAllByRefDocumentAction
  | DeletePreUploadDocumentSuccessAction
  | DeletePreUploadDocumentFailureAction
  | UpdatePreUploadDocumentAction
  | DeletePreUploadAllByIdsDocumentAction;
