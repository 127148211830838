import { inject } from '@angular/core';
import {
  CanActivateFn,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter, take, tap } from 'rxjs';
import { PermissionType } from '../public-api';
import { PermissionsService } from '../services/permissions.service';

let isFirstNavigation = true;

export const PermissionGuard: CanActivateFn = (
  _,
  { url }: RouterStateSnapshot
) => {
  const router = inject(Router);
  const permissionsService = inject(PermissionsService);

  if (isFirstNavigation) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        isFirstNavigation = false;
      });
  }

  return permissionsService.isAllowed$(url, PermissionType.View).pipe(
    tap(isAllowed => {
      if (isAllowed) {
        return;
      }
      if (isFirstNavigation) {
        permissionsService.getFirstMenuRoute().subscribe(route => {
          router.navigate([route]);
        });
      } else {
        router.navigate(['prohibited']);
      }
    })
  );
};
