import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  CountryCode,
  Frequency,
  LanguageOption,
  RelationGroup,
  Role,
  Timezone,
  Unit,
} from '@qtek/shared/models';
import { extractKeys } from '@qtek/shared/utils';
import { MetaActions } from './meta.actions';

export interface MetaState {
  roles: {
    user: Role[];
    vendor: Role[];
    customer: Role[];
    staff: Role[];
  };
  units: Unit[];
  frequencies: Frequency[];
  languages: LanguageOption[];
  mobileTitle: string;
  title: string;
  countryCode: CountryCode[] | null;
  guiShrDomain: string;
  guiDomain: string;
  timezones: Timezone[] | null;
  guiOnlineBookDomain: string;
  wsPingPong: number;
  curs: string[] | null;
  licenseCmpId: string;
  countries: Array<{ id: string; nm: string }>;
  // TODO add interface
  countryInfo: any;
  states: {
    [key: string]: string[];
  };
  licenseWebId: string;
  token: string | null;
  darkTheme: boolean;
  powerSearchMeta: Record<string, any>;
}

const initialState: MetaState = {
  roles: {
    user: [],
    vendor: [],
    customer: [],
    staff: [],
  },
  units: [],
  frequencies: [],
  languages: [],
  mobileTitle: '',
  title: '',
  countryCode: null,
  guiShrDomain: '',
  guiDomain: '',
  timezones: null,
  curs: null,
  guiOnlineBookDomain: '',
  wsPingPong: null,
  licenseCmpId: '',
  licenseWebId: '',
  countries: [],
  countryInfo: null,
  states: null,
  token: null,
  darkTheme: false,
  powerSearchMeta: {},
};

const metaReducer = createReducer(
  initialState,
  on(MetaActions.loadMetaSuccess, (state, { payload }) => ({
    ...state,
    ...(payload.unts && { units: extractKeys(payload.unts) as Unit[] }),
    ...(payload.frq && {
      frequencies: extractKeys(payload.frq) as Frequency[],
    }),
    ...(payload.ptlRoleGroups && {
      roles: [
        { id: RelationGroup.USER, name: 'user' },
        { id: RelationGroup.VENDOR, name: 'vendor' },
        { id: RelationGroup.CUSTOMER, name: 'customer' },
        { id: RelationGroup.STAFF, name: 'staff' },
      ].reduce((o: any, role) => {
        o[role.name] = Object.keys(
          payload.ptlRoleGroups[role.id].roles || {}
        ).map(id => ({
          id,
          name: payload.ptlRoleGroups[role.id].roles[id],
        }));

        return o;
      }, {}),
    }),
    ...(payload.lngs && {
      languages: extractKeys(payload.lngs) as LanguageOption[],
    }),
    ...(payload.ptl && {
      mobileTitle: payload.ptl.title1,
      title: payload.ptl.title2,
      guiDomain: payload.ptl.guiDomain,
      guiOnlineBookDomain: payload.ptl.guiOnlineBookDomain,
      guiShrDomain: payload.ptl.guiShrDomain,
    }),
    ...(payload.phns &&
      payload.phns.cntrs && {
        countryCode: payload.phns.cntrs,
      }),
    ...(payload.tzs && {
      timezones: Object.keys(payload.tzs).map(key => ({
        timezone: key,
        names: payload.tzs[key],
      })),
    }),
    ...(payload.wsPingPong && {
      wsPingPong: payload.wsPingPong,
    }),
    ...(payload.curs && {
      curs: payload.curs,
    }),
    ...(payload.licCmp && {
      licenseCmpId: payload.licCmp,
    }),
    ...(payload.licWebId && {
      licenseWebId: payload.licWebId,
    }),
    ...(payload.adr && {
      adr: payload.adr.stateForCountry,
    }),
  })),
  on(MetaActions.loadMetaCountriesSuccess, (state, { payload }) => ({
    ...state,
    countries: payload || [],
  })),
  on(MetaActions.loadMetaTokenSuccess, (state, { payload }) => ({
    ...state,
    token: payload,
  })),
  on(MetaActions.loadMetaCountrySuccess, (state, { payload }) => ({
    ...state,
    countryInfo: payload,
  })),
  on(MetaActions.setMetaUiTheme, (state, { payload }) => ({
    ...state,
    darkTheme: payload.isDarkTheme,
  })),
  on(MetaActions.loadMetaUiThemeSuccess, (state, { payload }) => ({
    ...state,
    darkTheme: payload,
  })),
  on(MetaActions.updateMetaPowerSearchDataSuccess, (state, { payload }) => ({
    ...state,
    powerSearchMeta: {
      ...state.powerSearchMeta,
      [payload.mid]: payload.data || '{}',
    },
  }))
);

export const MetaFeature = createFeature({
  name: 'meta',
  reducer: metaReducer,
  extraSelectors: ({ selectMetaState }) => ({
    selectUserRole: createSelector(
      selectMetaState,
      (state: MetaState) => state.roles.user
    ),
    selectVendorRole: createSelector(
      selectMetaState,
      (state: MetaState) => state.roles.vendor
    ),
    selectCustomerRole: createSelector(
      selectMetaState,
      (state: MetaState) => state.roles.customer
    ),
    selectStaffRole: createSelector(
      selectMetaState,
      (state: MetaState) => state.roles.staff
    ),
    selectAllRoles: createSelector(selectMetaState, (state: MetaState) => [
      ...state.roles.user,
      ...state.roles.vendor,
      ...state.roles.staff,
      ...state.roles.customer,
    ]),
    getPSMetaByMID: (mid: string) =>
      createSelector(
        createSelector(
          selectMetaState,
          (state: MetaState) => state.powerSearchMeta
        ),
        psMeta => psMeta[mid]
      ),
  }),
});

export const {
  selectUnits,
  selectFrequencies,
  selectLanguages,
  selectMobileTitle,
  selectTitle,
  selectCountryCode,
  selectGuiDomain,
  selectGuiShrDomain,
  selectTimezones,
  selectGuiOnlineBookDomain,
  selectWsPingPong,
  selectCurs,
  selectAllRoles,
  selectLicenseCmpId,
  selectLicenseWebId,
  selectCountries,
  selectCountryInfo,
  selectStates,
  selectToken,
  selectDarkTheme,
  selectPowerSearchMeta,
  selectUserRole,
  selectVendorRole,
  selectCustomerRole,
  selectStaffRole,
  getPSMetaByMID,
} = MetaFeature;
