import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTP_BASE_URL } from '@qtek/shared/models';
import { QtHttpInterceptor } from '../qtek-interceptor';

@Injectable()
export class BaseUrlInterceptor implements QtHttpInterceptor {
  readonly qtek = true;
  private absolute = new RegExp('^(?:[a-z]+:)?//', 'i');

  constructor(@Inject(HTTP_BASE_URL) private baseUrl: string) {}

  /**
   * Intercept relative requests and prepend baseUrl to request's url.
   *
   * Absolute requests are passed down as is.
   *
   * @param req Request
   * @param next Next interceptor in chain or backend.
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isAbsoluteUrl(req.url)) {
      const url = this.baseUrl + req.url;
      const newReq = req.clone({
        url,
      });

      return next.handle(newReq);
    }

    return next.handle(req);
  }

  private isAbsoluteUrl(url: string): boolean {
    return this.absolute.test(url);
  }
}
