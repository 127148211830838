<mat-toolbar
  class="mat-toolbar--small"
  [class.mat-toolbar--border]="toolbarConfig?.showBorder">
  <button
    mat-icon-button
    *ngIf="!mini && !toolbarConfig?.showBack"
    (click)="toggle.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  <a
    mat-icon-button
    *ngIf="toolbarConfig?.showBack"
    [routerLink]="toolbarConfig?.url"
    [matTooltip]="'CMD_GO_BACK' | translate"
    matTooltipPosition="right">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="end center"
    class="toolbar-content-wrapper">
    <ng-template [ngTemplateOutlet]="toolbarConfig?.template"></ng-template>
    <qt-avatar-menu
      *ngIf="media.isActive('gt-xs')"
      isHiddenUserName="true"></qt-avatar-menu>
  </div>
</mat-toolbar>
