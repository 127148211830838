import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { firstValueFrom, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';

import { HTTP_BASE_URL } from '@qtek/core/api-core';
import { LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { Message } from '@qtek/shared/models';
import { QtHttpInterceptor } from '../qtek-interceptor';

@Injectable()
export class ErrorHandlerInterceptor implements QtHttpInterceptor, OnDestroy {
  readonly qtek = true;
  private isErrorPath$: Observable<boolean>;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(HTTP_BASE_URL) private baseUrl: string,
    private matDialog: MatDialog,
    private router: Router,
    private dialogService: LazyDialogLoader
  ) {
    this.isErrorPath$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url.includes('/error')),
      shareReplay(1),
      takeUntil(this.destroy$)
    );
    this.isErrorPath$.subscribe();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Not a backend call, don't try to revalidate.
    // TODO(marcincichocki) use metadata instead of url when available
    if (
      !req.url.startsWith(this.baseUrl) ||
      req.url.includes('/api/v1/service/logout')
    ) {
      return next.handle(req);
    }

    // RRRR: Remove req
    return next
      .handle(req)
      .pipe(
        catchError((response: HttpErrorResponse) =>
          this.handleError(response, req)
        )
      );
  }

  handleError(response: HttpErrorResponse, restReq?: HttpRequest<any>) {
    console.log('HTTP ERROR: ', response.status, response);
    switch (response.status) {
      /**
       * We don't want to use {@link ErrorHandlerInterceptor#handleOtherStatusCode}
       * for 401 status codes.
       *
       * Revalidation is handled separately in {@link Revalidateinterceptor}.
       */
      case 401: {
        break;
      }

      case 426: {
        this.dialogService.open('selectPlan').subscribe();

        break;
      }

      case 500: {
        this.handle500StatusCode(response);

        break;
      }

      case 504:
      case 0: {
        this.handleNoConnection(response);
        break;
      }

      default: {
        this.handleOtherStatusCode(response);

        break;
      }
    }

    return throwError(response);
  }

  /**
   * Hadle 500 status code error specifically.
   */
  handle500StatusCode(response: HttpErrorResponse) {
    this.matDialog.closeAll();
    this.saveErrorToDataLayer(response);

    this.router.navigate(['error']);
  }

  async handleNoConnection(response: HttpErrorResponse) {
    this.saveErrorToDataLayer(response);
    const skipNotification = await firstValueFrom(this.isErrorPath$);

    // Skip error notification for error page
    if (skipNotification) {
      return;
    }

    this.matDialog.closeAll();

    this.router.navigate(['error', 'network']);
  }

  /**
   * Handle all other status codes.
   *
   * @param response Server response, can be used to gather error info.
   */
  handleOtherStatusCode(response: HttpErrorResponse) {
    this.saveErrorToDataLayer(response);

    const { sts } = response.error;
    const errors = Object.keys(sts && sts.attrs ? sts.attrs : {}).map(
      key => sts.attrs[key]
    );
    const main = errors.find(error => error.main) || {
      msg: 'ERR_DEFAULT_MSG',
    };
    const details = errors.filter(error => !error.main);

    this.dialogService
      .open('alert', {
        message: new Message(main.msg, main.prms),
        details: details.map(detail => new Message(detail.msg, detail.prms)),
      })
      .subscribe();
  }

  /**
   * Send error to google tag manager
   *
   */
  saveErrorToDataLayer(response: HttpErrorResponse) {
    if ((<any>window).dataLayer) {
      (<any>window).dataLayer.push({
        event: 'error',
        event_category: 'http_error',
        event_value: response.error,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
