import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { KeyboardContainerComponent } from '../components';

export class MatKeyboardRef<T> {
  private _afterClosed: Subject<void> = new Subject();

  constructor(
    public instance: T,
    public containerInstance: KeyboardContainerComponent,
    private overlayRef: OverlayRef
  ) {
    containerInstance.onExit.subscribe(() => this._finishDismiss());
  }

  dismiss() {
    if (!this._afterClosed.closed) {
      this.containerInstance.exit();
    }
  }

  afterDismissed(): Observable<void> {
    return this._afterClosed.asObservable();
  }

  private _finishDismiss() {
    this.overlayRef.dispose();
    this._afterClosed.next();
    this._afterClosed.complete();
  }
}
