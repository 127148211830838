import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { TranslateCorePipe } from './pipes/translate-core.pipe';
import { TranslationCoreEffects } from './store/translation-core.effects';
import { TranslationCoreFeature } from './store/translation-core.reducer';

@NgModule({
  declarations: [TranslateCorePipe],
  imports: [CommonModule],
  providers: [
    provideEffects(TranslationCoreEffects),
    provideState(TranslationCoreFeature),
  ],
  exports: [TranslateCorePipe],
})
export class TranslationCoreModule {}
