<div
  class="icon-picker"
  #dialogPopup
  [hidden]="!show"
  [style.visibility]="this.hidden ? 'hidden' : 'visible'"
  [style.height.px]="ipHeight"
  [style.width.px]="ipWidth">
  <mat-form-field class="ip-search-input">
    <input
      #input
      matInput
      qtAutoFocus
      autocapitalize="none"
      type="text"
      [ngx-ip-text]="search"
      [value]="search"
      [placeholder]="ipPlaceHolder"
      (input)="setSearch(input)" />
  </mat-form-field>
  <cdk-virtual-scroll-viewport
    [itemSize]="10"
    class="icon-grid"
    [ngStyle]="{ 'max-height.px': ipMaxHeight }">
    <ng-container
      *cdkVirtualFor="
        let iconSet of icons | searchIcon: search | convertToRows: 5
      ">
      <button
        *ngFor="let icon of iconSet"
        mat-icon-button
        class="ip-button-icon {{ ipButtonStyleClass }}"
        type="button"
        title="{{ icon.name }}"
        [ngClass]="{ active: icon === selectedIcon }"
        (click)="selectIcon(icon)">
        <mat-icon
          *ngIf="icon.type === iconType.Material"
          class="material-icons">
          {{ icon.id }}
        </mat-icon>
      </button>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
