import { Injectable, Provider } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { QtIntl } from './intl';

@Injectable()
export class QtPaginatorIntl extends MatPaginatorIntl implements QtIntl {
  rangeLabelPreposition = 'of';

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.rangeLabelPreposition} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${
      this.rangeLabelPreposition
    } ${length}`;
  };

  updateTranslations(translations: any) {
    this.itemsPerPageLabel = translations['INTL_PAGIN_ITM_PPAGE'];
    this.nextPageLabel = translations['INTL_PAGIN_NEXT_P'];
    this.previousPageLabel = translations['INTL_PAGIN_PREV_P'];
    this.firstPageLabel = translations['INTL_PAGIN_FIRST_P'];
    this.lastPageLabel = translations['INTL_PAGIN_LAST_P'];
    this.rangeLabelPreposition = translations['INTL_PAGIN_RANGE'];

    this.changes.next();
  }

  getTranslations(): string[] {
    return [
      'INTL_PAGIN_ITM_PPAGE',
      'INTL_PAGIN_NEXT_P',
      'INTL_PAGIN_PREV_P',
      'INTL_PAGIN_FIRST_P',
      'INTL_PAGIN_LAST_P',
      'INTL_PAGIN_RANGE',
    ];
  }
}

export const INTL_PAGINATOR_PROVIDERS: Provider[] = [
  QtPaginatorIntl,
  {
    provide: MatPaginatorIntl,
    useExisting: QtPaginatorIntl,
  },
  {
    provide: QtIntl,
    useExisting: QtPaginatorIntl,
    multi: true,
  },
];
